import React from "react";
import { Radio, Input, Select, Row, Button, DatePicker } from "antd";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DateOptionUi = (props) => {
  if (
    (props.selectedChart === "team-line-chart" &&
      props.lineChartDisplay === "previous_dates") ||
    (props.selectedChart === "team-dates-total-chart" &&
      props.datesTotalDisplay === "previous_dates") ||
    (props.selectedChart === "single-stat-display" &&
      props.datesTotalDisplay === "previous_dates")
  ) {
    return (
      <div style={{ marginTop: "5px" }}>
        <Radio.Group
          value={props.radioOption}
          onChange={props.selectRadioOption}
        >
          {props.selectedChart === "team-dates-total-chart" ||
          props.selectedChart === "single-stat-display" ? (
            <Radio style={{ display: "block" }} value={"previous_day"}>
              Previous Day
            </Radio>
          ) : null}
          <Radio style={{ display: "block" }} value={"previous_week"}>
            Previous Week
          </Radio>
          <Radio style={{ display: "block" }} value={"previous_month"}>
            Previous Month
          </Radio>
          <Radio style={{ display: "block" }} value={"previous_year"}>
            Previous Year
          </Radio>
        </Radio.Group>
      </div>
    );
  } else if (
    (props.selectedChart === "team-line-chart" &&
      props.lineChartDisplay === "rolling_dates") ||
    (props.selectedChart === "team-dates-total-chart" &&
      props.datesTotalDisplay === "rolling_dates") ||
    (props.selectedChart === "single-stat-display" &&
      props.datesTotalDisplay === "rolling_dates")
  ) {
    return (
      <div style={{ marginTop: "5px" }}>
        <Row>
          <Input
            style={{ display: "inline-block", width: "50%" }}
            value={props.rollingValue}
            onChange={
              (event) => props.handleInputRollingValue(event.target.value)
              // this.setState({ rollingValue: event.target.value })
            }
          ></Input>
          <Select
            style={{ width: "50%" }}
            value={props.rollingUnit}
            onChange={props.handleSelectRollingUnit}
          >
            <Option value={"days"}>Days</Option>
            <Option value={"weeks"}>Weeks</Option>
            <Option value={"months"}>Months</Option>
          </Select>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <Button onClick={props.handleRollingValue}>Enter</Button>
          </div>
        </Row>
      </div>
    );
  } else if (
    (props.selectedChart === "team-line-chart" &&
      props.lineChartDisplay === "static_dates") ||
    (props.selectedChart === "team-dates-total-chart" &&
      props.datesTotalDisplay === "static_dates") ||
    (props.selectedChart === "single-stat-display" &&
      props.datesTotalDisplay === "static_dates")
  ) {
    return (
      <div style={{ marginTop: "5px" }}>
        <RangePicker
          format={"YYYY-MM-DD"}
          onChange={props.pickDateRange}
          value={
            props.staticStart
              ? [
                  moment(props.staticStart, "YYYY-MM-DD"),
                  moment(props.staticEnd, "YYYY-MM-DD"),
                ]
              : null
          }
        />
      </div>
    ); //value={[moment(props.staticStart, 'YYYY-MM-DD'), moment(props.staticEnd, 'YYYY-MM-DD')]}
  } else if (
    (props.selectedChart === "team-line-chart" &&
      props.lineChartDisplay === "current_dates") ||
    (props.selectedChart === "team-dates-total-chart" &&
      props.datesTotalDisplay === "current_dates") ||
    (props.selectedChart === "single-stat-display" &&
      props.datesTotalDisplay === "current_dates")
  ) {
    return (
      <div style={{ marginTop: "5px" }}>
        <Radio.Group
          value={props.curRadioOption}
          onChange={props.selectRadioOption}
        >
          {props.selectedChart === "team-dates-total-chart" ||
          props.selectedChart === "single-stat-display" ? (
            <Radio style={{ display: "block" }} value={"current_day"}>
              Current Day
            </Radio>
          ) : null}
          <Radio style={{ display: "block" }} value={"current_week"}>
            Current Week
          </Radio>
          <Radio style={{ display: "block" }} value={"current_month"}>
            Current Month
          </Radio>
          <Radio style={{ display: "block" }} value={"current_year"}>
            Current Year
          </Radio>
        </Radio.Group>
      </div>
    );
  }
};

export default DateOptionUi;
