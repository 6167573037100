import React from 'react';
import PrettyButton from '../../../../reusableComponents/standard/prettyButton/PrettyButton';
import classes from './helpDeskModal.module.scss';

interface HelpDeskModalProps {
  setHelpDeskModal: React.Dispatch<React.SetStateAction<boolean>>;
  recordingTopic: string;
}

const helpDeskModal = ({
  setHelpDeskModal,
  recordingTopic
}: HelpDeskModalProps) => {
  const handleClickModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleConnectHelpDesk = () => {
    window.open(
      'https://clubwealth.zoom.us/j/99195738734?pwd=MktmQWczZFN2YTBZVWJEM21DU3FmZz09'
    );
  };

  return (
    <div className={classes['help-desk-modal']} onClick={handleClickModal}>
      <div className={classes['modal__header']}>
        <h3>{recordingTopic}</h3>
        <div
          onClick={() => setHelpDeskModal(false)}
          className={classes['modal__close']}
        >
          ×
        </div>
      </div>

      <div className={classes['modal__body']}>
        You do not have access to this recording
        <PrettyButton onClick={handleConnectHelpDesk}>Client Care</PrettyButton>
      </div>
    </div>
  );
};

export default helpDeskModal;
