import DomainInfo from "./DomainInfo";
import axios from "axios";

var MediaHttpHandler = {};

MediaHttpHandler.get = async (path, params) => {
  const transport = axios.create({ withCredentials: true });
  return new Promise(async (resolve, reject) => {
    try {
      params.headers = { "authorization": localStorage.getItem("vidAuthorization") }
      const result = await transport.get(DomainInfo.mediaServerBaseUrl + path, {
        params: params,
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

MediaHttpHandler.post = async (path, body) => {
  const transport = axios.create({ withCredentials: true });
  return new Promise(async (resolve, reject) => {
    try {
      const result = await transport.post(
        DomainInfo.mediaServerBaseUrl + path,
        body,
        { headers: { "Authorization": localStorage.getItem("vidAuthorization") }}
      );
      resolve(result);
    } catch (error) {
      reject(error.response);
    }
  });
};

MediaHttpHandler.patch = async (path, body) => {
  const transport = axios.create({ withCredentials: true });
  return new Promise(async (resolve, reject) => {
    try {
      const result = await transport.post(
        DomainInfo.mediaServerBaseUrl + path,
        body,
                { headers: { "Authorization": localStorage.getItem("vidAuthorization") }}

      );
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

MediaHttpHandler.delete = async (path, params) => {
  const transport = axios.create({ withCredentials: true });
  return new Promise(async (resolve, reject) => {
    try {
      const result = await transport.delete(
        DomainInfo.mediaServerBaseUrl + path,
        {
          data: params,
          headers: { "Authorization": localStorage.getItem("vidAuthorization") }
        }
      );
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export default MediaHttpHandler;
