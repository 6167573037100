import { contactRoutes } from '../../api/contactRoutes/contactRoutes';
import { ProcessedContactValues } from '../../types/statTracker';
import { ContactSlice, GetFn, SetFn } from '../../types/zustandTypes';

export const createContactSlice = (set: SetFn, get: GetFn): ContactSlice => ({
  contacts: {},
  contactFields: [],
  crmContactId: 0,
  contactsApi: {
    getContacts: async (teamId: number) => {
      try {
        const contactInfos = await contactRoutes.getContacts(teamId);
        const collection: { [key: string]: ProcessedContactValues } = {};
        contactInfos.forEach((element: ProcessedContactValues) => {
          collection[element.id] = element;
        });
        set((state) => {
          state.contacts = collection;
        });
      } catch (error) {
        console.error(error);
      }
    },
    getContactFields: async () => {
      const contactFields = await contactRoutes.getContactFormFields();
      set((state) => {
        state.contactFields = contactFields;
      });
    },
    addContact: (contact) => {
      set((state) => {
        state.contacts = { ...state.contacts, [contact.id]: contact };
      });
    },
    setCRMContactId: (contactId) => {
      set({ crmContactId: contactId });
    }
  }
});
