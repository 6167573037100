import React, { useEffect, useState } from 'react';
import PrettyButton from '../../../../reusableComponents/standard/prettyButton/PrettyButton';
import classes from './recordingModal.module.scss';

import { Recording } from './Search';
import { getRequest } from '../../../../../helpers/httpHandler2';

interface RecordingModalProps {
  recordings: Recording;
  selectedOccId: number;
  setOpenRecordingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface RecordingUrl {
  videoUrl: string;
  audioUrl: string;
}

const RecordingModal = ({
  recordings,
  selectedOccId,
  setOpenRecordingModal
}: RecordingModalProps) => {
  const [recordingUrl, setRecodingUrl] = useState<RecordingUrl>();
  const [occurrenceId, setOccurrenceId] = useState<number>(selectedOccId);

  const handleCloseModal = () => {
    setOpenRecordingModal(false);
  };

  const getRecordingUrl = async (occurrenceId: number) => {
    const signedUrl = await getRequest('/api/conference/recordingSignedUrl', {
      occurrenceId: occurrenceId
    });

    setRecodingUrl(signedUrl);
    return;
  };

  useEffect(() => {
    getRecordingUrl(occurrenceId);
  }, [occurrenceId, recordings]);

  const preventCloseModal = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleSelectRecording = (occurrenceId: number) => {
    setOccurrenceId(occurrenceId);
  };

  return (
    <div className={classes['recording-modal']} onClick={preventCloseModal}>
      <div className={classes['modal__header']}>
        <h3> {recordings.topic}</h3>
        <div onClick={handleCloseModal}>×</div>
      </div>
      <div className={classes['modal__body']}>
        <div className={classes['body__media']}>
          {recordingUrl?.audioUrl && (
            <div className={classes['audio']}>
              <audio controls key={recordingUrl.audioUrl}>
                <source src={recordingUrl.audioUrl} type='audio/mp4'></source>
              </audio>
            </div>
          )}
          {recordingUrl?.videoUrl && (
            <div className={classes['body__video']}>
              <video
                className={classes['video']}
                src={recordingUrl.videoUrl}
                controls
              ></video>
            </div>
          )}
        </div>
        <div className={classes['body__date-list']}>
          <h4 className={classes['list__header']}>Date</h4>
          <div className={classes['list__body']}>
            {recordings.recordingsList.map((recording) => {
              return (
                <div
                  key={recording.date}
                  onClick={() => handleSelectRecording(recording.occurrenceId)}
                  className={
                    recording.occurrenceId === occurrenceId
                      ? classes['list__selected']
                      : classes['list__date']
                  }
                >
                  {recording.date}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={classes['modal__footer']}>
        <PrettyButton onClick={handleCloseModal}>CLOSE</PrettyButton>
      </div>
    </div>
  );
};

export default RecordingModal;
