import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import classes from './NavBar.module.scss';
import UserContext from '../../../../contexts/UserContext';
import ShoppingCartContext from '../../../../contexts/ShoppingCartContext';
import { FaRegBell, FaSearch, FaShoppingCart } from 'react-icons/fa';
import DesktopNavItems from './desktopNavItems/DesktopNavItems';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import ShoppingCart from './shoppingCart/ShoppingCart';
import Notifications from './notifications/Notifications';
import Search from './search/Search';
import { isMobile } from 'react-device-detect';
import NavItemsTray from './navItemsTray/NavItemsTray';
import { MenuToggle } from './menuToggle/MenuToggle';
import { getRequest } from '../../../../helpers/httpHandler2';
import socketHandler from '../../../../services/Socket';
import { useStore } from '../../../../store/useStore';

type Props = {
  element: any;
  ruleAccess: { [id: number]: boolean };
  editMode: boolean;
};

type NavData = {
  homeLogoUrl: string;
  navItems: NavItem[];
};

export type NavItem = {
  text: string;
  url: string;
  url_type: string;
  rule_set: number;
  dropDownItems: DropDownItem[];
};

export type DropDownItem = {
  text: string;
  url: string;
  url_type: string;
  rule_set: number;
};

export enum UtilityMode {
  NONE = 'none',
  SEARCH = 'search',
  NOTIFICATION = 'notification',
  SHOPPING_CART = 'shopping cart'
}

const NavBar = ({ element, ruleAccess, editMode }: Props) => {
  const navigate = useNavigate();
  const [hideDesktop, setHideDesktop] = useState<boolean>();
  const [showTray, setShowTray] = useState<boolean>();
  const { styles, details } = element;
  const { user_bundle } = useContext(UserContext);
  const { shopping_cart } = useContext(ShoppingCartContext);
  const navData: NavData = details;
  const desktopItemsRef = useRef<HTMLDivElement>(null);
  const centerAreaRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowSize();
  //const [utilityMode, setUtilityMode] = useState<UtilityMode>(UtilityMode.NONE);
  const utilityBoxRef = useRef<HTMLDivElement>(null);
  const navItemsRef = useRef<HTMLDivElement>(null);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const [utilityMode, setUtilityMode] = useStore((state) => [
    state.utilityMode,
    state.setUtilityMode
  ]);

  useEffect(() => {
    if (!desktopItemsRef.current || !centerAreaRef.current) return;
    const desktopItemsWidth =
      desktopItemsRef.current.getBoundingClientRect().width;
    const centerAreaWidth = centerAreaRef.current.getBoundingClientRect().width;

    if (desktopItemsWidth > centerAreaWidth) {
      if (!hideDesktop) {
        setHideDesktop(true);
      }
    } else if (hideDesktop) {
      setHideDesktop(false);
    }
  }, [hideDesktop, width]);

  const windowClick = useCallback(
    (event: MouseEvent) => {
      if (!event.target) return;
      if (utilityBoxRef.current) {
        if (!utilityBoxRef.current?.contains(event.target as Node)) {
          setUtilityMode(UtilityMode.NONE);
        }
      }
      if (navItemsRef.current) {
        if (!navItemsRef.current.contains(event.target as Node)) {
          setShowTray(false);
        }
      }
    },
    [setUtilityMode]
  );

  useEffect(() => {
    window.addEventListener('click', windowClick);
    return () => {
      window.removeEventListener('click', windowClick);
    };
  });

  const getUtility = () => {
    switch (utilityMode) {
      case UtilityMode.SHOPPING_CART:
        return <ShoppingCart setUtilityMode={setUtilityMode} />;
      case UtilityMode.NOTIFICATION:
        return <Notifications setNotificationCount={setNotificationCount} />;
      case UtilityMode.SEARCH:
        return <Search />;
      default:
        return <div>Error</div>;
    }
  };

  useEffect(() => {
    const getNotificationCount = async () => {
      try {
        const count = await getRequest('/api/notifications/count');
        setNotificationCount(count);
      } catch (error) {
        console.error('could not get notification count');
      }
    };
    getNotificationCount();
  }, []);

  useEffect(() => {
    const incrementNotificationCount = () => {
      setNotificationCount(notificationCount + 1);
    };
    socketHandler.getSocket().on('notification', incrementNotificationCount);

    return () => {
      socketHandler.getSocket().off('notification', incrementNotificationCount);
    };
  }, [notificationCount]);

  return (
    <div style={{ ...styles, verticalAlign: 'top' }}>
      <div className={classes.nav}>
        {(isMobile || hideDesktop) && (
          <motion.div animate={showTray ? 'open' : 'closed'}>
            <MenuToggle toggle={() => setShowTray(!showTray)} />
          </motion.div>
        )}
        {!isMobile && (
          <div
            className={`${classes.desktop} ${
              hideDesktop && classes['desktop--hidden']
            }`}
          >
            <div ref={desktopItemsRef} className={classes.desktop__items}>
              <DesktopNavItems
                items={navData.navItems}
                ruleAccess={ruleAccess}
                editMode={editMode}
              />
            </div>
          </div>
        )}
        <div className={classes.logo} onClick={() => navigate('/')}>
          <img alt='logo' src={navData.homeLogoUrl} />
        </div>
        <div ref={centerAreaRef} className={classes['center-area']} />
        <div className={classes['icons-group']}>
          <motion.div
            animate={{ y: [0, -5, 0] }}
            transition={{
              duration: 0.7,
              ease: 'easeOut',
              loop: Infinity,
              repeatDelay: 4
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (utilityMode === UtilityMode.SEARCH)
                setUtilityMode(UtilityMode.NONE);
              else setUtilityMode(UtilityMode.SEARCH);
            }}
            className={classes['icons-group__small']}
          >
            <FaSearch style={{ fontSize: '24px' }} />
          </motion.div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (utilityMode === UtilityMode.NOTIFICATION)
                setUtilityMode(UtilityMode.NONE);
              else setUtilityMode(UtilityMode.NOTIFICATION);
            }}
            className={classes['icons-group__small']}
          >
            <FaRegBell />
            {notificationCount > 0 && (
              <div className={classes['icon-count']}>{notificationCount}</div>
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (utilityMode === UtilityMode.SHOPPING_CART)
                setUtilityMode(UtilityMode.NONE);
              else setUtilityMode(UtilityMode.SHOPPING_CART);
            }}
            className={classes['icons-group__small']}
          >
            <FaShoppingCart />
            <div className={classes['icon-count']}>
              {shopping_cart.itemList.length}
            </div>
          </div>
          <div
            className={classes['icons-group__large']}
            onClick={() => navigate('/account/details')}
          >
            <img
              alt='profile'
              src={
                user_bundle.user.profile_pic_url ||
                'https://elasticbeanstalk-us-east-2-783675859554.s3.amazonaws.com/user-solid.svg'
              }
            />
            <div />
          </div>
        </div>
        <AnimatePresence>
          {showTray && (isMobile || hideDesktop) && (
            <motion.div
              ref={navItemsRef}
              initial={{ opacity: 0, x: '-300px' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '-300px' }}
              transition={{ type: 'tween' }}
              className={classes.tray}
            >
              <NavItemsTray
                closeTray={() => setShowTray(false)}
                items={navData.navItems}
              />
            </motion.div>
          )}
          {utilityMode !== UtilityMode.NONE && (
            <motion.div
              ref={utilityBoxRef}
              initial={{ opacity: 0, x: '300px' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '300px' }}
              transition={{ type: 'tween' }}
              className={classes['utility-box']}
            >
              <div className={classes['utility-box__content']}>
                {getUtility()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default NavBar;
