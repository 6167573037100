import moment from 'moment';
import httpHandler from './httpHandler';
var AdditionalStatHelper = {};

const getPreviousWorkday = (now) => {
  let workday = now;
  let day = workday.day();
  let diff = 1; // returns yesterday
  if (+day === 0 || +day === 1) {
    // is Sunday or Monday
    diff = day + 2; // returns Friday
  }
  return workday.subtract(diff, 'days');
};

AdditionalStatHelper.getDates = (
  selectedDate,
  selectedChart,
  rollingUnit,
  rollingValue,
  lineChartDisplay,
  datesTotalDisplay,
  staticStart,
  staticEnd,
  prevRadioOption,
  curRadioOption
) => {
  const today = moment(selectedDate);

  let dates;
  //7
  let days = 6;
  if (
    selectedChart === 'team-hybrid-bar-chart' ||
    selectedChart === 'team-total-column-chart'
  ) {
    dates = [
      selectedDate,
      getPreviousWorkday(moment(selectedDate)).format('YYYY-MM-DD')
    ];
  } else {
    if (rollingUnit === 'days') {
      days = rollingValue - 1;
    } else if (rollingUnit === 'weeks') {
      days = rollingValue * 7 - 1;
    } else if (rollingUnit === 'months') {
      days = rollingValue * 30 - 1;
    }
    if (
      (selectedChart === 'team-line-chart' &&
        lineChartDisplay !== 'previous_dates' &&
        lineChartDisplay !== 'static_dates' &&
        lineChartDisplay !== 'current_dates') ||
      (selectedChart === 'team-dates-total-chart' &&
        datesTotalDisplay !== 'previous_dates' &&
        datesTotalDisplay !== 'static_dates' &&
        datesTotalDisplay !== 'current_dates') ||
      (selectedChart === 'single-stat-display' &&
        datesTotalDisplay !== 'previous_dates' &&
        datesTotalDisplay !== 'static_dates' &&
        datesTotalDisplay !== 'current_dates')
    ) {
      const res = Array(days)
        .fill()
        .map(() => today.subtract(1, 'd').format('YYYY-MM-DD'));
      dates = [moment(selectedDate).format('YYYY-MM-DD'), ...res];
    } else {
      let startDate;
      let endDate;
      if (
        (selectedChart === 'team-line-chart' &&
          !staticStart &&
          !staticEnd &&
          lineChartDisplay !== 'static_dates') ||
        (selectedChart === 'team-dates-total-chart' &&
          !staticStart &&
          !staticEnd &&
          datesTotalDisplay !== 'static_dates') ||
        (selectedChart === 'single-stat-display' &&
          !staticStart &&
          !staticEnd &&
          datesTotalDisplay !== 'static_dates')
      ) {
        if (
          (selectedChart === 'team-line-chart' &&
            lineChartDisplay === 'previous_dates') ||
          (selectedChart === 'team-dates-total-chart' &&
            datesTotalDisplay === 'previous_dates') ||
          (selectedChart === 'single-stat-display' &&
            datesTotalDisplay === 'previous_dates')
        ) {
          if (prevRadioOption === 'previous_week') {
            startDate = moment(selectedDate)
              .subtract(1, 'weeks')
              .startOf('isoWeek')
              .format('YYYY-MM-DD');
            endDate = moment(selectedDate)
              .subtract(1, 'weeks')
              .endOf('isoWeek')
              .format('YYYY-MM-DD');
          } else if (prevRadioOption === 'previous_month') {
            startDate = moment(selectedDate)
              .subtract(1, 'month')
              .startOf('month')
              .format('YYYY-MM-DD');
            endDate = moment(selectedDate)
              .subtract(1, 'month')
              .endOf('month')
              .format('YYYY-MM-DD');
          } else if (prevRadioOption === 'previous_day') {
            startDate = moment(selectedDate)
              .subtract(1, 'days')
              .format('YYYY-MM-DD');
            endDate = moment(selectedDate)
              .subtract(1, 'days')
              .format('YYYY-MM-DD');
          } else if (prevRadioOption === 'previous_year') {
            startDate = moment(selectedDate)
              .subtract(1, 'years')
              .startOf('year')
              .format('YYYY-MM-DD');
            endDate = moment(selectedDate)
              .subtract(1, 'years')
              .endOf('year')
              .format('YYYY-MM-DD');
          }
        } else if (
          (selectedChart === 'team-line-chart' &&
            lineChartDisplay === 'current_dates') ||
          (selectedChart === 'team-dates-total-chart' &&
            datesTotalDisplay === 'current_dates') ||
          (selectedChart === 'single-stat-display' &&
            datesTotalDisplay === 'current_dates')
        ) {
          if (curRadioOption === 'current_day') {
            startDate = moment(selectedDate).format('YYYY-MM-DD');
            endDate = moment(selectedDate).format('YYYY-MM-DD');
          } else if (curRadioOption === 'current_week') {
            startDate = moment(selectedDate)
              .subtract(0, 'weeks')
              .startOf('isoWeek')
              .format('YYYY-MM-DD');
            endDate = moment(selectedDate)
              .subtract(0, 'weeks')
              .endOf('isoWeek')
              .format('YYYY-MM-DD');
          } else if (curRadioOption === 'current_month') {
            startDate = moment(selectedDate)
              .subtract(0, 'months')
              .startOf('month')
              .format('YYYY-MM-DD');
            endDate = moment(selectedDate)
              .subtract(0, 'months')
              .endOf('month')
              .format('YYYY-MM-DD');
          } else if (curRadioOption === 'current_year') {
            startDate = moment(selectedDate)
              .subtract(0, 'years')
              .startOf('year')
              .format('YYYY-MM-DD');
            endDate = moment(selectedDate)
              .subtract(0, 'years')
              .endOf('year')
              .format('YYYY-MM-DD');
          }
        }
        dates = getDateRange(startDate, endDate, 'days').reverse();
      } else {
        if (staticStart && staticEnd) {
        } else {
          const res = Array(6)
            .fill()
            .map(() => today.subtract(1, 'd').format('YYYY-MM-DD'));
          dates = [moment(selectedDate).format('YYYY-MM-DD'), ...res];
        }
      }
    }
  }
  return dates;
};

const getDateRange = (startDate, endDate, type) => {
  let fromDate = moment(startDate);
  let toDate = moment(endDate);
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, type).format('YYYY-MM-DD'));
  }
  range.push(endDate);
  return range;
};

AdditionalStatHelper.getFinalDatesTotalStats = (
  finalStats,
  squads,
  members,
  showTeamTotalForChart
) => {
  let result = [];
  if (finalStats) {
    for (let stat of finalStats) {
      if (
        stat.user_id &&
        members.includes(
          stat.first_name.split(' ')[0] +
            '_' +
            stat.last_name +
            '_' +
            stat.user_id
        )
      ) {
        result.push(stat);
      } else if (
        !stat.user_id &&
        stat.squad_id &&
        squads.includes(stat.first_name + '~~' + stat.squad_id)
      ) {
        result.push(stat);
      } else if (
        stat.team_id &&
        !stat.user_id &&
        !stat.squad_id &&
        showTeamTotalForChart.length > 0 &&
        showTeamTotalForChart.includes(stat.first_name + '~~' + stat.team_id)
      ) {
        result.push(stat);
      }
    }
  }
  return result;
};

AdditionalStatHelper.getProcessDatesTotalData = (
  data,
  membersTotalStats,
  teamTotalStats,
  squadTotalStats,
  squads
) => {
  for (let member of data) {
    let found = false;
    let stat = {
      added_to_facebook_group: 0,
      commission_dollars_received: 0,
      number_closed: 0,
      number_of_appointments_set: 0,
      number_of_contacts: 0,
      number_of_contracts_written: 0,
      number_of_follow_up_calls: 0,
      number_of_hours_doing_calls: 0,
      number_of_new_lead_calls: 0,
      first_name: null,
      last_name: null,
      team_id: null,
      team_leader_id: null,
      profile_pic_url: null,
      user_id: null
    };
    for (let memberTotal of membersTotalStats) {
      if (memberTotal.user_id === member.userId) {
        found = true;
        break;
      }
    }
    if (!found) {
      stat.user_id = member.userId;
      stat.first_name = member.first_name;
      stat.last_name = member.last_name;
      stat.team_id = member.team_id;
      stat.team_leader_id = member.team_leader_id;
      stat.profile_pic_url = member.profile_pic_url;
      membersTotalStats.push(stat);
    }
  }
  for (let i = 0; i < membersTotalStats.length; i++) {
    let stats = membersTotalStats[i];
    for (let key of Object.keys(stats)) {
      if (key === 'first_name') {
        stats[key] += ` (${i + 1})`;
      }
    }
  }
  if (teamTotalStats.length === 0) {
    let stat = {
      added_to_facebook_group: 0,
      commission_dollars_received: 0,
      number_closed: 0,
      number_of_appointments_set: 0,
      number_of_contacts: 0,
      number_of_contracts_written: 0,
      number_of_follow_up_calls: 0,
      number_of_hours_doing_calls: 0,
      number_of_new_lead_calls: 0,
      first_name: 'Team total'
    };
    teamTotalStats.push(stat);
  } else {
    teamTotalStats[0]['first_name'] = 'Team total';
  }
  squadTotalStats = squadTotalStats.concat(
    checkSquadDatesTotalStat(squadTotalStats, squads)
  );
};

AdditionalStatHelper.getfilterTotalDatesData = (
  finalData,
  squads,
  members,
  tempSquad,
  tempMembers
) => {
  // let tempSquad = [];
  // let tempMembers = [];
  for (let squad of squads) {
    for (let data of finalData) {
      if (parseInt(squad) === data.squad_id) {
        tempSquad.push(data);
        break;
      }
    }
  }
  for (let memberId of members) {
    for (let data of finalData) {
      if (memberId === 'team_total' && data.first_name === 'Team total') {
        tempMembers.push(data);
        break;
      } else if (parseInt(memberId) === data.user_id) {
        tempMembers.push(data);
        break;
      }
    }
  }
};

AdditionalStatHelper.checkSquadStanding = (
  curSquads,
  curData,
  selectedSortField
) => {
  let squadTotal = {};
  for (let i = 0; i < curData.length; i++) {
    let data = curData[i];
    if (!squadTotal[data.squad_id]) {
      squadTotal[data.squad_id] = data[selectedSortField];
    } else {
      squadTotal[data.squad_id] += data[selectedSortField];
    }
  }
  let temp = curData;
  let result = [];
  let squads = [...curSquads];
  squadTotal = Object.entries(squadTotal).sort((a, b) => b[1] - a[1]);
  for (let [key, value] of squadTotal) {
    for (let data of temp) {
      if (key === 'null' && !data.squad_id) {
        result.push(data);
      } else if (data.squad_id === parseInt(key)) {
        result.push(data);
      }
    }
    for (let squad of squads) {
      if (key === 'null' && !squad.id) {
        squad.squadSortTotal = value;
      } else if (squad.id === parseInt(key)) {
        squad.squadSortTotal = value;
      }
    }
  }
  let sameOrder = true;
  squads.sort((a, b) => b.squadSortTotal - a.squadSortTotal);
  for (let i = 0; i < squads.length; i++) {
    let squad = squads[i];
    if (curSquads && squad.id !== curSquads[i].id) {
      sameOrder = false;
      break;
    }
  }
  return sameOrder;
};

AdditionalStatHelper.saveChartConfig = async (
  curRadioOption,
  prevRadioOption,
  startDate,
  endDate,
  rollingDateType,
  rollingDateValue,
  selectedChart,
  selectedSortField,
  statGoal,
  showYesterday,
  stackedChartFields,
  selectedTeam,
  dataDisplay,
  squadView,
  lineChartDisplay,
  datesTotalDisplay,
  chartSquadSeries,
  chartMemberSeries
) => {
  let config = {
    chartType: selectedChart,
    sortByStat: selectedSortField ? selectedSortField : null,
    goal: parseInt(statGoal),
    showYesterday: showYesterday.length > 0 ? true : false,
    prevDatesType:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? prevRadioOption
        : null,
    startDate:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? startDate
        : null,
    endDate:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? endDate
        : null,
    rollingDateType:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? rollingDateType
        : null,
    rollingDateValue:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? rollingDateValue
        : null,
    stats: stackedChartFields ? stackedChartFields : [],
    teamId: selectedTeam.team_id,
    teamLeader: selectedTeam,
    dataDisplay: dataDisplay,
    viewSquad: squadView.length > 0,
    chartDatesDisplay:
      selectedChart === 'team-line-chart'
        ? lineChartDisplay
        : selectedChart === 'team-dates-total-chart'
        ? datesTotalDisplay
        : null,
    squadForDates:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? chartSquadSeries.join(',')
        : null,
    membersForDates:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? chartMemberSeries.join(',')
        : null,
    curDatesType:
      selectedChart === 'team-line-chart' ||
      selectedChart === 'team-dates-total-chart'
        ? curRadioOption
        : null
  };
  try {
    let result = await httpHandler.post('/api/st1/teamStats/saveChartConfig', {
      config: config
    });
    return result;
  } catch (error) {
    console.error('unable to set configuration', error);
  }
};

AdditionalStatHelper.saveChartOptionSettings = async (
  selectedTeam,
  statDisplayId,
  graph_name,
  selectedChart,
  showYesterday,
  selectedSortField,
  dataDisplay,
  lineChartDisplay,
  datesTotalDisplay,
  radioOption,
  rollingUnit,
  rollingValue,
  staticStart,
  staticEnd,
  statGoal,
  squadView,
  curRadioOption
) => {
  let settings = {
    teamId: selectedTeam.team_id,
    location: statDisplayId ? graph_name.split('_')[0] : graph_name,
    chartType: selectedChart,
    showYesterday: showYesterday.length > 0,
    sortByField: selectedSortField,
    dataDisplay: dataDisplay,
    dateType:
      selectedChart === 'team-line-chart'
        ? lineChartDisplay
        : datesTotalDisplay,
    prevDateOption: radioOption,
    curDateOption: curRadioOption,
    rollingUnit: rollingUnit,
    rollingValue: rollingValue,
    startDate: staticStart,
    endDate: staticEnd,
    statGoal: statGoal,
    viewSquad: squadView.length > 0,
    statDisplayId: statDisplayId
  };
  try {
    let result = await httpHandler.post(
      '/api/st1/customStats/updateChartOptionSettings',
      {
        settings: settings
      }
    );
    return result;
  } catch (error) {
    console.error('Fail to save chart option settings', error);
  }
};

AdditionalStatHelper.saveChartConfigSettings = async (
  selectedTeam,
  statDisplayId,
  graph_name,
  selectedChart,
  selectedSingleStatDataPoint,
  coachingDashboardData
) => {
  try {
    let result = await httpHandler.post(
      '/api/st1/customStats/saveChartConfigSettings',
      {
        teamId: selectedTeam.team_id,
        location: statDisplayId ? graph_name.split('_')[0] : graph_name,
        chartType: selectedChart,
        statDisplayId: statDisplayId,
        singleStatOption: selectedSingleStatDataPoint,
        coachingDashboardData
      }
    );
    return result;
  } catch (error) {
    console.error('Fail to save chart config settings', error);
  }
};

AdditionalStatHelper.handleSelectingStackedFields = async (
  field,
  checked,
  selectedTeam,
  statDisplayId,
  graph_name
) => {
  try {
    let result = await httpHandler.post(
      '/api/st1/customStats/updateSavedStackedFields',
      {
        teamId: selectedTeam.team_id,
        statDisplayId: statDisplayId,
        fieldId:
          field.split('_')[0] === 'Category'
            ? null
            : parseInt(field.split('_')[field.split('_').length - 1]),
        location: statDisplayId ? graph_name.split('_')[0] : graph_name,
        categoryId:
          field.split('_')[0] === 'Category'
            ? parseInt(field.split('_')[field.split('_').length - 1])
            : null,
        checked: checked
      }
    );

    return result;
  } catch (error) {
    console.error('Fail to save stacked fields', error);
  }
};

AdditionalStatHelper.getOptionsForStackedFields = (
  categoryOptions,
  selectedStatFields
) => {
  let options = [];
  categoryOptions.forEach((option, index) => {
    options.push({
      label: `Category ${option.name}`,
      value: `Category_${option.name.split(' ').join('_')}_${option.id}`
    });
  });
  for (let i = 0; i < selectedStatFields.length; i++) {
    let option = selectedStatFields[i];
    options.push({
      label: option.field_name,
      value: option.field_name.split(' ').join('_') + `_${option.field_id}`
    });
  }
  return options;
};

const checkSquadDatesTotalStat = (squadTotalStats, squads) => {
  // let squads = [...squads];
  let temp = [];
  for (let squad of squads) {
    let found = false;
    let stat = {
      added_to_facebook_group: 0,
      commission_dollars_received: 0,
      number_closed: 0,
      number_of_appointments_set: 0,
      number_of_contacts: 0,
      number_of_contracts_written: 0,
      number_of_follow_up_calls: 0,
      number_of_hours_doing_calls: 0,
      number_of_new_lead_calls: 0,
      first_name: null,
      squad_id: -1
    };
    for (let squadStats of squadTotalStats) {
      if (!squad.id && !squadStats.squad_id) {
        found = true;
        squadStats['first_name'] = 'No squad';
        squadStats.squad_id = -1;
        break;
      } else if (squad.id === squadStats.squad_id) {
        found = true;
        squadStats['first_name'] = squad.squad_name;
        break;
      }
    }
    if (!found) {
      if (!squad.id) {
        stat.first_name = 'No squad';
        // stat.squad_id = -1;
        temp.push(stat);
      } else {
        stat.first_name = squad.squad_name;
        stat.squad_id = squad.id;
        temp.push(stat);
      }
    }
  }
  return temp;
};

export default AdditionalStatHelper;
