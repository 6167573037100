import httpHandler from '../../helpers/httpHandler';

const fetchUserTasks = async (userId) => {
  try {
    const tasks = await httpHandler.get(
      `/api/board/widgetLists/tasks/${userId}`
    );
    return tasks;
  } catch (error) {
    console.error(`error fetching user tasks`, error);
  }
};

const fetchUserHomeworkCompletion = async (userId) => {
  try {
    const completionData = await httpHandler.get(
      '/api/board/widgetLists/getUserHomeworkCompletions',
      { userId }
    );
    return completionData;
  } catch (error) {
    console.error(`error fetching user hw completion`, error);
  }
};

const mapHwWithCompleted = (homework, completion) =>
  homework.map((currentHW) =>
    completion.some((currentData) => currentData.homework_id === currentHW.id)
      ? {
          ...currentHW,
          completed: 1
        }
      : {
          ...currentHW,
          completed: 0
        }
  );

const tasksApi = {
  fetchUserTasks,
  fetchUserHomeworkCompletion,
  mapHwWithCompleted
};
export default tasksApi;
