import httpHandler from '../../helpers/httpHandler';
import {
  Form,
  FormField,
  FormSelectOption,
  FormFieldAccess
} from '../../types/form';
import { FormFieldValue } from '../../types/statTracker';

interface FormData {
  [key: string]: string;
}

const postFormSubmission = async (
  formId: number,
  formValues: FormData,
  stageId: number,
  statFieldId: number,
  targetUserId: number,
  teamId: number,
  date: string,
  socketId: number
) => {
  const response = await httpHandler.post(`/api/form-submissions`, {
    formValues,
    stageId,
    statFieldId,
    targetUserId,
    teamId,
    date,
    socketId,
    formId
  });
  return response;
};

const moveFormSubmission = async (
  formId: number,
  formValues: FormData,
  formSubmissionId: number,
  stageId: number,
  statFieldId: number,
  targetUserId: number,
  teamId: number,
  date: string,
  socketId: number
) => {
  const response = await httpHandler.patch(
    `/api/form-submissions/${formSubmissionId}/pipeline-stages/${stageId}`,
    {
      formValues,
      statFieldId,
      targetUserId,
      teamId,
      date,
      socketId,
      formId
    }
  );
  return response;
};

const patchFormSubmission = async (
  formId: number,
  formValues: { [key: number]: string },
  formSubmissionId: number,
  teamId: number | undefined
) => {
  const response = await httpHandler.patch(
    `/api/form-submissions/${formSubmissionId}`,
    {
      formValues,
      teamId,
      formId
    }
  );
  return response;
};

const getForm = async (formId: number) => {
  const form: Promise<Form[]> = await httpHandler.get(`/api/forms/${formId}`);
  return form;
};

const getAllFormFields = async () => {
  const formFields: Promise<FormField[]> = await httpHandler.get(
    `/api/forms/form-fields`
  );
  return formFields;
};

const getTeamFormFields = async (formId: number) => {
  const teamFormFields = await httpHandler.get(
    `/api/forms/${formId}/form-fields`
  );
  return teamFormFields;
};

const getFormFieldGroups = async () => {
  const fieldGroups = await httpHandler.get(`/api/forms/form-fields/groups`);
  return fieldGroups;
};

const patchFormField = async (
  formId: number,
  teamId: number,
  requiredFormFields: { [key: string]: FormField },
  formFields: FormField[]
) => {
  const response = await httpHandler.patch(`/api/forms/${formId}`, {
    formFields,
    teamId,
    requiredFormFields
  });
  return response;
};

const toggleRequired = async (
  required: boolean,
  fieldId: number,
  formId: number
) => {
  await httpHandler.patch(`/api/forms/${formId}/form-fields/${fieldId}`, {
    required: required
  });
};

const getSelectOptions = async (fieldIds: number[]) => {
  const selectOptions: Promise<{ [key: string]: FormSelectOption[] }> =
    await httpHandler.get('/api/forms/form-fields/select-options', {
      fieldIds
    });
  return selectOptions;
};
// return an array instead of an object
// TODO: extract the server parsing logic and place it in the client
const getSelectOptionsArray = async (
  fieldIds: number[],
  noCollection: boolean
) => {
  const selectOptions: Promise<FormSelectOption[]> = await httpHandler.get(
    '/api/forms/form-fields/select-options',
    {
      fieldIds,
      noCollection
    }
  );
  return selectOptions;
};

const getTransactionsInStage = async (
  formId: number,
  stageId: number,
  userId: number,
  statFieldId: number | null,
  startDate?: string,
  endDate?: string
) => {
  const transactions = await httpHandler.get(
    `/api/form-submissions/pipeline-stages/${stageId}`,
    {
      startDate,
      endDate,
      statFieldId,
      userId,
      formId
    }
  );

  return transactions;
};

const moveTransactionBack = async (
  formSubmissionId: number,
  userId: number,
  startDate: string,
  endDate: string,
  date: string,
  socketId: number
) => {
  // this does not use the form id because automation is not tied to it
  const result = await httpHandler.delete(
    `/api/form-submissions/${formSubmissionId}/pipeline-stages`,
    {
      userId,
      startDate,
      endDate,
      date,
      socketId
    }
  );
  return result;
};

const getFormAccessibility = async (pipelineId: number) => {
  try {
    const result: FormFieldAccess[] = await httpHandler.get(
      `/api/pipelines/${pipelineId}/fields/accessibility`
    );
    return result;
  } catch (error) {
    console.error(error);
  }
};

const addPipelineFieldRoleViewAccess = async (
  pipelineId: number,
  fieldId: number,
  userRoleId: number
) => {
  await httpHandler.post(
    `/api/pipelines/${pipelineId}/fields/${fieldId}/view-access`,
    { userRoleId }
  );
};
const addPipelineFieldRoleEditAccess = async (
  pipelineId: number,
  fieldId: number,
  userRoleId: number
) => {
  await httpHandler.post(
    `/api/pipelines/${pipelineId}/fields/${fieldId}/edit-access`,
    { userRoleId }
  );
};
const deletePipelineFieldRoleViewAccess = async (
  pipelineId: number,
  fieldId: number,
  userRoleId: number
) => {
  await httpHandler.delete(
    `/api/pipelines/${pipelineId}/fields/${fieldId}/view-access`,
    { userRoleId }
  );
};
const deletePipelineFieldRoleEditAccess = async (
  pipelineId: number,
  fieldId: number,
  userRoleId: number
) => {
  await httpHandler.delete(
    `/api/pipelines/${pipelineId}/fields/${fieldId}/edit-access`,
    { userRoleId }
  );
};

const submitTransactionCsv = async (
  transactions: {
    [key: string]: string;
  }[],
  contactDetails: {
    [key: string]: string;
  }[],
  teamId: number,
  formId: number,
  pipelineId: number
) => {
  try {
    // prepare for contacts for bulk insertion on server
    const contactInsertions = [...Array(contactDetails.length)].map((frame) => [
      teamId
    ]);
    // prepare contact values for bulk insertion tuples do not work on current build
    // but type is [string, string][]
    let bulkInsertContactValues: any[] = [];
    let repeated = 0;
    contactDetails.forEach((contact, idx) => {
      if (idx === 0) {
        repeated = Object.keys(contact).length;
      }
      for (const entry of Object.entries(contact)) {
        bulkInsertContactValues.push(entry);
      }
    });

    const result = await httpHandler.post('/api/form-submissions/csv-upload', {
      transactions,
      contactDetails: bulkInsertContactValues,
      contactInsertions,
      formId,
      teamId,
      pipelineId,
      totalContactFields: repeated
    });
    return result;
  } catch (error) {
    console.error(error);
  }
};

const getFormFieldValues = async (formId: number, formFieldId: number) => {
  // get property values
  const values: Promise<FormFieldValue[]> = await httpHandler.get(
    `/api/forms/${formId}/form-fields/${formFieldId}/values`
  );
  return values;
};

export const transactionFormApi = {
  patchFormSubmission,
  getForm,
  getAllFormFields,
  getTeamFormFields,
  getFormFieldGroups,
  patchFormField,
  toggleRequired,
  getSelectOptions,
  getSelectOptionsArray,
  postFormSubmission,
  moveFormSubmission,
  getTransactionsInStage,
  moveTransactionBack,
  getFormAccessibility,
  addPipelineFieldRoleViewAccess,
  addPipelineFieldRoleEditAccess,
  deletePipelineFieldRoleViewAccess,
  deletePipelineFieldRoleEditAccess,
  submitTransactionCsv,
  getFormFieldValues
};
