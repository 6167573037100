import { notification } from 'antd';
import moment from 'moment';
import socketHandler from '../services/Socket';
import {
  CURRENT,
  PREVIOUS,
  ROLLING,
  SINGLESTATCHART,
  STATIC
} from './ChartConstants';
import DeepCopy from './DeepCopy';
import httpHandler from './httpHandler';
var StatTrackerHelper = {};

function getPreviousWorkday(now) {
  let workday = now;
  let day = workday.day();
  let diff = 1; // returns yesterday
  if (day === 0 || day === 1) {
    // is Sunday or Monday
    diff = day + 2; // returns Friday
  }
  return workday.subtract(diff, 'days');
}

StatTrackerHelper.subscribeToGraph = (team) => {
  if (team) {
    socketHandler.getSocket().on('connect', () => {
      socketHandler
        .getSocket()
        .emit(
          'subscribe',
          'team_' +
            team.first_name.replace(/ \(.*/, '') +
            '_' +
            team.last_name +
            '_' +
            team.team_id +
            '_room'
        );
    });
    socketHandler
      .getSocket()
      .emit(
        'subscribe',
        'team_' +
          team.first_name.replace(/ \(.*/, '') +
          '_' +
          team.last_name +
          '_' +
          team.team_id +
          '_room'
      );
  }
};

StatTrackerHelper.filterDataWithRoles = function (stats) {
  let curStats = stats[0];
  let roles = stats[1];
  let results = [];
  for (let stat of curStats) {
    if (stat.role && stat.role.split(',').length > 0) {
      for (let role of stat.role.split(',')) {
        if (roles.includes(role)) {
          results.push(stat);
          break;
        }
      }
    }
  }
  // console.log(curStats)
  // console.log(roles)
  // console.log(results);
  return results;
};
//parsing initial date
StatTrackerHelper.parseInitialData = function (stats) {
  // console.log(stats)
  // stats = stats[0]
  let finalData = { users: {}, teams: {}, squads: {} };
  for (const stat of stats) {
    if (!stat.category_id) {
      stat.category_id = 0;
      stat.category_name = 'no category';
    }
  }
  processUsers(finalData.users, stats);
  processTeam(finalData.teams, stats);
  processSquads(finalData.squads, stats);
  // console.log("final data", finalData);
  return finalData;
};

//processing stats for daily tracker
StatTrackerHelper.processDailyTrackerData = function (
  stats,
  fields,
  today,
  allMembers,
  categories
) {
  // console.log(stats)
  // console.log(fields)
  // console.log(allMembers)
  let yesterday = getPreviousWorkday(moment.utc(today)).format('YYYY-MM-DD');
  let finalData = [];
  let users = stats.users;
  let template = {
    first_name: null,
    last_name: null,
    profile_pic_url: null,
    date_today: today,
    date_yesterday: yesterday,
    user_id: null
  };
  populateFields(template, fields, true);
  fillCategories(template, categories, true);
  // console.log(template)
  for (let [userName, fields] of Object.entries(users)) {
    let temp = JSON.parse(JSON.stringify(template));
    temp.first_name = userName.split('_')[0] + ` (${userName.split('_')[2]})`;
    temp.last_name = userName.split('_')[1];
    temp.user_id = parseInt(userName.split('_')[2]);
    for (let [fieldName, fieldData] of Object.entries(fields.fields)) {
      if (fieldData.entries) {
        for (let entry of fieldData.entries) {
          let date = moment.utc(entry.date).format('YYYY-MM-DD');
          // let newName = '';
          // for (let i = 0; i < fieldName.split('_').length; i++) {
          //   if (!parseInt(fieldName.split('_')[i])) {
          //     newName += fieldName.split('_')[i];
          //     if (
          //       i + 1 < fieldName.split('_').length &&
          //       !parseInt(fieldName.split('_')[i + 1])
          //     )
          //       newName += '_';
          //   }
          // }
          if (date === today) {
            temp[fieldName] += entry.value;
            temp[
              `Category_${entry.categoryName.split(' ').join('_')}_${
                entry.categoryId
              }`
            ] += entry.value;
          }
          if (date === yesterday) {
            temp[fieldName + '_yesterday'] += entry.value;
            temp[
              `Category_${entry.categoryName.split(' ').join('_')}_${
                entry.categoryId
              }_yesterday`
            ] += entry.value;
          }
        }
      }
    }
    finalData.push(temp);
  }
  for (let member of allMembers) {
    for (let data of finalData) {
      if (data.user_id && data.user_id === member.user_id) {
        data.profile_pic_url = member.profile_pic_url;
        data.squad_id = member.squad_id;
        data['for_portrait'] = 0;
        break;
      }
    }
  }
  // addPortrait(finalData);
  // console.log(finalData)
  return finalData;
};

StatTrackerHelper.processDatesBarChartData = function (
  stats,
  dates,
  fields,
  allSquads,
  allMembers,
  categories
) {
  // console.log("dates total stats", stats);
  // console.log("dates", dates);
  // console.log("dates total fields", fields);
  // console.log(allSquads)
  let finalData = [];
  // let users = stats.users;
  let template = {
    first_name: null,
    last_name: null,
    profile_pic_url: null,
    user_id: null
  };
  populateFields(template, fields, false);
  fillCategories(template, categories, false);

  compileDatesChartDataForUsers(template, stats.users, finalData, allMembers);
  compileDatesChartDataForTeams(template, stats.teams, finalData);
  compileDatesChartDataForSquads(template, stats.squads, finalData, allSquads);
  // console.log("for dates bar chart", finalData)
  return finalData;
};

StatTrackerHelper.processDatesLineChartData = function (
  stats,
  dates,
  fields,
  allSquads,
  dataDisplay,
  unit,
  categories
) {
  // console.log("line chart fields", fields)

  let result = {};
  let missingStats = [];
  // let monthlyResult = [];
  // let weeklyResult = [];
  // let dailyResult = [];
  let finalResult = [];
  buildDailyStats(
    stats,
    dates,
    fields,
    allSquads,
    missingStats,
    result,
    categories
  );
  // console.log(result)
  // console.log(result)
  if (unit === 'monthly') {
    finalResult = buildMonthlyLineChartData(
      JSON.parse(JSON.stringify(result)),
      dates,
      fields,
      dataDisplay,
      categories
    );
  } else if (unit === 'weekly') {
    finalResult = buildWeeklyLineChartData(
      JSON.parse(JSON.stringify(result)),
      dates,
      fields,
      dataDisplay,
      categories
    );
  } else {
    finalResult = buildDailyLineChartData(
      JSON.parse(JSON.stringify(result)),
      dates,
      fields,
      dataDisplay
    );
  }
  // console.log("for line chart", finalResult)
  return finalResult;
};

StatTrackerHelper.processDailyTeamTotalData = function (
  stats,
  fields,
  date,
  selectedTeam,
  categories
) {
  let yesterday = getPreviousWorkday(moment.utc(date)).format('YYYY-MM-DD');
  let tempYesterday = { day: 'yesterday' };
  let tempToday = { day: 'today' };
  populateFields(tempYesterday, fields, false);
  fillCategories(tempYesterday, categories, false);
  populateFields(tempToday, fields, false);
  fillCategories(tempToday, categories, false);
  for (let [teamName, fields] of Object.entries(stats.teams)) {
    if (parseInt(teamName.split('~~')[1]) === selectedTeam.team_id) {
      for (let [fieldName, entry] of Object.entries(fields.fields)) {
        if (entry.entries) {
          for (let data of entry.entries) {
            if (data.date === date) {
              tempToday[fieldName] += data.value;
              tempToday[
                `Category_${data.categoryName.split(' ').join('_')}_${
                  data.categoryId
                }`
              ] += data.value;
            }
            if (data.date === yesterday) {
              tempYesterday[fieldName] += data.value;
              tempYesterday[
                `Category_${data.categoryName.split(' ').join('_')}_${
                  data.categoryId
                }`
              ] += data.value;
            }
          }
        }
      }
    }
  }
  // console.log([tempYesterday, tempToday]);
  return [tempYesterday, tempToday];
};

StatTrackerHelper.processSquadViewData = function (stats, squads, sortField) {
  let squadTotal = {};
  let orderSquads = JSON.parse(JSON.stringify(squads));
  let temp = [...stats];
  for (let i = 0; i < stats.length; i++) {
    let data = stats[i];
    if (!squadTotal[data.squad_id]) {
      // squadTotal[data.squad_id] = data["number_of_appointments_set"]
      squadTotal[data.squad_id] = data[sortField];
    } else {
      // squadTotal[data.squad_id] += data["number_of_appointments_set"];
      squadTotal[data.squad_id] += data[sortField];
    }
  }

  let result = [];
  squadTotal = Object.entries(squadTotal).sort((a, b) => b[1] - a[1]);
  for (let [key, value] of squadTotal) {
    // console.log(key)
    // console.log(value)
    for (let data of temp) {
      if (parseInt(key) === -1 && data.squad_id === parseInt(key)) {
        data.squad_name = 'No squad';
        result.push(data);
      } else if (data.squad_id === parseInt(key)) {
        let curSquad = orderSquads.filter(
          (squad) => squad.id === data.squad_id
        )[0];
        data.squad_name = curSquad.squad_name;
        result.push(data);
      }
    }
    for (let squad of orderSquads) {
      if (parseInt(key) === -1 && !squad.id) {
        squad.squadSortTotal = value;
        squad.id = -1;
        squad.squad_name = 'No squad';
      } else if (squad.id === parseInt(key)) {
        squad.squadSortTotal = value;
      }
    }
  }
  orderSquads = orderSquads
    ? orderSquads.sort((a, b) => b.squadSortTotal - a.squadSortTotal)
    : orderSquads;

  return { result: result, orderSquads: orderSquads };
};

StatTrackerHelper.processMetricsStats = function (
  stats,
  dates,
  metricsfields,
  allSquads,
  allMembers,
  metricsEntries,
  columns,
  categories,
  fields
) {
  let finalData = [];
  // let template = populateMetricsFields(fields);
  let template = {
    first_name: null,
    last_name: null,
    profile_pic_url: null,
    user_id: null
  };
  populateFields(template, fields, false);
  fillCategories(template, categories, false);
  compileDatesChartDataForUsers(template, stats.users, finalData, allMembers);
  compileDatesChartDataForTeams(template, stats.teams, finalData);
  compileDatesChartDataForSquads(template, stats.squads, finalData, allSquads);
  let metricStats = compileMetrics(finalData, metricsEntries, columns);
  return metricStats;
};

//helper functions
function processUsers(users, stats) {
  for (let stat of stats) {
    if (!users[`${stat.first_name}_${stat.last_name}_${stat.user_id}`]) {
      users[`${stat.first_name}_${stat.last_name}_${stat.user_id}`] = {
        fields: {}
      };

      if (stat.date) {
        users[`${stat.first_name}_${stat.last_name}_${stat.user_id}`].fields[
          `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
        ] = {
          entries: [
            {
              date: stat.date,
              value: stat.stat_value,
              categoryId: stat.category_id,
              categoryName: stat.category_name
            }
          ],
          total: 0
        };
      }
    } else {
      if (stat.date) {
        if (
          !users[`${stat.first_name}_${stat.last_name}_${stat.user_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ]
        ) {
          users[`${stat.first_name}_${stat.last_name}_${stat.user_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ] = {
            entries: [
              {
                date: stat.date,
                value: stat.stat_value,
                categoryId: stat.category_id,
                categoryName: stat.category_name
              }
            ],
            total: 0
          };
        } else {
          users[`${stat.first_name}_${stat.last_name}_${stat.user_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ].entries.push({
            date: stat.date,
            value: stat.stat_value,
            categoryId: stat.category_id,
            categoryName: stat.category_name
          });
        }
      }
    }
  }
  for (let fields of Object.values(users)) {
    for (let entries of Object.values(fields.fields)) {
      let total = 0;
      for (let entry of entries.entries) {
        total += entry.value;
      }
      entries.total = total;
    }
  }
}

function processTeam(teams, stats) {
  for (let stat of stats) {
    if (!teams[`${stat.team_name}~~${stat.team_id}`]) {
      teams[`${stat.team_name}~~${stat.team_id}`] = { fields: {} };
      if (stat.date) {
        teams[`${stat.team_name}~~${stat.team_id}`].fields[
          `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
        ] = {
          entries: [
            {
              date: stat.date,
              value: stat.stat_value,
              categoryId: stat.category_id,
              categoryName: stat.category_name
            }
          ],
          total: 0
        };
      }
    } else {
      if (stat.date) {
        if (
          !teams[`${stat.team_name}~~${stat.team_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ]
        ) {
          teams[`${stat.team_name}~~${stat.team_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ] = {
            entries: [
              {
                date: stat.date,
                value: stat.stat_value,
                categoryId: stat.category_id,
                categoryName: stat.category_name
              }
            ],
            total: 0
          };
        } else {
          teams[`${stat.team_name}~~${stat.team_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ].entries.push({
            date: stat.date,
            value: stat.stat_value,
            categoryId: stat.category_id,
            categoryName: stat.category_name
          });
        }
      }
    }
  }
  for (let fields of Object.values(teams)) {
    for (let entries of Object.values(fields.fields)) {
      let total = 0;
      for (let entry of entries.entries) {
        total += entry.value;
      }
      entries.total = total;
    }
  }
}

function processSquads(squads, stats) {
  for (let stat of stats) {
    if (!squads[`${stat.squad_name}~~${stat.squad_id}`]) {
      squads[`${stat.squad_name}~~${stat.squad_id}`] = { fields: {} };
      if (stat.date) {
        squads[`${stat.squad_name}~~${stat.squad_id}`].fields[
          `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
        ] = {
          entries: [
            {
              date: stat.date,
              value: stat.stat_value,
              categoryId: stat.category_id,
              categoryName: stat.category_name
            }
          ],
          total: 0
        };
      }
    } else {
      if (stat.date) {
        if (
          !squads[`${stat.squad_name}~~${stat.squad_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ]
        ) {
          squads[`${stat.squad_name}~~${stat.squad_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ] = {
            entries: [
              {
                date: stat.date,
                value: stat.stat_value,
                categoryId: stat.category_id,
                categoryName: stat.category_name
              }
            ],
            total: 0
          };
        } else {
          squads[`${stat.squad_name}~~${stat.squad_id}`].fields[
            `${stat.field_name.split(' ').join('_')}_${stat.field_id}`
          ].entries.push({
            date: stat.date,
            value: stat.stat_value,
            categoryId: stat.category_id,
            categoryName: stat.category_name
          });
        }
      }
    }
  }
  for (let fields of Object.values(squads)) {
    for (let entries of Object.values(fields.fields)) {
      let total = 0;
      for (let entry of entries.entries) {
        total += entry.value;
      }
      entries.total = total;
    }
  }
}
function populateFields(template, fields, onlyTodayAndYesterday) {
  for (let field of fields) {
    template[`${field.field_name.split(' ').join('_')}_${field.field_id}`] = 0;
    if (onlyTodayAndYesterday) {
      template[
        `${field.field_name.split(' ').join('_')}_${field.field_id}_yesterday`
      ] = 0;
    }
  }
}
function compileDatesChartDataForUsers(template, users, finalData, allMembers) {
  for (let [userName, fields] of Object.entries(users)) {
    let temp = JSON.parse(JSON.stringify(template));
    temp.first_name = userName.split('_')[0] + ` (${userName.split('_')[2]})`;
    temp.last_name = userName.split('_')[1];
    temp.user_id = parseInt(userName.split('_')[2]);
    for (let [fieldName, fieldData] of Object.entries(fields.fields)) {
      if (fieldData.entries) {
        for (let entry of fieldData.entries) {
          temp[fieldName] += entry.value;
          temp[
            `Category_${entry.categoryName.split(' ').join('_')}_${
              entry.categoryId
            }`
          ] += entry.value;
        }
      }
    }
    finalData.push(temp);
  }
  for (let member of allMembers) {
    for (let data of finalData) {
      if (data.user_id && data.user_id === member.user_id) {
        data.profile_pic_url = member.profile_pic_url;
        data.squad_id = member.squad_id;
        break;
      }
    }
  }
}
function compileDatesChartDataForTeams(template, teams, finalData) {
  for (let [teamName, fields] of Object.entries(teams)) {
    let temp = JSON.parse(JSON.stringify(template));
    temp.first_name = teamName.split('~~')[0];
    temp.team_id = parseInt(teamName.split('~~')[1]);
    for (let [fieldName, fieldData] of Object.entries(fields.fields)) {
      if (fieldData.entries) {
        for (let entry of fieldData.entries) {
          temp[fieldName] += entry.value;
          temp[
            `Category_${entry.categoryName.split(' ').join('_')}_${
              entry.categoryId
            }`
          ] += entry.value;
        }
      }
    }
    finalData.push(temp);
  }
  // console.log(finalData)
}
function compileDatesChartDataForSquads(
  template,
  squads,
  finalData,
  allSquads
) {
  for (let [squadName, fields] of Object.entries(squads)) {
    let temp = JSON.parse(JSON.stringify(template));
    temp.first_name = squadName.split('~~')[0];
    temp.squad_id = parseInt(squadName.split('~~')[1]);
    for (let [fieldName, fieldData] of Object.entries(fields.fields)) {
      if (fieldData.entries) {
        for (let entry of fieldData.entries) {
          temp[fieldName] += entry.value;
          temp[
            `Category_${entry.categoryName.split(' ').join('_')}_${
              entry.categoryId
            }`
          ] += entry.value;
        }
      }
    }
    finalData.push(temp);
  }
  let squadIds = finalData.map((entry) => entry.squad_id).filter((id) => id);
  for (let squad of allSquads) {
    let temp = JSON.parse(JSON.stringify(template));
    if (squad.id && !squadIds.includes(squad.id)) {
      temp.first_name = squad.squad_name;
      temp.squad_id = squad.id;
      finalData.push(temp);
    }
  }
  if (!squadIds.includes(-1)) {
    let temp = JSON.parse(JSON.stringify(template));
    temp.first_name = 'No squad';
    temp.squad_id = -1;
    finalData.push(temp);
  }
  // console.log(finalData)
}
function buildDailyStats(
  stats,
  dates,
  fields,
  allSquads,
  missingStats,
  result,
  categories
) {
  for (let date of dates) {
    let stat = {
      date: date
    };
    populateFields(stat, fields, false);
    missingStats.push(stat);
  }
  for (let [userName] of Object.entries(stats.users)) {
    result[userName] = [];
  }
  for (let [teamName] of Object.entries(stats.teams)) {
    result[teamName] = [];
  }
  for (let squad of allSquads) {
    if (squad.id) {
      result[`${squad.squad_name}~~${squad.id}`] = [];
    } else {
      result['No squad~~-1'] = [];
    }
  }

  // console.log(result)
  for (let [name, stats] of Object.entries(result)) {
    for (let stat of missingStats) {
      let temp = JSON.parse(JSON.stringify(stat));
      if (name.split('~~').length === 2) {
        temp.first_name = name.split('~~')[0];
        temp.id = parseInt(name.split('~~')[1]);
      } else {
        temp.first_name = name.split('_')[0];
        temp.last_name = name.split('_')[1];
        temp.user_id = parseInt(name.split('_')[2]);
      }
      fillCategories(temp, categories, false);
      stats.push(temp);
    }
  }
  // console.log(result)
  fillInDatesData(stats.users, result);
  fillInDatesData(stats.teams, result);
  fillInDatesData(stats.squads, result);
}
function fillInDatesData(initData, result) {
  // console.log(initData)
  for (let [name, fields] of Object.entries(initData)) {
    for (let [fieldName, entries] of Object.entries(fields.fields)) {
      // console.log(fieldName)
      for (let entry of entries.entries) {
        // console.log(name)
        // console.log(entry)
        for (let [dataName, statPoints] of Object.entries(result)) {
          // console.log(dataName)
          if (name === dataName) {
            // console.log(dataName)
            for (let stat of statPoints) {
              if (stat.date === moment.utc(entry.date).format('YYYY-MM-DD')) {
                stat[fieldName] += entry.value;
                stat[
                  `Category_${entry.categoryName.split(' ').join('_')}_${
                    entry.categoryId
                  }`
                ] += entry.value;
              }
            }
          }
        }
      }
    }
  }
  // console.log(result)
}
function buildMonthlyLineChartData(
  result,
  dates,
  fields,
  dataDisplay,
  categories
) {
  // console.log(result)
  let finalResult = [];
  let months = {};
  for (let date of dates) {
    let month = moment.utc(date).format('YYYY-MM');
    if (!months[month]) {
      let stat = { date: month };
      populateFields(stat, fields, false);
      fillCategories(stat, categories, false);
      months[month] = stat;
    }
  }
  // console.log(months);
  for (let monthData of Object.values(months)) {
    finalResult.push({
      date: monthData.date,
      month_number: monthData.date
    });
  }
  // console.log(finalResult)
  for (let [key, data] of Object.entries(result)) {
    //danny_tseng_1505: [{}, {}]
    let tempMonths = {};
    for (let [monthNum, monthData] of Object.entries(months)) {
      let stat = { date: monthData.date };
      populateFields(stat, fields, false);
      fillCategories(stat, categories, false);
      tempMonths[monthNum] = stat;
    }
    // console.log("temp month", tempMonths)
    for (let dateStat of data) {
      //{first_name: danny, ....}
      for (let [statKey, statValue] of Object.entries(dateStat)) {
        //first_name: danny
        if (statKey === 'date') {
          let month = moment.utc(statValue).format('YYYY-MM');
          // console.log(month)
          for (let [monthKey] of Object.entries(tempMonths[month])) {
            if (monthKey !== 'date' && dateStat[monthKey]) {
              // console.log(monthKey)
              tempMonths[month][monthKey] += dateStat[monthKey];
            }
          }
        }
      }
    }
    // console.log(tempMonths)
    // eslint-disable-next-line no-unused-vars
    for (let [tempKey, tempValue] of Object.entries(tempMonths)) {
      for (let finalData of finalResult) {
        for (let [finalKey, finalValue] of Object.entries(finalData)) {
          if (finalKey === 'month_number') {
            // tempWeeks[finalValue][this.state.dataDisplay];
            //Cold_call_sales_23
            finalData[key] = tempMonths[finalValue][dataDisplay];
          }
        }
      }
    }
  }
  // console.log(finalResult);
  return finalResult;
}
function buildWeeklyLineChartData(
  result,
  dates,
  fields,
  dataDisplay,
  categories
) {
  // console.log(dataDisplay)
  // console.log(result)
  let finalResult = [];
  let weeks = {};
  let dateToWeeks = {};
  for (let date of dates) {
    let stat = { date: date };
    populateFields(stat, fields, false);
    fillCategories(stat, categories, false);
    // console.log(stat)
    let weekNumber = moment.utc(date, 'YYYY-MM-DD').isoWeek();
    let year = moment.utc(date).startOf('isoWeek').format('YYYY');
    dateToWeeks[date] = `${year}_${weekNumber}`;
    if (!weeks[`${year}_${weekNumber}`]) {
      weeks[`${year}_${weekNumber}`] = [stat];
    } else {
      weeks[`${year}_${weekNumber}`].push(stat);
    }
  }

  for (let [number, dates] of Object.entries(weeks)) {
    if (dates.length > 1) {
      weeks[number].push(
        `${dates[dates.length - 1].date.split('-')[1]}/${
          dates[dates.length - 1].date.split('-')[2]
        } - ${dates[0].date.split('-')[1]}/${dates[0].date.split('-')[2]}`
      );
    } else {
      weeks[number].push(
        `${dates[0].date.split('-')[1]}/${dates[0].date.split('-')[2]}`
      );
    }
  }
  for (let [weekNum, weekDates] of Object.entries(weeks)) {
    finalResult.push({
      // date: weekDates[weekDates.length - 1],
      date: moment
        .utc(
          weekNum.split('_')[0] +
            '/' +
            weekDates[weekDates.length - 1].split('-')[0],
          'YYYY/MM/DD'
        )
        .format('YYYY-MM-DD'),
      week_number: weekNum
    });
  }
  for (let [key, data] of Object.entries(result)) {
    //danny_tseng_1505: [{}, {}]
    // console.log(key)
    let tempWeeks = {};
    for (let [weekNum, weekDates] of Object.entries(weeks)) {
      let stat = { date: weekDates[weekDates.length - 1] };
      populateFields(stat, fields, false);
      fillCategories(stat, categories, false);
      tempWeeks[weekNum] = stat;
      // console.log("tempWeeks", tempWeeks)
    }
    for (let dateStat of data) {
      //{first_name: danny, ....}
      for (let [statKey, statValue] of Object.entries(dateStat)) {
        //first_name: danny
        if (statKey === 'date') {
          let weekNumber = moment.utc(statValue, 'YYYY-MM-DD').isoWeek();
          let year = moment.utc(statValue).startOf('isoWeek').format('YYYY');
          let yearWeek = `${year}_${weekNumber}`;
          for (let [weekKey] of Object.entries(tempWeeks[yearWeek])) {
            //49: {}
            if (weekKey !== 'date' && dateStat[weekKey]) {
              tempWeeks[yearWeek][weekKey] += dateStat[weekKey];
            }
          }
        }
      }
    }

    // eslint-disable-next-line no-unused-vars
    for (let [tempKey, tempValue] of Object.entries(tempWeeks)) {
      for (let finalData of finalResult) {
        for (let [finalKey, finalValue] of Object.entries(finalData)) {
          if (finalKey === 'week_number') {
            finalData[key] = tempWeeks[finalValue][dataDisplay];
          }
        }
      }
    }
  }
  return finalResult;
  // console.log(finalResult)
}
function buildDailyLineChartData(result, dates, fields, dataDisplay) {
  // console.log(result)
  let finalResult = [];
  for (let date of dates) {
    let lineData = {};
    lineData['date'] = date;
    for (let [key, stats] of Object.entries(result)) {
      for (let stat of stats) {
        if (stat.date === date) {
          lineData[key] = stat[dataDisplay];
        }
      }
    }
    finalResult.push(lineData);
  }
  // console.log(finalResult);
  return finalResult;
}

function compileMetrics(stats, entries, columns) {
  // console.log(stats);
  // console.log(entries)
  // console.log(columns)
  let finalStats = [];

  let dic = {};
  // console.log(entries)
  for (let entry of entries) {
    if (!dic[entry.name + '_' + entry.metrics_id]) {
      dic[entry.name + '_' + entry.metrics_id] = [entry];
    } else {
      dic[entry.name + '_' + entry.metrics_id].push(entry);
    }
  }
  for (let [metricsName, fields] of Object.entries(dic)) {
    let numerators = [];
    let denominators = [];
    for (let data of fields) {
      // console.log(data)
      if (data.type === 'numerator') {
        numerators.push(data);
      } else {
        denominators.push(data);
      }
    }
    let numeratorString = numerators
      .map((num) =>
        num.category_id === -1
          ? num.field_name.split(' ').join('_') + '_' + num.field_id
          : `Category_${num.category_name.split(' ').join('_')}_${
              num.category_id
            }`
      )
      .join('+');
    let denominatorString =
      denominators.length > 0
        ? denominators
            .map((denom) =>
              denom.category_id === -1
                ? denom.field_name.split(' ').join('_') + '_' + denom.field_id
                : `Category_${denom.category_name.split(' ').join('_')}_${
                    denom.category_id
                  }`
            )
            .join('+')
        : '';
    dic[metricsName] = numeratorString + '/' + denominatorString;
    // numerators.map(num => num.category_id === -1 ? num.field_name.split(" ").join("_") + "_" + num.field_id : `Category_${num.category_name.split(" ").join("_")}_${num.category_id}`
    // ).join(" + ") + "/" + denominators.map(denom => denom.category_id === -1 ? denom.field_name.split(" ").join("_") + "_" + denom.field_id : `Category_${denom.category_name.split(" ").join("_")}_${denom.category_id}`
    // ).join(" + ")
  }
  // console.log(dic)
  for (let stat of stats) {
    // console.log(stat)
    let template = {};
    for (let column of columns) {
      if (column.value !== 'name') {
        template[column.value] = 0;
      }
      // column.metric_formula =
    }
    if (stat.user_id) {
      template.user_id = stat.user_id;
      template.first_name = stat.first_name.split(' ')[0];
      template.last_name = stat.last_name;
      template.index =
        stat.first_name.split(' ')[0] +
        '_' +
        stat.last_name +
        '_' +
        stat.user_id;
      template.profile_pic_url = stat.profile_pic_url;
      template.table_name =
        stat.first_name.split(' ')[0] + ' ' + stat.last_name;
    } else if (!stat.user_id && !stat.team_id) {
      template.squad_id = stat.squad_id;
      template.first_name = stat.first_name;
      template.index = 'squad_' + stat.squad_id;
      template.table_name = stat.first_name;
    } else {
      template.team_id = stat.team_id;
      template.first_name = stat.first_name;
      template.index = 'team_' + stat.team_id;
      template.table_name = stat.first_name;
    }
    for (let [fieldName, formula] of Object.entries(dic)) {
      let numerators = formula.split('/')[0].split('+');
      let denominators = formula.split('/')[1]
        ? formula.split('/')[1].split('+')
        : [];
      let numSum = 0;
      let denomSum = 0;
      for (let num of numerators) {
        // console.log(num);
        // console.log(stat[num])
        numSum += stat[num] ? stat[num] : 0;
      }
      if (denominators.length > 0) {
        for (let denom of denominators) {
          denomSum += stat[denom] ? stat[denom] : 0;
        }
      } else {
        denomSum = 1;
      }

      if (denomSum === 0 && numSum !== 0) {
        template[fieldName] = '-';
      } else {
        template[fieldName] = round(numSum / denomSum, 1)
          ? round(numSum / denomSum, 1)
          : 0;
      }
    }
    finalStats.push(template);
  }
  // console.log(finalStats)
  return finalStats;
}

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function fillCategories(template, categories, forDailyTracker) {
  for (let category of categories) {
    template[
      `Category_${category.name.split(' ').join('_')}_${category.id}`
    ] = 0;
    if (forDailyTracker) {
      template[
        `Category_${category.name.split(' ').join('_')}_${
          category.id
        }_yesterday`
      ] = 0;
    }
  }
}

// ---------------------------------- Rjs codes --------------------------------- //

// Helper to use after /api/st1/customStats/getAggregatedTeamMemberStatsByFieldIds
StatTrackerHelper.parseFetchedData = (fetchedData, userIds, targetUserIds) => {
  /* userIds must be { 
      [user_id] : {
        user profile, first_name, profile pic,
        some id, but probably not needed, etc.
      },
      [another_user_id] : {
        some data
      }
    }
  */

  const fieldIds = Object.keys(fetchedData);
  const usersObjects = {};
  let userIdsObj = DeepCopy.deepCopy(userIds);

  targetUserIds.forEach((userId) => {
    const data = userIdsObj[userId];
    const initialFieldValue = {};
    fieldIds.forEach((fieldId) => {
      initialFieldValue[fieldId] = 0;
    });

    if (data)
      usersObjects[userId] = {
        // [fieldId]: data.stat_sum,
        name: data.first_name,
        total: 0,
        squad_id: data.squad_id ? data.squad_id : 777,
        profile_pic_url: data.profile_pic_url,
        squad_name: data.squad_name,
        id: data.user_id,
        profile_pic: 0,
        ...initialFieldValue
      };
  });

  // keys equate to an array of users that has value for a certain field
  Object.entries(fetchedData).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];

    value.forEach((user) => {
      if (usersObjects[user.user_id]) {
        usersObjects[user.user_id][key] = user.stat_sum;
        usersObjects[user.user_id].total += user.stat_sum;
      }
    });
  });
  return Object.values(usersObjects);
};

// The helper would group by similar group value and a given array of properties
// Data should be [ { otherProperties: otherValues, group: value }, {}, ... ]
// Property group can be anything, this is specified
// ** THE GROUP PROPERTY MUST NOT BE IN A NESTED OBJECT **
// sumKeys is the list of properties of an element that will be added to each other
// some keys of sumKeys might not exist on a certain element
// Second parameter is the property that would segregate the data
// Third Parameter is the list of properties that will be added up,
// once added up, use the values to determine which groups should be first
StatTrackerHelper.aggregateGroup = (data, property, sumKeys) => {
  if (typeof property !== 'string')
    throw new Error('Second parameter must be a string');
  if (!Array.isArray(sumKeys))
    throw new Error('Third Parameter must be an array');

  const caughtGroups = {};

  //Group the users by the given 2nd parameter
  data.forEach((element) => {
    // if group doesnt exist in caughtGroups, add the element
    if (!caughtGroups[element[property]]) {
      caughtGroups[element[property]] = [element];

      // if group exists in caughtGroups, add element and the current data
    } else {
      caughtGroups[element[property]].push(element);
    }
  });

  const groupKeys = Object.keys(caughtGroups);
  const groupTotals = {};

  // caughtGroups = { groupKey : [ {}, {} ], anothaGroupKey: [{}] }

  // For each of the given sumKeys, find the value of those keys
  // in the element and add them in group totals respective
  // to their group keys
  groupKeys.forEach((key) => {
    groupTotals[key] = 0;
    caughtGroups[key].forEach((user) => {
      sumKeys.forEach((sumKey) => {
        if (user[sumKey]) {
          groupTotals[key] += user[sumKey];
        }
      });
    });
  });

  // groupTotals = {groupKey: sumValue, ...}
  let sortedArray = Object.entries(groupTotals).sort(function (a, b) {
    return b[1] - a[1];
  });

  let finalData = [];
  //Sort the contents of the grouped data
  sortedArray.forEach((entry) => {
    const key = entry[0];

    caughtGroups[key].sort((a, b) => b.total - a.total);
    caughtGroups[key].forEach((userData) => {
      finalData.push(userData);
    });
  });
  return finalData;
};

// Property refers to the individual contents of the array, does not access
// nested objects in the individual content
StatTrackerHelper.sortObjectArray = (array, property, order = 'ASC') => {
  const newOrder = order.toUpperCase();

  if (typeof property !== 'string')
    throw new Error('Second Parameter must be a string');
  if (newOrder !== 'ASC' && newOrder !== 'DESC')
    throw new Error("Third Parameter must be either 'asc' or 'desc'");

  if (newOrder === 'DESC') {
    return array.sort((a, b) => {
      return b[property] - a[property];
    });
  } else {
    return array.sort((a, b) => {
      return a[property] - b[property];
    });
  }
};

// Creates the start date and end date
StatTrackerHelper.parseChartDate = (dates) => {
  const newDates = [];
  dates.forEach((date) => {
    const { id, chart_id, date_type, start_date, end_date, dynamic_value } =
      date;

    switch (date_type) {
      case 'rolling':
        const rollingDate = Object.values(JSON.parse(dynamic_value));
        const value = rollingDate[0];
        const unit = rollingDate[1];

        let startDate = moment()
          .set({ hours: 0, mintues: 0, seconds: 0 })
          .subtract(value, unit)
          .toISOString();
        const endDate = moment()
          .set({ hours: 24, mintues: 0, seconds: 0 })
          .toISOString();

        newDates.push({
          id,
          chart_id,
          date_type,
          start_date: startDate,
          end_date: endDate,
          dynamic_value
        });
        break;

      case 'current':
        switch (dynamic_value) {
          case 'current_day':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment()
                .set({ hours: 0, minutes: 0, second: 0 })
                .toISOString(),
              end_date: moment()
                .set({ hours: 24, minutes: 0, second: 0 })
                .toISOString(),
              dynamic_value
            });
            break;
          case 'current_week':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment().startOf('isoWeek').toISOString(),
              end_date: moment().endOf('isoWeek').toISOString(),
              dynamic_value
            });
            break;
          case 'current_month':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment().startOf('month').toISOString(),
              end_date: moment().endOf('month').toISOString(),
              dynamic_value
            });
            break;
          case 'current_year':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment().startOf('year').toISOString(),
              end_date: moment().endOf('year').toISOString(),
              dynamic_value
            });
            break;

          default:
            break;
        }

        break;

      case 'previous':
        switch (dynamic_value) {
          case 'previous_day':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(1, 'day')
                .toISOString(),
              end_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .toISOString(),
              dynamic_value
            });
            break;
          case 'previous_week':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(1, 'weeks')
                .startOf('week')
                .toISOString(),
              end_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(1, 'weeks')
                .endOf('week')
                .toISOString(),
              dynamic_value
            });
            break;
          case 'previous_month':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(1, 'months')
                .startOf('month')
                .toISOString(),
              end_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(1, 'months')
                .endOf('month')
                .toISOString(),
              dynamic_value
            });
            break;
          case 'previous_year':
            newDates.push({
              id,
              chart_id,
              date_type,
              start_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(1, 'year')
                .startOf('year')
                .toISOString(),
              end_date: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(1, 'year')
                .endOf('year')
                .toISOString(),
              dynamic_value
            });
            break;

          default:
            break;
        }

        break;

      default:
        newDates.push({
          id,
          chart_id,
          date_type,
          start_date,
          end_date,
          dynamic_value
        });
        break;
    }
  });

  return newDates;
};

// Creates data formatted for the bar chart given a recently fetched chart data
StatTrackerHelper.createBarChartData = async (
  chart,
  selectedTeamId,
  userDataCollection,
  newFieldCollection
) => {
  const newDates = StatTrackerHelper.parseChartDate(chart.dates);
  const promiseArray = [];
  // Prepare fetching of chart data * number of dates
  newDates.forEach((date) => {
    promiseArray.push(
      httpHandler.post(
        '/api/st1/customStats/getAggregatedTeamMemberStatsByFieldIds',
        {
          startDate: new Date(
            new Date(date.start_date).toISOString().split('T')[0]
          ),
          endDate: new Date(
            new Date(date.end_date).toISOString().split('T')[0]
          ),
          fieldIds:
            chart.chart_type === SINGLESTATCHART && !chart.by_categories
              ? [chart.single_stat_id]
              : [...chart.field_ids],
          userIds: chart.user_ids,
          teamId: selectedTeamId
        }
      )
    );
  });

  const results = await Promise.all(promiseArray);
  const chartDatas = [];

  //Requirement after fetch, parse data
  results.forEach((data) => {
    const myResult = StatTrackerHelper.parseFetchedData(
      data,
      userDataCollection,
      [...chart.user_ids]
    );
    chartDatas.push(myResult);
  });

  let finalData;

  // #optimize handle > 2 bars | we would only do 2 bars, but you know, handle it |
  // If 2 dates are found
  if (chartDatas.length > 1) {
    //Initiate variables
    const firstData = chartDatas[0];
    const secondData = chartDatas[1];
    const collection = {};

    //check if showing by category,
    if (chart.by_categories) {
      //convert to key pair
      firstData.forEach((user) => {
        const userId = user.id;
        collection[user.id] = {
          id: user.id,
          name: user.name,
          profile_pic: user.profile_pic,
          profile_pic_url: user.profile_pic_url,
          squad_id: user.squad_id,
          squad_name: user.squad_name
        };

        // We delete these properties so that the only properties left
        // are field ids
        delete user.id;
        delete user.name;
        delete user.profile_pic;
        delete user.profile_pic_url;
        delete user.squad_id;
        delete user.squad_name;

        //All field ids left, get which category
        Object.entries(user).forEach((entry) => {
          const key = entry[0]; //Key
          const value = entry[1]; //Number | Value of the particular field

          if (key !== 'total') {
            const categoryId = newFieldCollection[key].category_id;

            if (!collection[userId][categoryId]) {
              collection[userId][categoryId] = value;
            } else {
              collection[userId][categoryId] += value;
            }
          } else {
            collection[userId][key] = value;
          }
        });
      });

      secondData.forEach((data) => {
        const userId = data.id;

        // We delete these properties so that the only properties left
        // are field ids
        delete data.id;
        delete data.name;
        delete data.profile_pic;
        delete data.profile_pic_url;
        delete data.squad_id;
        delete data.squad_name;

        // All fields left
        Object.entries(data).forEach((entry) => {
          const key = entry[0];
          const value = entry[1];

          if (key !== 'total') {
            const categoryId = newFieldCollection[key].category_id;

            if (!collection[userId][`secondary ${categoryId}`]) {
              collection[userId][`secondary ${categoryId}`] = value;
            } else {
              collection[userId][`secondary ${categoryId}`] += value;
            }
          } else {
            collection[userId][`secondary ${key}`] = value;
          }
        });
      });
      const newData = Object.values(collection);
      finalData = newData;
    } else {
      //convert to key pair
      firstData.forEach((user) => {
        collection[user.id] = user;
      });

      secondData.forEach((data) => {
        const userId = data.id;

        // We delete these properties so that the only properties left
        // are field ids
        delete data.id;
        delete data.name;
        delete data.profile_pic;
        delete data.profile_pic_url;
        delete data.squad_id;
        delete data.squad_name;

        const entries = Object.entries(data);

        entries.forEach((entry) => {
          const key = entry[0];
          const value = entry[1];
          collection[userId][`secondary ${key}`] = value;
        });
      });

      const newData = Object.values(collection);
      finalData = newData;
    }
  } else {
    // Single data
    const newData = [];
    if (chart.by_categories) {
      chartDatas[0].forEach((data) => {
        let dataCopy = {
          id: data.id,
          name: data.name,
          profile_pic: data.profile_pic,
          profile_pic_url: data.profile_pic_url,
          squad_id: data.squad_id,
          squad_name: data.squad_name
        };

        // We delete these properties so that the only properties left
        // are field ids
        delete data.id;
        delete data.name;
        delete data.profile_pic;
        delete data.profile_pic_url;
        delete data.squad_id;
        delete data.squad_name;

        Object.entries(data).forEach((entry) => {
          const key = entry[0];
          const value = entry[1];

          if (key !== 'total') {
            const categoryId = newFieldCollection[key].category_id;

            if (!dataCopy[categoryId]) {
              dataCopy[categoryId] = value;
            } else {
              dataCopy[categoryId] += value;
            }
          } else {
            dataCopy[key] = value;
          }
        });
        newData.push(dataCopy);
      });
      finalData = newData;
    } else {
      finalData = chartDatas[0];
    }
  }

  // amcharts doesnt successfully sort the data sometimes
  // we sort it for ourselves
  finalData.sort((a, b) => {
    return b.total - a.total;
  });

  return finalData;
};

StatTrackerHelper.createDateLineChartData = async (
  chart,
  selectedTeamId,
  userDataCollection,
  allRolesCollection
) => {
  try {
    const {
      single_stat_id,
      by_categories,
      user_ids,
      dates,
      group_by,
      show_by,
      category_ids,
      squad_ids,
      role_ids
    } = chart;
    const promiseArray = [];
    const newDates = StatTrackerHelper.parseChartDate(dates);

    if (by_categories) {
      switch (show_by) {
        case 'user':
          promiseArray.push(
            httpHandler.post(
              '/api/st1/customStats/getIndividualTeamMemberStatsByCategoryId',
              {
                teamId: selectedTeamId,
                categoryId: single_stat_id,
                userIds: user_ids,
                startDate: newDates[0].start_date,
                endDate: newDates[0].end_date,
                groupBy: group_by
              }
            )
          );
          break;

        case 'role':
          promiseArray.push(
            httpHandler.post('/api/st1/customStats/getRoleStatsByCategoryId', {
              teamId: selectedTeamId,
              categoryId: category_ids[0],
              roleIds: role_ids,
              startDate: newDates[0].start_date,
              endDate: newDates[0].end_date,
              groupBy: group_by
            })
          );
          break;

        case 'squad':
          promiseArray.push(
            httpHandler.post('/api/st1/customStats/getSquadStatsByCategoryId', {
              teamId: selectedTeamId,
              categoryId: category_ids[0],
              squadIds: squad_ids,
              startDate: newDates[0].start_date,
              endDate: newDates[0].end_date,
              groupBy: group_by
            })
          );
          break;

        default:
          break;
      }
    } else {
      switch (show_by) {
        case 'user':
          promiseArray.push(
            httpHandler.post(
              '/api/st1/customStats/getIndividualTeamMemberStatsByFieldId',
              {
                teamId: selectedTeamId,
                fieldId: single_stat_id,
                userIds: user_ids,
                startDate: newDates[0].start_date,
                endDate: newDates[0].end_date,
                groupBy: group_by
              }
            )
          );
          break;

        case 'role':
          promiseArray.push(
            httpHandler.post(
              '/api/st1/customStats/getIndividualRoleStatsByFieldId',
              {
                teamId: selectedTeamId,
                fieldId: single_stat_id,
                roleIds: role_ids,
                startDate: newDates[0].start_date,
                endDate: newDates[0].end_date,
                groupBy: group_by
              }
            )
          );
          break;

        case 'squad':
          promiseArray.push(
            httpHandler.post(
              '/api/st1/customStats/getIndividualSquadStatsByFieldId',
              {
                teamId: selectedTeamId,
                fieldId: single_stat_id,
                squadIds: squad_ids,
                startDate: newDates[0].start_date,
                endDate: newDates[0].end_date,
                groupBy: group_by
              }
            )
          );
          break;

        default:
          break;
      }
    }

    let [data] = await Promise.all(promiseArray);

    switch (show_by) {
      case 'user':
        data.forEach((results, index) => {
          // each results is a list of all the rows in the db for the particular field/category
          if (results.length === 0) {
            results.push({
              value: 0,
              name: userDataCollection[user_ids[index]].first_name,
              date: newDates[0].start_date,
              profile_pic_url:
                userDataCollection[user_ids[index]].profile_pic_url,
              user_id: userDataCollection[user_ids[index]].id
            });
          } else {
            results.forEach((data) => {
              data.user_id = userDataCollection[user_ids[index]].id;
            });
          }
        });

        // sort alphabetically
        data.sort((a, b) => a[0].name.localeCompare(b[0].name));
        break;

      case 'role':
        data.forEach((results, index) => {
          // each results is a list of all the rows in the db for the particular field/category
          if (results.length === 0) {
            results.push({
              value: 0,
              name: allRolesCollection[role_ids[index]].role_name,
              date: newDates[0].start_date,
              role_id: role_ids[index].id
            });
          }
        });

        data.sort((a, b) => b.length - a.length);
        data.sort((a, b) =>
          a[0]?.name.localeCompare(b[0]?.name ? b[0].name : '')
        );

        break;

      default:
        break;
    }

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// SingleStatChart Specific
// fetchedChart must be in the same format as
StatTrackerHelper.getStatLabel = (fetchedChart) => {
  const categoryIds = fetchedChart.category_ids;
  const fieldIds = fetchedChart.field_ids;

  const ShowValidationError = () => {
    notification.error({
      message: 'Chart Validation failed',
      description: `The chart data is not valid. Please recreate this chart
        starting by deleting this chart.`,
      placement: 'topRight'
    });

    return null;
  };

  // There should be 1 or 0 only. Both must exist on either

  if (categoryIds.length > fieldIds.length) {
    // a category id is selected via chart creation
    const id = categoryIds[0];
    fetchedChart.category_datas.forEach((data) => {
      if (data.id === id) {
        return data.name;
      }
    });

    return ShowValidationError();
  } else if (categoryIds.length < fieldIds.length) {
    // a field id is selected via chart creation

    const id = fieldIds[0];
    fetchedChart.field_datas.forEach((data) => {
      if (data.id === id) {
        return data.name;
      }
    });

    return ShowValidationError();
  } else {
    //Both are empty, creation of this chart ahs an error
    notification.error({
      message: 'Invalid Chart',
      description: `The chart seems to be malformed. 
          Please attempt to recreate this chart starting by deleting this chart.`,
      placement: 'topRight'
    });
    return null;
  }
};

// *The exact same format of a date when fetched from the database
// which is :
// date = { chart_id, date_type, dynamic_value, start_date, end_date, id }
//
StatTrackerHelper.generateDateLabel = (date) => {
  const dateType = date.date_type;

  switch (dateType) {
    case CURRENT:
    case PREVIOUS:
      return date.dynamic_value.replace('_', ' ').toUpperCase();

    case STATIC:
      const startDate = date.start_date;
      const endDate = date.end_date;
      return `${new Date(startDate).toDateString()} - ${new Date(
        endDate
      ).toDateString()}`;

    case ROLLING:
      const dynamicValue = Object.values(JSON.parse(date.dynamic_value));
      return `Rolling dates: ${dynamicValue[0]} ${dynamicValue[1]}`;

    default:
      return '---';
  }
};

StatTrackerHelper.getFieldsOfTeamId = (teamId) => {
  return new Promise((resolve, reject) => {
    httpHandler
      .get(`/api/st1/fields/getFieldsByTeamId/${teamId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

StatTrackerHelper.getFormSubmissions = (form, teamId) => {
  return new Promise((resolve, reject) => {
    httpHandler
      .get('/api/st1/financials/getFormSubmissionsByTeam', {
        teamId,
        ...form
      })
      .then((res) => {
        resolve(res);
      });
  });
};

//event is the 'event' parameter for the bar chart series on click
StatTrackerHelper.onSeriesClick = async (event, chart, teamId) => {
  const axis = event.target._virtualParent.dataFields;
  const obj = {};
  const dates = StatTrackerHelper.parseChartDate(chart.dates);

  if (axis['valueX'].includes('secondary')) {
    obj.startDate = dates[1].start_date;
    obj.endDate = dates[1].end_date;

    if (!chart.by_categories)
      obj.fieldIds = [
        parseInt(
          axis['valueX'].split(' ')[axis['valueX'].split(' ').length - 1]
        )
      ];
    else
      obj.categoryIds = [
        parseInt(
          axis['valueX'].split(' ')[axis['valueX'].split(' ').length - 1]
        )
      ];
  } else {
    obj.startDate = dates[0].start_date;
    obj.endDate = dates[0].end_date;

    if (!chart.by_categories) obj.fieldIds = [parseInt(axis['valueX'])];
    else obj.categoryIds = [parseInt(axis['valueX'])];
  }

  const userId = event.target.dataItem._dataContext.id;
  const submissions = await StatTrackerHelper.getFormSubmissions(
    { userId, ...obj },
    teamId
  );

  return submissions;
};

export default StatTrackerHelper;
