import React from 'react';
import { Select, Tooltip, Form } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import httpHandler from '../../../../helpers/httpHandler';

const { Option } = Select;
const requiredRule = [
  {
    required: true,
    message: 'Select an option'
  }
];
class FormSelectElement extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false
    };
  }
  componentDidMount() {
    this.getContactFieldSelections();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.submitting !== prevProps.submitting &&
      this.props.submitting
    ) {
      this.validateSelection();
    }
  }

  getContactFieldSelections = () => {
    httpHandler
      .get('/api/st1/financials/getFormSelectionOptionsForField', {
        fieldId: this.props.field.id
      })
      .then((options) => {
        this.setState({
          options: options,
          [`field_${this.props.field.id}`]: null
        });
      })
      .catch((error) => {})
      .finally(() => {});
  };

  getSelectOptions = () => {
    if (this.state.options && this.state.options.length > 0) {
      let selections = [];
      // selections.push(<Option key={-1} value={"Select an option"}>- Select an option -</Option>);
      for (let option of this.state.options) {
        selections.push(
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        );
      }
      return selections;
    }
  };
  selectOption = (value) => {
    this.setState(
      { [`field_${this.props.field.id}`]: value, visible: false },
      () => {
        let option = this.state.options.filter(
          (option, index) =>
            option.field_id === this.props.field.id && option.id === value
        )[0];
        if (option) {
          if (
            this.props.field.type === 'closing type' ||
            this.props.field.field_id === 23
          ) {
            this.props.getClosingAmount(option.amount ? option.amount : 0);
          }
        }
      }
    );
  };
  validateSelection = () => {
    // if (!this.state[`field_${this.props.field.id}`]) {
    //   this.setState({visible: true});
    // } else {
    //   this.props.getSelectionValues(JSON.parse(JSON.stringify({...this.state, field: this.props.field})));
    // }
  };
  getSelectElement = () => {
    if (this.props.field) {
      return (
        <div>
          <span>{this.props.field.name}</span>
          <span style={{ marginLeft: '5px' }}>
            {this.props.tooltip ? (
              <Tooltip title={this.props.field.tooltip}>
                <InfoCircleFilled style={{ color: '#0789ed' }} />
              </Tooltip>
            ) : null}
          </span>
          <Form.Item
            name={[`${this.props.field.type}`, `field_${this.props.field.id}`]}
            rules={
              this.props.field.required || this.props.field.chosen_required
                ? requiredRule
                : null
            }
          >
            <Select
              // value={this.props.field ? this.state[`field_${this.props.field.id}`] : null}
              placeholder='Select an option'
              style={{ width: '100%' }}
              onChange={this.selectOption}
            >
              {this.getSelectOptions()}
            </Select>
          </Form.Item>
        </div>
      );
    }
    // else if (this.props.field && (this.props.field.required || this.props.field.chosen_required)) {
    //   return (
    //     <div>
    //       <Card>
    //         <span>{this.props.field.name}</span>
    //         <span style={{marginLeft: "5px"}}>
    //           {this.props.field.required || this.props.field.chosen_required ?
    //             <Tooltip title={this.props.field.tooltip ? this.props.field.tooltip : "placeholder"}><InfoCircleFilled style={{color: "#0789ed"}}/></Tooltip>
    //             :
    //             null
    //           }
    //         </span>
    //         <div
    //           // className='form-select-validation'
    //           visible={this.state.visible}
    //           placement="right"
    //           style={{
    //             background: "red"
    //           }}
    //         >
    //           <Select
    //             placeholder="Select option"
    //             style={{width: "100%"}}
    //             onChange={this.selectOption}
    //           >
    //             {this.getSelectOptions()}
    //           </Select>
    //         </div>
    //       </Card>
    //     </div>
    //   )
    // }
  };

  render() {
    return <div>{this.getSelectElement()}</div>;
  }
}

export default FormSelectElement;
