import { ClientSlice, GetFn, SetFn } from '../../types/zustandTypes';
import clientApi from '../apis/clientApi';

export const createClientSlice = (set: SetFn, get: GetFn): ClientSlice => ({
  coachClients: null,
  clientApi: {
    fetchUserClients: async (userCrmId: number) => {
      try {
        const clients = await clientApi.fetchUserClients(userCrmId);
        set({ coachClients: clients });
        return clients;
      } catch (error) {
        console.error(`error`, error);
      }
    }
  }
});
