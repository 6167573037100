import bowser from "bowser";

var VideoSettingsAndConstraints = {};

VideoSettingsAndConstraints.WEBCAM_SIMULTCAST_ENCODINGS = [
  {
    rid: "r0",
    scalabilityMode: "S1T3",
    scaleResolutionDownBy: 4,
    maxBitRate: 500000,
  },
  {
    rid: "r1",
    scalabilityMode: "S1T3",
    scaleResolutionDownBy: 2,
    maxBitRate: 1000000,
  },
  {
    rid: "r2",
    scalabilityMode: "S1T3",
    scaleResolutionDownBy: 1,
    maxBitRate: 5000000,
  },
];

VideoSettingsAndConstraints.PC_PROPRIETARY_CONSTRAINTS = {
  optional: [{ googDscp: true }],
};

//Used for VP9 webcam video
VideoSettingsAndConstraints.WEBCAM_KSVC_ENCODINGS = [
  { scalabilityMode: "S3T3_KEY" },
];

//Used for simulcast screen sharing
VideoSettingsAndConstraints.SCREEN_SHARING_SIMULCAST_ENCODINGS = [
  { dtx: true, maxBitrate: 1500000 },
  { dtx: true, maxBitrate: 6000000 },
];

//Use4d for VP9 screen sharing
VideoSettingsAndConstraints.SCREEN_SHARING_SVC_ENCODINGS = [
  { scalabilityMode: "S3T3", dtx: true },
];

VideoSettingsAndConstraints.VIDEO_CONSTRAINTS = {
  realSmoll: { width: { ideal: 40 }, height: { ideal: 20 } },
  qqvga: { width: { ideal: 160 }, height: { ideal: 120 } },
  qvga: { width: { ideal: 320 }, height: { ideal: 240 } },
  vga: { width: { ideal: 640 }, height: { ideal: 480 } },
  hd: { width: { ideal: 1280 }, height: { ideal: 720 } },
  fullHd: { width: { ideal: 1920 }, height: { ideal: 1080 } },
};

VideoSettingsAndConstraints.deviceInfo = () => {
  const ua = navigator.userAgent;
  const browser = bowser.getParser(ua);
  let flag;

  if (browser.satisfies({ chrome: ">=0", chromium: ">=0" })) flag = "chrome";
  else if (browser.satisfies({ firefox: ">=0" })) flag = "firefox";
  else if (browser.satisfies({ safari: ">=0" })) flag = "safari";
  else if (browser.satisfies({ opera: ">=0" })) flag = "opera";
  else if (browser.satisfies({ "microsoft edge": ">=0" })) flag = "edge";
  else flag = "unknown";

  return {
    flag,
    name: browser.getBrowserName(),
    version: browser.getBrowserVersion(),
  };
};

export default VideoSettingsAndConstraints;
