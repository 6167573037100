import React, { useState } from 'react';
import { useEffect } from 'react';
import {
  deleteRequest,
  getRequest,
  patchRequest
} from '../../../../../helpers/httpHandler2';
import classes from './Notifications.module.scss';
import { useNavigate } from 'react-router-dom';
import { FaCheck, FaEllipsisH, FaRegWindowClose } from 'react-icons/fa';
import Popover from '../../../../reusableComponents/standard/popover/Popover';
import produce from 'immer';
import NotificationEntry from './notificationEntry/NotificationEntry';
//import socketHandler from '../../../../../../src/services/Socket';

import { keysToCamel } from '../../../../../helpers/KeysToCamel';
import socketHandler from '../../../../../services/Socket';

export enum NotificationType {
  BOARD_ACCESS = 'boardAccess',
  CARD_MEMBER = 'cardMember',
  TEAM_INVITE = 'teamInvite',
  ACCEPTED_TEAM_INVITE = 'acceptedTeamInvite',
  JOIN_TEAM_REQUEST = 'joinTeamRequest',
  ACCEPTED_TEAM_REQUEST = 'acceptedTeamRequest'
}

export enum NotificationAction {
  ACCEPTED_TEAM_INVITE = 'acceptedTeamInvite',
  REJECTED_TEAM_INVITE = 'rejectedTeamInvite',
  ACCEPTED_JOIN_REQUEST = 'acceptedJoinRequest',
  REJECTED_JOIN_REQUEST = 'rejectedJoinRequest'
}

export interface Notification {
  id: number;
  senderId: number;
  senderFirstName: string;
  senderLastName: string;
  senderProfilePicUrl: string;
  receiverId: number;
  notificationType: string;
  redirectUrl: string;
  notificationRead: boolean;
  actionApplied: string;
  time: string;
  boardName?: string;
  cardName?: string;
  teamId?: number;
  teamName?: string;
  teamInviteId: number;
  teamInviteSecretCode?: string;
}

type ActionProps = {
  close?: () => void;
  markAllRead: () => void;
  deleteAllRead: () => void;
};

const Actions = ({ close, markAllRead, deleteAllRead }: ActionProps) => {
  return (
    <ul className={classes.actions}>
      <li
        onClick={(e) => {
          e.stopPropagation();
          markAllRead();
          if (close) close();
        }}
      >
        <FaCheck />
        <span>{'Mark all as read'}</span>
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          deleteAllRead();
          if (close) close();
        }}
      >
        <FaRegWindowClose />
        <span>Delete all read</span>
      </li>
    </ul>
  );
};

type Props = {
  setNotificationCount: (count: number) => void;
};

const Notifications = ({ setNotificationCount }: Props) => {
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const navigate = useNavigate();
  const [unreadOnly, setUnreadOnly] = useState<boolean>(true);

  useEffect(() => {
    const getNotifications = async () => {
      setLoadingNotifications(true);
      const result = await getRequest('/api/notifications');
      setNotifications(result);
      setLoadingNotifications(false);
    };
    getNotifications();
  }, []);

  useEffect(() => {
    const socketNotification = (notificationData: Notification) => {
      setNotifications([notificationData, ...notifications]);
    };
    socketHandler
      .getSocket()
      .on('notification', (data: any) => socketNotification(keysToCamel(data)));
    return () => {
      socketHandler.getSocket().off('notification', socketNotification);
    };
  }, [notifications]);

  useEffect(() => {
    if (loadingNotifications) return;
    setNotificationCount(
      notifications.filter((n) => !n.notificationRead).length
    );
  }, [loadingNotifications, notifications, setNotificationCount]);

  const toggleReadNotification = async (
    notification: Notification,
    read?: boolean,
    actionApplied?: string
  ) => {
    try {
      setNotifications(
        produce((draft) => {
          const index = notifications.findIndex(
            (n) => n.id === notification.id
          );
          draft[index].notificationRead =
            read || !notification.notificationRead;
          if (actionApplied) draft[index].actionApplied = actionApplied;
        })
      );
      await patchRequest(`/api/notifications/${notification.id}`, {
        notification: {
          notificationRead: !notification.notificationRead,
          actionApplied
        }
      });
    } catch (error) {
      console.error('failed to mark notification read', error);
    }
  };

  const deleteNotification = async (notification: Notification) => {
    try {
      await deleteRequest(`/api/notifications/${notification.id}`);
      setNotifications(notifications.filter((n) => n.id !== notification.id));
    } catch (error) {
      console.error('failed to delete notification');
    }
  };

  const markAllRead = async () => {
    try {
      await patchRequest(`/api/notifications/read`, {});
      setNotifications(
        produce((draft) => {
          for (const entry of draft) entry.notificationRead = true;
        })
      );
    } catch (error) {
      console.error('error setting all read');
    }
  };

  const deleteAllRead = async () => {
    try {
      await deleteRequest(`/api/notifications/read`);
      setNotifications(notifications.filter((n) => !n.notificationRead));
    } catch (error) {
      console.error('error deleting all read notifications');
    }
  };

  const navigateToNotification = async (notification: Notification) => {
    if (!notification.notificationRead) {
      await toggleReadNotification(notification);
    }
    navigate(notification.redirectUrl);
  };

  const displayNotification = notifications.filter((n) => {
    if (!n.notificationRead || !unreadOnly) return true;
    return false;
  });

  return (
    <div className={classes.container}>
      <h3>Notifications</h3>

      <ul className={classes.filters}>
        <li
          className={`${!unreadOnly && classes['filter--focus']}`}
          onClick={() => setUnreadOnly(false)}
        >
          All
        </li>
        <li
          className={`${unreadOnly && classes['filter--focus']}`}
          onClick={() => setUnreadOnly(true)}
        >
          Unread
        </li>
        <Popover
          ignoreWindowScroll={true}
          trigger='click'
          content={
            <Actions
              markAllRead={() => markAllRead()}
              deleteAllRead={() => deleteAllRead()}
            />
          }
        >
          <li>
            <FaEllipsisH />
          </li>
        </Popover>
      </ul>
      {displayNotification.map((notification, index) => {
        return (
          <NotificationEntry
            key={notification.id}
            notification={notification}
            toggleReadNotification={toggleReadNotification}
            deleteNotification={deleteNotification}
            navigateToNotification={navigateToNotification}
          />
        );
      })}
    </div>
  );
};

export default Notifications;
