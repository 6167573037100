import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { RiLockFill } from 'react-icons/ri';
import { MdDateRange } from 'react-icons/md';
import classes from './resultItem.module.scss';
import { Recording } from './Search';

interface RecordingCardProps {
  recordings: Recording;
  style: CSSProperties;
  setRowHeight: (index: number, size: number) => void;
  index: number;
  highlightText: (text: string) => (string | JSX.Element)[];
  icon?: JSX.Element;
  handleSelectRecording: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    recordings: Recording,
    occurrenceId: number
  ) => void;
  handleLockedRecording: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: string
  ) => void;
}

const RecordingCard = ({
  recordings,
  setRowHeight,
  index,
  style,
  highlightText,
  icon,
  handleSelectRecording,
  handleLockedRecording
}: RecordingCardProps) => {
  const [limit, setLimit] = useState<number>(3);
  const handleLoadMore = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setLimit((prevState) => prevState + 3);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current &&
      setRowHeight(index, containerRef.current.clientHeight || 0);
  }, [index, limit, setRowHeight, containerRef]);

  const recordingsList = [];
  if (recordings.recordingsList) {
    for (let i = 0; i < limit; i++) {
      const recording = recordings.recordingsList[i];
      const tags = recording?.tags ? recording.tags.split(',') : null;
      recording &&
        recordingsList.push(
          <div
            key={recordings.topic + recording.date + i}
            className={classes['item__recording']}
          >
            <div
              className={classes['recording__date']}
              onClick={(event) =>
                handleSelectRecording(event, recordings, recording.occurrenceId)
              }
            >
              <MdDateRange size='16px' />
              {` Date:`} {recording.date}
            </div>
            {tags && (
              <div className={classes['recording__tags']}>
                {tags.map((tag, i) => {
                  return (
                    <div
                      className={classes['tags__item']}
                      key={recording.tags + i}
                    >
                      {highlightText(tag)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
    }
  }

  return (
    <div style={style} className={classes['card']}>
      {!recordings.hasPermission && (
        <div
          className={classes['locked-card']}
          onClick={(e) => handleLockedRecording(e, recordings.topic)}
        >
          <RiLockFill size={30} />
        </div>
      )}

      <div ref={containerRef}>
        <div className={classes['result-item']}>
          <div className={classes['item__image-display']}>
            <img
              className={classes['image-display__image']}
              src={recordings.image}
              alt='topic'
            />
            <div className={classes['image-display__icon']}>{icon}</div>
          </div>
          <div style={{ width: '100%' }}>
            <div className={classes['item__name']}>
              {highlightText(recordings.topic)}
            </div>
            {recordingsList}
            {recordings.recordingsList.length > limit && (
              <div
                className={classes['item__load-more']}
                onClick={handleLoadMore}
              >
                Load more ...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordingCard;
