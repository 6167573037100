import httpHandler from '../../helpers/httpHandler';
import { FormFieldEditAccess, FormFieldViewAccess } from '../../types/form';
import { UserRole } from '../../types/user';

const formEditAccess = async (userId: number, formId: number) => {
  const userAccess: Promise<FormFieldEditAccess[]> = await httpHandler.get(
    `/api/forms/${formId}/edit-access`,
    {
      userId
    }
  );
  return userAccess;
};

const formViewAccess = async (userId: number, formId: number) => {
  const viewAccess: Promise<FormFieldViewAccess[]> = await httpHandler.get(
    `/api/forms/${formId}/view-access`,
    {
      userId
    }
  );
  return viewAccess;
};

const getUserRoles = async (formId: number) => {
  const roles: Promise<UserRole[]> = await httpHandler.get(
    `/api/users/me/stat-tracker/forms/${formId}`
  );
  return roles;
};

export const transactionFormRoutes = {
  formEditAccess,
  formViewAccess,
  getUserRoles
};
