import { ListLabelTag, WidgetListLabel } from "../../types/label";
import { GetFn, SetFn } from "../../types/zustandTypes";
import listLabelApi from "../apis/listLabelApi";

export const createListLabelSlice = (set: SetFn, get: GetFn) => ({
  boardLabels: [],
  boardLabelTags: [],
  listLabelApi: {
    getBoardLabels: async (boardId: number) => {
      const boardLabels = await listLabelApi.getBoardLabels(boardId);
      set({ boardLabels });
    },
    getBoardLabelTags: async (boardId: number) => {
      const boardLabelTags = await listLabelApi.getBoardLabelTags(boardId);
      set({ boardLabelTags });
    },
    setBoardLabels: async (boardLabels: WidgetListLabel[]) => {
      set({ boardLabels });
    },
    setBoardLabelTags: async (labelTags: ListLabelTag[]) => {
      set({ boardLabelTags: labelTags });
    },
  },
});
