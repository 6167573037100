import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import classes from './Popover.module.scss';

type TriggerTypes = 'click' | 'hover';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  trigger: TriggerTypes;
  width?: number;
  height?: number;
  ignoreWindowScroll?: boolean;
};

type Coordinates = {
  x: number;
  y: number;
};

const Popover = ({
  children,
  content,
  trigger,
  width,
  height,
  ignoreWindowScroll
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const coordinates = useRef<Coordinates>({ x: 0, y: 0 });

  const openPopover = (triggeredBy: string) => {
    if (isOpen) return;
    if (triggeredBy !== trigger) return;
    if (!containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    coordinates.current.x = rect.x + rect.width / 2;
    coordinates.current.y = rect.bottom;
    if (!ignoreWindowScroll) coordinates.current.y += window.scrollY;

    if (rect.x + (width || 0) > window.innerWidth) {
      coordinates.current.x = window.innerWidth - (width || 0);
    }
    flushSync(() => {
      setIsOpen(true);
    });
  };

  const closePopover = useCallback(
    (triggeredBy: string) => {
      if (triggeredBy !== trigger) return;
      setIsOpen(false);
    },
    [trigger]
  );

  useEffect(() => {
    if (isOpen) {
      const clickListener = (e: MouseEvent) => {
        if (!e.target || !contentRef.current) return;
        if (
          (e.target as Node) !== contentRef.current &&
          !contentRef.current.contains(e.target as Node)
        ) {
          closePopover('click');
        }
      };
      window.addEventListener('click', clickListener);
      return () => {
        window.removeEventListener('click', clickListener);
      };
    }
  }, [closePopover, isOpen]);

  const modalDiv = document.getElementById('popover-root');
  const close = useCallback(() => {
    setIsOpen(false);
    // setTimeout(() => {
    //   setIsOpen(false);
    // }, 1);
  }, []);

  return (
    <div
      ref={containerRef}
      className={classes.container}
      onClick={(e) => {
        if (isOpen) {
          setIsOpen(false);
        } else {
          e.stopPropagation();
          openPopover('click');
        }
      }}
      onMouseEnter={() => openPopover('hover')}
      onMouseLeave={() => closePopover('hover')}
    >
      {children}
      {isOpen && modalDiv ? (
        createPortal(
          <div
            ref={contentRef}
            className={classes.popover}
            style={{
              left: coordinates.current.x,
              top: coordinates.current.y,
              width: width ? `${width}px` : 'auto',
              height: height ? `${height}px` : 'auto'
            }}
          >
            {content &&
              React.isValidElement(content) &&
              React.cloneElement(content, close)}
          </div>,
          modalDiv
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default Popover;
