import React from "react";
import { Row, Tooltip, Form, Checkbox } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

class FormCheckboxElement extends React.Component {
  constructor() {
    super();
    this.state={

    }
  }

  getCheckboxElement = () => {
    if (this.props.field) {
      return (
        <div>
          <span style={{marginRight: "5px"}}>{this.props.field.name}</span>
          {this.props.field.tooltip ?
            <span><Tooltip title={this.props.field.tooltip}><InfoCircleFilled style={{color: "#0789ed"}}/></Tooltip></span>
            :null
          }
          <Form.Item
            name={[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]}
            valuePropName="checked"
            initialValue={false}
            // rules={this.props.field.required || this.props.field.chosen_required ? requiredRule : null}
          >
            <Checkbox
              className="form-checkbox"
            >

            </Checkbox>
          </Form.Item>
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <div>
          <Row>
            {this.getCheckboxElement()}
          </Row>
        </div>
      </div>
    )
  }


}

export default FormCheckboxElement;