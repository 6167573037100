import { Contact, ProcessedContactValues } from "../../types/statTracker";

function camelize(str: string) {
  return str.replace(
    /(?:^\w|[A-Z]|\b\w|\s+)/g,
    function (match: string, index: number) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    }
  );
}

export const processContacts = (contacts: Contact[]) => {
  const processedContacts: ProcessedContactValues[] = [];
  contacts.forEach((contact) => {
    const { id, teamId, crmId, crmContactId, fieldName, value } = contact;
    const contactBundle = processedContacts.find(
      (processedContact) => processedContact.id === contact.id
    );
    const camelizeField = camelize(fieldName);
    if (!contactBundle) {
      const newContactBundle = {
        id,
        teamId,
        crmId,
        crmContactId,
        [camelizeField]: value,
      };
      processedContacts.push(newContactBundle);
    } else {
      contactBundle[camelizeField] = value;
    }
  });
  return processedContacts;
};
