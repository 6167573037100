import React from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';
import ProgressBar from '../../../reusableComponents/standard/progressBar/ProgressBar';
import classes from './ProgressModal.module.scss';
import { EventSubscriptionUpdate } from '../SubscriptionProgress';

interface Props {
  socketData: EventSubscriptionUpdate;
  removeProgressCard: (conferenceId: number, softwareName: string) => void;
  closeProgressModal: (id: number) => void;
}

const ProgressModal = ({ socketData, closeProgressModal }: Props) => {
  const { id, total, completed, softwareName, action } = socketData;
  const label = ` ${softwareName.toUpperCase()} - ${action} events`;

  return (
    <div className={classes['progress_modal']}>
      <div
        className={classes['close_button']}
        onClick={() => closeProgressModal(id)}
      >
        <RiCloseCircleFill />
      </div>
      <ProgressBar
        total={total}
        completed={completed}
        label={label}
        height={16}
        handleCompleted={() => closeProgressModal(id)}
      />
    </div>
  );
};

export default ProgressModal;
