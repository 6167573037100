import { ModuleToView } from '../../types/course';
import { SetFn, GetFn, CourseViewSlice } from '../../types/zustandTypes';
import { courseViewerApi } from '../apis/courseViewerApi';
import { createModuleStructure } from '../../helpers/CreateCourseData';

export const createCourseViewSlice = (
  set: SetFn,
  get: GetFn
): CourseViewSlice => ({
  index: 0,
  currentView: null,
  moduleView: null,
  sectionView: null,
  moduleSlides: [],
  courseTree: [],
  course: null,
  isTestPassed: false,
  courseProgress: 0,
  courseViewApi: {
    fetchCourse: async (id) => {
      const loadedCourse = await courseViewerApi.fetchCourse(id);
      set({ course: loadedCourse });
    },
    setCourse: (course) => {
      set({ course: course });
    },
    setCurrentView: (view) => {
      set({ currentView: view });
    },
    setCourseProgress: (progress) => {
      set({ courseProgress: progress });
    },
    patchCourseTree: (tree) => set({ courseTree: tree }),
    fetchCourseSkeleton: async (courseId) => {
      try {
        const constructedTree = await courseViewerApi.fetchCourseSkeleton(
          courseId
        );
        constructedTree && set({ courseTree: constructedTree });
      } catch (error) {
        console.error('Error setting course tree state');
      }
    },
    fetchModule: async (moduleId) => {
      try {
        const loadedData = await courseViewerApi.fetchAllModuleData(moduleId);
        const sanitizedData = createModuleStructure(loadedData);
        const slides = [];

        if (sanitizedData.moduleVideo) {
          slides.push({ type: 'video' });
        }
        if (sanitizedData.moduleContent) {
          slides.push({
            type: 'content'
          });
        }
        if (sanitizedData.checklists.length > 0) {
          slides.push({ type: 'checklists' });
        }

        set({ moduleView: sanitizedData });
        set({
          moduleSlides: slides
        });
      } catch (error) {
        console.error('Error getting module', error);
      }
    },
    fetchSection: async (id) => {
      try {
        const loadedSection = await courseViewerApi.fetchSectionView(id);
        set({ sectionView: loadedSection });
      } catch (error) {
        console.error('error loading sections', error);
      }
    },
    setModule: (mod: ModuleToView) => set({ moduleView: mod })
  }
});
