import { transactionFormRoutes } from "../api/transactionFormRoutes/transactionFormRoutes";
import { FormField } from "../types/form";

export const parseSortedField = async (
  currentFormFields: FormField[],
  formId: number,
  userId: number
) => {
  const collection: {
    [key: string]: FormField[];
  } = {};

  // Edit is for disable
  // View is for hidden

  // When react-scripts is updated, convert to promise.all

  const editAccess = await transactionFormRoutes.formEditAccess(userId, formId);
  const viewAccess = await transactionFormRoutes.formViewAccess(userId, formId);
  const userRoles = await transactionFormRoutes.getUserRoles(formId);
  let isAdmin = false;

  userRoles.some((role) => {
    if (role.role_id === 1) {
      isAdmin = true;
      return true;
    }

    return false;
  });

  const currentFormFieldCollection: { [key: string]: FormField } = {};

  currentFormFields.forEach((field) => {
    const tmpField = { ...field };
    tmpField.visible = false;
    tmpField.disabled = true;

    currentFormFieldCollection[field.id] = tmpField;
  });

  if (!isAdmin) {
    viewAccess.forEach((element) => {
      if (currentFormFieldCollection[element.form_field_id]) {
        currentFormFieldCollection[element.form_field_id].visible = true;
      }
    });

    editAccess.forEach((element) => {
      if (currentFormFieldCollection[element.form_field_id]) {
        currentFormFieldCollection[element.form_field_id].disabled = false;
      }
    });
  } else {
    Object.values(currentFormFieldCollection).forEach((data) => {
      data.visible = true;
      data.disabled = false;
    });
  }

  Object.values(currentFormFieldCollection).forEach((data) => {
    if (!collection[data.group_id]) collection[data.group_id] = [data];
    else collection[data.group_id].push(data);
  });

  const formFieldSequence: number[] = [];

  currentFormFields.forEach((obj) => formFieldSequence.push(obj.id));

  Object.values(collection).forEach((fields) => {
    fields.sort(function (a, b) {
      return formFieldSequence.indexOf(a.id) - formFieldSequence.indexOf(b.id);
    });
  });

  return collection;
};
