import { AppointmentSlice, SetFn, GetFn } from '../../types/zustandTypes';

export const createAppointmentSlice = (
  set: SetFn,
  get: GetFn
): AppointmentSlice => ({
  appointments: [],
  appointmentsApi: {
    addAppointment: async (appointment) => {
      try {
        set((state) => ({
          appointments: [...state.appointments, appointment]
        }));
      } catch (error) {
        console.error(`Error adding apt`, error);
      }
    },
    removeAppointment: async (deleteId) => {
      try {
        set((state) => ({
          appointments: state.appointments.filter(
            ({ id: currentId }) => currentId !== deleteId
          )
        }));
      } catch (error) {
        console.error(`Error removing apt`, error);
      }
    }
  }
});
