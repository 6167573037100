import { Spin } from 'antd';
import React from 'react';
import classes from './PrettyButton.module.css';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

type PrettyButtonProps = {
  danger?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  children: React.ReactNode;
  customStyles?: React.CSSProperties;
  disabled?: boolean;
  hidden?: boolean;
  id?: string;
  loading?: boolean;
  form?: string;
  ariaLabel?: string;
  className?: string;
};

const PrettyButton = ({
  danger,
  onClick,
  type,
  children,
  customStyles,
  disabled,
  hidden,
  id,
  loading,
  form,
  ariaLabel,
  className
}: PrettyButtonProps) => {
  const appliedClass = danger ? classes.danger : classes.pretty;

  return (
    <button
      onClick={onClick}
      type={type}
      style={customStyles}
      className={`${appliedClass} ${className}`}
      disabled={disabled || loading}
      hidden={hidden}
      id={id}
      form={form}
      aria-label={ariaLabel}
    >
      {children}

      {loading && <Spin indicator={antIcon} style={{ marginLeft: 10 }} />}
    </button>
  );
};

export default PrettyButton;
