import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.addLicense('CH243823277');
am4core.options.autoDispose = true;
class TeamTotalColumnChart extends React.Component {
  constructor() {
    super();
    this.state = {
      team_totals: [],
      circleRadiusPercentage: Math.min(
        window.innerWidth / 1920,
        window.innerHeight / 937
      )
    };
  }

  componentDidMount() {
    if (this.props.dataDisplay && this.props.teamTotals) {
      this.buildChart(this.props.dataDisplay);
    }
  }
  buildChart(dataDisplay) {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    var chart = am4core.create(
      this.props.statDisplayId
        ? this.props.graph_name + '_' + this.props.statDisplayId
        : this.props.graph_name,
      am4charts.XYChart
    );
    this.chart = chart;
    chart.data = this.props.teamTotals;

    var title = chart.titles.create();
    let label = dataDisplay.split('_');
    label.splice(label.length - 1);
    label = label.join(' ');

    title.textAlign = 'middle';
    title.marginBottom = '4%';
    title.marginTop = '5%';

    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'day';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.fontSize = this.props.graph_name.includes('team-management')
      ? 20
      : 20 * Math.cbrt(this.state.circleRadiusPercentage);
    categoryAxis.renderer.labels.template.fill = am4core.color('#091a33');
    categoryAxis.renderer.labels.template.fontSize =
      this.props.graph_name.includes('team-management')
        ? 20
        : 20 * Math.cbrt(this.state.circleRadiusPercentage);
    //categoryAxis.renderer.stroke.width = 30;
    //categoryAxis.renderer.grid.template.strokeWidth = 30;
    //categoryAxis.renderer.grid.template.stroke = "#ff0000";
    //categoryAxis.renderer.grid.template.disabled = true;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = label;
    valueAxis.fontSize = this.props.graph_name.includes('team-management')
      ? 20
      : 20 * Math.cbrt(this.state.circleRadiusPercentage);
    //valueAxis.renderer.grid.template.disabled = true;
    valueAxis.maxPrecision = 0;
    valueAxis.renderer.grid.template.stroke = am4core.color('#fff');

    valueAxis.renderer.labels.template.fill = am4core.color('#091a33');
    valueAxis.min = 0;
    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    // series.dataFields.valueY = "number_of_appointments_set";
    series.dataFields.valueY = dataDisplay;
    series.dataFields.categoryX = 'day';
    series.name = dataDisplay;
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    series.columns.template.column.cornerRadius(20, 20, 20, 20);
    //series.columns.template.fillOpacity = .8;
    chart.colors.list = [
      am4core.color('rgba(103, 148, 220, 0.9)'),
      am4core.color('#091a33')

      // am4core.color("#FF6F91"),
      // am4core.color("#FF9671"),
      // am4core.color("#FFC75F"),
      // am4core.color("#F9F871"),
    ];
    series.columns.template.adapter.add('fill', function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });
    var bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = false;
    bullet.dy = 35;
    bullet.label.text = '[bold font-size: 3em]{valueY}';
    bullet.label.fill = am4core.color('#ffffff');
    if (this.props.statDisplayId) {
      title.text = `[bold font-size: 12 #091a33]Team Total (${label})`;
      categoryAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 15
          : 15 * Math.cbrt(this.state.circleRadiusPercentage);
    } else {
      title.text = `[bold font-size: 20 #091a33]Team Total (${label})`;
      valueAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 20
          : 20 * Math.cbrt(this.state.circleRadiusPercentage);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.teamTotals !== prevProps.teamTotals) {
      if (this.chart) {
        this.chart.data = this.props.teamTotals;
      } else {
        this.buildChart(this.props.dataDisplay);
      }
    }
    if (this.chart && this.props.dataDisplay !== prevProps.dataDisplay) {
      this.chart.dispose();
      this.buildChart(this.props.dataDisplay);
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {/* <h1 style={{ color: "#fff", textAlign: "center" }}>
          Team Total ({this.props.dataDisplay.split("_").join(" ")})
        </h1> */}
        {!this.props.teamTotals || !this.props.dataDisplay ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className='lds-spinner'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        <div
          id={
            this.props.statDisplayId
              ? this.props.graph_name + '_' + this.props.statDisplayId
              : this.props.graph_name
          }
          style={{ height: '100%' }}
        ></div>
      </div>
    );
  }
}

export default TeamTotalColumnChart;
