import { GetFn, SetFn, StatTrackerSlice } from '../../types/zustandTypes';
import { getAllIntegrations } from '../apis/integrationsApi';
import { pipelineApi } from '../apis/pipelineApi';

export const createStatTrackerSlice = (
  set: SetFn,
  get: GetFn
): StatTrackerSlice => ({
  scope: undefined,
  scopeValue: undefined,
  statTrackerApi: {
    setScope: async (scope, scopeValue) => {
      set({
        scope,
        scopeValue
      });
    },
    fetchInitialSTData: async (teamId: number) => {
      const allPipelines = await pipelineApi.getAllPipelinesWithStages(teamId);
      const integrationsSoftware = await getAllIntegrations();
      for (const pl of allPipelines) {
        pl.pipelineStages?.sort((a, b) => a.stageIndex - b.stageIndex);
      }
      const selectedPipeline = allPipelines[0];
      set({
        contacts: {},
        pipelines: allPipelines,
        currentPipeline: selectedPipeline,
        pipelineLoading: false,
        scope: `team ${teamId}`,
        integrationsSoftwareArray: integrationsSoftware,
        leadSources: {}
      });
    }
  }
});
