import React from 'react';
import { Row, Select, Tooltip, Form } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import httpHandler from '../../../../helpers/httpHandler';

const { Option } = Select;
const requiredRule = [
  {
    required: true,
    message: 'Select a lead source'
  }
];
class FormLeadSourceElement extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    if (this.props.selectedTeam) {
      this.getLeadSources();
    }
  }

  getLeadSources = () => {
    httpHandler
      .get('/api/st1/financials/getTeamLeadSources', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((options) => {
        this.setState({ leadSources: options });
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getSourceOptions = () => {
    if (this.state.leadSources && this.state.leadSources.length > 0) {
      let selections = [];
      // selections.push(<Option key={-1} value={"Select an option"}>- Select an option -</Option>);
      for (let option of this.state.leadSources) {
        selections.push(
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        );
      }
      return selections;
    }
  };
  getSourceElement = () => {
    if (this.props.selectedForm) {
      return (
        <div style={{ width: '100%' }}>
          <span>{this.props.field.name}</span>
          <span style={{ marginLeft: '5px' }}>
            {this.props.tooltip ? (
              <Tooltip title={this.props.field.tooltip}>
                <InfoCircleFilled style={{ color: '#0789ed' }} />
              </Tooltip>
            ) : null}
          </span>
          <Form.Item
            name={[`${this.props.field.type}`, `field_${this.props.field.id}`]}
            rules={
              this.props.field.required || this.props.field.chosen_required
                ? requiredRule
                : null
            }
          >
            <Select
              // value={this.props.field ? this.state[`field_${this.props.field.id}`] : null}
              placeholder='Select an option'
              style={{ width: '100%' }}
              onChange={this.selectOption}
            >
              {this.getSourceOptions()}
            </Select>
          </Form.Item>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {/* <Card> */}
        <Row>{this.getSourceElement()}</Row>
        {/* </Card> */}
      </div>
    );
  }
}

export default FormLeadSourceElement;
