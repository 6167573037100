import React, { createContext, useRef, useState } from 'react';

const BoardsContext = createContext();

const accessLevels = {
  none: 0,
  read: 1,
  write: 2
};

export const BoardsProvider = ({ children }) => {
  const [allRecordings, setAllRecordings] = useState([]);
  const [coach, setCoach] = useState({});
  const [teamLeader, setTeamLeader] = useState({});
  const [boardType, setBoardType] = useState('');
  const [creatorId, setCreatorId] = useState();
  const [board, setBoard] = useState({});
  const [accessLevel, setAccessLevel] = useState(accessLevels.write);
  const [isAdmin, setIsAdmin] = useState(false);
  const [memberAccess, setMemberAccess] = useState([]);
  const [teamAccess, setTeamAccess] = useState([]);
  const [teamMembersAccess, setTeamMembersAccess] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedBackground, setSelectedBackground] = useState();
  const [createBoardModal, setCreateBoardModal] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [paramItemId, setParamItemId] = useState();

  const copyWidget = async (widget, boardId) => {
    // add gridRef later for copying to the same board, or maybe setItems.
    try {
      /**
       * TODO: use board.id (from this context) to check for case where widget is being copied to the same board. If so, it needs to find an open spot for it before creating and placing it in the grid.
       */
    } catch (error) {
      return error;
    }
  };

  const changeBoard = (board) => {
    setBoard(board);
  };
  const changeCreatorId = (id) => setCreatorId(id);
  const changeRecordings = (input) => setAllRecordings(input);
  const changeIsAdmin = (input) => setIsAdmin(input);
  const changeType = (newType = '') => setBoardType(newType);
  const changeMemberAccess = (newArr = []) => setMemberAccess(newArr);
  const changeAccessLevel = (newLevel) =>
    setAccessLevel(accessLevels[newLevel]);

  const getBoardUrl = () => {
    switch (board.board_type) {
      case 'coaching':
        return `/boards/coaching/${board.client_id}`;

      default:
        return board.board_type === 'public'
          ? `/boards/public/${board.id}`
          : `/boards/private/${board.id}`;
    }
  };

  const addAccess = (memberId, role) => {};

  const boardRef = useRef();
  boardRef.current = board;

  return (
    <BoardsContext.Provider
      value={{
        teamLeader,
        setTeamLeader,
        coach,
        setCoach,
        boardType,
        changeType,
        accessLevel,
        changeAccessLevel,
        memberAccess,
        changeMemberAccess,
        isAdmin,
        changeIsAdmin,
        addAccess,
        board: boardRef.current,
        changeBoard,
        allRecordings,
        changeRecordings,
        creatorId,
        changeCreatorId,
        selectedBackground,
        setSelectedBackground,
        createBoardModal,
        setCreateBoardModal,
        copyWidget,
        isTemplate,
        setIsTemplate,
        categories,
        setCategories,
        teamAccess,
        setTeamAccess,
        teamMembersAccess,
        setTeamMembersAccess,
        paramItemId,
        setParamItemId,
        getBoardUrl
      }}
    >
      {children}
    </BoardsContext.Provider>
  );
};

export default BoardsContext;
