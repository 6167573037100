import React from 'react';
import { Form, InputNumber, Row, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import UserContext from '../../../../contexts/UserContext';

const requiredRule = [
  {
    required: true,
    message: 'Please enter a valid amount!'
  }
];

class FormAmountElement extends React.Component {
  static contextType = UserContext;
  // formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      // commission: 100
    };
  }

  componentDidMount() {
    this.getTransactionSetting();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.transactionSettings !== prevProps.transactionSettings &&
      this.props.transactionSettings
    ) {
      this.getTransactionSetting();
    }
  }
  getTransactionSetting = () => {
    if (
      this.props.transactionSettings &&
      this.props.transactionSettings.length > 0
    ) {
      let curSetting;
      let userSetting;
      for (let setting of this.props.transactionSettings) {
        if (setting.pre_defined) {
          curSetting = setting;
        }
        if (setting.user_id === this.context.user_bundle.user.id) {
          userSetting = setting;
        }
      }
      this.setState({
        transactionSetting: curSetting,
        userSetting: userSetting
      });
    }
  };
  handleAmount = (value) => {
    if (this.props.field.id === 14) {
      this.props.getTransactionAmount(
        `${this.props.field.type}_${this.props.field.id}`,
        value
      );
    } else if (this.props.field.id === 13) {
      this.setState(
        { [`${this.props.field.type}_${this.props.field.id}`]: value },
        () => {
          this.props.getCommissionAmount(
            `${this.props.field.type}_${this.props.field.id}`,
            value
          );
        }
      );
    } else if (this.props.field.id === 18) {
      this.setState({
        [`${this.props.field.type}_${this.props.field.id}`]: value
      });
    } else if (this.props.field.id === 19) {
      this.setState(
        { [`${this.props.field.type}_${this.props.field.id}`]: value },
        () => {
          // this.props.erasedUpdatedHourlyRate();
        }
      );
    } else {
      this.setState({
        [`${this.props.field.type}_${this.props.field.id}`]: value
      });
    }
  };
  setCommissionAmount = () => {
    if (this.state.transactionSetting) {
      this.props.formElement.current.setFieldsValue({
        [`${this.props.field.type}_${this.props.field.id}`]: Number.parseFloat(
          this.props.transactionAmount *
            (this.state.transactionSetting.gci / 100)
        ).toPrecision(3)
      });
      this.setState(
        {
          [`${this.props.field.type}_${this.props.field.id}`]:
            Number.parseFloat(
              this.props.transactionAmount *
                (this.state.transactionSetting.gci / 100)
            ).toPrecision(3)
        },
        () => {
          this.props.autoUpdatedGCI({
            [`${this.props.field.type}_${this.props.field.id}`]: parseFloat(
              this.state[`${this.props.field.type}_${this.props.field.id}`]
            )
          });
          this.props.getCommissionAmount(
            Number.parseFloat(
              this.props.transactionAmount *
                (this.state.transactionSetting.gci / 100)
            ).toPrecision(3)
          );
        }
      );
    }
  };
  setGapiAmount = () => {
    let gapi = 1;
    if (this.state.userSetting) {
      gapi = this.state.userSetting.gapi;
    } else if (this.state.transactionSetting) {
      gapi = this.state.transactionSetting.gapi;
    }
    this.setState(
      {
        [`${this.props.field.type}_${this.props.field.id}`]: Number.parseFloat(
          this.props.teamCommissionAmount * (gapi / 100)
        ).toPrecision(3)
      },
      () => {
        this.props.autoUpdatedAgentPaidCommission({
          [`${this.props.field.type}_${this.props.field.id}`]: parseFloat(
            this.state[`${this.props.field.type}_${this.props.field.id}`]
          )
        });
      }
    );
  };
  getControlledInput = () => {
    if (this.props.field) {
      return (
        <Form.Item
          name={[`${this.props.field.type}_${this.props.field.id}`]}
          rules={
            this.props.field.required || this.props.field.chosen_required
              ? requiredRule
              : null
          }
          initialValue={0}
        >
          <InputNumber
            style={{ width: '100%' }}
            onChange={this.handleAmount}
            step='1.00'
            min='0.00'
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            stringMode
          />
        </Form.Item>
      );
    }
    // if (this.props.field.id === 13 && this.props.autoUpdateCommission) {
    //   return (
    //     <div style={{marginBottom: "24px"}}>
    //       <Row>
    //         <InputNumber
    //           style={{width: "100%"}}
    //           defaultValue={0}
    //           // formatter={this.currencyFormatter}
    //           // parser={this.currencyParser}
    //           onChange={this.handleAmount}
    //           step="1.00"
    //           min="0"
    //           formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //           parser={value => value.replace(/\$\s?|(,*)/g, '')}
    //           value={this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]
    //           ? this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`] : 0}
    //           // placeholder={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           stringMode
    //         />
    //       </Row>
    //     </div>

    //   )
    // } else if (this.props.field.id === 18 && this.props.autoUpdateAgentPaid) {
    //   return (
    //     <div style={{marginBottom: "24px"}}>
    //       <Row>
    //         <InputNumber
    //           style={{width: "100%"}}
    //           defaultValue={0}
    //           // formatter={this.currencyFormatter}
    //           // parser={this.currencyParser}
    //           onChange={this.handleAmount}
    //           step="1.00"
    //           min="0"
    //           formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //           parser={value => value.replace(/\$\s?|(,*)/g, '')}
    //           value={this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]
    //           ? this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`] : 0}
    //           // placeholder={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           stringMode
    //         />
    //       </Row>
    //     </div>

    //   )
    // } else if (this.props.field.id === 19 && this.props.updatedHourlyRate) {
    //   return (
    //     <div style={{marginBottom: "24px"}}>
    //       <Row>
    //         <InputNumber
    //           style={{width: "100%"}}
    //           defaultValue={this.state.userSetting ? this.state.userSetting.hourly_rate : this.state.transactionSetting ? this.state.transactionSetting.hourly_rate : 0}
    //           // formatter={this.currencyFormatter}
    //           // parser={this.currencyParser}
    //           onChange={this.handleAmount}
    //           step="1.00"
    //           min="0"
    //           formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //           parser={value => value.replace(/\$\s?|(,*)/g, '')}
    //           value={this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]
    //           ? this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`] : 0}
    //           // placeholder={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           stringMode
    //         />
    //       </Row>
    //     </div>
    //   )
    // } else if (this.props.closingAmount && this.props.field.id === 14) {
    //   return (
    //     <div style={{marginBottom: "24px"}}>
    //       <Row>
    //         <InputNumber
    //           style={{width: "100%"}}
    //           defaultValue={0}
    //           // formatter={this.currencyFormatter}
    //           // parser={this.currencyParser}
    //           onChange={(value) => {this.handleAmount(value);}}
    //           step="1.00"
    //           min="0"
    //           formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //           parser={value => value.replace(/\$\s?|(,*)/g, '')}
    //           value={this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]
    //           ? this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`] : 0}
    //           // placeholder={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //           stringMode
    //         />
    //       </Row>
    //     </div>

    //   )
    // } else {
    //   return (
    //     <Form.Item
    //       name={[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]}
    //       rules={this.props.field.required || this.props.field.chosen_required ? requiredRule : null}
    //       initialValue={0}
    //         // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //     >
    //       <InputNumber
    //         style={{width: "100%"}}
    //         // defaultValue={0}
    //         // formatter={this.currencyFormatter}
    //         // parser={this.currencyParser}
    //         onChange={this.handleAmount}
    //         step="1.00"
    //         min="0"
    //         formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    //         parser={value => value.replace(/\$\s?|(,*)/g, '')}
    //         // value={this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`] ? this.state[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`] : 0}
    //         // placeholder={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //         // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
    //         stringMode
    //       />
    //     </Form.Item>
    //   )
    // }
  };
  getCommissionLabel = () => {
    if (
      this.props.field.name.toLowerCase() === 'gci' ||
      this.props.field.id === 13
    ) {
      return (
        <span>
          {' '}
          {this.state.transactionSetting
            ? `(${this.state.transactionSetting.gci}% of Transaction Amount)`
            : null}
        </span>
      );
    } else if (this.props.field.id === 18) {
      return (
        <span>
          {this.state.userSetting
            ? `(${this.state.userSetting.gapi}% of GCI)`
            : this.state.transactionSetting
            ? `(${this.state.transactionSetting.gapi})`
            : null}
        </span>
      );
    }
  };
  // getHourlyRateInput = () => {
  //   return (
  //     <Form.Item
  //       name={[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]}
  //       rules={this.props.field.required || this.props.field.chosen_required ? requiredRule : null}
  //       initialValue={this.state.userSetting ? this.state.userSetting.hourly_rate : this.state.transactionSetting ? this.state.transactionSetting.hourly_rate : 0}
  //       // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
  //     >
  //       <InputNumber
  //         style={{width: "100%"}}
  //         onChange={this.handleAmount}
  //         step="1.00"
  //         min="0"
  //         formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //         parser={value => value.replace(/\$\s?|(,*)/g, '')}
  //         stringMode
  //       />
  //     </Form.Item>
  //   )
  // }
  // getNoSettingHourlyRateInput = () => {
  //   return (
  //     <Form.Item
  //       name={[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]}
  //       rules={this.props.field.required || this.props.field.chosen_required ? requiredRule : null}
  //       initialValue={this.state.userSetting ? this.state.userSetting.hourly_rate : this.state.transactionSetting ? this.state.transactionSetting.hourly_rate : 0}
  //       // value={this.props.field.id === 13 && this.state.commission? this.state.commission : 0}
  //     >
  //       <InputNumber
  //         style={{width: "100%"}}
  //         onChange={this.handleAmount}
  //         step="1.00"
  //         min="0"
  //         formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
  //         parser={value => value.replace(/\$\s?|(,*)/g, '')}
  //         stringMode
  //       />
  //     </Form.Item>
  //   )
  // }
  getAmountInput = () => {
    if (this.props.field) {
      return (
        <div style={{ width: '100%' }}>
          <span style={{ marginRight: '5px' }}>{this.props.field.name}</span>
          {this.getCommissionLabel()}
          {this.props.field.tooltip ? (
            <span>
              <Tooltip title={this.props.field.tooltip}>
                <InfoCircleFilled style={{ color: '#0789ed' }} />
              </Tooltip>
            </span>
          ) : null}
          {this.getControlledInput()}
        </div>
      );
    }
  };

  render() {
    return (
      <div ref={this.formRef}>
        <div>
          <Row>{this.getAmountInput()}</Row>
        </div>
      </div>
    );
  }
}

export default FormAmountElement;
