import React from "react";
import { Row, Input, Tooltip, Form } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

const requiredRule = [
  {
    // type: 'text',
    required: true,
    message: "Response is requried"
  }
]
class FormTextField extends React.Component {

  constructor() {
    super();
    this.state={
      textContent: null,
    }
  }

  getTextInput = () => {
    if (this.props.field) {
      return (
        <div style={{width: "100%"}}>
          <span style={{marginRight: "5px"}}>{this.props.field.name}</span>
          {this.props.field.tooltip ?
            <span><Tooltip title={this.props.field.tooltip}><InfoCircleFilled style={{color: "#0789ed"}}/></Tooltip></span>
            :null
          }
          <Form.Item
            name={[`${this.props.field.type}`, `${this.props.field.type}_${this.props.field.id}`]}
            rules={this.props.field.required || this.props.field.chosen_required ? requiredRule : null}
          >
            {/* <DatePicker style={{width: "50%"}}/> */}
            <Input></Input>
          </Form.Item>
        </div>
      )
    }
  }

  render() {

    return (
      <div>
        <div>
          <Row>
            {this.getTextInput()}
          </Row>
        </div>
      </div>
    )
  }
  




}

export default FormTextField;



