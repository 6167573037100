import React from 'react';
import { message, DatePicker, Popover } from 'antd';
import httpHandler from '../../../helpers/httpHandler';
import StatTrackerHelper from '../../../helpers/StatTrackerHelper';
import AdditionalStatHelper from '../../../helpers/AdditionalStatHelper';
import socketHandler from '../../../services/Socket';
import ListOfSubmissionsModal from './listOfSubmissionsModal/ListOfSubmissionsModal';
import ChartList from './chartList/ChartList';
import SettingsPanel from './settingsPanel/SettingsPanel';
import { SettingFilled } from '@ant-design/icons';
import moment from 'moment-timezone';
import DateHelper from '../../../helpers/DateHelper';
import dayjs from 'dayjs';
class GraphViewer extends React.Component {
  constructor() {
    super();
    this.state = {
      padding: 30,
      initData: [],
      dataDisplay: 'Cold_call_appointments_set_19',
      totalDataDisplay: 'number_of_appointments_set',
      displayOptions: [],
      teamLeader: null,
      dataForBarChart: null,
      stackedChartFields: [],
      hybridChartFields: [
        'number_of_appointments_set',
        'added_to_facebook_group'
      ],
      showYesterday: ['Show yesterday'],
      selectedSortField: 'Cold_call_appointments_set_19',
      lineChartDisplay: 'rolling_dates',
      radioOption: 'previous_week',
      curRadioOption: 'current_week',
      rollingUnit: 'weeks',
      rollingValue: 4,
      statGoal: 3,
      teamTotals: [],
      showOptions: [],
      memberTeams: null,
      selectedTeam: null,
      squadView: [],
      // lineChartSeries: ["team_total"],
      lineChartSeries: [],
      chartMemberSeries: [],
      chartSquadSeries: [],
      dataForLineChart: null,
      showTeamTotalForChart: [],
      datesTotalDisplay: 'rolling_dates',
      processedDatesTotalData: null,
      dailyCommit: 3,
      selectedStatFields: [],
      dailyTrackerStats: [],
      isActive: false,
      panelVisible: false,
      dateOptionDisplay: 'rolling_dates',
      loadingDailyTracker: false,
      loadingLineChart: false,
      categoryOptions: []
    };
    this.graphRef = React.createRef();
  }

  componentDidMount() {
    socketHandler.getSocket().on('statUpdate', (data) => {
      this.updateGraph(data);
    });
    if (this.props.selectedTeam) {
      this.loadMyTeams();
    }
    if (this.props.selectedChart) {
      this.setState(
        {
          selectedChart: this.props.selectedChart
        },
        () => {
          if (this.props.initialized) {
            this.props.initialized();
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedTeam !== prevProps.selectedTeam) {
      this.loadMyTeams();
    }
    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.getDates();
    }
    if (
      (this.state.lineChartDisplay !== prevState.lineChartDisplay &&
        this.state.lineChartDisplay !== 'static_dates') ||
      (this.state.datesTotalDisplay !== prevState.datesTotalDisplay &&
        this.state.datesTotalDisplay !== 'static_dates')
    ) {
      if (
        prevState.datesTotalDisplay === 'static_dates' ||
        (prevState.lineChartDisplay === 'static_dates' &&
          this.props.selectedTeam === prevProps.selectedTeam)
      ) {
        if (this.props.enableCalendar) {
          this.props.enableCalendar();
        }
      }
      this.setState({ staticStart: null, staticEnd: null });
    }
    if (
      this.props.selectedChart !== prevProps.selectedChart &&
      this.props.selectedChart
    ) {
      this.setState(
        {
          selectedChart: this.props.selectedChart
        },
        () => {
          this.loadChartOptionSettings();
          this.loadChartConfigSettings();
        }
      );
    }
  }

  //This gets the fields for the particular selected team id
  //the fields are use for parsing
  loadCustomFieldConfig = () => {
    httpHandler
      .get('/api/st1/customStats/getStatTrackingFields', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((selectedFields) => {
        this.setState({ selectedStatFields: selectedFields }, () => {
          if (this.props.getSelectedStatFields) {
            this.props.getSelectedStatFields(selectedFields);
          }
          this.loadSelectedStackFields();
          this.loadSquads();
          this.loadCategories();
        });
      });
  };

  loadCategories = () => {
    httpHandler
      .get('/api/st1/customStats/getCategories', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((categories) => {
        this.setState({ categoryOptions: categories });
      });
  };

  loadChartOptionSettings = () => {
    httpHandler
      .get('/api/st1/customStats/getChartOptionSettings', {
        teamId: this.props.selectedTeam.team_id,
        location: this.props.statDisplayId
          ? this.props.graph_name.split('_')[0]
          : this.props.graph_name,
        chartType: this.state.selectedChart,
        statDisplayId: this.props.statDisplayId
      })
      .then((settings) => {
        let setting = settings[0];
        if (setting) {
          this.setState(
            {
              dataDisplay: setting.data_display,
              curRadioOption: setting.cur_date_option,
              dateOptionDisplay: setting.date_type,
              lineChartDisplay:
                this.state.selectedChart === setting.chart_type &&
                setting.date_type
                  ? setting.date_type
                  : 'rolling_dates',
              datesTotalDisplay:
                this.state.selectedChart === setting.chart_type &&
                setting.date_type
                  ? setting.date_type
                  : 'rolling_dates',
              radioOption: setting.prev_date_option,
              rollingUnit: setting.rolling_date_unit,
              rollingValue: setting.rolling_date_value,
              showYesterday: setting.show_yesterday ? ['Show yesterday'] : [],
              selectedSortField: setting.sort_by_field,
              statGoal: setting.stat_goal,
              squadView: setting.view_squad ? ['Squad view'] : [],
              staticStart: setting.static_start_date,
              staticEnd: setting.static_end_date
            },
            () => {
              this.getDates();
            }
          );
        } else {
          if (
            this.state.selectedChart === 'team-total-column-chart' ||
            this.state.selectedChart === 'team-hybrid-bar-chart'
          ) {
            this.getDates();
            // this.getDailyTrackerStats();
          } else if (
            this.state.selectedChart === 'team-dates-total-chart' ||
            this.state.selectedChart === 'team-line-chart' ||
            this.state.selectedChart === 'single-stat-display'
          ) {
            this.setState(
              {
                dataDisplay: 'Category_Appointment_2'
              },
              () => {
                this.getDates();
              }
            );
          }
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  loadDataPointsToDisplay = () => {
    httpHandler
      .get('/api/st1/customStats/getAllDataPointSelections', {
        teamId: this.props.selectedTeam.team_id,
        location: this.props.statDisplayId
          ? this.props.graph_name.split('_')[0]
          : this.props.graph_name,
        chartType: this.state.selectedChart,
        statDisplayId: this.props.statDisplayId
      })
      .then((selections) => {
        let squads = [];
        let members = [];
        let team = [];
        for (let selection of selections) {
          if (selection.type === 'squad') {
            squads.push(selection.name);
          } else if (selection.type === 'team') {
            team.push(selection.name);
          } else if (selection.type === 'member') {
            members.push(selection.name);
          }
        }
        this.setState(
          {
            chartMemberSeries: members,
            showTeamTotalForChart: team,
            chartSquadSeries: squads,
            lineChartSeries: [...team, ...squads, ...members]
          },
          () => {
            this.loadChartOptionSettings();
          }
        );
      })
      .catch((error) => {})
      .finally(() => {});
  };

  loadSelectedStackFields = () => {
    httpHandler
      .get('/api/st1/customStats/getAllSelectedFieldsAtLocation', {
        teamId: this.props.selectedTeam.team_id,
        location: this.props.statDisplayId
          ? this.props.graph_name.split('_')[0]
          : this.props.graph_name,
        statDisplayId: this.props.statDisplayId
      })
      .then((trackedFields) => {
        this.setState(
          {
            stackedChartFields: [
              ...trackedFields.map((field) => {
                if (field.id) {
                  return field.name.split(' ').join('_') + `_${field.id}`;
                } else {
                  return `Category_${field.category_name
                    .split(' ')
                    .join('_')}_${field.category_id}`;
                }
              })
            ]
          },
          () => {}
        );
      })
      .catch((error) => {})
      .finally(() => {});
  };
  loadSquads = () => {
    httpHandler
      .get('/api/st1/teamStats/getSquads', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((squads) => {
        squads.forEach((squad) => (squad.squadSortTotal = 0));
        this.setState(
          {
            squads: [
              ...squads,
              { id: -1, squadSortTotal: 0, squad_name: 'No squad' }
            ]
          },
          () => {
            this.getLineChartSquadOptions();
            this.loadDataPointsToDisplay();
          }
        );
      })
      .catch((error) => {
        console.log('unable to get team totals', error);
      })
      .finally(() => {});
  };

  getDailyTrackerStats = () => {
    httpHandler
      .get('/api/st1/customStats/getTeamMemberStats', {
        teamId: this.props.selectedTeam.team_id,
        endDate: this.props.selectedDate,
        chartType: this.state.selectedChart
      })
      .then((memberStats) => {
        let stats = StatTrackerHelper.filterDataWithRoles(memberStats);
        let processedStats = StatTrackerHelper.parseInitialData(stats);
        this.setState({ processedStats: processedStats }, () => {
          let dailyStats = StatTrackerHelper.processDailyTrackerData(
            processedStats,
            this.state.selectedStatFields,
            this.props.selectedDate,
            stats,
            this.state.categoryOptions
          );

          this.setState(
            {
              dailyTrackerStats: dailyStats.sort(
                (a, b) =>
                  b[this.state.selectedSortField + '_yesterday'] -
                  a[this.state.selectedSortField + '_yesterday']
              ),
              loadingDailyTracker: false
            },
            () => {
              if (
                (this.state.squadView.length > 0 &&
                  this.state.selectedChart === 'team-hybrid-bar-chart') ||
                this.state.selectedChart === 'team-line-chart'
              ) {
                this.restructureChartData();
              }
              // this.subscribeToGraph();
              this.getOptionsForStackedFields();
            }
          );
          let teamTotal = StatTrackerHelper.processDailyTeamTotalData(
            processedStats,
            this.state.selectedStatFields,
            this.props.selectedDate,
            this.props.selectedTeam,
            this.state.categoryOptions
          );
          this.setState({
            statsForTeamTotal: teamTotal
          });
        });
      });
  };
  getDateRangeStats = (data) => {
    httpHandler
      .get('/api/st1/customStats/getTeamMemberStats', {
        teamId: this.props.selectedTeam.team_id,
        endDate: !this.state.dates
          ? this.props.selectedDate
          : this.state.dates[0],
        startDate: this.state.dates
          ? this.state.dates[this.state.dates.length - 1]
          : null,
        chartType: this.state.selectedChart
      })
      .then((memberStats) => {
        if (this.state.selectedChart === 'single-stat-display') {
          memberStats[1] = [
            'ISA',
            'Buyer Agent',
            'Listing Agent',
            'Squad Leader',
            'Showing Agent',
            'Admin',
            'Transaction Coordinator',
            'Recruiter'
          ];
        }
        let stats = StatTrackerHelper.filterDataWithRoles(memberStats);
        let processedStats = StatTrackerHelper.parseInitialData(stats);
        this.setState({ processedStats: processedStats }, () => {
          if (
            this.state.selectedChart === 'team-dates-total-chart' ||
            this.state.selectedChart === 'single-stat-display'
          ) {
            let finalStats = StatTrackerHelper.processDatesBarChartData(
              processedStats,
              this.state.dates,
              this.state.selectedStatFields,
              this.state.squads,
              stats,
              this.state.categoryOptions
            );
            this.setState({ statsForDatesTotal: finalStats }, () =>
              this.filterFinalDatesTotalStats(data)
            );
          } else if (this.state.selectedChart === 'team-line-chart') {
            if (
              this.state.lineChartDisplay === 'rolling_dates' &&
              this.state.rollingUnit === 'months'
            ) {
              this.getMonthlyLineChartStats();
            } else if (
              this.state.lineChartDisplay === 'rolling_dates' &&
              this.state.rollingUnit === 'weeks'
            ) {
              this.getWeeklyLineChartStats();
            } else {
              let finalStats = StatTrackerHelper.processDatesLineChartData(
                processedStats,
                this.state.dates,
                this.state.selectedStatFields,
                this.state.squads,
                this.state.dataDisplay,
                'day',
                this.state.categoryOptions
              );
              this.setState({
                statsForLineChart: finalStats,
                loadingLineChart: false
              });
            }
          }
          this.getLineChartDisplayDataOptions();
        });
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getMonthlyLineChartStats = () => {
    let finalStats = StatTrackerHelper.processDatesLineChartData(
      this.state.processedStats,
      this.state.dates,
      this.state.selectedStatFields,
      this.state.squads,
      this.state.dataDisplay,
      'monthly',
      this.state.categoryOptions
    );
    this.setState({ statsForLineChart: finalStats, loadingLineChart: false });
  };
  getWeeklyLineChartStats = () => {
    let finalStats = StatTrackerHelper.processDatesLineChartData(
      this.state.processedStats,
      this.state.dates,
      this.state.selectedStatFields,
      this.state.squads,
      this.state.dataDisplay,
      'weekly',
      this.state.categoryOptions
    );
    this.setState({ statsForLineChart: finalStats, loadingLineChart: false });
  };
  filterFinalDatesTotalStats = (data) => {
    let result = AdditionalStatHelper.getFinalDatesTotalStats(
      this.state.statsForDatesTotal,
      this.state.chartSquadSeries,
      this.state.chartMemberSeries,
      this.state.showTeamTotalForChart
    );
    this.setState((prevState) => ({
      processedDatesTotalData: result,
      dataForDatesTotalChart: data
        ? data.stat
        : prevState.dataForDatesTotalChart
    }));
  };

  loadChartConfigSettings = () => {
    httpHandler
      .get('/api/st1/customStats/getChartConfigSettings', {
        teamId: this.props.selectedTeam.team_id,
        location: this.props.statDisplayId
          ? this.props.graph_name.split('_')[0]
          : this.props.graph_name,
        statDisplayId: this.props.statDisplayId
      })
      .then((settings) => {
        if (settings.length > 0) {
          let setting = settings[0];
          this.setState(
            {
              selectedChart: setting.chart_type,
              loadingLineChart: setting.chart_type === 'team-line-chart',
              selectedSingleStatDataPoint: setting.selected_data_point
            },
            () => {
              this.loadCustomFieldConfig();
            }
          );
        } else {
          this.setState({ selectedChart: 'team-hybrid-bar-chart' }, () => {
            this.loadCustomFieldConfig();
          });
        }
      })
      .catch((error) => {})
      .finally(() => {
        this.loadAllInputFieldConfig();
      });
  };

  saveChartConfigSettings = async () => {
    const currentUrl = window.location.href.split('/');
    let result = await AdditionalStatHelper.saveChartConfigSettings(
      this.props.selectedTeam,
      this.props.statDisplayId,
      this.props.graph_name,
      this.state.selectedChart,
      this.state.selectedSingleStatDataPoint,
      {
        isCoachingDashboard: this.props.isCoachingDashboard,
        clientId: currentUrl[currentUrl.length - 1]
      }
    );
    if (result) {
      this.saveChartOptionSettings();
    }
  };

  saveChartOptionSettings = () => {
    let result = AdditionalStatHelper.saveChartOptionSettings(
      this.props.selectedTeam,
      this.props.statDisplayId,
      this.props.graph_name,
      this.state.selectedChart,
      this.state.showYesterday,
      this.state.selectedSortField,
      this.state.dataDisplay,
      this.state.lineChartDisplay,
      this.state.datesTotalDisplay,
      this.state.radioOption,
      this.state.rollingUnit,
      this.state.rollingValue,
      this.state.staticStart,
      this.state.staticEnd,
      this.state.statGoal,
      this.state.squadView,
      this.state.curRadioOption
    );
    if (result) {
      message.success('Settings have been saved successfully.');
    }
  };

  getTeamOptionForChartSettings = () => {
    if (this.props.selectedTeam) {
      this.setState({
        teamOption:
          this.props.selectedTeam.team_name +
          '~~' +
          this.props.selectedTeam.team_id
      });
    }
  };

  ////////

  getUserSquad = () => {
    httpHandler
      .get('/api/st1/teamStats/getUserSquad', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((squadInfo) => {
        if (squadInfo.length > 0) {
          this.setState({ userSquadInfo: squadInfo[0] });
        }
      })
      .catch((error) => {})
      .finally(() => {
        this.loadAllInputFieldConfig();
      });
  };

  checkSquadStandings = () => {
    let sameOrder = AdditionalStatHelper.checkSquadStanding(
      this.state.squads,
      this.state.initData,
      this.state.selectedSortField
    );
    if (!sameOrder) {
      this.restructureChartData();
    }
  };

  restructureChartData = () => {
    if (this.state.squadView.length > 0) {
      let { result, orderSquads } = StatTrackerHelper.processSquadViewData(
        this.state.dailyTrackerStats,
        this.state.squads,
        this.state.selectedSortField
      );
      this.setState({ dailyTrackerStats: result, squads: orderSquads });
    } else {
      // this.setState(prevState => ({initData: prevState.initData.sort((a, b) => b["number_of_appointments_set_yesterday"] - a["number_of_appointments_set_yesterday"])}));
      this.setState((prevState) => ({
        dailyTrackerStats: prevState.dailyTrackerStats.sort(
          (a, b) =>
            b[this.state.selectedSortField] - a[this.state.selectedSortField]
        )
      }));
    }
  };

  loadAllInputFieldConfig = () => {
    httpHandler
      .get('/api/st1/customStats/getStatTrackingFields', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((fieldConfigs) => {
        if (this.state.userSquadInfo && this.state.userSquadInfo.squad_id) {
          let squadConfig = fieldConfigs.filter(
            (config) => config.squad_id === this.state.userSquadInfo.squad_id
          );
          if (squadConfig.length > 0) {
            let config = squadConfig[0];
            if (config.override_team_config) {
              this.setState(
                {
                  curTeamFieldConfig: fieldConfigs
                    .filter(
                      (fieldConfig) =>
                        fieldConfig.squad_id ===
                        this.state.userSquadInfo.squad_id
                    )[0]
                    .field.split(',')
                },
                () => {
                  if (this.props.getFieldConfig)
                    this.props.getFieldConfig(this.state.curTeamFieldConfig);
                }
              );
            } else {
              this.setState(
                {
                  curTeamFieldConfig: fieldConfigs
                    .filter((fieldConfig) => fieldConfig.squad_id === -1)[0]
                    .field.split(',')
                },
                () => {
                  if (this.props.getFieldConfig)
                    this.props.getFieldConfig(this.state.curTeamFieldConfig);
                }
              );
            }
          }
        } else {
          this.setState(
            {
              curTeamFieldConfig: fieldConfigs
                .filter((fieldConfig) => fieldConfig.squad_id === -1)[0]
                .field.split(',')
            },
            () => {
              if (this.props.getFieldConfig)
                this.props.getFieldConfig(this.state.curTeamFieldConfig);
            }
          );
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  loadMyTeams = () => {
    this.setState(
      {
        statsForDatesTotal: null,
        processedDatesTotalData: null,
        loadingDailyTracker: true
      },
      () => {
        this.getTeamTotals();
        this.subscribeToGraph();
        this.loadChartConfigSettings();
        this.getTeamOptionForChartSettings();
        this.getUserSquad();
      }
    );
  };

  getTeamTotals = () => {
    httpHandler
      .get('/api/st1/teamStats/teamTotalStats', {
        date: DateHelper.ToUtcDateTime(
          this.props.selectedDate,
          { hours: new Date().getHours() },
          'YYYY-MM-DD'
        ),
        teamId: this.props.selectedTeam.team_id,
        teamLeader: this.props.selectedTeam
      })
      .then((team_totals) => {
        team_totals[0].day = 'yesterday';
        team_totals[1].day = 'today';
        this.setState({ teamTotals: team_totals }, () => {});
      })
      .catch((error) => {
        console.log('unable to get team totals', error);
      })
      .finally(() => {});
  };

  updateGraph = (data) => {
    let { user_id, field, value, date, category } = data.stat;

    let curStats = [...this.state.dailyTrackerStats];
    curStats = JSON.parse(JSON.stringify(curStats));
    let difference = 0;
    for (let i = 0; i < curStats.length; i++) {
      let user = curStats[i];
      if (user.user_id === user_id) {
        if (date === user.date_today) {
          difference = user[field] - value;
          user[field] = value;
          user[category] -= difference;
        } else if (date === user.date_yesterday) {
          difference = user[field + '_yesterday'] - value;
          user[field + '_yesterday'] = value;
          user[category + '_yesterday'] -= difference;
        }
      }
    }

    this.setState(
      (prevState) => ({
        initData: curStats,
        dailyTrackerStats: curStats,
        dataForBarChart: { ...data.stat, difference: difference },
        dataForLineChart: data
        // dataForDatesTotalChart: data.stat,
      }),
      () => {
        // this.getTeamTotals();
        if (this.state.selectedChart === 'team-total-column-chart') {
          // this.getDailyTrackerStats();
          this.getDates();
        }
        // this.checkSquadStandings();
        if (this.state.selectedChart === 'team-line-chart') {
          this.getDateRangeStats();
          // this.getLineChartStats();
        } else if (
          this.state.selectedChart === 'team-dates-total-chart' ||
          this.state.selectedChart === 'single-stat-display'
        ) {
          // this.getAggregateTotalStats();
          this.getDateRangeStats(data);
        }
        if (this.props.statsUpdated) {
          this.props.statsUpdated(data.stat);
        }
        this.checkSquadStandings();
      }
    );
  };

  subscribeToGraph = () => {
    if (this.props.selectedTeam) {
      StatTrackerHelper.subscribeToGraph(this.props.selectedTeam);
    }
  };

  handleSelectChart = (value) => {
    this.setState(
      {
        selectedChart: value,
        statsForDatesTotal: null,
        processedDatesTotalData: null
      },
      () => {
        this.loadCustomFieldConfig();
      }
    );
  };
  //settingspanel
  handleSelect = (value) => {
    if (this.state.dataDisplay !== value) {
      this.setState({ dataDisplay: value }, () => {
        if (
          this.state.rollingUnit === 'weeks' &&
          this.state.lineChartDisplay === 'rolling_dates' &&
          this.state.selectedChart === 'team-line-chart'
        ) {
          // this.buildWeeklyLineChartData();
          if (!this.state.dates) {
            this.getDates();
          } else {
            this.getWeeklyLineChartStats();
          }
        } else if (
          this.state.rollingUnit === 'months' &&
          this.state.lineChartDisplay === 'rolling_dates' &&
          this.state.selectedChart === 'team-line-chart'
        ) {
          // this.buildMonthlyLineChartData();
          if (!this.state.dates) {
            this.getDates();
          } else {
            this.getMonthlyLineChartStats();
          }
        } else if (this.state.selectedChart === 'team-line-chart') {
          // this.buildFinalLineChartData();
          if (!this.state.dates) {
            this.getDates();
          } else {
            this.getDateRangeStats();
          }
        } else {
          this.getDates();
        }
      });
    }
  };

  handleChangeFields = (e) => {
    let fields = [...this.state.stackedChartFields];
    let checked = e.target.checked;
    if (checked) {
      fields.push(e.target.value);
      this.handleSelectingStackedFields(e.target.value, fields, checked);
    } else {
      fields = fields.filter((field, index) => field !== e.target.value);
      this.handleSelectingStackedFields(e.target.value, fields, checked);
    }
  };

  handleSelectingStackedFields = (field, values, checked) => {
    let result = AdditionalStatHelper.handleSelectingStackedFields(
      field,
      checked,
      this.props.selectedTeam,
      this.props.statDisplayId,
      this.props.graph_name
    );
    if (result) {
      this.setState({ stackedChartFields: values });
    }
  };

  getOptionsForStackedFields = () => {
    let options = AdditionalStatHelper.getOptionsForStackedFields(
      this.state.categoryOptions,
      this.state.selectedStatFields
    );
    this.setState({ showOptions: options });
  };

  handleDaySelect = (value) => {
    this.setState({ showYesterday: value });
  };

  handleSquadViewSelect = (value) => {
    this.setState({ squadView: value }, () => this.restructureChartData());
  };

  handleChangeSort = (value) => {
    this.setState({ selectedSortField: value }, () => {
      if (
        this.state.selectedChart === 'team-hybrid-bar-chart' &&
        this.state.squadView.length > 0
      ) {
        this.restructureChartData();
      }
    });
  };

  handleSetGoal = (e) => {
    this.setState({ statGoal: e.target.value >= 0 ? e.target.value : 0 });
  };

  getTeamFormSubmissions = (userId, date) => {
    httpHandler
      .get('/api/st1/financials/getFormSubmissionsForTeam', {
        teamId: this.props.selectedTeam.team_id,
        date,
        userId
      })
      .then((submissions) => {
        this.setState({
          teamFormSubmissions: submissions.sort(
            (a, b) => moment(b.date) - moment(a.date)
          )
        });
      });
  };
  getClickedOnSeries = (category, data) => {
    this.setState(
      {
        visibleSubmissions: true,
        selectedSeriesCategory: category,
        selectedSeriesData: data
      },
      () => {
        let date = null;
        if (category.valueX.includes('yesterday')) {
          date = this.state.dates[this.state.dates.length - 1];
        } else {
          date = this.state.dates[0];
        }

        const openIndex = data.first_name.indexOf('(');
        const closeIndex = data.first_name.indexOf(')');
        const userId = +data.first_name.slice(openIndex + 1, closeIndex);
        this.getTeamFormSubmissions(userId, date);
      }
    );
  };
  saveChartConfig = () => {
    let result = AdditionalStatHelper.saveChartConfig(
      this.state.curRadioOption,
      this.state.prevRadioOption,
      this.state.startDate,
      this.state.endDate,
      this.state.rollingDateType,
      this.state.rollingDateValue,
      this.state.selectedChart,
      this.state.selectedSortField,
      this.state.statGoal,
      this.state.showYesterday,
      this.state.stackedChartFields,
      this.state.selectedTeam,
      this.state.dataDisplay,
      this.state.squadView,
      this.state.lineChartDisplay,
      this.state.datesTotalDisplay,
      this.state.chartSquadSeries,
      this.state.chartMemberSeries
    );
    if (result) {
      message.success('Configuration saved.');
    }
  };

  getDates = () => {
    let curDates = AdditionalStatHelper.getDates(
      this.props.selectedDate,
      this.state.selectedChart,
      this.state.rollingUnit,
      this.state.rollingValue,
      this.state.lineChartDisplay,
      this.state.datesTotalDisplay,
      this.state.staticStart,
      this.state.staticEnd,
      this.state.radioOption,
      this.state.curRadioOption
    );
    if (
      this.state.selectedChart === 'team-hybrid-bar-chart' ||
      this.state.selectedChart === 'team-total-column-chart'
    ) {
      this.setState({ dates: curDates }, () => this.getDailyTrackerStats());
    } else {
      if (
        (this.state.selectedChart === 'team-line-chart' &&
          this.state.lineChartDisplay !== 'previous_dates' &&
          this.state.lineChartDisplay !== 'static_dates' &&
          this.state.lineChartDisplay !== 'current_dates') ||
        (this.state.selectedChart === 'team-dates-total-chart' &&
          this.state.datesTotalDisplay !== 'previous_dates' &&
          this.state.datesTotalDisplay !== 'static_dates' &&
          this.state.datesTotalDisplay !== 'current_dates') ||
        (this.state.selectedChart === 'single-stat-display' &&
          this.state.datesTotalDisplay !== 'previous_dates' &&
          this.state.datesTotalDisplay !== 'static_dates' &&
          this.state.datesTotalDisplay !== 'current_dates')
      ) {
        this.setState({ dates: curDates }, () => {
          this.getDateRangeStats();
        });
      } else {
        if (
          (this.state.selectedChart === 'team-line-chart' &&
            !this.state.staticStart &&
            !this.state.staticEnd &&
            this.state.lineChartDisplay !== 'static_dates') ||
          (this.state.selectedChart === 'team-dates-total-chart' &&
            !this.state.staticStart &&
            !this.state.staticEnd &&
            this.state.datesTotalDisplay !== 'static_dates') ||
          (this.state.selectedChart === 'single-stat-display' &&
            !this.state.staticStart &&
            !this.state.staticEnd &&
            this.state.datesTotalDisplay !== 'static_dates')
        ) {
          this.setState({ dates: curDates }, () => {
            this.getDateRangeStats();
          });
        } else {
          if (this.state.staticStart && this.state.staticEnd) {
            this.pickDateRange([
              moment.utc(this.state.staticStart),
              moment.utc(this.state.staticEnd)
            ]);
          } else {
            this.setState({ dates: curDates }, () => {
              this.getDateRangeStats();
            });
          }
        }
      }
    }
  };

  getAggregateTotalStats = async () => {
    const result = await httpHandler.get(
      '/api/st1/teamStats/getAggregateTotalByDates',
      {
        endDate: DateHelper.ToUtcDateTime(
          this.state.dates[0],
          { hours: 24 },
          'YYYY-MM-DD HH:mm'
        ),
        startDate: DateHelper.ToUtcDateTime(
          this.state.dates[this.state.dates.length - 1],
          { hours: 0 },
          'YYYY-MM-DD HH:mm'
        ),
        teamId: this.props.selectedTeam.team_id
      }
    );
    this.setState({ initialDatesTotalStats: result }, () => {
      this.processDatesTotalData();
      this.getDateRangeStats();
    });
  };
  processDatesTotalData = () => {
    let data = [...this.state.initData];
    let membersTotalStats = [...this.state.initialDatesTotalStats[0].members];
    let teamTotalStats = [...this.state.initialDatesTotalStats[1].team];
    let squadTotalStats = JSON.parse(
      JSON.stringify(this.state.initialDatesTotalStats[2].squads)
    );
    AdditionalStatHelper.getProcessDatesTotalData(
      data,
      membersTotalStats,
      teamTotalStats,
      squadTotalStats,
      this.state.squads
    );

    this.setState(
      {
        allProcessedDatesTotalData: [
          ...membersTotalStats,
          ...teamTotalStats,
          ...squadTotalStats
        ]
      },
      () => {
        this.filterTotalDatesData();
      }
    );
  };
  filterTotalDatesData = () => {
    let finalData = JSON.parse(
      JSON.stringify(this.state.allProcessedDatesTotalData)
    );

    let squads = this.state.chartSquadSeries;
    let members = this.state.chartMemberSeries;
    let tempSquad = [];
    let tempMembers = [];
    AdditionalStatHelper.getfilterTotalDatesData(
      finalData,
      squads,
      members,
      tempSquad,
      tempMembers
    );
    this.setState({ processedDatesTotalData: [...tempSquad, ...tempMembers] });
  };

  pickDateRange = (dates) => {
    if (dates && dates.length > 1) {
      let startDate = dates[0].format('YYYY-MM-DD');
      let endDate = dates[1].format('YYYY-MM-DD');
      let newDates = this.getDateRange(startDate, endDate, 'days');

      this.setState(
        {
          dates: newDates.reverse(),
          staticStart: startDate,
          staticEnd: endDate
        },
        () => {
          if (this.state.selectedChart === 'team-line-chart') {
            this.getDateRangeStats();
          } else if (
            this.state.selectedChart === 'team-dates-total-chart' ||
            this.state.selectedChart === 'single-stat-display'
          ) {
            this.getDateRangeStats();
          }
        }
      );
    } else {
      this.setState({ staticStart: null, staticEnd: null }, () => {
        this.getDates();
      });
    }
  };
  getDateRange = (startDate, endDate, type) => {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, type);
    let range = [];
    for (let i = 0; i < diff; i++) {
      range.push(moment(startDate).add(i, type).format('YYYY-MM-DD'));
    }
    range.push(endDate);
    return range;
  };
  handleRollingValue = () => {
    let value = this.state.rollingValue;
    if (
      parseFloat(value) === parseInt(value) &&
      !isNaN(value) &&
      parseInt(value) !== 0
    ) {
      if (this.state.rollingUnit === 'weeks') {
        let dates = this.getDateRange(
          moment(this.props.selectedDate)
            .subtract(this.state.rollingValue * 7 - 1, 'd')
            .format('YYYY-MM-DD'),
          this.props.selectedDate,
          'days'
        );
        this.setState({ dates: dates.reverse() }, () => {
          if (this.state.selectedChart === 'team-line-chart') {
            this.getDateRangeStats();
            // this.getLineChartStats()
          } else if (
            this.state.selectedChart === 'team-dates-total-chart' ||
            this.state.selectedChart === 'single-stat-display'
          ) {
            this.getAggregateTotalStats();
          }
        });
      } else if (this.state.rollingUnit === 'days') {
        this.getDates();
      } else if (this.state.rollingUnit === 'months') {
        let dates = this.getDateRange(
          moment(this.props.selectedDate)
            .subtract(this.state.rollingValue * 30 - 1, 'd')
            .format('YYYY-MM-DD'),
          this.props.selectedDate,
          'days'
        );
        this.setState({ dates: dates.reverse() }, () => {
          if (this.state.selectedChart === 'team-line-chart') {
            this.getDateRangeStats();
            // this.getLineChartStats()
          } else if (
            this.state.selectedChart === 'team-dates-total-chart' ||
            this.state.selectedChart === 'single-stat-display'
          ) {
            this.getAggregateTotalStats();
          }
        });
      }
    } else {
      message.warning('Please enter a valid number');
    }
  };
  selectRadioOption = (event) => {
    if (this.state.selectedChart === 'team-line-chart') {
      if (this.state.lineChartDisplay === 'previous_dates') {
        this.setState({ radioOption: event.target.value }, () =>
          this.getDates()
        );
      } else if (this.state.lineChartDisplay === 'current_dates') {
        this.setState({ curRadioOption: event.target.value }, () =>
          this.getDates()
        );
      }
    } else if (
      this.state.selectedChart === 'team-dates-total-chart' ||
      this.state.selectedChart === 'single-stat-display'
    ) {
      if (this.state.datesTotalDisplay === 'previous_dates') {
        this.setState({ radioOption: event.target.value }, () =>
          this.getDates()
        );
      } else if (this.state.datesTotalDisplay === 'current_dates') {
        this.setState({ curRadioOption: event.target.value }, () =>
          this.getDates()
        );
      }
    }
  };
  handleSelectDatesDisplay = (value) => {
    if (this.state.selectedChart === 'team-line-chart') {
      this.setState({ lineChartDisplay: value }, () => this.getDates());
    } else if (
      this.state.selectedChart === 'team-dates-total-chart' ||
      this.state.selectedChart === 'single-stat-display'
    ) {
      this.setState({ datesTotalDisplay: value }, () => this.getDates());
    }
  };
  getLineChartDisplayDataOptions = () => {
    let options = [];

    if (this.state.processedStats) {
      for (let [name] of Object.entries(this.state.processedStats.users)) {
        let label = name.split('_');
        label.splice(label.length - 1);
        label = label.join(' ');
        options.push({ label: label, value: name });
      }
    }

    this.setState({ lineChartMembersOptions: options });
  };
  //this.state.selectedChart === "team-line-chart" ? `squad_${squad.squad_name.split(" ").join("")}_${squad.id}` : squad.id
  getLineChartSquadOptions = () => {
    let squadOptions = [];
    if (this.state.squads) {
      this.state.squads.forEach((squad, index) => {
        if (squad.id) {
          squadOptions.push({
            label: squad.squad_name,
            value: squad.squad_name + '~~' + squad.id
          });
        } else {
          // squadOptions.push({ label: "No squad", value: "No squad~~-1" });
        }
      });
    }
    // squadOptions.push({ label: "No squad", value: "No squad~~-1" });
    this.setState({ lineChartSquadOptions: squadOptions });
  };
  changeLineChartSeries = (value) => {
    let found;
    if (this.state.chartMemberSeries.length > value.length) {
      for (let series of this.state.chartMemberSeries) {
        if (!value.includes(series)) {
          found = series;
          break;
        }
      }
    } else {
      for (let series of value) {
        if (!this.state.chartMemberSeries.includes(series)) {
          found = series;
          break;
        }
      }
    }
    this.handleSelectingDataPoints(
      'member',
      found,
      found.split('_')[found.split('_').length - 1],
      value
    );
  };
  changeChartSquadSeries = (squadSeries) => {
    let found = null;
    if (this.state.chartSquadSeries.length > squadSeries.length) {
      for (let series of this.state.chartSquadSeries) {
        if (!squadSeries.includes(series)) {
          found = series;
          break;
        }
      }
    } else {
      for (let series of squadSeries) {
        if (!this.state.chartSquadSeries.includes(series)) {
          found = series;
          break;
        }
      }
    }
    this.handleSelectingDataPoints(
      'squad',
      found,
      found.split('~~')[1],
      squadSeries
    );
  };
  handleShowTeamTotal = (value) => {
    this.handleSelectingDataPoints(
      'team',
      this.state.teamOption,
      this.state.teamOption.split('~~')[1],
      value
    );
  };
  setShowTeamTotal = (value) => {
    this.setState({ showTeamTotalForChart: value }, () => {
      if (value.length > 0) {
        this.setState(
          (prevState) => ({
            lineChartSeries: prevState.lineChartSeries.concat(value),
            chartMemberSeries:
              prevState.chartMemberSeries.filter(
                (series) => series === this.state.teamOption
              ).length > 0
                ? prevState.chartMemberSeries
                : [...prevState.chartMemberSeries, this.state.teamOption]
          }),
          () => {
            if (this.state.selectedChart === 'team-dates-total-chart') {
              this.filterFinalDatesTotalStats();
            }
          }
        );
      } else {
        this.setState(
          (prevState) => ({
            lineChartSeries: prevState.lineChartSeries.filter(
              (series) => series !== this.state.teamOption
            ),
            chartMemberSeries: prevState.chartMemberSeries.filter(
              (series) => series !== this.state.teamOption
            )
          }),
          () => {
            if (this.state.selectedChart === 'team-dates-total-chart') {
              this.filterFinalDatesTotalStats();
            }
          }
        );
      }
    });
  };
  setLineChartSeries = (value) => {
    // this.setState({lineChartSeries: [...value, ...this.state.chartSquadSeries, ...this.state.showTeamTotalForChart], chartMemberSeries: [...value, ...this.state.showTeamTotalForChart]});
    if (this.state.selectedChart === 'team-line-chart') {
      this.setState({
        lineChartSeries: [
          ...value,
          ...this.state.chartSquadSeries,
          ...this.state.showTeamTotalForChart
        ],
        chartMemberSeries: [...value]
      });
    } else if (this.state.selectedChart === 'team-dates-total-chart') {
      this.setState({ chartMemberSeries: [...value] }, () =>
        this.filterFinalDatesTotalStats()
      );
    }
  };
  setChartSquadSeries = (squadSeries) => {
    this.setState({ chartSquadSeries: squadSeries }, () => {
      let lineChartSeries = [...this.state.lineChartSeries];
      if (lineChartSeries.length > 0) {
        lineChartSeries = lineChartSeries.filter(
          (series) => series.split('~~').length !== 2
        );
      }
      if (this.state.showTeamTotalForChart.length > 0) {
        lineChartSeries.push(this.state.teamOption);
      }
      this.setState(
        { lineChartSeries: lineChartSeries.concat(squadSeries) },
        () => {
          if (this.state.selectedChart === 'team-dates-total-chart') {
            this.filterFinalDatesTotalStats();
          }
        }
      );
    });
  };
  handleSelectingDataPoints = (type, name, id, value) => {
    httpHandler
      .post('/api/st1/customStats/updateDataPointSelections', {
        teamId: this.props.selectedTeam.team_id,
        type: type,
        name: name,
        typeId: parseInt(id),
        location: this.props.statDisplayId
          ? this.props.graph_name.split('_')[0]
          : this.props.graph_name,
        chartType: this.state.selectedChart,
        statDisplayId: this.props.statDisplayId
      })
      .then((selections) => {
        if (type === 'team') {
          this.setShowTeamTotal(value);
        } else if (type === 'squad') {
          this.setChartSquadSeries(value);
        } else if (type === 'member') {
          this.setLineChartSeries(value);
        }
      })
      .catch((error) => {
        console.log('unable to save data points', error);
      });
  };

  handleSingleStatRadioSelection = (e) => {
    this.setState({ selectedSingleStatDataPoint: e.target.value });
  };

  handleModalClose = () => {
    this.setState({
      visibleSubmissions: false,
      selectedSeriesCategory: null,
      selectedSeriesData: null
    });
  };

  render() {
    const getChart = () => {
      return (
        <ChartList
          isWidget={this.props.isWidget}
          selectedChart={this.state.selectedChart}
          teamTotals={this.state.statsForTeamTotal}
          dataDisplay={this.state.dataDisplay}
          graph_name={this.props.graph_name}
          selectedDate={this.props.selectedDate}
          statDisplayId={this.props.statDisplayId}
          teamLeader={this.state.teamLeader}
          initData={this.state.initData}
          displayOptions={this.state.displayOptions}
          hybridChartFields={this.state.hybridChartFields}
          stackedChartFields={this.state.stackedChartFields}
          showYesterday={this.state.showYesterday}
          dataForBarChart={this.state.dataForBarChart}
          barChartUpdated={() => this.setState({ dataForBarChart: null })}
          selectedSortField={this.state.selectedSortField}
          statGoal={this.state.statGoal}
          squads={this.state.squads}
          squadView={this.state.squadView}
          selectedStatFields={this.state.selectedStatFields}
          dailyTrackerStats={this.state.dailyTrackerStats}
          loadingDailyTracker={this.state.loadingDailyTracker}
          getClickedOnSeries={this.getClickedOnSeries}
          lineChartSeries={this.state.lineChartSeries}
          lineChartSquadSeries={this.state.chartSquadSeries}
          dataForLineChart={this.state.dataForLineChart}
          lineChartUpdated={() => this.setState({ dataForLineChart: null })}
          rollingUnit={this.state.rollingUnit}
          lineChartDisplay={this.state.lineChartDisplay}
          lineChartData={this.state.statsForLineChart}
          loadingLineChart={this.state.loadingLineChart}
          processedDatesTotalData={this.state.processedDatesTotalData}
          staticStart={this.state.staticStart}
          staticEnd={this.state.staticEnd}
          datesTotalDisplay={this.state.datesTotalDisplay}
          rollingValue={this.state.rollingValue}
          dates={this.state.dates}
          radioOption={this.state.radioOption}
          dataForDatesTotalChart={this.state.dataForDatesTotalChart}
          updated={() => this.setState({ dataForDatesTotalChart: null })}
          curRadioOption={this.state.curRadioOption}
          dateOptionDisplay={this.state.dateOptionDisplay}
          selectedSingleStatDataPoint={this.state.selectedSingleStatDataPoint}
          statsForDatesTotal={this.state.statsForDatesTotal}
          graphRef={this.graphRef}
        />
      );
    };

    // boxShadow: "2px 2px 2px #888888", borderRadius: "4px"
    const getSettingsPanel = () => {
      return (
        <SettingsPanel
          isCoachingDashboard={this.props.isCoachingDashboard}
          dailyTrackerStats={this.state.dailyTrackerStats}
          statsForDatesTotal={this.state.statsForDatesTotal}
          statsForLineChart={this.state.statsForLineChart}
          statsForTeamTotal={this.state.statsForTeamTotal}
          statDisplayId={this.props.statDisplayId}
          selectedChart={this.state.selectedChart}
          handleSelectChart={this.handleSelectChart}
          selectedTeam={this.props.selectedTeam}
          saveChartConfigSettings={this.saveChartConfigSettings}
          dataDisplay={this.state.dataDisplay}
          handleSelect={this.handleSelect}
          selectedStatFields={this.state.selectedStatFields}
          categoryOptions={this.state.categoryOptions}
          lineChartDisplay={this.state.lineChartDisplay}
          datesTotalDisplay={this.state.datesTotalDisplay}
          handleSelectDatesDisplay={this.handleSelectDatesDisplay}
          radioOption={this.state.radioOption}
          selectRadioOption={this.selectRadioOption}
          rollingValue={this.state.rollingValue}
          rollingUnit={this.state.rollingUnit}
          handleSelectRollingUnit={(value) =>
            this.setState({ rollingUnit: value })
          }
          handleInputRollingValue={(value) =>
            this.setState({ rollingValue: value })
          }
          handleRollingValue={this.handleRollingValue}
          pickDateRange={this.pickDateRange}
          staticStart={this.state.staticStart}
          staticEnd={this.state.staticEnd}
          curRadioOption={this.state.curRadioOption}
          showOptions={this.state.showOptions}
          handleSetGoal={this.handleSetGoal}
          statGoal={this.state.statGoal}
          stackedChartFields={this.state.stackedChartFields}
          handleChangeFields={this.handleChangeFields}
          selectedSortField={this.state.selectedSortField}
          handleChangeSort={this.handleChangeSort}
          squadView={this.state.squadView}
          handleSquadViewSelect={this.handleSquadViewSelect}
          showYesterday={this.state.showYesterday}
          handleDaySelect={this.handleDaySelect}
          teamOption={this.state.teamOption}
          showTeamTotalForChart={this.state.showTeamTotalForChart}
          squads={this.state.squads}
          lineChartSquadOptions={this.state.lineChartSquadOptions}
          chartSquadSeries={this.state.chartSquadSeries}
          changeChartSquadSeries={this.changeChartSquadSeries}
          processedStats={this.state.processedStats}
          lineChartMembersOptions={this.state.lineChartMembersOptions}
          lineChartSeries={this.state.lineChartSeries}
          chartMemberSeries={this.state.chartMemberSeries}
          changeLineChartSeries={this.changeLineChartSeries}
          selectedSingleStatDataPoint={this.state.selectedSingleStatDataPoint}
          handleSingleStatRadioSelection={this.handleSingleStatRadioSelection}
          handleShowTeamTotal={this.handleShowTeamTotal}
        />
      );
    };

    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          background: 'transparent',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 2 }}>
          <ListOfSubmissionsModal
            submissions={this.state.teamFormSubmissions}
            visibleSubmissions={this.state.visibleSubmissions}
            onCancel={this.handleModalClose}
            isVisible={this.state.visibleSubmissions}
          />
          {this.props.isMyTeam ? null : (
            <Popover
              trigger='click'
              content={getSettingsPanel()}
              placement='rightBottom'
              open={this.state.panelVisible}
              onOpenChange={() =>
                this.setState((prevState) => ({
                  panelVisible: !prevState.panelVisible,
                  isActive: !prevState.panelVisible
                }))
              }
            >
              <SettingFilled
                style={{
                  color: '#091a33',
                  fontSize: this.props.statDisplayId ? '14px' : '20px',
                  paddingTop: this.props.isCoachingDashboard ? '30px' : 0
                }}
                rotate={this.state.isActive ? 90 : 0}
                onClick={() =>
                  this.setState((prevState) => ({
                    isActive: !prevState.isActive
                  }))
                }
              />
            </Popover>
          )}
        </div>

        {this.props.statDisplayId ? (
          <div style={{ height: '18px' }}></div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <div style={{ fontSize: '15px', marginRight: '10px' }}>
              {this.props.selectedTeam
                ? this.props.selectedTeam.team_name +
                  ' - ' +
                  this.props.selectedTeam.first_name +
                  ' ' +
                  this.props.selectedTeam.last_name
                : null}
            </div>
            <DatePicker
              value={dayjs(this.props.selectedDate)}
              onChange={(e) => this.props.updateDate(e.format('YYYY-MM-DD'))}
              allowClear={false}
            />
          </div>
        )}

        <div ref={this.graphRef} style={{ flex: 1 }}>
          {this.props.statDisplayId ? null : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'absolute'
              }}
            />
          )}

          {this.state.initData ? getChart() : null}
        </div>
      </div>
    );
  }
}

export default GraphViewer;
