import React, { useEffect, useState } from 'react';
import classes from './SubscriptionProgress.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import socketHandler from '../../../services/Socket';
import ProgressModal from './progressModal/ProgressModal';

export type EventSubscriptionUpdate = {
  id: number;
  total: number;
  completed: number;
  softwareName: string;
  conferenceId: number;
  action: string;
};

const SubscriptionProgress = () => {
  const [eventSubscriptionUpdates, setEventSubscriptionUpdates] = useState<
    EventSubscriptionUpdate[]
  >([]);
  const [noShowList, setNoShowList] = useState<number[]>([]);

  useEffect(() => {
    const handleNewEventSubscriptionUpdate = (
      eventUpdate: EventSubscriptionUpdate
    ) => {
      if (!eventSubscriptionUpdates) {
        setEventSubscriptionUpdates([eventUpdate]);
      } else {
        const actions = eventSubscriptionUpdates;
        const index = actions.findIndex(
          (action) => action.id === eventUpdate.id
        );
        if (index !== -1) {
          let newState = [...eventSubscriptionUpdates];
          newState[index] = eventUpdate;
          setEventSubscriptionUpdates(newState);
        } else {
          setEventSubscriptionUpdates([
            ...eventSubscriptionUpdates,
            eventUpdate
          ]);
        }
      }
    };

    socketHandler
      .getSocket()
      .on('delete_events_progress', (response: EventSubscriptionUpdate) => {
        if (!noShowList.includes(response.id)) {
          handleNewEventSubscriptionUpdate(response);
        }
      });

    socketHandler
      .getSocket()
      .on('create_events_progress', (response: EventSubscriptionUpdate) => {
        if (!noShowList.includes(response.id)) {
          handleNewEventSubscriptionUpdate(response);
        }
      });
  }, [eventSubscriptionUpdates, noShowList]);

  const closeProgressModal = (id: number) => {
    const index = eventSubscriptionUpdates.findIndex(
      (element) => element.id === id
    );

    if (index !== -1) {
      const newState = [...eventSubscriptionUpdates];
      newState.splice(index, 1);
      setEventSubscriptionUpdates(newState);
      setNoShowList([...noShowList, id]);
    }
  };

  return (
    <>
      {eventSubscriptionUpdates && (
        <div className={classes['progress_modal_container']}>
          <AnimatePresence>
            {eventSubscriptionUpdates.map((response, index) => (
              <motion.div
                initial={{ x: -200 }}
                animate={{ x: 0 }}
                exit={{ x: -350 }}
                transition={{ type: 'tween' }}
                key={response.id}
                layout
              >
                <ProgressModal
                  socketData={response}
                  closeProgressModal={closeProgressModal}
                  removeProgressCard={function (
                    conferenceId: number,
                    softwareName: string
                  ): void {
                    throw new Error('Function not implemented.');
                  }}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default SubscriptionProgress;
