import React, { useContext } from 'react';
import ShoppingCartContext from '../../../../../contexts/ShoppingCartContext';
import PrettyButton from '../../../../reusableComponents/standard/prettyButton/PrettyButton';
import { UtilityMode } from '../NavBar';
import CartItem from './CartItem';
import classes from './shoppingCart.module.scss';
import { RiShoppingCartLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

export interface CartItemType {
  imageUrl: string;
  name: string;
  price: number;
  id: number;
}

const ShoppingCart = ({
  setUtilityMode
}: {
  //setUtilityMode: React.Dispatch<React.SetStateAction<UtilityMode>>;
  setUtilityMode: (mode: UtilityMode) => void;
}) => {
  const { shopping_cart } = useContext(ShoppingCartContext);
  const navigate = useNavigate();

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const cartItems = shopping_cart.itemList;
  const totalPrice = currencyFormat.format(shopping_cart.totalPrice / 100);

  const handleButton = (label: string) => {
    if (label === 'check out') {
      navigate('/checkout');
    } else if (label === 'view cart') {
      navigate('/viewShoppingCart');
    }
    setUtilityMode(UtilityMode.NONE);
  };

  return (
    <div className={classes['cart']}>
      {!cartItems.length ? (
        <div className={classes['empty-cart']}>
          <RiShoppingCartLine size={50} />
          <span>Your cart is empty</span>
        </div>
      ) : (
        <>
          <h3 className={classes['cart__header']}>Shopping Cart</h3>
          <div className={classes['cart__body']}>
            {cartItems.map((item: CartItemType) => (
              <div key={item.id}>
                <CartItem item={item} />
              </div>
            ))}
            <div className={classes['body__item']}></div>
          </div>
          <div className={classes['cart__total']}>
            <span>Total:</span>
            <span>{totalPrice}</span>
          </div>
          <div className={classes['cart__button']}>
            <PrettyButton
              className={classes['button__pretty-button']}
              onClick={() => handleButton('check out')}
            >
              Check Out
            </PrettyButton>
            <PrettyButton
              className={classes['button__pretty-button']}
              onClick={() => handleButton('view cart')}
            >
              View Cart{' '}
            </PrettyButton>
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppingCart;
