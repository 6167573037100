import httpHandler from '../../helpers/httpHandler';
import { Squad } from '../../types/team';
import {
  UserCollectionData,
  UserRoleCollectionData,
  Role
} from '../../types/user';
import { TransactionTypes } from '../../types/team';

const getUserTeams = async () => {
  try {
    const data = await httpHandler.get(`/api/teams/`);
    return data;
  } catch (error) {
    console.error('Could get get user teams', error);
  }
};

const getTeamMembersCollection = async (teamId: number) => {
  try {
    const data = await httpHandler.get(`/api/teams/${teamId}/members`);
    const collection: { [key: string]: UserCollectionData } = {};
    data.forEach(
      (user: UserCollectionData) => (collection[user.user_id] = user)
    );

    return collection;
  } catch (error) {
    console.error(error);
    return {};
  }
};

const getUserRolesCollection = async (teamId: number, userId?: number) => {
  try {
    const data = await httpHandler.get(
      `/api/teams/${teamId}/users/${userId || 'me'}/roles`
    );
    const collection: { [key: number]: UserRoleCollectionData } = {};
    data.forEach(
      (userRole: UserRoleCollectionData) =>
        (collection[userRole.role_id] = userRole)
    );
    return collection;
  } catch (error) {
    console.error(error);
    return {};
  }
};
// TODO: squads are not currently user in current iteration of ST but will be added
const getSquadsCollection = async (teamId: number) => {
  try {
    const data: Squad[] = await httpHandler.get(`/api/teams/${teamId}/squads`);

    const collection: { [key: number]: Squad } = {};
    data.forEach((squad: Squad) => (collection[squad.id] = squad));
    return collection;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllUserRoles = async () => {
  try {
    const result: Promise<Role[]> = await httpHandler.get('/api/users/roles');
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getRolesByType = async (typeId: number) => {
  try {
    const result: Promise<Role[]> = await httpHandler.get(
      `/api/users/roles/${typeId}`
    );
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getTransactionTypes = async (teamId: number, vocationId: number) => {
  const transactionTypes: Promise<TransactionTypes[]> = await httpHandler.get(
    `/api/teams/${teamId}/compass/transaction-types`,
    {
      vocationId
    }
  );
  return transactionTypes;
};

export const teamApi = {
  getUserTeams,
  getTeamMembersCollection,
  getUserRolesCollection,
  getSquadsCollection,
  getAllUserRoles,
  getTransactionTypes,
  getRolesByType
};
