import React from 'react';
import { Row, Checkbox, Radio } from 'antd';

const DataToDisplayPanel = (props) => {
  const getRadioSquadOptions = () => {
    return props.lineChartSquadOptions.map((option, index) => {
      return (
        <Radio value={option.value} key={`squad_` + index}>
          {option.label}
        </Radio>
      );
    });
  };
  const getRadioMemberOptions = () => {
    return props.lineChartMembersOptions.map((option, index) => {
      return (
        <Radio value={option.value} key={`member_` + index}>
          {option.label}
        </Radio>
      );
    });
  };
  const getDataPointPanel = () => {
    return (
      <div style={{ width: '270px' }}>
        <Row
          style={{
            width: '245px',
            background: '#fff',
            margin: '0 auto 10px auto'
          }}
        >
          <div style={{ width: '100%', marginBottom: '15px' }}>
            <p style={{ width: '100%', fontWeight: 'bold' }}>Team:</p>
            {props.teamOption &&
            props.selectedChart !== 'single-stat-display' ? (
              <Checkbox.Group
                options={[
                  {
                    label: props.teamOption.split('~~')[0],
                    value: props.teamOption
                  }
                ]}
                value={props.showTeamTotalForChart}
                onChange={props.handleShowTeamTotal}
              />
            ) : null}
            {props.selectedTeam &&
            props.selectedChart === 'single-stat-display' ? (
              <Radio.Group
                onChange={props.handleSingleStatRadioSelection}
                value={props.selectedSingleStatDataPoint}
              >
                <Radio
                  value={
                    props.selectedTeam.team_name +
                    '~~' +
                    props.selectedTeam.team_id
                  }
                  style={{ borderRadius: '10px' }}
                >
                  {props.selectedTeam.team_name}
                </Radio>
              </Radio.Group>
            ) : null}
          </div>
          <div style={{ width: '100%', marginBottom: '15px' }}>
            <p style={{ width: '100%', fontWeight: 'bold' }}>Squads:</p>
            {props.squads && props.squads.length > 0 ? (
              <div
                className='cart-scroll'
                style={{ maxHeight: '12vh', overflow: 'auto' }}
              >
                {props.selectedChart !== 'single-stat-display' ? (
                  <Checkbox.Group
                    style={{ width: '89%' }}
                    options={props.lineChartSquadOptions}
                    value={props.chartSquadSeries}
                    onChange={props.changeChartSquadSeries}
                  />
                ) : (
                  <Radio.Group
                    onChange={props.handleSingleStatRadioSelection}
                    value={props.selectedSingleStatDataPoint}
                  >
                    {getRadioSquadOptions()}
                  </Radio.Group>
                )}
              </div>
            ) : null}
          </div>
          <div style={{ width: '100%', marginBottom: '15px' }}>
            <p style={{ width: '100%', fontWeight: 'bold' }}>Members:</p>
            {props.processedStats ? (
              <div
                className='cart-scroll'
                style={{ maxHeight: '12vh', overflow: 'auto' }}
              >
                {props.selectedChart === 'single-stat-display' ? (
                  <Radio.Group
                    onChange={props.handleSingleStatRadioSelection}
                    value={props.selectedSingleStatDataPoint}
                  >
                    {getRadioMemberOptions()}
                  </Radio.Group>
                ) : (
                  <Checkbox.Group
                    style={{ width: '89%' }}
                    options={props.lineChartMembersOptions}
                    value={
                      props.selectedChart === 'team-line-chart'
                        ? props.lineChartSeries
                        : props.chartMemberSeries
                    }
                    onChange={props.changeLineChartSeries}
                  />
                )}
              </div>
            ) : null}
          </div>
        </Row>
      </div>
    );
  };

  return <div>{getDataPointPanel()}</div>;
};

export default DataToDisplayPanel;
