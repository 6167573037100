import { GetFn, SetFn, TeamSlice } from '../../types/zustandTypes';
import { teamApi } from '../apis/teamApi';
import { Role } from '../../types/user';
import { Team } from '../../types/team';
import { pipelineApi } from '../apis/pipelineApi';
import { leadSourcesRoutes } from '../../api/leadSourcesRoutes/leadSourcesRoutes';
import { LeadSource } from '../../types/statTracker';

export const createTeamSlice = (set: SetFn, get: GetFn): TeamSlice => ({
  userTeams: [],
  transactionTypes: null,
  teamMembersCollection: {},
  userRolesCollection: {},
  squadCollection: {},
  allRoles: [],
  allRolesCollection: {},
  selectedTeam: undefined,
  leadSources: {},
  teamsApi: {
    setTeam: async (team: Team) => {
      set({
        selectedTeam: team
      });
    },
    setUserTeams: (teams: Team[]) => {
      set({ userTeams: teams });
    },
    getTeamLeadSources: async (teamId) => {
      try {
        const leadSources = await leadSourcesRoutes.getLeadSources(teamId);

        if (leadSources.length === 0) return;

        const collection: { [key: string]: LeadSource } = {};

        leadSources.forEach((element: LeadSource) => {
          collection[element.id] = element;
        });

        set({ leadSources: collection });
      } catch (error) {
        console.error('Error getting Team Lead Sources', error);
      }
    },
    fetchUserTeams: async (userId) => {
      try {
        const userTeams: Team[] = await teamApi.getUserTeams();
        let selectedTeamId = userTeams[0]?.id;
        const storedTeamId = window.localStorage.getItem('currentTeam');
        if (storedTeamId) {
          const possibleNumber = parseInt(storedTeamId);
          if (!isNaN(possibleNumber)) selectedTeamId = possibleNumber;
        }
        const selectedTeam =
          userTeams.find((team) => team.team_id === selectedTeamId) ||
          userTeams[0];

        const rolesCollection: { [key: string]: Role } = {};

        const [
          teamMembersCollection,
          userRolesCollection,
          squadCollection,
          allRoles
        ] = await Promise.all([
          teamApi.getTeamMembersCollection(selectedTeam?.team_id),
          teamApi.getUserRolesCollection(selectedTeam?.team_id, userId),
          teamApi.getSquadsCollection(selectedTeam?.team_id),
          teamApi.getAllUserRoles()
        ]);

        allRoles.forEach((role) => {
          rolesCollection[role.id] = role;
        });

        const filteredRoles = selectedTeam
          ? allRoles.filter(
              (r) =>
                r.team_type_id === selectedTeam?.team_type_id ||
                r.team_type_id === -1
            )
          : allRoles;

        set({
          userTeams,
          selectedTeam: selectedTeam || userTeams[0],
          userRolesCollection,
          squadCollection,
          teamMembersCollection,
          allRoles: filteredRoles,
          allRolesCollection: rolesCollection
        });
      } catch (error) {
        console.error(`Error getting user teams/members`, error);
        return error;
      }
    },
    setSelectedTeam: async (team) => {
      const teamId = team.team_id;
      const vocationId = team.vocation_type_id;

      const [
        teamMembersCollection,
        userRolesCollection,
        squadCollection,
        rolesByType
      ] = await Promise.all([
        teamApi.getTeamMembersCollection(teamId),
        teamApi.getUserRolesCollection(teamId),
        teamApi.getSquadsCollection(teamId),
        teamApi.getRolesByType(team.team_type_id)
      ]);

      const transactionTypes = await teamApi.getTransactionTypes(
        teamId,
        vocationId
      );

      const allPipelines = await pipelineApi.getAllPipelinesWithStages(teamId);

      if (!allPipelines) {
        console.error('Error getting all pipelines');
        return;
      }
      // TODO: current pipeline is just the first pipeline in the array when you switch teams,
      // TODO: it might be a better UX to cache the last pipelineId when you were on that team
      const pipelineResponse = await pipelineApi.getPipeline(
        allPipelines[0]?.id
      );

      if (!pipelineResponse) {
        return;
      }

      pipelineResponse.sort((a, b) => (a.stageIndex > b.stageIndex ? 1 : -1));
      window.localStorage.setItem('currentTeam', team.team_id?.toString());
      // add fields
      set({
        currentPipeline: allPipelines[0],
        pipelines: allPipelines,
        pipelineLoading: false,
        selectedTeam: team,
        userRolesCollection,
        squadCollection,
        teamMembersCollection,
        scope: `team ${team.team_id}`,
        transactionTypes,
        allRoles: rolesByType
      });
    },
    getNewlyCreatedTeam: async (teamId: number) => {
      const userTeams: Team[] = await teamApi.getUserTeams();
      const selectedTeam = userTeams.find((team) => team.team_id === teamId);
      set({
        userTeams
      });
      return selectedTeam;
    }
  }
});
