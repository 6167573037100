import { patchRequest } from '../../helpers/httpHandler2';
import { ContestType } from '../../types/contestTypes';
import { ContestSlice, GetFn, SetFn } from '../../types/zustandTypes';

export const createContestSlice = (set: SetFn, get: GetFn): ContestSlice => ({
  selectedContest: null,
  contests: [],
  contestApi: {
    setSelectedContest: (contest: ContestType | null) => {
      set({ selectedContest: contest });
    },
    setContests: (contests: ContestType[]) => {
      set({ contests: contests });
    },
    editContest: async (contest: ContestType) => {
      await patchRequest(`/api/contest/${contest.id}`, contest);
      set({ selectedContest: contest });
    },
    removeContest: (contestId: number) => {
      const { contests } = get();
      const updatedContests = contests.filter(
        (contest) => contest.id !== contestId
      );
      if (updatedContests.length) {
        set({ contests: updatedContests, selectedContest: updatedContests[0] });
      } else {
        set({
          contests: [],
          selectedContest: null
        });
      }
    }
  }
});
