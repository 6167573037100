import CoursesHelper from '../../helpers/CoursesHelper';
import httpHandler from '../../helpers/httpHandler';

const fetchCourseSkeleton = async (id: number) => {
  try {
    const unstructuredCourse = await httpHandler.get(
      '/api/course/courseOverview',
      { id }
    );
    const courseTree = CoursesHelper.reconstructSkeleton(
      unstructuredCourse.course_skeleton
    );
    return courseTree;
  } catch (error) {
    console.error('Error fetching course', error);
  }
};

const fetchSectionView = async (id: number) => {
  try {
    const fetchedSection = await httpHandler.get('/api/course/getSection', {
      id
    });
    return fetchedSection;
  } catch (error) {
    console.error('Error fetching section', error);
  }
};

const fetchAllModuleData = async (moduleId: number) => {
  try {
    const fetchedData = await httpHandler.get('/api/course/allmoduledata', {
      module_id: moduleId
    });
    return fetchedData;
  } catch (error) {
    console.error('Error getting module and related data', error);
  }
};

const fetchCourse = async (courseId: number) => {
  try {
    const fetchedCourse = await httpHandler.get('/api/course/getCourse', {
      id: courseId
    });
    return fetchedCourse;
  } catch (error) {
    console.error('Error fetching course', error);
  }
};

export const courseViewerApi = {
  fetchSectionView,
  fetchAllModuleData,
  fetchCourseSkeleton,
  fetchCourse
};
