import httpHandler from '../../helpers/httpHandler';
import { putRequest } from '../../helpers/httpHandler2';
import { visibilityDataParser } from './visibilityDataParser';

const getPipelineStageVisibility = async (pipelineId: number) => {
  const response = await httpHandler.get(
    `/api/pipelines/${pipelineId}/pipeline-stages/visibility`
  );
  const sanitizedData = visibilityDataParser(response);
  return sanitizedData;
};

const putPipelineVisibility = async (
  key: 'visible' | 'subscribeToStage',
  value: number,
  teamId: number,
  pipelineStageId: number,
  roleId: number
) => {
  const response = await putRequest(
    `/api/pipelines/pipeline-stages/${pipelineStageId}/visibility`,
    {
      key: key === 'visible' ? 'visible' : 'subscribe_to_stage',
      value,
      teamId,
      pipelineStageId,
      roleId
    }
  );
  return response;
};

export const pipelineVisibilityRoutes = {
  getPipelineStageVisibility,
  putPipelineVisibility
};
