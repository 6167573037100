import VideoSettingsAndConstraints from './VideoSettingsAndConstraints';
import * as mediasoupClient from 'mediasoup-client';
import MediaHttpHandler from './MediaHttpHandler';
import socketIOClient from 'socket.io-client';
import DomainInfo from './DomainInfo';

class VideoCallHandler {
  constructor(videoCallContext, roomId, joinCode, displayName) {
    this.videoCallContext = videoCallContext;

    this.displayName = displayName;

    //room specific information
    this.roomId = roomId;
    this.joinCode = joinCode;

    //room state
    this.closed = false;
    this.socket = null;
    this.externalVideo = null;
    this.externalVideoStream = null;

    //video conference technical settings
    this.device = VideoSettingsAndConstraints.deviceInfo();
    this.useSimulcast = true;
    this.useSharingSimulcast = true;
    this.forceTcp = false;
    this.produce = true;
    this.consume = true;
    this.forceH264 = false;
    this.forceVP9 = false;
    this.useDatachannel = true;

    //mediasoup data
    this.mediasoupDevice = null;
    this.sendTransport = null;
    this.recvTransport = null;
    this.micProducer = null;
    this.webcamProducer = null;
    this.shareProducer = null;
    this.chatDataProducer = null;
    this.botDataProducer = null;
    this.consumers = new Map();
    this.dataConsumers = new Map();
    this.webCams = new Map();
    this.webCam = {
      device: null,
      resolution: 'hd'
    };

    this.initiate(displayName);
  }
  initiate = async (displayName) => {
    await this.initiateUserSession(displayName);
    this.initiateSocketConnection();
    this.initiateSocketEvents();
  };
  ///////////////////////////////// 1 /////////////////////////////////
  initiateUserSession = async (displayName) => {
    let initiationResult = await MediaHttpHandler.post('/initiateSession', {
      displayName: displayName
    });
    const data = initiationResult.data;
    if (data.token) localStorage.setItem('vidAuthorization', data.token);
    this.videoCallContext.changeMySetting('myId', data.sessionId);
  };
  ///////////////////////////////// 2a /////////////////////////////////
  initiateSocketConnection = () => {
    this.socket = socketIOClient(DomainInfo.mediaServerBaseUrl, {
      extraHeaders: {
        Authorization: localStorage.getItem('vidAuthorization')
      }
    });
  };
  ///////////////////////////////// 2b /////////////////////////////////
  initiateSocketEvents = async () => {
    //lifecycle events
    this.socket.on('connect', () => {
      this.enterRoom();
    });
    this.socket.on('connect_error', (error) => {
      console.error('media socket connection failed with error: ', error);
    });
    this.socket.on('disconnect', (error) => {
      console.error('media socket disconnected with error', error);
      this.closeTransports();
    });
    this.socket.on('new_room_member', (data) => {
      console.error('new room member');
      this.videoCallContext.addRoomMember({ ...data, consumers: [] });
    });
    this.socket.on('room_member_left', (data) => {
      console.error('room member left', data);
      this.videoCallContext.removeRoomMember(data);
    });
    this.socket.on('new_consumer', async (data) => {
      this.addNewConsumer(data);
    });
    this.socket.on('consumer_closed', (data) => {
      console.error('close consumer');
      this.closeConsumer(data);
    });
    this.socket.on('consumer_resumed', (data) => {
      console.error('consumer resumed');
      this.resumeConsumer(data);
    });
    this.socket.on('consumer_layers_changed', (data) => {
      console.error('consumer layers changed', data);
      const { consumerId, spatialLayer, temporalLayer } = data;
      const consumer = this.consumers.get(consumerId);
      if (!consumer) return;
      //***set consumer layers in state
      this.videoCallContext.setConsumerLayers(
        consumerId,
        spatialLayer,
        temporalLayer
      );
    });
    this.socket.on('active_speaker', (data) => {
      const { memberId } = data;
      this.videoCallContext.changeRoomSetting('activeSpeakerId', memberId);
    });
    this.socket.on('room_closed', (data) => {
      this.videoCallContext.leaveRoom();
    });
  };

  resumeConsumer = async (data) => {
    const { consumerId } = data;
    const consumer = this.consumers.get(consumerId);
    if (!consumer) return;
    consumer.resume();
  };

  closeConsumer = async (data) => {
    const { consumerId } = data;
    const consumer = this.consumers.get(consumerId);
    if (!consumer) return;
    consumer.close();
    this.consumers.delete(consumerId);
    //***remove consumer from state
    this.videoCallContext.removeConsumer(consumerId);
  };

  addNewConsumer = async (data) => {
    if (!this.consume) return;
    try {
      const consumeOptions = {
        id: data.id,
        producerId: data.producerId,
        kind: data.kind,
        rtpParameters: data.rtpParameters,
        appData: { ...data.appData, memberId: data.memberId }
      };
      const consumer = await this.recvTransport.consume(consumeOptions);
      this.consumers.set(consumer.id, consumer);

      // const videoConsumerCount = Array.from(this.consumers.values()).filter(
      //   (consumer) => consumer._track.kind === "video"
      // ).length;

      consumer.on('transportclose', () => {
        this.consumers.delete(consumer.id);
        this.videoCallContext.removeConsumer(consumer.id);
      });
      const { spatialLayers, temporalLayers } =
        mediasoupClient.parseScalabilityMode(
          consumer.rtpParameters.encodings[0].scalabilityMode
        );

      //***set consumer in state
      let consumerInfo = {
        id: consumer.id,
        type: data.type,
        locallyPaused: false,
        remotelyPaused: data.producerPaused,
        rtpParameters: consumer.rtpParameters,
        spatialLayers: spatialLayers,
        temporalLayers: temporalLayers,
        preferredSpatialLayer: spatialLayers - 1,
        preferredTemporalLayer: temporalLayers - 1,
        priority: 1,
        codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
        track: consumer.track
      };
      MediaHttpHandler.post(`/rooms/${this.roomId}/consumers/resume`, {
        consumerId: data.id
      });
      if (data.appData.share) {
        this.videoCallContext.setShareConsumer(consumerInfo, data.memberId);
      } else {
        this.videoCallContext.addConsumer(consumerInfo, data.memberId);
      }
    } catch (error) {
      console.error('error setting up new consumer', error);
    }
  };
  closeTransports = () => {
    if (this.sendTransport) this.sendTransport.close();
    if (this.recvTransport) this.recvTransport.close();
    this.videoCallContext.reset();
  };

  setupSendTransport = async () => {
    let body = {
      forceTcp: this.forceTcp,
      producing: true,
      consuming: false,
      sctpCapabilities: this.useDatachannel
        ? this.mediasoupDevice.sctpCapabilities
        : undefined
    };
    try {
      const createResult = await MediaHttpHandler.post(
        `/rooms/${this.roomId}/transports/create`,
        body
      );
      const transportInfo = createResult.data;
      this.sendTransport = this.mediasoupDevice.createSendTransport({
        id: transportInfo.id,
        iceParameters: transportInfo.iceParameters,
        iceCandidates: transportInfo.iceCandidates,
        dtlsParameters: transportInfo.dtlsParameters,
        sctpParameters: transportInfo.sctpParameters,
        iceServers: [],
        proprietaryConstraints:
          VideoSettingsAndConstraints.PC_PROPRIETARY_CONSTRAINTS
      });
      this.sendTransport.on(
        'connect',
        async ({ dtlsParameters }, callback, errback) => {
          try {
            await MediaHttpHandler.post(
              `/rooms/${this.roomId}/transports/connect`,
              {
                transportId: this.sendTransport.id,
                dtlsParameters: dtlsParameters
              }
            );
            callback();
          } catch (error) {
            console.error('failed to connect send transport');
            errback(error);
          }
        }
      );
      this.sendTransport.on(
        'produce',
        async ({ kind, rtpParameters, appData }, callback, errback) => {
          try {
            const result = await MediaHttpHandler.post(
              `/rooms/${this.roomId}/transports/produce`,
              {
                transportId: this.sendTransport.id,
                kind,
                rtpParameters: rtpParameters,
                appData: appData
              }
            );
            let id = result.data;
            callback({ id: id });
          } catch (error) {
            console.error('failed to produce with send transport');
            errback(error);
          }
        }
      );
    } catch (error) {
      console.error("couldn't setup receive transport");
    }
  };

  setupReceiveTransport = async () => {
    let body = {
      forceTcp: this.forceTcp,
      producing: false,
      consuming: true,
      sctpCapabilities: this.useDatachannel
        ? this.mediasoupDevice.sctpCapabilities
        : undefined
    };
    try {
      const createResult = await MediaHttpHandler.post(
        `/rooms/${this.roomId}/transports/create`,
        body
      );
      const transportInfo = createResult.data;
      this.recvTransport = this.mediasoupDevice.createRecvTransport({
        id: transportInfo.id,
        iceParameters: transportInfo.iceParameters,
        iceCandidates: transportInfo.iceCandidates,
        dtlsParameters: transportInfo.dtlsParameters,
        sctpParameters: transportInfo.sctpParameters,
        iceServers: []
      });
      this.recvTransport.on(
        'connect',
        async ({ dtlsParameters }, callback, errback) => {
          try {
            await MediaHttpHandler.post(
              `/rooms/${this.roomId}/transports/connect`,
              {
                transportId: this.recvTransport.id,
                dtlsParameters: dtlsParameters
              }
            );
            callback();
          } catch (error) {
            console.error('error connecting receive transport');
            errback(error);
          }
        }
      );
    } catch (error) {
      console.error('could not set up send transport', error);
    }
  };
  audioHack = async () => {
    //stuff to play remove audios due to browsers' new autoplay policy
    //just get access to the mic and don't close mictrack for a while
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
      const audioTrack = stream.getAudioTracks()[0];
      audioTrack.enabled = false;
      setTimeout(() => {
        audioTrack.stop();
      }, 100);
    } catch (error) {
      console.error('failed on mic hack');
    }
  };
  ///////////////////////////////// 3 /////////////////////////////////
  enterRoom = async () => {
    try {
      //create client mediasoup device
      this.mediasoupDevice = new mediasoupClient.Device();
      //request the server's rtp capabilities
      //this will create the room if it does not yet exist
      const routerRtpCapabilities = await MediaHttpHandler.post(
        `/rooms/${this.roomId}/join`,
        { joinCode: this.joinCode }
      );
      //load capabilities into media soup device
      await this.mediasoupDevice.load({
        routerRtpCapabilities: routerRtpCapabilities.data
      });
      //await this.audioHack();
      let transportPromises = [];
      //create send transport for sending video and audio to other participants
      if (this.produce) {
        //await this.setupSendTransport();
        transportPromises.push(this.setupSendTransport());
      }
      //create receive transport for receiving video and audio of other participants
      if (this.consume) {
        transportPromises.push(this.setupReceiveTransport());
        //await this.setupReceiveTransport();
      }
      await Promise.all(transportPromises);
      //now that we've established transports, we can enter the room, from which we will recieve produce and consume streams from other room members
      let enterRoomRequestData = {
        rtpCapabilities: this.consume
          ? this.mediasoupDevice.rtpCapabilities
          : undefined,
        sctpCapabilities:
          this.useDatachannel && this.consume
            ? this.mediasoupDevice.sctpCapabilities
            : undefined,
        device: this.device
      };
      let otherRoomMemberInfo = await MediaHttpHandler.post(
        `/rooms/${this.roomId}/enterRoom`,
        enterRoomRequestData
      );
      otherRoomMemberInfo = otherRoomMemberInfo.data;
      for (const roomMemberInfo of otherRoomMemberInfo) {
        this.videoCallContext.addRoomMember({
          ...roomMemberInfo,
          consumers: []
        });
      }
      //finally, we will configure and then produce our mic and webcam streams
      if (this.produce) {
        await this.videoCallContext.changeMySetting(
          'canSendMic',
          this.mediasoupDevice.canProduce('audio')
        );
        await this.videoCallContext.changeMySetting(
          'canSendWebcam',
          this.mediasoupDevice.canProduce('video')
        );
        this.enableMic();
        this.enableWebcam();
      }
    } catch (error) {
      console.error('error joining room!', error);
      if (error.status === 406) {
        this.socket.close();
        this.initiate(this.displayName);
      }
    }
  };

  enableMic = async () => {
    if (this.micProducer) return;
    if (!this.mediasoupDevice.canProduce('audio')) return;

    let track;

    try {
      if (!this.externalVideo) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });

        track = stream.getAudioTracks()[0];
      }

      this.micProducer = await this.sendTransport.produce({
        track: track,
        codecOptions: { opusStereo: 1, opusDtx: 1 }
      });

      //***set mic producer in state */
      this.videoCallContext.addProducer({
        id: this.micProducer.id,
        paused: this.micProducer.paused,
        track: this.micProducer.track,
        rtpParameters: this.micProducer.rtpParameters,
        codec: this.micProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
      });

      this.micProducer.on('transportclose', () => {
        this.micProducer.close();
        this.micProducer = null;
        //***notify state mic disconnected
        //***disable mic
      });
    } catch (error) {
      console.error('error enabling mic', error);
      //notify state failed ot connect mic
      //stop track
    }
  };

  muteMic = async () => {
    this.micProducer.pause();
    //notify the server that the producer needs to be paused
    try {
      await MediaHttpHandler.post(`/rooms/${this.roomId}/producers/pause`, {
        producerId: this.micProducer.id
      });
      this.videoCallContext.setProducerPaused(this.micProducer.id);
    } catch (error) {
      console.error('error pausing mic producer', error);
    }
  };

  unmuteMic = async () => {
    //unpause the mic locally
    this.micProducer.resume();
    //notify the server that the producer needs to be resumed
    try {
      await MediaHttpHandler.post(`/rooms/${this.roomId}/producers/resume`, {
        producerId: this.micProducer.id
      });
      this.videoCallContext.setProducerResumed(this.micProducer.id);
    } catch (error) {
      console.error('error resuming the mic producer', error);
    }
  };

  changeMic = async (micDevice) => {
    if (!this.mediasoupDevice.canProduce('audio')) return;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: micDevice.deviceId
        }
      });
      let track = stream.getAudioTracks()[0];
      this.micProducer.track.stop();
      await this.micProducer.replaceTrack({ track: track });
      this.videoCallContext.setProducerTrack(this.micProducer.id, track);
    } catch (error) {
      console.error('error changing mic', error);
    }
  };

  updateWebCams = async () => {
    this.webCams = new Map();

    const devices = await navigator.mediaDevices.enumerateDevices();
    for (const device of devices) {
      if (device.kind !== 'videoinput') continue;
      this.webCams.set(device.deviceId, device);
    }

    const array = Array.from(this.webCams.values());
    const len = array.length;
    const currentWebcamId = this.webCam.device
      ? this.webCam.device.deviceId
      : undefined;

    if (len === 0) this.webCam.device = null;
    else if (!this.webCams.has(currentWebcamId)) this.webCam.device = array[0];

    /*** set state can set webcam to true */
    this.videoCallContext.changeMySetting(
      'canChangeWebcam',
      this.webCams.size > 1
    );
  };

  getWebcamType(device) {
    if (/(back|rear)/i.test(device.label)) {
      return 'back';
    } else {
      return 'front';
    }
  }

  enableWebcam = async () => {
    if (this.webcamProducer) return;
    if (!this.mediasoupDevice.canProduce('video')) return;
    let track;
    let device;

    //*** set state webcam in progress */
    this.videoCallContext.changeMySetting('webcamInProgress', true);

    try {
      await this.updateWebCams();
      device = this.webCam.device;

      const { resolution } = this.webCam;

      if (!device) {
        console.error('no webcam devices');
        throw new Error('no webcam devices');
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { ideal: device.deviceId },
          ...VideoSettingsAndConstraints.VIDEO_CONSTRAINTS[resolution]
        }
      });

      track = stream.getVideoTracks()[0];

      let encodings;

      if (this.useSimulcast) {
        encodings = VideoSettingsAndConstraints.WEBCAM_SIMULTCAST_ENCODINGS;
      }

      let codec;
      const codecOptions = {
        videoGoogleStartBitrate: 1000
      };

      if (this.forceH264) {
        codec = this.mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === 'video/h264'
        );
        if (!codec)
          throw new Error('desired H264 codex+configuration not supported');
      }
      if (this.forceVP9) {
        codec = this.mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === 'video/vp9'
        );
        if (!codec)
          throw new Error('desired VP9 codex+configuration not supported');
      }

      this.webcamProducer = await this.sendTransport.produce({
        track,
        encodings,
        codecOptions,
        codec
      });

      /*** add webcam producer to state */
      this.videoCallContext.addProducer({
        id: this.webcamProducer.id,
        deviceLabel: device.label,
        type: this.getWebcamType(device),
        paused: this.webcamProducer.paused,
        track: this.webcamProducer.track,
        rtpParameters: this.webcamProducer.rtpParameters,
        codec:
          this.webcamProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
      });
      this.webcamProducer.on('transportclose', () => {
        this.webcamProducer.close();
        this.webcamProducer = null;
      });
    } catch (error) {
      console.error('encoutered an error: ', error);
      if (track) track.stop();
    }

    /*** set state webcam in progress */
    this.videoCallContext.changeMySetting('webcamInProgress', false);
  };

  disableWebcam = async () => {
    if (!this.webcamProducer) return;
    this.webcamProducer.close();
    this.videoCallContext.changeMySetting('webcamInProgress', false);
    this.videoCallContext.removeProducer(this.webcamProducer.id);
    try {
      await MediaHttpHandler.post(
        `/rooms/${this.roomId}/producers/closeProducer`,
        { producerId: this.webcamProducer.id }
      );
    } catch (error) {
      console.error('error closing webcam producer', error);
    }
    this.webcamProducer = null;
  };

  enableShare = async () => {
    if (this.shareProducer) return;
    //if (this.webcamProducer) this.disableWebcam();

    let track;

    this.videoCallContext.changeMySetting('shareInProgress', true);

    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: {
          displaySurface: 'monitor',
          logicalSurface: true,
          cursor: true,
          width: { max: 1920 },
          height: { max: 1080 },
          frameRate: { max: 30 }
        }
      });
      if (!stream) {
        return;
      }
      track = stream.getVideoTracks()[0];
      let encodings;
      let codec;
      let codecOptions = {
        videoGoogleStartBitrate: 1000
      };
      if (this.forceH264) {
        codec = this.mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === 'video/h264'
        );
        if (!codec) throw new Error('desired H264 codec+config not supported');
      }
      if (this.forceVP9) {
        codec = this.mediasoupDevice.rtpCapabilities.codecs.find(
          (c) => c.mimeType.toLowerCase() === 'video/vp9'
        );
        if (!codec) throw new Error('desired VP9 codec+config not supported');
      }
      if (this.useSharingSimulcast) {
        const firstVideoCodec =
          this.mediasoupDevice.rtpCapabilities.codecs.find(
            (c) => c.kind === 'video'
          );
        if (
          (this.forceVP9 && codec) ||
          firstVideoCodec.mimeType.toLowerCase() === 'video/vp9'
        )
          encodings = VideoSettingsAndConstraints.SCREEN_SHARING_SVC_ENCODINGS;
        else
          encodings =
            VideoSettingsAndConstraints.SCREEN_SHARING_SIMULCAST_ENCODINGS.map(
              (encoding) => ({
                ...encoding,
                dtx: true
              })
            );
      }
      this.shareProducer = await this.sendTransport.produce({
        track,
        encodings,
        codecOptions,
        codec,
        appData: { share: true }
      });

      this.shareProducer.on('transportclose', () => {
        this.shareProducer.close();
        this.shareProducer = null;
      });
      this.shareProducer.on('trackended', async () => {
        await this.disableShare();
        // if (this.mediasoup_device.canProduce('video'))
        // {
        //     this.enableWebcam();
        // }
      });

      this.videoCallContext.addProducer({
        id: this.shareProducer.id,
        type: 'share',
        paused: this.shareProducer.paused,
        track: this.shareProducer.track,
        rtpParameters: this.shareProducer.rtpParameters,
        codec: this.shareProducer.rtpParameters.codecs[0].mimeType.split('/')[1]
      });
    } catch (error) {
      console.error('error sharing screen: ', error);
      if (track) track.stop();
      this.videoCallContext.changeMySetting('shareInProgress', false);
    }
  };

  disableShare = async () => {
    if (!this.shareProducer) return;
    this.shareProducer.close();
    this.videoCallContext.removeProducer(this.shareProducer.id);
    try {
      await MediaHttpHandler.post(
        `/rooms/${this.roomId}/producers/closeProducer`,
        {
          producerId: this.shareProducer.id
        }
      );
      this.videoCallContext.changeMySetting('shareInProgress', false);
    } catch (error) {
      console.error('error closing share producer', error);
    }

    this.shareProducer = null;
  };

  startRecording = async () => {
    try {
      await MediaHttpHandler.post(`/rooms/${this.roomId}/record`);
    } catch (error) {
      console.error('error starting recording: ', error);
    }
  };

  stopRecording = async () => {
    //not yet implemented
    try {
      await MediaHttpHandler.post(`/rooms/${this.roomId}/stopRecording`);
    } catch (error) {
      console.error('error stopping recording', error);
    }
  };

  changeWebcam = async (webCamDevice) => {
    this.videoCallContext.changeMySetting('webcaminProgress', true);

    try {
      this.webCam.device = webCamDevice;

      this.webCam.resolution = 'hd';

      if (!this.webCam.device) throw new Error('webcam device not found');

      this.webcamProducer.track.stop();

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: this.webCam.device.deviceId },
          ...VideoSettingsAndConstraints.VIDEO_CONSTRAINTS[
            this.webCam.resolution
          ]
        }
      });

      const track = stream.getVideoTracks()[0];

      await this.webcamProducer.replaceTrack({ track });

      this.videoCallContext.setProducerTrack(this.webcamProducer.id, track);
    } catch (error) {
      console.error('error changing webcam: ', error);
    }

    this.videoCallContext.changeMySetting('webcamInProgress', false);
  };

  changeWebcamResolution = async (resolution) => {
    this.videoCallContext.changeMySetting('webcaminProgress', true);
    try {
      switch (resolution) {
        case 'realSmoll':
          this.webCam.resolution = 'realSmoll';
          break;
        case 'qqvga':
          this.webCam.resolution = 'smoll';
          break;
        case 'qvga':
          this.webCam.resolution = 'qvga';
          break;
        case 'vga':
          this.webCam.resolution = 'vga';
          break;
        case 'hd':
          this.webCam.resolution = 'hd';
          break;
        case 'fullHd':
          this.webCam.resolution = 'fullHd';
          break;
        default:
          this.webCam.resolution = 'vga';
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: { exact: this.webCam.device.deviceId },
          ...VideoSettingsAndConstraints.VIDEO_CONSTRAINTS[
            this.webCam.resolution
          ]
        }
      });

      const track = stream.getVideoTracks()[0];

      await this.webcamProducer.replaceTrack({ track });

      this.videoCallContext.setProducerTrack(this.webcamProducer.id, track);
    } catch (error) {
      console.error('could not change webcam resolution', error);
    }

    this.videoCallContext.changeMySetting('webcaminProgress', false);
  };

  pauseConsumer = async (consumer, producerMemberId) => {
    if (consumer.paused) return;
    try {
      await MediaHttpHandler.post(`/rooms/${this.roomId}/consumers/pause`, {
        consumerId: consumer
      });
      consumer.pause();
      await this.videoCallContext.setConsumerPaused(
        consumer.id,
        producerMemberId
      );
    } catch (error) {
      console.error('could not pause consumer: ', error);
    }
  };

  // resumeConsumer = async (consumer, producerMemberId) => {
  //   if (consumer.paused) return;
  //   try {
  //     await MediaHttpHandler.post(`/rooms/${this.roomId}/consumers/resume`, {
  //       consumerId: consumer
  //     });
  //     consumer.resume();
  //     await this.videoCallContext.setConsumerResumed(
  //       consumer.id,
  //       producerMemberId
  //     );
  //   } catch (error) {
  //     console.error('could not resume consumer: ', error);
  //   }
  // };

  changeConsumerLayers = async (consumer, layer) => {
    consumer = Array.from(this.consumers.values()).filter(
      (consumer) => consumer._track.kind === 'video'
    )[0];
    //if (!consumer) consumer = Array.from(this.consumers.values()).find()
    try {
      await MediaHttpHandler.post(
        `/rooms/${this.roomId}/consumers/preferredLayers`,
        {
          consumerId: consumer.id,
          spatialLayer: layer,
          temporalLayer: layer
        }
      );
    } catch (error) {
      console.error('could not change layers');
    }
  };
}

export default VideoCallHandler;
