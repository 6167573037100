import {
  RawModuleData,
  ModuleToView,
  Checklist,
  Download,
  Link
} from '../types/course';

export const createModuleStructure = (
  moduleData: RawModuleData[]
): ModuleToView => {
  const m = moduleData[0];
  const moduleDetails = {
    id: m.module_id,
    courseId: m.course_id,
    complete: m.module_completed,
    moduleContent: m.module_content,
    moduleName: m.module_name,
    moduleVideo: m.module_video,
    moduleVideoPoster: m.module_video_poster,
    sectionId: m.section_id,
    videoComplete: m.video_complete,
    checklists: [],
    downloads: [],
    links: []
  } as ModuleToView;

  for (const rmd of moduleData) {
    if (rmd.checklist_id) {
      if (
        !moduleDetails.checklists.find(
          (checklist: Checklist) => checklist.id === rmd.checklist_id
        )
      ) {
        moduleDetails.checklists.push({
          checklist_name: rmd.checklist_name,
          id: rmd.checklist_id,
          module_id: rmd.module_id,
          checklist_items: [],
          checklist_index: 0
        } as Checklist);
      }
    }
    if (rmd.homework_id) {
      if (
        rmd.hw_content &&
        rmd.module_id &&
        rmd.copy_of_homework_id &&
        rmd.checklist_id
      ) {
        const checklist = moduleDetails.checklists.find(
          (checklist: Checklist) => checklist.id === rmd.checklist_id
        );

        if (checklist) {
          if (
            !checklist.checklist_items.find((ci) => ci.id === rmd.homework_id)
          ) {
            // TODO: sort checklist item by module index
            checklist.checklist_items.push({
              id: rmd.homework_id,
              content: rmd.hw_content,
              module_id: rmd.module_id,
              copy_of_homework_id: rmd.copy_of_homework_id,
              checklist_id: rmd.checklist_id,
              homework_index: rmd.hw_index || 0,
              completed: rmd.hw_completed ? true : false
            });
          }
        }
      }
    }
    if (rmd.module_download_id) {
      if (
        !moduleDetails.downloads.find(
          (download: Download) => download.id === rmd.module_download_id
        )
      ) {
        if (
          rmd.module_download_id &&
          rmd.media_id &&
          rmd.module_id &&
          rmd.copy_of_download_id &&
          rmd.media_source &&
          rmd.file_name
        ) {
          moduleDetails.downloads.push({
            id: rmd.module_download_id,
            module_id: rmd.module_id,
            media_id: rmd.media_id,
            copy_of_download_id: rmd.copy_of_download_id,
            source: rmd.media_source,
            fileName: rmd.file_name
          });
        }
      }
    }
    if (rmd.link_id) {
      if (!moduleDetails.links.find((link: Link) => link.id === rmd.link_id)) {
        if (
          rmd.link_id &&
          rmd.url &&
          rmd.copy_type &&
          rmd.module_id &&
          rmd.copy_of_link_id &&
          rmd.available_when_copied
        ) {
          moduleDetails.links.push({
            id: rmd.link_id,
            url: rmd.url,
            replacement_url: rmd.replacement_url || null,
            copy_type: rmd.copy_type,
            module_id: rmd.module_id,
            copy_of_link_id: rmd.copy_of_link_id,
            available_when_copied: rmd.available_when_copied
          });
        }
      }
    }
  }
  return moduleDetails;
};
