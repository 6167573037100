import React from 'react';
import { Row, Select, Col, Card, Button, Divider } from 'antd';
import httpHandler from '../../../../helpers/httpHandler';
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
class InfusionContactSelection extends React.Component {
  constructor() {
    super();
    this.state = {
      allContacts: [],
      filteredContacts: null,
      selectedContact: null,
      loading: false,
      loadMore: false
    };
  }
  componentDidMount() {
    this.getInfusionsoftContacts();
  }
  getInfusionsoftContacts = () => {
    this.setState({ loading: true });
    httpHandler
      .get('/api/st1/financials/getInfusionsoftContactSelections')
      .then((contacts) => {
        this.setState({
          allContacts: contacts,
          filteredContacts: contacts.slice(0, 300)
        });
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  loadMoreInfusionSoftContacts = () => {
    this.setState({ loadMore: true });
    httpHandler
      .get('/api/st1/financials/loadMoreInfusionSoftContacts', {
        next: this.state.nextUrl
      })
      .then((contacts) => {
        let nextUrl = contacts.next;
        contacts = contacts.contacts;
        let allContacts = this.state.allContacts;
        let dic = {};
        for (let contact of allContacts) {
          dic[contact.infusionsoft_id] = contact;
        }
        if (contacts.length > 0) {
          for (let contact of contacts) {
            if (dic[contact.id]) {
              let curContact = dic[contact.id];
              curContact.first_name = contact.given_name;
              curContact.last_name = contact.family_name;
              curContact.email =
                contact.email_addresses.length > 0
                  ? contact.email_addresses[0].email
                  : '';
              curContact.phone =
                contact.phone_numbers.length > 0
                  ? contact.phone_numbers[0].number
                  : '';
            } else {
              allContacts.push({
                infusionsoft_id: contact.id,
                first_name: contact.given_name,
                last_name: contact.family_name,
                email:
                  contact.email_addresses.length > 0
                    ? contact.email_addresses[0].email
                    : '',
                phone:
                  contact.phone_numbers.length > 0
                    ? contact.phone_numbers[0].number
                    : ''
              });
            }
          }
        }
        this.setState(
          {
            allContacts: allContacts,
            filteredContacts: allContacts.slice(0, 300),
            nextUrl: nextUrl
          },
          () => {
            this.onSearch(this.state.searchValue);
          }
        );
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({ loadMore: false });
      });
  };
  onSearch = (searchVal) => {
    let allContacts = this.state.allContacts;
    let filtered = [];
    //filter based on search criteria
    if (searchVal != null) {
      searchVal = searchVal.toLowerCase();
      let searchValues = searchVal.split(' ');
      filtered = allContacts.filter((contact) => {
        let aggregate_matches = 0;
        for (let i = 0; i < searchValues.length; i++) {
          if (
            (contact.first_name &&
              contact.first_name.toLowerCase().includes(searchValues[i])) ||
            (contact.last_name &&
              contact.last_name.toLowerCase().includes(searchValues[i])) ||
            (contact.email &&
              contact.email.toLowerCase().includes(searchValues[i])) ||
            (contact.phone &&
              contact.phone.toLowerCase().includes(searchValues[i]))
          ) {
            aggregate_matches++;
          }
        }
        if (aggregate_matches >= searchValues.length) return true;
        return false;
      });

      filtered = filtered.slice(0, 300);
      this.setState({ filteredContacts: filtered, searchValue: searchVal });
    }
  };
  getContactOptions = () => {
    let options = [];
    if (this.state.filteredContacts) {
      for (let contact of this.state.filteredContacts) {
        options.push(
          <Option key={contact.infusionsoft_id} value={contact.infusionsoft_id}>
            {!this.state.selectedContact ||
            (this.state.selectedContact &&
              this.state.selectedContact.infusionsoft_id !==
                contact.infusionsoft_id) ? (
              <Card size='small'>
                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                  {contact.infusionsoft_id}
                </div>
                <Row>{`${contact.first_name} ${contact.last_name}`}</Row>
                <Row>{`${contact.email}`}</Row>
                <Row>{`${contact.phone}`}</Row>
              </Card>
            ) : (
              <Row>
                <Col
                  span={24}
                >{`${contact.first_name} ${contact.last_name}`}</Col>
                <Col span={24}>{`${contact.email}`}</Col>
                <Col span={24}>{`${contact.phone}`}</Col>
              </Row>
            )}
          </Option>
        );
      }
    }
    return [
      <Option key={-1} value={null}>
        --Select One--
      </Option>,
      ...options
    ];
  };
  handleSelectContact = (value) => {
    let contact = this.state.filteredContacts.filter(
      (contact) => contact.infusionsoft_id === value
    )[0];
    this.setState(
      {
        selectedContact: contact
      },
      () => {
        this.props.getSelectedInfusionSoftContact(contact);
      }
    );
  };
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Select
          showSearch
          placeholder={'Select a client contact'}
          value={
            this.state.selectedContact
              ? this.state.selectedContact.infusionsoft_id
              : null
          }
          style={{ width: '100%' }}
          onSearch={this.onSearch}
          loading={this.state.loading}
          disabled={this.state.filteredContacts == null}
          // filterOption={(input, option) =>
          //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          // }
          dropdownRender={(menu) => (
            <div>
              {/* {menu} */}
              <div>
                <Button
                  style={{ borderRadius: '15px', marginLeft: '10px' }}
                  onClick={this.loadMoreInfusionSoftContacts}
                >
                  {this.state.loadMore ? (
                    <LoadingOutlined />
                  ) : (
                    <ReloadOutlined />
                  )}
                  Load More
                </Button>
              </div>
              <Divider style={{ margin: '4px 0' }} />
              {menu}
            </div>
          )}
          filterOption={(input, option) => {
            return (
              this.state.filteredContacts
                .map((contact) => contact.infusionsoft_id)
                .includes(option.value) || option.value === null
            );
          }}
          onChange={this.handleSelectContact}
        >
          {this.getContactOptions()}
        </Select>
      </div>
    );
  }
}

export default InfusionContactSelection;
