import DomainInfo from './DomainInfo';
import axios from 'axios';
import { keysToCamel } from './KeysToCamel';

//this class is used once the user is logged in, so basically any component nested under /
//It makes sure the user is logged in and redirects them if not.

const handleError = function (error: any) {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 426) {
      window.location.href = '/login';
    } else if (error.response.data) {
      throw new Error(error.response.data);
    }
  } else {
    throw new Error(error);
  }
};

export async function getRequest(path: string, params?: object) {
  try {
    const response = await axios.get(`${DomainInfo.baseUrl}${path}`, {
      params: params,
      headers: { AuthToken: localStorage.getItem('CWAuthToken') }
    });
    return keysToCamel(response.data);
  } catch (error: any) {
    handleError(error);
  }
}

export async function postRequest(path: string, body?: object) {
  try {
    const response = await axios.post(`${DomainInfo.baseUrl}${path}`, body, {
      headers: { AuthToken: localStorage.getItem('CWAuthToken') }
    });
    return keysToCamel(response.data);
  } catch (error) {
    handleError(error);
  }
}

export async function patchRequest(path: string, body: object) {
  try {
    const response = await axios.patch(`${DomainInfo.baseUrl}${path}`, body, {
      headers: { AuthToken: localStorage.getItem('CWAuthToken') }
    });
    return keysToCamel(response.data);
  } catch (error) {
    handleError(error);
  }
}

export async function deleteRequest(path: string, params?: object) {
  try {
    const response = await axios.delete(DomainInfo.baseUrl + path, {
      data: params,
      headers: { AuthToken: localStorage.getItem('CWAuthToken') }
    });
    return keysToCamel(response.data);
  } catch (error) {
    handleError(error);
  }
}

export async function putRequest(path: string, body?: object) {
  try {
    const response = await axios.put(DomainInfo.baseUrl + path, body, {
      headers: { AuthToken: localStorage.getItem('CWAuthToken') }
    });
    return keysToCamel(response.data);
  } catch (error) {
    handleError(error);
  }
}
