export const BARCHART = 'BAR CHART';
export const SINGLESTATCHART = 'SINGLE STAT CHART';
export const LINECHART = 'LINE CHART';
export const FUNNELCHART = 'FUNNEL CHART';
export const PARETOCHART = 'PARETO CHART';

export const ROLLING = 'rolling';
export const CURRENT = 'current';
export const PREVIOUS = 'previous';
export const STATIC = 'static';

export const CURRENTDAY = 'current_day';
export const CURRENTWEEK = 'current_week';
export const CURRENTMONTH = 'current_month';
export const CURRENTYEAR = 'current_year';

export const PREVIOUSDAY = 'previous_day';
export const PREVIOUSWEEK = 'previous_week';
export const PREVIOUSMONTH = 'previous_month';
export const PREVIOUSYEAR = 'previous_year';
