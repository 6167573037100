import React from "react";
import { createPortal } from "react-dom";
import classes from "./Modal.module.css";

interface ModalProps {
  onClose?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
}

const Modal = ({ children, onClose }: ModalProps) => {
  const modalDiv = document.getElementById("modal-root");
  // check if modalDiv is null for TS
  return modalDiv
    ? createPortal(
        <div onClick={onClose} className={classes.modal}>
          {children}
        </div>,
        modalDiv
      )
    : null;
};

export default Modal;
