import { WidgetItem } from '../../../../types/board';

export const DEFAULT_ITEMS: WidgetItem[] = [
  { type: 'note', x: 0, y: 0, w: 3, h: 3, item_index: 0 }
];

export const HELP_TEXT = 'Learn more about how to use your board.';

export const HELP_CONTENT = [
  {
    title: 'Moving Widgets',
    content:
      'Your new board comes preloaded with a few widgets. You can move one by clicking the top of a widget and dragging it to a new location.'
  },
  {
    title: 'Resizing Widgets',
    content:
      'You can change the size of a widget by dragging the resize arrow icon that appears when you hover your mouse near the bottom right corner of a widget.'
  },
  {
    title: 'Adding New Widgets',
    content:
      'To add a widget, expand the toolbar found at the top left corner of the page and drag the item you want into your board. The toolbar is split into sections. For Recordings, Courses, Sections, and Modules, you can type in the searchbar for a specific item.'
  }
];

export const COLOR_PICKER_OPTIONS = [
  { color: 'Red', rgb: 'rgb(213 59 59)' },
  { color: 'Blue', rgb: 'rgb(12 69 155)' },
  { color: 'Green', rgb: 'rgb(83 117 39)' },
  { color: 'Aqua', rgb: 'rgb(86 151 130)' },
  { color: 'Purple', rgb: 'rgb(109 42 117)' },
  { color: 'Orange', rgb: 'rgb(233 180 102)' },
  { color: 'Pink', rgb: 'rgb(227 139 180)' },
  { color: 'Light Blue', rgb: 'rgb(127 196 211)' }
];

export const GCI_FIELD = 13;
export const TRANSACTION_AMOUNT_FIELD = 14;
export const PAID_GROSS_FIELD = 18;
