import React from 'react';
import { Form, Input, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import BrowserInfo from '../../../../../helpers/BrowserInfo';
import { Draggable } from 'react-beautiful-dnd';
import './formItemCard.css';

class FormItemCard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  handleIncrement = () => {
    this.setState({ typingInProgress: false }, () => {
      if (
        this.props.field &&
        this.props.field.form_id &&
        this.props.field.active
      ) {
        this.props.getUpdatingField(
          this.props.field,
          this.props.activityValue,
          'increment'
        );
      } else {
        this.props.incrementRecord(this.props.field, this.props.activityValue);
      }
    });
  };

  handleDecrement = () => {
    if (this.props.activityValue > 0) {
      this.setState({ typingInProgress: false }, () => {
        if (
          this.props.field &&
          this.props.field.form_id &&
          this.props.field.active
        ) {
          this.props.getUpdatingField(
            this.props.field,
            this.props.activityValue,
            'decrement'
          );
        } else {
          this.props.decrementRecord(
            this.props.field,
            this.props.activityValue
          );
        }
      });
    }
  };

  handleChange = (e) => {
    this.setState(
      {
        typeInValue:
          e.target.value === ''
            ? 0
            : e.target.value.length > 0 && e.target.value[0] === '0'
            ? parseFloat(e.target.value)
            : e.target.value,
        typingInProgress: true
      },
      () => {
        if (this.state.typeInValue === 0) {
          this.props.setRecord(this.props.field, 0);
        } else if (parseFloat(this.state.typeInValue)) {
          this.props.setRecord(
            this.props.field,
            parseFloat(this.state.typeInValue)
          );
        }
      }
    );
  };
  render() {
    if (!BrowserInfo.isMobile()) {
      return (
        <Draggable
          key={this.props.index}
          draggableId={this.props.title}
          index={this.props.index}
        >
          {(provided) => (
            <div
              className='formItem-div'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              key={this.props.index}
            >
              <div
                style={{
                  width: '95%',
                  background: 'white',
                  margin: '0 auto',
                  padding: '3px 3px 2px 3px',
                  borderRadius: '4px'
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#383737',
                    fontFamily: 'Inter',
                    marginTop: '5px'
                  }}
                >
                  {this.props.title}
                </p>
                <Button
                  style={{
                    display: 'inline-block',
                    width: '20%',
                    background: '#173757',
                    borderRadius: '4px'
                  }}
                  onClick={this.handleDecrement}
                >
                  <span style={{ fontSize: '80%' }}>
                    <MinusOutlined style={{ color: 'white' }} />
                  </span>
                </Button>
                <Form.Item style={{ width: '60%', display: 'inline-block' }}>
                  {this.props.field &&
                  this.props.field.form_id &&
                  this.props.field.active ? (
                    <Input
                      value={this.props.activityValue}
                      onChange={this.handleChange}
                      placeholder={this.props.activityValue}
                      style={{ textAlign: 'center' }}
                      disabled
                      tabIndex={this.props.index}
                    />
                  ) : (
                    <Input
                      value={
                        this.state.typeInValue && this.state.typingInProgress
                          ? this.state.typeInValue
                          : this.props.activityValue
                      }
                      onChange={this.handleChange}
                      style={{ textAlign: 'center' }}
                      tabIndex={this.props.index}
                      onBlur={() => this.setState({ typingInProgress: false })}
                      onPressEnter={() =>
                        this.setState({ typingInProgress: false })
                      }
                    />
                  )}
                </Form.Item>
                <Button
                  style={{
                    display: 'inline-block',
                    width: '20%',
                    background: '#173757',
                    borderRadius: '4px'
                  }}
                  onClick={this.handleIncrement}
                >
                  <span style={{ fontSize: '80%', width: '100%' }}>
                    <PlusOutlined style={{ color: 'white' }} />
                  </span>
                </Button>
              </div>
            </div>
          )}
        </Draggable>
      );
    } else {
      return (
        <Draggable
          key={this.props.index}
          draggableId={this.props.title}
          index={this.props.index}
        >
          {(provided, snapshot) => (
            <div
              style={{ width: '100%' }}
              className='formItem-div'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              key={this.props.index}
            >
              <div
                style={{
                  width: '95%',
                  background: 'white',
                  margin: '0 auto',
                  borderBottom: '1px solid #cfcccc',
                  borderRadius: '4px'
                }}
              >
                <p
                  style={{
                    fontSize: '14px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#383737',
                    fontFamily: 'Inter',
                    marginTop: '3px'
                  }}
                >
                  {this.props.title}
                </p>
                <div style={{ width: '90%', margin: '0 auto', height: '45px' }}>
                  <Button
                    style={{
                      display: 'inline-block',
                      width: '20%',
                      background: '#173757',
                      borderRadius: '4px'
                    }}
                    onClick={this.handleDecrement}
                  >
                    <span style={{ fontSize: '10px' }}>
                      <MinusOutlined style={{ color: 'white' }} />
                    </span>
                  </Button>
                  <Form.Item style={{ width: '60%', display: 'inline-block' }}>
                    {this.props.field &&
                    this.props.field.form_id &&
                    this.props.field.active ? (
                      <Input
                        value={this.props.activityValue}
                        onChange={this.handleChange}
                        placeholder={this.props.activityValue}
                        style={{ textAlign: 'center' }}
                        disabled
                        tabIndex={this.props.index}
                      />
                    ) : (
                      <Input
                        value={
                          this.state.typeInValue && this.state.typingInProgress
                            ? this.state.typeInValue
                            : this.props.activityValue
                        }
                        onChange={this.handleChange}
                        placeholder={this.props.activityValue}
                        style={{ textAlign: 'center' }}
                        tabIndex={this.props.index}
                        onBlur={() =>
                          this.setState({ typingInProgress: false })
                        }
                        onPressEnter={() =>
                          this.setState({ typingInProgress: false })
                        }
                      />
                    )}
                  </Form.Item>
                  <Button
                    style={{
                      display: 'inline-block',
                      width: '20%',
                      background: '#173757',
                      borderRadius: '4px'
                    }}
                    onClick={this.handleIncrement}
                  >
                    <span style={{ fontSize: '10px' }}>
                      <PlusOutlined style={{ color: 'white' }} />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Draggable>
      );
    }
  }
}

export default FormItemCard;
