import { message } from 'antd';
import React from 'react';
import { FaCheck, FaEllipsisH, FaRegWindowClose } from 'react-icons/fa';
import {
  deleteRequest,
  postRequest
} from '../../../../../../helpers/httpHandler2';
import { timeSince } from '../../../../../../helpers/TimeHelpers';
import Popover from '../../../../../reusableComponents/standard/popover/Popover';
import {
  Notification,
  NotificationAction,
  NotificationType
} from '../Notifications';
import classes from './NotificationEntry.module.scss';

type Props = {
  notification: Notification;
  toggleReadNotification: (
    notification: Notification,
    read?: boolean,
    actionApplied?: string
  ) => Promise<void>;
  deleteNotification: (notification: Notification) => Promise<void>;
  navigateToNotification: (notification: Notification) => Promise<void>;
};

type ActionProps = {
  read: boolean;
  close?: () => void;
  remove: () => void;
  markRead: () => void;
};
const Actions = ({ read, close, remove, markRead }: ActionProps) => {
  return (
    <ul className={classes.actions}>
      <li
        onClick={(e) => {
          e.stopPropagation();
          markRead();
          if (close) close();
        }}
      >
        <FaCheck />
        <span>{read ? 'Mark as unread' : 'Mark as read'}</span>
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          remove();
          if (close) close();
        }}
      >
        <FaRegWindowClose />
        <span>Remove</span>
      </li>
    </ul>
  );
};

const NotificationEntry = ({
  notification,
  toggleReadNotification,
  deleteNotification,
  navigateToNotification
}: Props) => {
  const acceptTeamInvite = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    notification: Notification
  ) => {
    e.stopPropagation();
    try {
      toggleReadNotification(
        notification,
        true,
        NotificationAction.ACCEPTED_TEAM_INVITE
      );
      await postRequest(`/api/teams/invite/accept`, {
        joinTeamSecretCode: notification.teamInviteSecretCode
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const rejectTeamInvite = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    notification: Notification
  ) => {
    e.stopPropagation();
    try {
      toggleReadNotification(
        notification,
        true,
        NotificationAction.REJECTED_TEAM_INVITE
      );
      await deleteRequest(
        `/api/teams/${notification.teamId}/invites/${notification.teamInviteId}`
      );
    } catch (error) {
      message.error('could not remove notification');
    }
  };

  const acceptJoinTeamRequest = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    notification: Notification
  ) => {
    e.stopPropagation();
    try {
      toggleReadNotification(
        notification,
        true,
        NotificationAction.ACCEPTED_JOIN_REQUEST
      );
      await postRequest(`/api/teams/requests/accept`, {
        memberId: notification.senderId,
        teamId: notification.teamId
      });
      message.success(
        `Succesfully Added ${notification.senderFirstName} to the team`
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const rejectJoinTeamRequest = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    notification: Notification
  ) => {
    e.stopPropagation();
    try {
      toggleReadNotification(
        notification,
        true,
        NotificationAction.REJECTED_JOIN_REQUEST
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const notificationBodyFromType = (notification: Notification) => {
    switch (notification.notificationType) {
      case NotificationType.BOARD_ACCESS:
        return (
          <div>
            <span>{`${notification.senderFirstName} ${notification.senderLastName} has invited you to the board `}</span>
            <span className={classes.bold}>{notification.boardName}</span>
          </div>
        );
      case NotificationType.CARD_MEMBER:
        return (
          <div>
            <span>{`${notification.senderFirstName} has added you to the card `}</span>
            <span className={classes.bold}>{notification.cardName}</span>
            <span>{` from the board ${notification.boardName}`}</span>
          </div>
        );
      case NotificationType.ACCEPTED_TEAM_INVITE:
        return (
          <div>
            <span>{`${notification.senderFirstName} ${notification.senderLastName} has accepted your team invite`}</span>
          </div>
        );
      case NotificationType.TEAM_INVITE:
        return (
          <div>
            {!notification.actionApplied ? (
              <>
                <span>{`${notification.senderFirstName} has invited you to join the team `}</span>
                <span className={classes.bold}>{notification.teamName}</span>
              </>
            ) : (
              <>
                <span>{`Your response to the team invite sent by `}</span>
                <span className={classes.bold}>
                  {notification.senderFirstName}
                </span>
              </>
            )}

            <div className={classes.notification__options}>
              {notification.actionApplied ? (
                <>
                  {notification.actionApplied ===
                  NotificationAction.ACCEPTED_TEAM_INVITE ? (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/stattracker';
                      }}
                      className={classes.option__button}
                    >
                      Accepted - Go to Stat Tracker
                    </div>
                  ) : (
                    <div>Rejected</div>
                  )}
                </>
              ) : (
                <>
                  {' '}
                  <div
                    onClick={(e) => acceptTeamInvite(e, notification)}
                    className={classes.option__button}
                  >
                    Join Team
                  </div>
                  <div
                    onClick={(e) => rejectTeamInvite(e, notification)}
                    className={classes.option__button}
                  >
                    Reject
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case NotificationType.JOIN_TEAM_REQUEST:
        return (
          <div>
            {!notification.actionApplied ? (
              <>
                <span>{`${notification.senderFirstName} ${notification.senderLastName} has requested to join the team `}</span>
                <span className={classes.bold}>{notification.teamName}</span>
              </>
            ) : (
              <>
                <span>{`Your response to the team invite sent by `}</span>
                <span className={classes.bold}>
                  {notification.senderFirstName}
                </span>
              </>
            )}

            <div className={classes.notification__options}>
              {notification.actionApplied ? (
                <>
                  {notification.actionApplied ===
                  NotificationAction.ACCEPTED_TEAM_INVITE ? (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = '/stattracker';
                      }}
                      className={classes.option__button}
                    >
                      Accepted
                    </div>
                  ) : (
                    <div>Rejected</div>
                  )}
                </>
              ) : (
                <>
                  {' '}
                  <div
                    onClick={(e) => acceptJoinTeamRequest(e, notification)}
                    className={classes.option__button}
                  >
                    Add to Team
                  </div>
                  <div
                    onClick={(e) => rejectJoinTeamRequest(e, notification)}
                    className={classes.option__button}
                  >
                    Reject
                  </div>
                </>
              )}
            </div>
          </div>
        );
      case NotificationType.ACCEPTED_TEAM_REQUEST:
        return (
          <div>
            <span>{`Your request to join the team ${notification.teamName} has been accepted`}</span>
          </div>
        );
      default:
        return (
          <>
            <span>{`${notification.senderFirstName} ${notification.senderLastName} did something`}</span>
          </>
        );
    }
  };

  const bodyClickEvent = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    notification: Notification
  ) => {
    e.stopPropagation();
    switch (notification.notificationType) {
      case NotificationType.BOARD_ACCESS:
        navigateToNotification(notification);
        break;
      case NotificationType.CARD_MEMBER:
        navigateToNotification(notification);
        break;
      case NotificationType.ACCEPTED_TEAM_INVITE:
        toggleReadNotification(notification, true);
        break;
      default:
        return;
    }
  };

  return (
    <div key={notification.id} className={classes.notification}>
      <div
        onClick={(e) => bodyClickEvent(e, notification)}
        className={classes.notification__body}
      >
        <div className={classes.notification__edit}>
          <Popover
            ignoreWindowScroll={true}
            trigger='click'
            content={
              <Actions
                read={notification.notificationRead}
                markRead={() => toggleReadNotification(notification)}
                remove={() => deleteNotification(notification)}
              />
            }
          >
            <FaEllipsisH />
          </Popover>
        </div>
        <div className={classes.notification__pic}>
          <img
            src={
              notification.senderProfilePicUrl ||
              'https://elasticbeanstalk-us-east-2-783675859554.s3.amazonaws.com/user-solid.svg'
            }
            alt='pic'
          />
        </div>
        <div className={classes.notification__content}>
          {notificationBodyFromType(notification)}
          <div className={classes.notification__time}>{`${timeSince(
            new Date(notification.time)
          )} ago`}</div>
        </div>
        <div className={classes['read-holder']}>
          <div
            className={`${classes['read-icon']} ${
              !notification.notificationRead && classes['read-icon--show']
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationEntry;
