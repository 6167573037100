import React from 'react';
import { Row, Tabs, Select, Tooltip, Checkbox, Col, Input } from 'antd';
import DataToDisplayPanel from './dataToDisplayPanel/DataToDisplayPanel';
import DateOptionUi from './dateOptionUI/DateOptionUi';
import { SaveFilled } from '@ant-design/icons';

const SettingsPanel = (props) => {
  const { TabPane } = Tabs;
  const { Option, OptGroup } = Select;

  const getConfigButton = () => {
    if (
      props.selectedTeam &&
      (props.selectedTeam.team_leader_id === props.selectedTeam.user_id ||
        props.selectedTeam.role.split(',').includes('Super Admin'))
    ) {
      return (
        <div
          style={{
            width: '100%',
            position: 'relative',
            marginBottom: '15px',
            marginTop: '5px'
          }}
        >
          <div style={{ position: 'absolute', right: 12, top: 5 }}>
            <Tooltip
              title={
                'Click to save chart configuration for team members to view'
              }
            >
              <SaveFilled
                onClick={props.saveChartConfigSettings}
                style={{ fontSize: '22px' }}
              />
            </Tooltip>
          </div>
        </div>
      );
    }
    return null;
  };

  const getStackedFieldOptions = () => {
    if (props.showOptions) {
      return props.showOptions.map((option, index) => {
        return (
          <Col span={24} key={index + '_' + option.label + '_for_stacked'}>
            <Checkbox
              value={option.value}
              checked={props.stackedChartFields.includes(option.value)}
              onChange={props.handleChangeFields}
            >
              {option.label}
            </Checkbox>
          </Col>
        );
      });
    }
  };

  const getDisplayOptionTab = () => {
    return (
      <div style={{ width: '270px' }}>
        <Row style={{ margin: '0 0 10px 10px' }}>
          <span style={{ fontWeight: 'bold', width: '100%' }}>
            Display options:
          </span>
          <Checkbox.Group
            style={{ width: '100%' }}
            options={['Squad view']}
            value={props.squadView}
            onChange={props.handleSquadViewSelect}
          />

          <Checkbox.Group
            options={['Show yesterday']}
            value={props.showYesterday}
            onChange={props.handleDaySelect}
          />
          <Checkbox.Group />
          <Row>{getStackedFieldOptions()}</Row>
        </Row>
      </div>
    );
  };

  const getDisplayOptions = () => {
    return props.selectedStatFields.map((option, index) => {
      return (
        <Option
          key={index}
          value={`${option.field_name.split(' ').join('_')}_${option.field_id}`}
        >
          {option.field_name}
        </Option>
      );
    });
  };

  const getCategorySelections = () => {
    return props.categoryOptions.map((option, index) => {
      return (
        <Option
          key={index + props.selectedStatFields.length * 2 + 1}
          value={`Category_${option.name.split(' ').join('_')}_${option.id}`}
        >
          {option.name}
        </Option>
      );
    });
  };

  const getDataToDisplay = () => {
    return (
      <DataToDisplayPanel
        teamOption={props.teamOption}
        selectedTeam={props.selectedTeam}
        showTeamTotalForChart={props.showTeamTotalForChart}
        squads={props.squads}
        lineChartSquadOptions={props.lineChartSquadOptions}
        chartSquadSeries={props.chartSquadSeries}
        changeChartSquadSeries={props.changeChartSquadSeries}
        processedStats={props.processedStats}
        lineChartMembersOptions={props.lineChartMembersOptions}
        selectedChart={props.selectedChart}
        lineChartSeries={props.lineChartSeries}
        chartMemberSeries={props.chartMemberSeries}
        changeLineChartSeries={props.changeLineChartSeries}
        selectedSingleStatDataPoint={props.selectedSingleStatDataPoint}
        handleSingleStatRadioSelection={props.handleSingleStatRadioSelection}
        handleShowTeamTotal={props.handleShowTeamTotal}
      />
    );
  };

  const getDatesConfigUI = () => {
    return (
      <Row
        style={{
          width: '245px',
          background: '#fff',
          margin: '0 auto 5px auto',
          paddingTop: '0'
        }}
      >
        <span style={{ width: '100%', fontWeight: 'bold', marginTop: '10px' }}>
          Date options:
        </span>
        <Select
          style={{ width: '100%' }}
          value={
            props.selectedChart === 'team-line-chart'
              ? props.lineChartDisplay
              : props.selectedChart === 'team-dates-total-chart' ||
                props.selectedChart === 'single-stat-display'
              ? props.datesTotalDisplay
              : 'rolling_dates'
          }
          onChange={props.handleSelectDatesDisplay}
        >
          <Option value={'current_dates'}>Current Day/Week/Month/Year</Option>
          <Option value={'previous_dates'}>Previous Day/Week/Month/Year</Option>
          <Option value={'rolling_dates'}>Rolling dates</Option>
          <Option value={'static_dates'}>Static Dates</Option>
        </Select>
        {getDatesOptionUI()}
      </Row>
    );
  };

  const getDatesOptionUI = () => {
    return (
      <DateOptionUi
        selectedChart={props.selectedChart}
        lineChartDisplay={props.lineChartDisplay}
        datesTotalDisplay={props.datesTotalDisplay}
        radioOption={props.radioOption}
        selectRadioOption={props.selectRadioOption}
        rollingValue={props.rollingValue}
        rollingUnit={props.rollingUnit}
        handleSelectRollingUnit={props.handleSelectRollingUnit}
        handleInputRollingValue={props.handleInputRollingValue}
        handleRollingValue={props.handleRollingValue}
        pickDateRange={props.pickDateRange}
        staticStart={props.staticStart}
        staticEnd={props.staticEnd}
        curRadioOption={props.curRadioOption}
      />
    );
  };

  const getConfigs = () => {
    if (
      props.selectedChart === 'team-total-column-chart' ||
      ((props.selectedChart === 'team-line-chart' ||
        props.selectedChart === 'team-dates-total-chart' ||
        props.selectedChart === 'single-stat-display') &&
        props.selectedTeam &&
        (props.selectedTeam.team_leader_id === props.selectedTeam.user_id ||
          props.selectedTeam.role.split(',').includes('Super Admin')))
    ) {
      return (
        <div style={{ width: '270px' }}>
          <Row
            style={{
              width: '245px',
              background: '#fff',
              margin: '0 auto 10px auto'
            }}
          >
            <span style={{ width: '100%', fontWeight: 'bold' }}>
              Display stat:
            </span>
            <Select
              style={{ width: '100%' }}
              value={props.dataDisplay}
              onChange={props.handleSelect}
            >
              <OptGroup label='Field'>{getDisplayOptions()}</OptGroup>
              <OptGroup label='Category'>{getCategorySelections()}</OptGroup>
            </Select>
            {props.selectedChart === 'single-stat-display' ? (
              <Row style={{ marginTop: '10px' }}>
                <span style={{ width: '100%', fontWeight: 'bold' }}>
                  Stat goal:
                </span>
                <Input
                  style={{ width: '50%', textAlign: 'center' }}
                  onChange={props.handleSetGoal}
                  value={props.statGoal}
                />
              </Row>
            ) : null}
            {props.selectedChart === 'team-line-chart' ||
            props.selectedChart === 'team-dates-total-chart' ||
            props.selectedChart === 'single-stat-display'
              ? getDatesConfigUI()
              : null}
          </Row>
        </div>
      );
    } else if (props.selectedChart === 'team-stacked-chart') {
      return (
        <Row style={{ background: '#f0f4f7' }}>
          <Checkbox.Group
            options={props.showOptions}
            value={props.stackedChartFields}
            onChange={props.handleChangeFields}
          />
        </Row>
      );
    } else if (
      props.selectedChart === 'team-hybrid-bar-chart' &&
      props.selectedTeam
    ) {
      // && props.dailyTrackerStats.length > 0
      if (
        props.selectedTeam.team_leader_id === props.selectedTeam.user_id ||
        props.selectedTeam.role.split(',').includes('Super Admin')
      ) {
        return (
          <div style={{ background: '#fff', width: '270px' }}>
            <div style={{ width: '245px', margin: '0 auto 10px auto' }}>
              <span style={{ fontWeight: 'bold' }}>Sort by:</span>
              <Row>
                <Select
                  style={{ width: '100%', margin: '0 auto' }}
                  value={props.selectedSortField}
                  onChange={props.handleChangeSort}
                >
                  <OptGroup label='Field'>{getDisplayOptions()}</OptGroup>
                  <OptGroup label='Category'>
                    {getCategorySelections()}
                  </OptGroup>
                </Select>
              </Row>
            </div>

            <Row style={{ marginLeft: '10px', marginBottom: '10px' }}>
              <span style={{ width: '100%', fontWeight: 'bold' }}>
                Set daily goal:
              </span>
              <Input
                style={{ width: '30%', textAlign: 'center' }}
                onChange={props.handleSetGoal}
                value={props.statGoal}
              />
            </Row>
          </div>
        );
      }
    }
  };

  return (
    <Row
      style={{
        width: '270px',
        background: '#fff',
        paddingBottom: '5px',
        height: 'auto'
      }}
    >
      {(props.dailyTrackerStats && props.dailyTrackerStats.length > 0) ||
      (props.statsForDatesTotal && props.statsForDatesTotal.length > 0) ||
      (props.statsForLineChart && props.statsForLineChart.length > 0) ||
      (props.statsForTeamTotal && props.statsForTeamTotal.length > 0)
        ? getConfigButton()
        : null}
      <Tabs>
        <TabPane tab='Team' key='setting-panel-1'>
          <div style={{ width: '245px', margin: '10px auto 10px auto' }}>
            {props.statDisplayId ? null : (
              <div>
                <span style={{ width: '100%', fontWeight: 'bold' }}>
                  Chart type:
                </span>
                <Select
                  style={{ width: '245px' }}
                  value={props.selectedChart}
                  onChange={props.handleSelectChart}
                >
                  <Option value='team-hybrid-bar-chart'>Daily Tracker</Option>
                  <Option value='team-dates-total-chart'>Bar Chart</Option>
                  <Option value='team-line-chart'>Line Chart</Option>
                  <Option value='team-total-column-chart'>
                    Total Column Chart
                  </Option>
                </Select>
              </div>
            )}
          </div>
          {getConfigs()}
        </TabPane>
        {props.selectedChart === 'team-hybrid-bar-chart' &&
        ((props.selectedTeam &&
          props.selectedTeam.team_leader_id === props.selectedTeam.user_id) ||
          (props.selectedTeam &&
            props.selectedTeam.role.split(',').includes('Super Admin'))) ? (
          <TabPane tab='Display options'>{getDisplayOptionTab()}</TabPane>
        ) : null}
        {(props.selectedChart === 'team-line-chart' ||
          props.selectedChart === 'team-dates-total-chart' ||
          props.selectedChart === 'single-stat-display') &&
        props.selectedTeam &&
        (props.selectedTeam.team_leader_id === props.selectedTeam.user_id ||
          (props.selectedTeam &&
            props.selectedTeam.role.split(',').includes('Super Admin'))) ? (
          <TabPane tab='Data to display' key='setting-panel-2'>
            {getDataToDisplay()}
          </TabPane>
        ) : null}
      </Tabs>
    </Row>
  );
};

export default SettingsPanel;
