import create, { State, StateCreator } from 'zustand';
import { StoreProperties } from '../types/zustandTypes';

import { createAppointmentSlice } from './slices/createAppointmentSlice';
import { createBoardSlice } from './slices/createBoardSlice';
import { createClientSlice } from './slices/createClientSlice';
import { createFormSlice } from './slices/createFormSlice';
import { createTransactionSlice } from './slices/createTransactionSlice';
import { createStatTrackerSlice } from './slices/createStatTrackerSlice';
import { createListLabelSlice } from './slices/createListLabelSlice';
import { createTaskSlice } from './slices/createTaskSlice';
import { createTeamSlice } from './slices/createTeamSlice';
import { createWidgetListSlice } from './slices/createWidgetListSlice';
import { createStatEditorSlice } from './slices/createStatEditorSlice';
import { createPipelineSlice } from './slices/createPipelineSlice';
import { createContactSlice } from './slices/createContactSlice';
import produce, { Draft, Immutable } from 'immer';
import { createIntegrationsSlice } from './slices/createIntegrationsSlice';
import { createCourseViewSlice } from './slices/createCourseViewSlice';
import { createNavBarSlice } from '../components/pageBuilder/elements/navBar/CreateNavBarSlice';
import { createContestSlice } from './slices/createContestSlice';

// * If your state needs async calls to fetch initial state,
// * do so in App.js -> fetchZustandGlobalState()

// --- IMMER PROXY --- //
// immer wrapper allows us to "mutate" state directly in zustand set() //
// do not try to mutate state directly outside of set() //

const immer =
  <T extends State>(config: StateCreator<T>): StateCreator<T> =>
  (set, get, api) =>
    config(
      (partial, replace) => {
        const nextState =
          typeof partial === 'function'
            ? produce(partial as (state: Draft<T>) => T)
            : (partial as Immutable<Draft<T>>); // Explicitly cast 'T' to 'Immutable<Draft<T>>'
        return set(nextState as T, replace);
      },
      get,
      api
    );

export const useStore = create<StoreProperties>(
  immer((set, get) => ({
    ...createAppointmentSlice(set, get),
    ...createTeamSlice(set, get),
    ...createClientSlice(set, get),
    ...createTaskSlice(set, get),
    ...createBoardSlice(set, get),
    ...createWidgetListSlice(set, get),
    ...createStatEditorSlice(set, get),
    ...createFormSlice(set, get),
    ...createTransactionSlice(set, get),
    ...createStatTrackerSlice(set, get),
    ...createPipelineSlice(set, get),
    ...createListLabelSlice(set, get),
    ...createContactSlice(set, get),
    ...createIntegrationsSlice(set, get),
    ...createCourseViewSlice(set, get),
    ...createNavBarSlice(set),
    ...createContestSlice(set, get)
  }))
);
