import React from 'react';
import PageViewer from '../viewer/PageViewer';
import { Modal } from 'antd';

class ModalViewer extends React.Component {
  render() {
    return (
      <div>
        <Modal
          // title={this.props.curPopup ? this.props.curPopup.title : "Basic Modal"}
          open={this.props.visible}
          onOk={this.props.closeModal}
          onCancel={this.props.closeModal}
          footer={null}
          destroyOnClose={true}
          width={'50%'}
        >
          <PageViewer page_id={this.props.pageId} />
        </Modal>
      </div>
    );
  }
}

export default ModalViewer;
