import httpHandler from '../../helpers/httpHandler';

const fetchUserClients = async (userCrmId) => {
  try {
    const clients = await httpHandler.get(
      `/api/infusionsoft/getCoachesClientsByParams/${userCrmId}`
    );
    return clients;
  } catch (error) {
    console.error(`error fetching user clients`, error);
  }
};

const clientApi = { fetchUserClients };
export default clientApi;
