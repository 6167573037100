import { Pipeline, PipelineStage } from '../../types/statTracker';
import httpHandler from '../../helpers/httpHandler';
import { getRequest } from '../../helpers/httpHandler2';

type Awaited<TResponse> = TResponse extends PromiseLike<infer UResponse>
  ? Awaited<UResponse>
  : TResponse;

const getPipeline = async (pipelineId: number) => {
  try {
    const response: Awaited<Promise<Promise<PipelineStage[]>>> =
      await httpHandler.get(`/api/pipelines/${pipelineId}/pipeline-stages`);
    return response;
  } catch (error) {
    console.error('Error getting pipeline items', error);
    return [];
  }
};

const moveStage = async (
  pipelineStageId: number,
  fromIndex: number,
  toIndex: number,
  pipelineId: number
) => {
  try {
    await httpHandler.patch(
      `/api/pipelines/${pipelineId}/pipeline-stages/${pipelineStageId}`,
      { fromIndex, toIndex }
    );
  } catch (error) {
    console.error('Error moving pipeline', error);
  }
};

const addStage = async (
  pipelineId: number,
  newStage: PipelineStage,
  teamId: number
) => {
  try {
    const response = await httpHandler.post(
      `/api/pipelines/${pipelineId}/pipeline-stages`,
      {
        ...newStage,
        teamId
      }
    );

    return response;
  } catch (error) {
    console.error('Error adding stage', error);
  }
};

const archiveStage = async (
  pipelineId: number,
  index: number,
  pipelineStageId: number,
  teamId: number
) => {
  try {
    const response = await httpHandler.delete(
      `/api/pipelines/${pipelineId}/pipeline-stages/${pipelineStageId}`,
      {
        index,
        teamId
      }
    );
    return response;
  } catch (error) {
    console.error('Error archiving stage', error);
  }
};

const updateStage = async (
  stageName: string,
  color: string,
  stageId: number,
  pipelineId: number,
  statFieldId: number | null,
  kpiCategoryId: number | null
) => {
  try {
    const response = httpHandler.patch(
      `/api/pipelines/${pipelineId}/pipeline-stages/${stageId}`,
      {
        stageName,
        color,
        statFieldId,
        kpiCategoryId
      }
    );

    return response;
  } catch (error) {
    console.error('Error updating pipeline stage', error);
  }
};

const getAllPipelines = async (teamId: number) => {
  try {
    const pipelines: Promise<Pipeline[]> = await httpHandler.get(
      '/api/pipelines',
      {
        teamId
      }
    );
    return pipelines;
  } catch (error) {
    console.error('Error getting pipelines', error);
  }
};

const getAllPipelinesWithStages = async (teamId: number) => {
  const pipelines: Promise<Pipeline[]> = await getRequest('/api/pipelines', {
    stages: true,
    teamId: teamId
  });
  return pipelines;
};

const postPipeline = async (name: string, teamId: number) => {
  try {
    const response = httpHandler.post('/api/pipelines', {
      name,
      teamId
    });
    return response;
  } catch (error) {
    console.error('could not add a pipeline stage', error);
  }
};

const deletePipeline = async (pipelineId: number) => {
  try {
    const response = httpHandler.delete(`/api/pipelines/${pipelineId}`);
    return response;
  } catch (error) {
    console.error('could not delete pipeline', error);
  }
};

const editPipeline = async (name: string, pipelineId: number) => {
  try {
    const response = httpHandler.patch(`/api/pipelines/${pipelineId}`, {
      name
    });
    return response;
  } catch (error) {
    console.error('could not edit pipeline', error);
  }
};

const getAllPipelineTransactionFields = async () => {
  const response = getRequest('/api/forms/form-fields');
  return response;
};

export const pipelineApi = {
  getPipeline,
  moveStage,
  addStage,
  archiveStage,
  updateStage,
  getAllPipelines,
  getAllPipelinesWithStages,
  postPipeline,
  deletePipeline,
  editPipeline,
  getAllPipelineTransactionFields
};
