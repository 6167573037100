import React from 'react';
import TeamHybridBarChart from './teamHybridBarChart/TeamHybridBarChart';
import TeamDatesTotalChart from './teamDatesTotalChart/TeamDatesTotalChart';
import TeamLineChart from './teamLineChart/TeamLineChart';
import TeamTotalColumnChart from './teamTotalColumnChart/TeamTotalColumnChart';
import SingleStatDisplay from './singleStatDisplay/SingleStatDisplay';

const ChartList = (props) => {
  if (props.selectedChart === 'team-hybrid-bar-chart') {
    return (
      <TeamHybridBarChart
        teamLeader={props.teamLeader}
        initData={props.initData}
        displayOptions={props.displayOptions}
        hybridChartFields={props.hybridChartFields}
        graph_name={props.graph_name}
        stackedChartFields={props.stackedChartFields}
        showYesterday={props.showYesterday}
        dataForBarChart={props.dataForBarChart}
        barChartUpdated={props.barChartUpdated}
        selectedSortField={props.selectedSortField}
        statGoal={props.statGoal}
        squads={props.squads}
        squadView={props.squadView}
        selectedStatFields={props.selectedStatFields}
        dailyTrackerStats={props.dailyTrackerStats}
        loadingDailyTracker={props.loadingDailyTracker}
        getClickedOnSeries={props.getClickedOnSeries}
        statDisplayId={props.statDisplayId}
        graphRef={props.graphRef}
      />
    );
  } else if (props.selectedChart === 'team-dates-total-chart') {
    return (
      <TeamDatesTotalChart
        initData={props.initData}
        selectedTeam={props.selectedTeam}
        teamLeader={props.teamLeader}
        graph_name={props.graph_name}
        dataDisplay={props.dataDisplay}
        processedDatesTotalData={props.processedDatesTotalData}
        staticStart={props.staticStart}
        staticEnd={props.staticEnd}
        datesTotalDisplay={props.datesTotalDisplay}
        rollingUnit={props.rollingUnit}
        rollingValue={props.rollingValue}
        dates={props.dates}
        radioOption={props.radioOption}
        selectedDate={props.selectedDate}
        dataForDatesTotalChart={props.dataForDatesTotalChart}
        updated={props.updated}
        curRadioOption={props.curRadioOption}
        dateOptionDisplay={props.dateOptionDisplay}
        getClickedOnSeries={props.getClickedOnSeries}
        statDisplayId={props.statDisplayId}
        graphRef={props.graphRef}
      />
    );
  } else if (props.selectedChart === 'team-line-chart') {
    return (
      <TeamLineChart
        initData={props.initData}
        selectedTeam={props.selectedTeam}
        teamLeader={props.teamLeader}
        displayOptions={props.displayOptions}
        graph_name={props.graph_name}
        dataDisplay={props.dataDisplay}
        lineChartSeries={props.lineChartSeries}
        lineChartSquadSeries={props.lineChartSquadSeries}
        dataForLineChart={props.dataForLineChart}
        lineChartUpdated={props.lineChartUpdated}
        rollingUnit={props.rollingUnit}
        lineChartDisplay={props.lineChartDisplay}
        lineChartData={props.lineChartData}
        loadingLineChart={props.loadingLineChart}
        statDisplayId={props.statDisplayId}
        graphRef={props.graphRef}
        // lineChartData={this.state.lineChartData}
      />
    );
  } else if (props.selectedChart === 'team-total-column-chart') {
    return (
      <TeamTotalColumnChart
        // teamTotals={this.state.teamTotals}
        teamTotals={props.teamTotals}
        dataDisplay={props.dataDisplay}
        graph_name={props.graph_name}
        selectedDate={props.selectedDate}
        statDisplayId={props.statDisplayId}
        graphRef={props.graphRef}
      />
    );
  } else if (props.selectedChart === 'single-stat-display') {
    return (
      <SingleStatDisplay
        isWidget={props.isWidget}
        initData={props.initData}
        selectedTeam={props.selectedTeam}
        teamLeader={props.teamLeader}
        graph_name={props.graph_name}
        dataDisplay={props.dataDisplay}
        processedDatesTotalData={props.processedDatesTotalData}
        statsForDatesTotal={props.statsForDatesTotal}
        staticStart={props.staticStart}
        staticEnd={props.staticEnd}
        datesTotalDisplay={props.datesTotalDisplay}
        rollingUnit={props.rollingUnit}
        rollingValue={props.rollingValue}
        dates={props.dates}
        radioOption={props.radioOption}
        selectedDate={props.selectedDate}
        dataForDatesTotalChart={props.dataForDatesTotalChart}
        updated={props.updated}
        curRadioOption={props.curRadioOption}
        dateOptionDisplay={props.dateOptionDisplay}
        getClickedOnSeries={props.getClickedOnSeries}
        statDisplayId={props.statDisplayId}
        statGoal={props.statGoal}
        selectedSingleStatDataPoint={props.selectedSingleStatDataPoint}
        graphRef={props.graphRef}
      />
    );
  } else {
    return null;
  }
};

export default ChartList;
