import { GetFn, SetFn, WidgetListSlice } from '../../types/zustandTypes';
import widgetListApi from '../apis/widgetListApi';
// import boardApi from "../apis/boardApi";

export const createWidgetListSlice = (
  set: SetFn,
  get: GetFn
): WidgetListSlice => ({
  widgetLists: {},
  dragging: false,
  nameChangeWidgetId: null,
  widgetListApi: {
    initialize: async (dashboardId) => {
      const widgetLists = await widgetListApi.initialize(dashboardId);
      set({ widgetLists });
    },
    setWidgetLists: (widgetLists) => {
      set({ widgetLists });
    },
    toggleDragging: () => {
      set((state) => ({ dragging: !state.dragging }));
    },
    setNameChangeWidgetId: (widgetId) => {
      set({ nameChangeWidgetId: widgetId });
    }
  }
});
