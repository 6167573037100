import { Col, Divider, Row } from "antd";
import React from "react";

const TeamVsTeamLeaderboard = ({ firstPlace, secondPlace }) => {
  return (
    <div style={{ background: "rgb(245 245 245)" }}>
      <Divider />
      <Row justify="center" align="middle">
        <h3>Teams</h3>
      </Row>
      <Row
        style={{
          borderBottom: "solid",
          borderWidth: "1px",
          borderColor: "rgb(245, 245, 245)",
          padding: "10px",
        }}
        type="flex"
        align="middle"
      >
        <Col span={4}>
          <span>
            <div
              style={{
                fontSize: "30px",
                background: "rgb(23, 55, 87)",
                borderRadius: "50%",
                width: "50px",
                color: "white",
                margin: "auto",
                // fontSize: "25px",
              }}
            >
              1
            </div>
          </span>
        </Col>
        <Col span={16}>
          <Row align="middle">
            <div
              style={{
                width: "50px",
                height: "50px",
                margin: 0,
                backgroundImage: `url("${firstPlace.profile_pic_url}")`,
                backgroundSize: "contain",
                borderRadius: "50%",
                float: "left",
              }}
            />
            <div style={{ marginLeft: "20px" }}>{firstPlace.name}</div>
          </Row>
        </Col>
        <Col span={4}>{firstPlace.total}</Col>
      </Row>
      <Row
        style={{
          borderBottom: "solid",
          borderWidth: "1px",
          borderColor: "rgb(245, 245, 245)",
          padding: "10px",
        }}
        type="flex"
        align="middle"
      >
        <Col span={4}>
          <span style={{}}>
            <div style={{ fontSize: "25px" }}>2</div>
          </span>
        </Col>
        <Col span={16}>
          <Row align="middle">
            <div
              style={{
                width: "50px",
                height: "50px",
                margin: 0,
                backgroundImage: `url("${secondPlace.profile_pic_url}")`,
                backgroundSize: "contain",
                borderRadius: "50%",
                float: "left",
              }}
            />
            <div style={{ marginLeft: "20px" }}>{secondPlace.name}</div>
          </Row>
        </Col>
        <Col span={4}>{secondPlace.total}</Col>
      </Row>
      <Divider />
    </div>
  );
};

export default TeamVsTeamLeaderboard;
