import { Card, Col, Row, Tag } from "antd";
import React from "react";
import moment from "moment";

const ChallengeInfo = ({ challenge }) => {
  return (
    <Col key={challenge.id} span={7.5}>
      <Card
        cover={
          <img
            alt="challenge"
            src={
              challenge.image_url
                ? challenge.image_url
                : "https://elasticbeanstalk-us-east-2-783675859554.s3.us-east-2.amazonaws.com/cw.PNG"
            }
            style={{ objectFit: "cover", height: "150px" }}
          />
        }
        style={{
          height: "325px",
          width: "350px",
          marginTop: "40px",
          border: "none",
        }}
      >
        <div style={{ height: "130px" }}>
          <Row align={"middle"} style={{ width: "100%" }}>
            <Col style={{ width: "100%", textAlign: "center" }}>
              <h2>{challenge.name} </h2>
              {challenge.grouping === "individual" && (
                <Tag color="green">Public Challenge</Tag>
              )}
              {challenge.grouping === "allTeams" && (
                <Tag color="volcano">Team vs. All Teams</Tag>
              )}
              {challenge.grouping === "teamVsTeam" && (
                <Tag color="red">Team vs. Team</Tag>
              )}
              {challenge.grouping === "team" && (
                <Tag color="cyan">Team Challenge</Tag>
              )}
              <br />
            </Col>
            <Col align={"middle"} lg={10} md={10} sm={10}></Col>
          </Row>
          <Row align="middle" justify="center">
            <span
              className="date-display"
              style={{
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              {moment(challenge.start_date).format("MMMM Do, YYYY")} until{" "}
              {moment(challenge.end_date).format("MMMM Do, YYYY")}
            </span>
          </Row>
          {challenge.description && (
            <Row justify="center" align="middle">
              <p
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                {challenge.description}
              </p>
            </Row>
          )}
        </div>
      </Card>
    </Col>
  );
};

export default ChallengeInfo;
