import React, { useEffect } from 'react';
import classes from './progressBar.module.scss';

interface ProgressBarProps {
  total: number;
  completed: number;
  height?: number;
  label: string;
  headerStyles?: React.CSSProperties;
  handleCompleted: () => void;
}

const ProgressBar = ({
  total,
  completed,
  height,
  label,
  headerStyles,
  handleCompleted
}: ProgressBarProps) => {
  const percent = Math.round((completed / total) * 100) || 0;

  const fullBar = {
    height: height || '2rem'
  };

  const completeBar = {
    width: `${percent}%`,
    fontSize: height ? (height * 70) / 100 : '1rem'
  };

  useEffect(() => {
    if (total !== 0 && total === completed) {
      setTimeout(() => {
        handleCompleted();
      }, 200);
    }
  }, [completed, handleCompleted, total]);

  return (
    <div className={classes['container']}>
      <div className={classes['bar-header']} style={headerStyles}>
        <label>{label}</label>
        <div className={classes['detail']}>{`${completed} / ${total}`}</div>
      </div>
      <div className={classes['full_bar']} style={fullBar}>
        <div className={classes['complete_bar']} style={completeBar}>
          <span className={classes['percentage']}>{`${percent}%`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
