import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Radio, Spin, Popover, Space } from 'antd';
import moment from 'moment';
import BarChartLeaderboard from './TeamChallenges/BarChartLeaderboard';
import StandardLeaderboard from './TeamChallenges/StandardLeaderboard';
import socketHandler from '../../../services/Socket';
import httpHandler from '../../../helpers/httpHandler';
import UserContext from '../../../contexts/UserContext';
import Prize from './shared/Prize';
import {
  ArrowLeftOutlined,
  InfoCircleOutlined,
  TrophyOutlined
} from '@ant-design/icons';
import ChallengeInfo from './cards/ChallengeInfo';
import { useNavigate } from 'react-router-dom';

const StatLeaderBoard = ({ dataStyle, challengeId, challenge }) => {
  const { user_bundle } = useContext(UserContext);
  const navigate = useNavigate();

  const [tiers, setTiers] = useState();
  const [selectedTier, setSelectedTier] = useState(0);
  const [allTierData, setAllTierData] = useState();

  const [userStats, setUserStats] = useState([]);
  const [currentDataStyle, setCurrentDataStyle] = useState(
    dataStyle ? dataStyle : 'barChart'
  );
  const [fieldOrCategory, setFieldOrCategory] = useState('');
  const [, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setIsMobile(window.innerWidth <= 768);
    if (window.innerWidth <= 768) setCurrentDataStyle('standard');
  };

  const [prizes, setPrizes] = useState([]);

  useEffect(() => {
    const getChallengePrizes = async () => {
      try {
        const data = await httpHandler.get(
          `/api/st1/publicChallengePrizes/challenge/${challenge[0].id}`
        );
        setPrizes(data);
      } catch (err) {
        console.error('Error: ', err);
      }
    };
    getChallengePrizes();
  }, [challenge]);

  useEffect(() => {
    if (window.innerWidth <= 768) setCurrentDataStyle('standard');
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const subscribeToChallenge = () => {
      const roomName = challengeId
        ? `challenge_${challengeId}`
        : 'seven_listings_challenge';
      socketHandler.getSocket().on('connect', () => {
        socketHandler.getSocket().emit('subscribe', roomName);
      });
      socketHandler.getSocket().emit('subscribe', roomName);

      socketHandler.getSocket().on('statUpdate', ({ stat }) => {
        const updatedData = userStats.map((user) => {
          user.total = user.id === stat.user_id ? stat.value : user.total;
          return user;
        });
        setUserStats(updatedData.sort((a, b) => (a.total > b.total ? -1 : 1)));
      });
    };

    const getUsersStats = async () => {
      if (challenge[0].grouping === 'individual') {
        httpHandler
          .get('/api/st1/teamStats/leaderBoardForFieldOrCategoryId', {
            dataType: challenge[0].data_type,
            field_category_id: challenge[0].data_id,
            start_time: moment(challenge[0].start_date).format('YYYY-MM-DD'),
            end_time: moment(challenge[0].end_date).format('YYYY-MM-DD')
          })
          .then((users_stats) => {
            setFieldOrCategory(users_stats[0].name);
            setUserStats(users_stats);
          })
          .catch((error) => {
            console.error('error getting leaderboard', error);
          });
      } else if (challenge[0].grouping === 'allTeams') {
        httpHandler
          .get('/api/st1/teamStats/allTeamsLeaderBoardForFieldOrCategoryId', {
            data_type: challenge[0].data_type,
            challengeId: challenge[0].id,
            start_time: moment(challenge[0].start_date).format('YYYY-MM-DD'),
            end_time: moment(challenge[0].end_date).format('YYYY-MM-DD')
          })
          .then((users_stats) => {
            setFieldOrCategory(users_stats[0].name);
            setUserStats(users_stats);
          })
          .catch((error) => {
            console.error('error getting leaderboard', error);
          });
      }
    };

    getUsersStats();
    subscribeToChallenge();
  }, [challenge, challengeId, userStats]);

  useEffect(() => {
    const getAllTeamInfusionsoftTiers = async () => {
      const tierMap = new Map();

      const tierList = await httpHandler.get(
        '/api/st1/publicChallenges/getUsersByTier'
      );
      for (let i = 0; i < tierList.length; i++) {
        for (let j = 0; j < tierList[i].length; j++) {
          tierMap.set(tierList[i][j].id, i + 1);
        }
      }
      setTiers(tierMap);
    };

    getAllTeamInfusionsoftTiers();
  }, []);

  useEffect(() => {
    const getTeamTierData = () => {
      const tier1Data = userStats.filter(
        (stat) => tiers.get(stat.team_leader_id) === 1
      );
      const tier2Data = userStats.filter(
        (stat) => tiers.get(stat.team_leader_id) === 2
      );
      const tier3Data = userStats.filter(
        (stat) => tiers.get(stat.team_leader_id) === 3
      );
      const tier4Data = userStats.filter(
        (stat) => tiers.get(stat.team_leader_id) === 4
      );
      const tier5Data = userStats.filter(
        (stat) => tiers.get(stat.team_leader_id) === 5
      );
      const tier6Data = userStats.filter(
        (stat) => tiers.get(stat.team_leader_id) === 6
      );
      const tier7Data = userStats.filter(
        (stat) => tiers.get(stat.team_leader_id) === 7
      );

      setAllTierData({
        0: userStats,
        1: tier1Data,
        2: tier2Data,
        3: tier3Data,
        4: tier4Data,
        5: tier5Data,
        6: tier6Data,
        7: tier7Data
      });
    };

    const getIndividualTierData = () => {
      const tier1Data = userStats.filter((stat) => tiers.get(stat.id) === 1);
      const tier2Data = userStats.filter((stat) => tiers.get(stat.id) === 2);
      const tier3Data = userStats.filter((stat) => tiers.get(stat.id) === 3);
      const tier4Data = userStats.filter((stat) => tiers.get(stat.id) === 4);
      const tier5Data = userStats.filter((stat) => tiers.get(stat.id) === 5);
      const tier6Data = userStats.filter((stat) => tiers.get(stat.id) === 6);
      const tier7Data = userStats.filter((stat) => tiers.get(stat.id) === 7);

      const userTier = tiers.get(user_bundle.user.id);
      if (userTier) setSelectedTier(userTier);

      setAllTierData({
        0: userStats,
        1: tier1Data,
        2: tier2Data,
        3: tier3Data,
        4: tier4Data,
        5: tier5Data,
        6: tier6Data,
        7: tier7Data
      });
    };

    tiers &&
      userStats.length > 0 &&
      challenge[0].grouping === 'allTeams' &&
      getTeamTierData();
    tiers &&
      userStats.length > 0 &&
      challenge[0].grouping === 'individual' &&
      getIndividualTierData();
  }, [challenge, tiers, userStats, user_bundle.user.id]);

  const rankTextFromIndex = (index, users_stats) => {
    // Find tiebreaker
    let i = 0;
    let tiedIndex;
    while (!tiedIndex || i < index - 1) {
      tiedIndex = null;
      if (users_stats[i].total === users_stats[index].total) {
        tiedIndex = i;
        break;
      }
      i++;
    }
    if (index === 0) {
      return (
        <div
          style={{
            fontSize: '30px',
            backgroundColor: 'rgb(23, 55, 87)',
            borderRadius: '50%',
            width: '50px',
            color: 'white',
            margin: 'auto'
          }}
        >
          {index + 1}
        </div>
      );
    }
    if (index === 1) {
      return (
        <div style={{ fontSize: '25px' }}>{tiedIndex + 1 || index + 1}</div>
      );
    }
    if (index === 2) {
      return (
        <div style={{ fontSize: '20px' }}>{tiedIndex + 1 || index + 1}</div>
      );
    }
    return <div style={{ fontSize: '16px' }}>{tiedIndex + 1 || index + 1}</div>;
  };

  const handleTierSelect = (e) => {
    setSelectedTier(e.target.value);
    // switch (e.target.value) {
    //   case 0:

    //     break;

    //   default:
    //     break;
    // }
  };

  return (
    <div>
      <div
        style={{
          margin: 'auto',
          width: '100%',
          backgroundColor: 'white',
          fontSize: '16px',
          textAlign: 'center',
          minHeight: '100vh'
        }}
      >
        <Row
          justify='start'
          align='middle'
          style={{
            backgroundColor: 'rgb(23, 55, 87)',
            color: 'white',
            padding: '20px'
          }}
        >
          <Space size='large'>
            <Popover
              placement='bottomLeft'
              content={<h4>Return to challenges page</h4>}
            >
              <ArrowLeftOutlined
                style={{
                  color: '#1890ff',
                  background: 'black',
                  borderRadius: '50%',
                  border: '2px solid #ffff',
                  padding: '7px',
                  fontSize: '24px'
                }}
                onClick={() => navigate('/challenges')}
              />
            </Popover>
            <Popover
              placement='bottomLeft'
              content={
                <div style={{ minWidth: '300px', maxWidth: '400px' }}>
                  {prizes &&
                    prizes.length > 0 &&
                    prizes.map((prize) => <Prize prize={prize} />)}
                  {prizes.length === 0 && (
                    <h3>No prizes set for this challenge</h3>
                  )}
                </div>
              }
              title='Prizes'
            >
              <TrophyOutlined
                style={{
                  color: '#faad14',
                  background: 'black',
                  borderRadius: '50%',
                  border: '2px solid #ffff',
                  padding: '7px',
                  fontSize: '24px'
                }}
              />
            </Popover>
            <Popover content={<ChallengeInfo challenge={challenge[0]} />}>
              <InfoCircleOutlined
                style={{
                  color: '#1890ff',
                  background: 'black',
                  borderRadius: '50%',
                  border: '2px solid #ffff',
                  padding: '7px',
                  fontSize: '24px'
                }}
              />
            </Popover>
            <span
              style={{ color: 'white', fontFamily: 'Rufina', fontSize: '20px' }}
            >
              {challenge[0].name}
            </span>
          </Space>
        </Row>
        {currentDataStyle === 'standard' && (
          <Row
            style={{
              backgroundColor: 'rgb(245, 245, 245)',
              padding: '10px',
              position: 'sticky',
              top: 0,
              zIndex: 1
            }}
          >
            <Col span={4}>Rank</Col>
            <Col span={16}>
              <div style={{ textAlign: 'left' }}>Agent</div>
            </Col>
            <Col span={4}>{fieldOrCategory}</Col>
          </Row>
        )}
        <Row justify='center' align='middle'>
          <Button
            onClick={() =>
              setCurrentDataStyle(
                currentDataStyle === 'standard' ? 'barChart' : 'standard'
              )
            }
            type='primary'
            style={{ height: '80%', marginTop: '20px' }}
            disabled={isMobile}
          >
            Switch To{' '}
            {currentDataStyle === 'standard'
              ? 'Bar Chart Leaderboard'
              : 'Text Leaderboard'}
          </Button>
        </Row>
        {allTierData && (
          <Row justify='center' align='middle' style={{ marginTop: '20px' }}>
            <label style={{ marginRight: '10px' }}>
              <strong>View By Tier: </strong>
            </label>
            <Radio.Group value={selectedTier} onChange={handleTierSelect}>
              <Radio.Button value={0}>All Tiers</Radio.Button>
              <Radio.Button disabled={allTierData[1].length === 0} value={1}>
                Tier 1
              </Radio.Button>
              <Radio.Button disabled={allTierData[2].length === 0} value={2}>
                Tier 2
              </Radio.Button>
              <Radio.Button disabled={allTierData[3].length === 0} value={3}>
                Tier 3
              </Radio.Button>
              <Radio.Button disabled={allTierData[4].length === 0} value={4}>
                Tier 4
              </Radio.Button>
              <Radio.Button disabled={allTierData[5].length === 0} value={5}>
                Tier 5
              </Radio.Button>
              <Radio.Button disabled={allTierData[6].length === 0} value={6}>
                Tier 6
              </Radio.Button>
              <Radio.Button disabled={allTierData[7].length === 0} value={7}>
                Tier 7
              </Radio.Button>
            </Radio.Group>
          </Row>
        )}
        {!allTierData && <Spin style={{ marginTop: '100px' }} size='large' />}
        {currentDataStyle === 'standard' && userStats && allTierData && (
          <StandardLeaderboard
            grouping={challenge[0].grouping}
            fieldOrCategory={fieldOrCategory}
            users_stats={allTierData[selectedTier]}
            rankTextFromIndex={rankTextFromIndex}
          />
        )}
        {currentDataStyle === 'barChart' && userStats && allTierData && (
          <BarChartLeaderboard
            grouping={challenge[0].grouping}
            fieldOrCategory={fieldOrCategory}
            users_stats={allTierData[selectedTier]}
          />
        )}
      </div>
    </div>
  );
};

export default StatLeaderBoard;
