import { IntegrationSoftware } from '../../components/statTracker2/settings/integrations/Integrations';
import { getRequest } from '../../helpers/httpHandler2';

const getAllIntegrations = async (): Promise<IntegrationSoftware[]> => {
  const integrations: IntegrationSoftware[] = await getRequest(
    '/api/teamIntegrations/providers'
  );
  return integrations;
};

export { getAllIntegrations };
