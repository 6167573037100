import React from 'react';
import { Row, Form, Button, Card, message } from 'antd';
import FormTextField from './formElements/FormTextField';
import FormContactElement from './formElements/FormContactElement';
import FormSelectElement from './formElements/FormSelectElement';
import FormDateElement from './formElements/FormDateElement';
import FormAmountElement from './formElements/FormAmountElement';
import FormTimeElement from './formElements/FormTimeElement';
import FormCheckboxElement from './formElements/FormCheckboxElement';
import FormLeadSourceElement from './formElements/FormLeadSourceElement';
import FormBulkTextElement from './formElements/FormBulkTextElement';
import httpHandler from '../../../helpers/httpHandler';
import UserContext from '../../../contexts/UserContext';

class FormViewer extends React.Component {
  static contextType = UserContext;
  formRef = React.createRef();
  constructor() {
    super();
    this.state = {
      selectionValues: [],
      teamCommissionAmount: null
    };
  }
  componentDidMount() {
    if (this.props.selectedTeam) {
      this.getFormFields();
    } else if (this.props.chosenFields) {
      this.setState({ chosenFields: this.props.chosenFields });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.selectedTeam !== prevProps.selectedTeam ||
      this.props.selectedForm !== prevProps.selectedForm
    ) {
      this.getFormFields();
    }
  }
  getTransactionSettings = () => {
    httpHandler
      .get('/api/st1/financials/getDetailedTransactionSettings', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((transactionSettings) => {
        let curSetting, userSetting;
        for (let setting of transactionSettings) {
          if (setting.pre_defined) {
            curSetting = setting;
          }
          if (setting.user_id === this.context.user_bundle.user.id) {
            userSetting = setting;
          }
        }
        this.setState(
          {
            transactionSettings: transactionSettings,
            defaultSetting: curSetting,
            userSetting: userSetting
          },
          () => {}
        );
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getFormFields = () => {
    httpHandler
      .get('/api/st1/financials/getFormFieldSelections', {
        teamId: this.props.selectedTeam.team_id,
        formId: this.props.selectedForm.id
      })
      .then((selections) => {
        this.setState({ chosenFields: selections }, () => {
          this.getTransactionSettings();
        });
      })
      .catch((error) => {})
      .finally(() => {});
  };

  getFormElements = () => {
    if (this.state.chosenFields && this.state.chosenFields.length > 0) {
      return this.state.chosenFields.map((field) => {
        if (field.type === 'text') {
          return (
            <FormTextField
              key={field.id}
              field={field}
              // submitting={this.state.submitting}
            />
          );
        } else if (field.type === 'select' || field.type === 'closing type') {
          return (
            <FormSelectElement
              key={field.id}
              field={field}
              formElement={this.formRef}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
              getClosingAmount={this.getClosingAmount}
              // getTransactionAmount
              // submitting={this.state.submitting}
              // getSelectionValues={this.getSelectionValues}
            />
          );
        } else if (field.type === 'date') {
          return (
            <FormDateElement
              key={field.id}
              field={field}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
            />
          );
        } else if (field.type === 'amount') {
          return (
            <FormAmountElement
              key={field.id}
              field={field}
              // formElement={this.state.formElement}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
              getCommissionAmount={this.getCommissionAmount}
              // closingAmount={this.state.closingAmount}
              // resetClosingAmount={() => this.setState({closingAmount: null})}
              transactionSettings={this.state.transactionSettings}
              getTransactionAmount={this.getTransactionAmount}
              // transactionAmount={this.state.transactionAmount}
              // autoUpdateCommission={this.state.autoUpdateCommission}
              // preventAutoUpdateCommission={this.state.preventAutoUpdateCommission}
              // autoUpdatedGCI={this.autoUpdatedGCI}
              // erasedUpdatedCommission={() => this.setState({updatedCommission: null})}

              // autoUpdateAgentPaid={this.state.autoUpdateAgentPaid}
              // teamCommissionAmount={this.state.teamCommissionAmount}
              // preventAutoUpdateGrossAgentPaid={() => this.setState({autoUpdateAgentPaid: false})}
              // erasedUpdatedGrossAgentPaid={() => this.setState({updatedAgentPaidCommission: null})}
              // autoUpdatedAgentPaidCommission={this.autoUpdatedAgentPaidCommission}
              // getHourlyRate={this.getHourlyRate}
              // updatedHourlyRate={this.state.updatedHourlyRate}
              // erasedUpdatedHourlyRate={() => this.setState({updatedHourlyRate: null})}
            />
          );
        } else if (field.type === 'time') {
          return (
            <FormTimeElement
              key={field.id}
              field={field}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
            />
          );
        } else if (field.type === 'yes/no') {
          return (
            <FormCheckboxElement
              key={field.id}
              field={field}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
            />
          );
        } else if (field.type === 'lead source') {
          return (
            <FormLeadSourceElement
              key={field.id}
              field={field}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
            />
          );
        } else if (field.type === 'bulk text') {
          return (
            <FormBulkTextElement
              key={field.id}
              field={field}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
            />
          );
        } else {
          return <></>;
        }
      });
    }
  };
  getContactElemet = () => {
    if (this.state.chosenFields && this.state.chosenFields.length > 0) {
      return this.state.chosenFields.map((field, index) => {
        if (field.type === 'contact') {
          return (
            <FormContactElement
              key={field.id}
              field={field}
              selectedTeam={this.props.selectedTeam}
              selectedForm={this.props.selectedForm}
              getSelectedContact={this.getSelectedContact}
              // chosenContact={this.state.chosenContact}
              // submitting={this.state.submitting}
            />
          );
        } else {
          return <></>;
        }
      });
    }
  };
  getSubmitButton = () => {
    if (true) {
      return (
        <Row style={{ marginTop: '20px' }}>
          <div style={{ margin: '0 auto' }}>
            <Form.Item>
              <Button
                onClick={this.handleSubmitForm}
                htmlType='submit'
                size='large'
                loading={this.state.submittingForm}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Row>
      );
    }
  };
  getClosingAmount = (amount) => {
    this.formRef.current.setFieldsValue({
      amount_14: amount
    });
    this.setGCI(null, amount, true);
  };

  getTransactionAmount = (key, value) => {
    this.formRef.current.setFieldsValue({
      [key]: (Math.round(value * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
    });
    this.setGCI(null, value, true);
  };
  setGCI = (key, amount, autoUpdate) => {
    if (this.state.defaultSetting) {
      let curKey = key ? key : 'amount_13';
      let curAmount = autoUpdate
        ? (
            Math.round(
              amount * (this.state.defaultSetting.gci / 100) * Math.pow(10, 2)
            ) / Math.pow(10, 2)
          ).toFixed(2)
        : amount;
      this.formRef.current.setFieldsValue({
        [curKey]: curAmount
      });
      this.setGAPI(null, curAmount, true);
    }
  };
  setGAPI = (key, amount, autoUpdate) => {
    if (this.state.defaultSetting) {
      let curSetting = this.state.userSetting
        ? this.state.userSetting
        : this.state.defaultSetting;
      let curKey = key ? key : 'amount_18';
      // let curAmount = autoUpdate ? Number.parseFloat(amount * (curSetting.gapi / 100)).toPrecision(3) : amount;
      let curAmount = autoUpdate
        ? (
            Math.round(amount * (curSetting.gapi / 100) * Math.pow(10, 2)) /
            Math.pow(10, 2)
          ).toFixed(2)
        : amount;
      this.formRef.current.setFieldsValue({
        [curKey]: curAmount
      });
    }
  };
  getSelectedContact = (contact) => {
    if (contact && contact.infusionsoft_id) {
      contact.crm_id = contact.infusionsoft_id;
      contact.crm_type_id = this.props.selectedTeam.integration_id;
    }
    this.setState({ chosenContact: contact });
  };
  autoUpdatedGCI = (gci) => {
    this.setState({ updatedCommission: gci });
  };
  getCommissionAmount = (key, value) => {
    this.setGCI(key, value, false);
    this.setState({
      teamCommissionAmount: parseFloat(value),
      autoUpdateAgentPaid: true
    });
  };
  autoUpdatedAgentPaidCommission = (gapi) => {
    this.setState({ updatedAgentPaidCommission: gapi });
  };
  getHourlyRate = (hourlyRate) => {
    this.setState({ updatedHourlyRate: hourlyRate });
  };
  handleSubmit = (values) => {
    this.setState({ submittingForm: true });
    let amount = {};
    for (let [key, value] of Object.entries(values)) {
      if (key.split('_')[0] === 'amount') {
        amount[key] = value;
      }
    }
    values = { ...values, amount };
    let contact = { ...this.state.chosenContact };
    httpHandler
      .post('/api/st1/financials/createFormSubmission', {
        date: this.props.selectedDate,
        teamId: this.props.selectedTeam.team_id,
        formId: this.props.selectedForm.id,
        info: values,
        teamLeaderId: this.props.selectedTeam.team_leader_id,
        contactId: this.state.chosenContact
          ? this.state.chosenContact.id
          : null,
        fieldId: this.props.operationField.field_id,
        crm_type_id: this.state.chosenContact
          ? this.state.chosenContact.crm_type_id
          : null,
        crm_id: this.state.chosenContact
          ? this.state.chosenContact.crm_id
          : null,
        selectedContact: this.state.chosenContact
      })
      .then((response) => {
        this.setState({ chosenContact: null, updatedCommission: null }, () =>
          this.props.formCompleted(contact, values, response.insertId)
        );
      })
      .catch((error) => {
        message.error('Form submission unsuccessful. Please try again.');
      })
      .finally(() => {
        this.setState({ submittingForm: false });
      });
  };
  render() {
    return (
      <div
        style={{
          width: '97%',
          margin: '15px auto',
          textTransform: 'uppercase'
        }}
      >
        <Form onFinish={this.handleSubmit} ref={this.formRef}>
          {this.getContactElemet()}
          <Card>{this.getFormElements()}</Card>
          {this.props.isBuilder ? null : this.getSubmitButton()}
        </Form>
      </div>
    );
  }
}

export default FormViewer;
