import { NavBarSlice, SetFn } from '../../../../types/zustandTypes';
import { UtilityMode } from './NavBar';

export const createNavBarSlice = (set: SetFn): NavBarSlice => ({
  utilityMode: UtilityMode.NONE,
  setUtilityMode: async (mode: UtilityMode) => {
    set({
      utilityMode: mode
    });
  }
});
