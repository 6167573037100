import httpHandler from '../../helpers/httpHandler';
import { getRequest, patchRequest } from '../../helpers/httpHandler2';
import moment from 'moment';
import { Field } from '../../types/statTracker';
import { putRequest } from '../../helpers/httpHandler2';

const getStatistics = async (
  teamId: number,
  startDate?: Date,
  endDate?: Date,
  userId?: number
) => {
  try {
    const reqBody: { [key: string]: string | number } = {};

    if (startDate && endDate) {
      // coerce start and end date to start and end of UTC day
      const utcStart = new Date(
        moment(startDate).startOf('day').toString()
      ).toISOString();

      const utcEnd = new Date(
        moment(endDate).endOf('day').toString()
      ).toISOString();

      reqBody.startDate = utcStart;
      reqBody.endDate = utcEnd;

      if (userId) reqBody.userId = userId;
    }

    reqBody.teamId = teamId;

    const response: Field[] = await getRequest(`/api/stat-fields`, reqBody);

    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getTrackedStatsInStage = async (
  teamId: number,
  pipelineStageId: number
) => {
  try {
    const stats = await httpHandler.get(
      `/api/teams/${teamId}/compass/pipeline-stages/${pipelineStageId}/stat-fields`
    );
    return stats;
  } catch (error) {
    console.error(error);
  }
};

const addNewTrackedStat = async (newStat: Field) => {
  try {
    const { teamId, index } = newStat;

    const reqBody = {
      teamId,
      name: newStat.name,
      index: index
    };

    const response = await httpHandler.post(`/api/stat-fields`, reqBody);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const deleteStatField = async (statId: number) => {
  try {
    await httpHandler.delete(`/api/stat-fields/${statId}`);
  } catch (error) {
    console.error(error);
  }
};

const toggleTrackedStat = async (
  checked: number,
  teamId: number,
  statFieldId: number
) => {
  try {
    const response = await httpHandler.patch(
      `/api/stat-fields/${statFieldId}/track`,
      {
        checked,
        teamId
      }
    );
    return response;
  } catch (error) {
    console.error('Error toggling tracked stat', error);
  }
};

const editTrackedStat = async (
  statFieldId: number,
  statName: string,
  commitment?: number
) => {
  try {
    const response = await httpHandler.patch(
      `/api/stat-fields/${statFieldId}`,
      {
        name: statName,
        commitment
      }
    );

    return response;
  } catch (error) {
    console.error('Error', error);
  }
};
const updateStatFieldValue = async (
  statFieldId: number,
  value: number,
  teamId: number,
  date: Date,
  selectedUserId?: number
) => {
  try {
    // selected user comes from scope
    const updatedStat = await putRequest(
      `/api/stat-fields/${statFieldId}/values`,
      {
        adjustValue: value,
        teamId,
        dateTime: date,
        isOtherUser: selectedUserId
      }
    );

    return updatedStat;
  } catch (error) {
    console.error(error);
  }
};

const updateIndex = async (stats: Field[], teamId: number) => {
  try {
    const update = await patchRequest('/api/stat-fields/updateIndex', {
      stats: stats,
      teamId: teamId
    });
    return update;
  } catch (error) {
    console.error('error update stat field index ', error);
  }
};

export const statEditorApi = {
  getStatistics,
  getTrackedStatsInStage,
  addNewTrackedStat,
  deleteStatField,
  toggleTrackedStat,
  editTrackedStat,
  updateStatFieldValue,
  updateIndex
};
