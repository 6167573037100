import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import MemberViewer from '../../statTracker1/team/memberViewer/MemberViewer';
import StatInputViewer from '../../statTracker1/statInputViewer/StatInputViewer';
import StatLeaderBoard from '../../statTracker1/challenges/ChallengeLeaderBoard';
import TeamTotalColumnChart from '../../statTracker1/graphViewer/chartList/teamTotalColumnChart/TeamTotalColumnChart';
import { BoardsProvider } from '../../../contexts/BoardsContext';
const TeamMemberSelection = lazy(
  () =>
    import('../../statTracker1/team/teamMemberSelection/TeamMemberSelection')
);
const ChallengesUserView = lazy(
  () => import('../../statTracker1/challenges/ChallengesUserView')
);
const PublicChallenge = lazy(
  () => import('../../statTracker1/challenges/PublicChallenge')
);
const StatDashboard = lazy(
  () => import('../../statTracker1/bigScreenView/statDashboard')
);
const TeamChallenge = lazy(
  () => import('../../statTracker1/challenges/TeamChallenges/TeamChallenge')
);
const TeamMemberPage = lazy(
  () => import('../../statTracker1/team/teamMemberPage/TeamMemberPage')
);

const ST1Routes = () => {
  return (
    <BoardsProvider>
      <Routes>
        <Route path='/teamTotals' element={<TeamTotalColumnChart />} />
        <Route path='/myTeam/:id' element={<MemberViewer />} />
        <Route path='/input-stat' element={<StatInputViewer />} />
        <Route path='/statDashboard' element={<StatDashboard />} />
        <Route
          path='/StatLeaderBoard'
          element={
            <StatLeaderBoard
              dataStyle={undefined}
              challengeId={undefined}
              challenge={undefined}
            />
          }
        />

        <Route path='/teamManagement' element={<TeamMemberSelection />} />
        <Route path='/challenges' element={<ChallengesUserView />} />
        <Route path='/publicChallenges/:id' element={<PublicChallenge />} />
        <Route path='/teamChallenges/:id' element={<TeamChallenge />} />
        <Route path='/myTeam' element={<TeamMemberPage />} />
        <Route
          path='/brokerage'
          element={<TeamMemberSelection isBrokerage={true} />}
        />
      </Routes>
    </BoardsProvider>
  );
};

export default ST1Routes;
