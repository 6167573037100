import { sqlReturn } from '../../types/statTracker';
import { GetFn, SetFn, StatEditorSlice } from '../../types/zustandTypes';
import { statEditorApi } from '../apis/statEditorApi';

// loading state is initialized with true so there is no flicker
export const createStatEditorSlice = (
  set: SetFn,
  get: GetFn
): StatEditorSlice => ({
  statCategories: [],
  trackedStats: [],
  loadingTrackedStats: true,
  loadingCommitments: true,
  statEditorApi: {
    addTrackedStat: async (stat) => {
      try {
        const response = await statEditorApi.addNewTrackedStat(stat);
        stat.id = response.insertId;
        stat.checked = 1;
        const trackedStats = get().trackedStats;
        const updatedTrackedStats = [...trackedStats];
        updatedTrackedStats.push(stat);
        set({
          trackedStats: updatedTrackedStats
        });
      } catch (error) {
        console.error('Could not add a new tracked stat', error);
      }
    },
    fetchTrackedStats: async (teamId, startDate, endDate, userId) => {
      try {
        if (startDate && endDate) {
          set({ loadingCommitments: true, trackedStats: [] });
        }
        const teamTrackedStats = await statEditorApi.getStatistics(
          teamId,
          startDate,
          endDate,
          userId
        );

        if (!teamTrackedStats) {
          return;
        }
        if (startDate && endDate) {
          set({ loadingCommitments: false });
        }

        const hasIndex0 = teamTrackedStats.find((stat) => stat.index === 0);
        if (hasIndex0) {
          const stats = teamTrackedStats.map((stat, i) => {
            stat.index = i + 1;
            return stat;
          });

          await statEditorApi.updateIndex(stats, teamId);
          set({ trackedStats: stats });
        } else {
          set({ trackedStats: teamTrackedStats });
        }

        set({ loadingTrackedStats: false });
      } catch (error) {
        console.error('Could not fetch tracked stats', error);
        set({ loadingTrackedStats: false });
      }
    },
    toggleTrackedStat: async (toggledStat, teamId) => {
      try {
        const trackedStats = get().trackedStats;
        const idx = trackedStats.findIndex(
          (stat) => stat.id === toggledStat.id
        );
        const toggle = trackedStats[idx].checked === 1 ? 0 : 1;
        await statEditorApi.toggleTrackedStat(toggle, teamId, toggledStat.id);
        set((state) => {
          state.trackedStats[idx].checked =
            trackedStats[idx].checked === 1 ? 0 : 1;
        });
      } catch (error) {
        console.error('Could not toggle stat', error);
      }
    },
    removeTrackedStat: async (statId, teamId) => {
      try {
        await statEditorApi.deleteStatField(statId);
        const trackedStats = get().trackedStats;
        const index = trackedStats.findIndex((stat) => stat.id === statId);
        trackedStats.splice(index, 1);
        const stats = trackedStats.map((stat, i) => {
          stat.index = i + 1;
          return stat;
        });
        await statEditorApi.updateIndex(stats, teamId);
        set({ trackedStats: stats });
      } catch (error) {
        console.error('Could not remove tracked stat', error);
      }
    },
    editTrackedStat: async (statId, statName, commitment) => {
      try {
        const response: sqlReturn | undefined =
          await statEditorApi.editTrackedStat(statId, statName, commitment);
        if (response) {
          const stats = get().trackedStats;
          const idx = stats.findIndex((st) => st.id === statId);
          set((state) => {
            state.trackedStats[idx].name = statName;
            state.trackedStats[idx].teamCommitment = commitment;
          });
        }

        return response;
      } catch (error) {
        console.error('Error editing tracked stat', error);
      }
    },
    changeTrackedStatIndex: async (fromIndex, toIndex, teamId) => {
      let trackedStats = [...get().trackedStats];
      const [removed] = trackedStats.splice(fromIndex, 1);
      trackedStats.splice(toIndex, 0, removed);
      const stats = trackedStats.map((stat, i) => {
        const copyStat = { ...stat };
        copyStat.index = i + 1;
        return copyStat;
      });

      set({ trackedStats: stats });
      await statEditorApi.updateIndex(stats, teamId);
    },
    incrementStat: (statFieldId, count) => {
      // move to create statEditorSlice
      set((state) => {
        const foundIdx = state.trackedStats.findIndex(
          (stat) => stat.id === statFieldId
        );

        const foundStat = state.trackedStats[foundIdx];

        if (!foundStat.value) {
          // initialize value if null
          foundStat.value = count;
        } else {
          foundStat.value += count;
        }
      });
    },
    decrementStat: (statFieldId, count) => {
      set((state) => {
        const foundIdx = state.trackedStats.findIndex(
          (stat) => stat.id === statFieldId
        );
        const foundStat = state.trackedStats[foundIdx];
        if (foundStat.value - 1 < 0) {
          foundStat.value = 0;
        } else {
          foundStat.value -= count;
        }
      });
    },
    editStats: async (statFieldId, teamId, value, date, isOtherUser) => {
      try {
        const updatedStat = await statEditorApi.updateStatFieldValue(
          statFieldId,
          value,
          teamId,
          date,
          isOtherUser
        );
        return updatedStat;
      } catch (error) {
        console.error(error);
      }
    },
    handleStatChange: (statFieldId, value, isSocket) => {
      set((state) => {
        const foundIdx = state.trackedStats.findIndex(
          (stat) => stat.id === statFieldId
        );

        const foundStat = state.trackedStats[foundIdx];
        if (isSocket) {
          foundStat.value += value;
          return;
        }

        foundStat.value = value;
      });
    },
    handledEditTrackStat: (statFieldId, isUsedByStage) => {
      set((state) => {
        const statIndex = state.trackedStats.findIndex(
          (stat) => stat.id === statFieldId
        );

        const statField = state.trackedStats[statIndex];
        statField.usedByPipelineStage = isUsedByStage ? 1 : 0;
      });
    }
  }
});
