import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

const seriesColor = {
  Cold_call_appointments_set_19: '#091a33',
  Cold_call_appointments_set_19_yesterday: 'rgba(26, 52, 91, 0.69)',
  Added_to_facebook_group_30: 'rgba(44, 255, 5, 1)',
  Added_to_facebook_group_30_yesterday: 'rgba(44, 255, 5, 0.4)',
  Category_Appointment_2: '#1a345c',
  Category_Appointment_2_yesterday: 'rgba(103, 148, 220, 0.6)',
  Category_Closing_10: '#8ea339',
  Category_Closing_10_yesterday: 'rgba(142, 163, 57, 0.4)',
  Number_closed_20: '#3d22b5',
  Number_closed_20_yesterday: 'rgba(61, 34, 181, 0.3)',
  Follow_up_call_appointments_set_16: '#d60000',
  Follow_up_call_appointments_set_16_yesterday: 'rgba(214, 0, 0, 0.3)',
  Category_Call_3: '#b06809',
  Category_Call_3_yesterday: 'rgba(176, 104, 9, 0.4)',
  Category_Hours_doing_calls_6: '#91088f',
  Category_Hours_doing_calls_6_yesterday: 'rgba(145, 8, 143, 0.4)',
  Category_Contact_4: '#0091c2',
  Category_Contact_4_yesterday: 'rgba(0, 145, 194, 0.4)',
  SS_Completed_74: '#133c52',
  SS_Completed_74_yesterday: 'rgba(19, 60, 83, 0.65)',
  SS_No_Show_75: '#d4a60d',
  SS_No_Show_75_yesterday: 'rgba(211, 165, 13, 0.49)',
  Cancelled_in_30_Days_76: '#d40d24',
  Cancelled_in_30_Days_76_yesterday: 'rgba(211, 13, 37, 0.53)',
  Google_Reviews_77: '#d63857',
  Google_Reviews_77_yesterday: 'rgba(214, 56, 88, 0.51)'
};

class TeamHybridBarChart extends React.Component {
  constructor() {
    super();
    this.state = {
      showOptions: [
        // "number_of_appointments_set",
        // "added_to_facebook_group",
        // "number_closed",
        // "number_of_contacts",
        // "number_of_contracts_written",
        // "number_of_follow_up_calls",
        // "number_of_hours_doing_calls",
        // "number_of_new_lead_calls",
        // "commission_dollars_received"
      ],
      circleRadiusPercentage: Math.min(
        window.innerWidth / 1920,
        window.innerHeight / 937
      )
    };
  }
  componentDidMount() {
    if (this.chart) {
      this.chart.dispose();
    }

    this.setState(
      {
        circleRadiusPercentage: Math.min(
          this.props.graphRef.current.offsetWidth / 1920,
          this.props.graphRef.current.offsetHeight / 937
        )
      },
      () => {
        this.initChart();
      }
    );

    if (
      this.props.dailyTrackerStats &&
      this.props.dailyTrackerStats.length > 0
    ) {
      // console.log(this.props.dailyTrackerStats);
      this.initChart();
    }
    //window.onresize is only one throughout the app, so for it to have the different functions for setting state,
    //we add them in to the window.onresize instead of simply declaring it.
    let previousResizeFunc =
      window.onresize !== null ? window.onresize : () => {};

    window.onresize = () => {
      previousResizeFunc();
      this.setState(
        {
          circleRadiusPercentage: Math.min(
            this.props.graphRef.current.offsetWidth / 1920,
            this.props.graphRef.current.offsetHeight / 937
          )
        },
        () => {
          this.initChart();
        }
      );
    };
  }

  componentWillUnmount = () => {
    window.onresize = null;
  };

  modifyByLength = (value, isFont) => {
    let results = value;

    if (this.props.dailyTrackerStats.length > 11) {
      results = value * (11 / this.props.dailyTrackerStats.length);

      if (isFont) results = results * 1.5;
    }

    return results;
  };

  initChart = () => {
    let chart = am4core.create(
      this.props.statDisplayId
        ? this.props.graph_name + '_' + this.props.statDisplayId
        : this.props.graph_name,
      am4charts.XYChart
    );
    this.chart = chart;
    var title = chart.titles.create();

    title.textAlign = 'middle';
    title.marginBottom = '2%';

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    // console.log("stat, data", this.props.dailyTrackerStats);
    chart.paddingRight = 40;
    chart.data = this.props.dailyTrackerStats;
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.labels.template.fill = am4core.color('#091a33');
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;

    chart.legend.itemContainers.template.cursorOverStyle =
      am4core.MouseCursorStyle.default;
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'first_name';

    categoryAxis.renderer.grid.template.opacity = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color('#091a33');
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.dx = -13;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minWidth = 120;
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 1.3;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0.9;
    valueAxis.renderer.grid.template.stroke = am4core.color('#091a33');
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.rangeChangeEasing = am4core.ease.linear;
    valueAxis.rangeChangeDuration = 1000;

    valueAxis.renderer.labels.template.fill = am4core.color('#091a33');
    if (this.props.statDisplayId) {
      title.text = `[bold font-size: 11 #091a33]Member Stats`;
      categoryAxis.renderer.labels.template.fontSize =
        20 * Math.cbrt(this.state.circleRadiusPercentage);
      valueAxis.renderer.labels.template.fontSize =
        20 * Math.cbrt(this.state.circleRadiusPercentage);
      var markerTemplate = chart.legend.markers.template;
      markerTemplate.width =
        15 - this.props.dailyTrackerStats.length * 1.2 >= 10
          ? 15 - this.props.dailyTrackerStats.length * 1.2
          : 10;
      markerTemplate.height =
        15 - this.props.dailyTrackerStats.length * 1.2 >= 10
          ? 15 - this.props.dailyTrackerStats.length * 1.2
          : 10;
      chart.legend.fontSize =
        10 *
        (this.props.graph_name.includes('team-management')
          ? 1
          : Math.cbrt(this.state.circleRadiusPercentage));
      chart.legend.maxWidth = 300;
      chart.legend.maxHeight = 40;
    } else {
      title.text = `[bold font-size: 20 #091a33]Member Stats`;
      categoryAxis.renderer.labels.template.fontSize =
        18 *
        (this.props.graph_name.includes('team-management')
          ? 1
          : Math.cbrt(this.state.circleRadiusPercentage));
      valueAxis.renderer.labels.template.fontSize =
        20 *
        (this.props.graph_name.includes('team-management')
          ? 1
          : Math.cbrt(this.state.circleRadiusPercentage));
      chart.legend.maxHeight = 100;
    }

    chart.legend.scrollable = true;
    categoryAxis.renderer.labels.template.adapter.add(
      'textOutput',
      function (text) {
        if (text) {
          return text.replace(/ \(.*/, '');
        }
      }
    );
    var range = valueAxis.axisRanges.create();
    range.value = parseInt(this.props.statGoal) - 0.01;
    range.endValue = parseInt(this.props.statGoal) + 0.01;
    range.axisFill.fill = am4core.color('red');
    range.axisFill.fillOpacity = 0.5;
    range.grid.strokeOpacity = 0;

    this.handleChange();
    let field = this.props.selectedSortField;
    let sortSeries = this.createSeries(
      field,
      field ? field.split('_').join(' ') + ' sort' : '',
      false
    );
    if (this.props.squadView.length === 0) {
      categoryAxis.sortBySeries = sortSeries;
    } else {
      this.toggleSquadView();
    }

    sortSeries.columns.template.maxHeight = 0;
    sortSeries.hiddenInLegend = true;
  };

  processSquad = () => {
    // console.log(this.props.initData)
    let data = this.props.dailyTrackerStats;
    // console.log(data);
    let track = {};
    for (let member of data) {
      if (!track[member.squad_id]) {
        this.addSquadRange(member);
        track[member.squad_id] = 1;
      }
    }
  };

  addSquadRange = (member) => {
    let categoryAxis = this.chart.yAxes._values[0];
    var squadRange = categoryAxis.axisRanges.create();
    squadRange.category = member.first_name;
    squadRange.label.dy = 12;
    squadRange.label.text = !member.squad_id ? 'no squad' : member.squad_name;
    squadRange.label.fill = am4core.color('#049edb');
    squadRange.label.fontWeight = 'bold';
    squadRange.label.fontSize =
      11 *
      (this.props.graph_name.includes('team-management')
        ? 1
        : Math.cbrt(this.state.circleRadiusPercentage));
    squadRange.label.dx = -60;
    squadRange.label.location = 0.5;
    squadRange.grid.stroke = am4core.color('#05070a');
    squadRange.grid.strokeOpacity = 1;
    squadRange.tick.length = 200;
    squadRange.tick.disabled = false;
    squadRange.tick.strokeOpacity = 0.6;
    squadRange.tick.stroke = am4core.color('#05070a');
    squadRange.tick.location = 0.5;
    squadRange.locations.category = -1;
  };

  createSeries = (field, name, stacked) => {
    var series = this.chart.series.push(new am4charts.ColumnSeries());
    let isYesterday =
      name.split(' ')[name.split(' ').length - 1] === 'yesterday';
    series.dataFields.valueX = field;
    series.dataFields.categoryY = 'first_name';
    series.stacked = stacked;
    series.columns.template.events.on(
      'hit',
      function (ev) {
        this.props.getClickedOnSeries(
          ev.target._virtualParent.dataFields,
          ev.target.dataItem._dataContext
        );
      },
      this
    );
    var hoverState = series.columns.template.states.create('hover');
    hoverState.properties.fill = am4core.color('#c0ecfc');
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    if (isYesterday) {
      let label = name.split(' ');
      label.splice(label.length - 2, 1);
      label = label.join(' ');
      series.legendSettings.labelText = `${label}`;
    } else {
      let label = name.split(' ');
      label.splice(label.length - 1);
      label = label.join(' ');
      series.legendSettings.labelText = `${label}`;
    }

    if (seriesColor[field]) {
      series.columns.template.stroke = am4core.color(seriesColor[field]);
      series.columns.template.fill = am4core.color(seriesColor[field]);
    }
    series.name = name;

    if (name.split(' ')[name.split(' ').length - 1] !== 'sort') {
      var labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.locationX = 0.5;
      labelBullet.label.text = '{valueX}';
      labelBullet.label.fill = am4core.color('#fff');
      labelBullet.label.fontSize =
        15 *
        (this.props.graph_name.includes('team-management')
          ? 1
          : Math.cbrt(this.state.circleRadiusPercentage));
      labelBullet.label.fontSize = this.modifyByLength(
        labelBullet.label.fontSize,
        true
      );
      labelBullet.label.fontWeight = 'bold';
      labelBullet.label.adapter.add('textOutput', function (text, target) {
        if (target.dataItem && target.dataItem.valueX === 0) {
          return '';
        }
        return text;
      });
    }

    return series;
  };
  componentDidUpdate(prevProps) {
    if (this.props.dailyTrackerStats !== prevProps.dailyTrackerStats) {
      if (!this.props.dataForBarChart || !this.chart) {
        if (this.chart) {
          this.chart.dispose();
        }
        this.initChart();
      } else {
        this.updateGraph(this.props.dataForBarChart);
      }
    }
    if (
      this.chart &&
      this.props.stackedChartFields !== prevProps.stackedChartFields
    ) {
      this.handleChange();
    }
    if (this.chart && this.props.showYesterday !== prevProps.showYesterday) {
      this.toggleYesterday();
    }
    if (
      this.chart &&
      this.props.selectedSortField !== prevProps.selectedSortField
    ) {
      this.chart.dispose();
      this.initChart();
    }
    if (this.chart && this.props.statGoal !== prevProps.statGoal) {
      this.updateGoalLine();
    }
    if (this.chart && this.props.squadView !== prevProps.squadView) {
      this.chart.dispose();
      this.initChart();
    }
  }

  toggleSquadView = () => {
    let categoryAxis = this.chart.yAxes._values[0];
    if (this.props.squadView.length > 0) {
      categoryAxis.renderer.grid.template.opacity = 0.5;
    } else {
      categoryAxis.renderer.grid.template.opacity = 0;
    }

    this.processSquad();
  };

  updateGoalLine = () => {
    let valueAxis = this.chart.xAxes._values[0];
    valueAxis.axisRanges._values[0].value =
      parseInt(this.props.statGoal) - 0.01;
    valueAxis.axisRanges._values[0].endValue =
      parseInt(this.props.statGoal) + 0.01;
  };

  updateGraph = (data) => {
    let { user_id, field, value, date, category, difference } = data;
    let curStats = this.chart.data;

    let shouldUpdate = false;
    for (let i = 0; i < curStats.length; i++) {
      let user = curStats[i];
      if (user.user_id === user_id) {
        if (date === user.date_today) {
          user[field] = value;
          user[category] -= difference;
          shouldUpdate = true;
        } else if (date === user.date_yesterday) {
          user[field + '_yesterday'] = value;
          user[category + '_yesterday'] -= difference;
          shouldUpdate = true;
        }
      }
    }
    if (shouldUpdate) {
      this.chart.invalidateRawData();
    }
    this.props.barChartUpdated();
  };

  toggleYesterday = () => {
    let chartSeries = this.chart.series.values;
    if (this.props.showYesterday.length === 0) {
      for (let i = 0; i < chartSeries.length; i++) {
        let series = chartSeries[i];
        if (
          series.name.split(' ')[series.name.split(' ').length - 1] ===
          'yesterday'
        ) {
          series.hidden = true;
          series.hide();
          series.hiddenInLegend = true;
        }
      }
    } else {
      for (let i = 0; i < chartSeries.length; i++) {
        let series = chartSeries[i];
        if (
          series.name.split(' ')[series.name.split(' ').length - 1] ===
          'yesterday'
        ) {
          series.show();
          series.hidden = false;
          series.hiddenInLegend = false;
        }
      }
    }
  };

  addPortrait = (series) => {
    var columnTemplate = series.columns.template;
    var bullet = columnTemplate.createChild(am4charts.CircleBullet);

    if (this.props.graph_name.includes('team-management')) {
      if (this.props.statDisplayId && this.props.processedDatesTotalData) {
        bullet.circle.radius =
          20 - this.props.processedDatesTotalData.length * 1.2 >= 9
            ? 20 - this.props.processedDatesTotalData.length * 1.2
            : 9;
        bullet.dx = 20 - this.props.processedDatesTotalData.length * 1.2;
      } else {
        bullet.circle.radius = 18;
      }
    } else {
      bullet.dx = 16 * Math.cbrt(this.state.circleRadiusPercentage);
      bullet.circle.radius = 30 * Math.cbrt(this.state.circleRadiusPercentage);
      bullet.circle.radius = this.modifyByLength(bullet.circle.radius);
    }

    // bullet.circle.radius = 40;
    bullet.valign = 'middle';
    bullet.align = 'right';
    bullet.isMeasured = true;
    bullet.interactionsEnabled = false;
    bullet.horizontalCenter = 'right';
    bullet.fill = am4core.color('#36597a');
    bullet.stroke = am4core.color('#36597a');
    series.zIndex = 100;
    series.mainContainer.mask = undefined;
    var outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add('radius', function (radius, target) {
      var circleBullet = target.parent;
      return circleBullet.circle.pixelRadius + 2;
    });
    var image = bullet.createChild(am4core.Image);
    //var dataItem = series.tooltipDataItem;
    if (this.props.graph_name.includes('team-management')) {
      image.width = 36;
      image.height = 36;
    } else {
      image.width = 60 * Math.cbrt(this.state.circleRadiusPercentage);
      image.height = 60 * Math.cbrt(this.state.circleRadiusPercentage);
    }
    image.width = this.modifyByLength(image.width);
    image.height = this.modifyByLength(image.height);
    image.horizontalCenter = 'middle';
    image.verticalCenter = 'middle';
    image.propertyFields.href = 'profile_pic_url';
    image.adapter.add('mask', function (mask, target) {
      var circleBullet = target.parent;
      return circleBullet.circle;
    });
  };

  handleChange = () => {
    let options = this.props.stackedChartFields;
    let chartSeries = this.chart.series.values;
    let yesterdayOptions = [];
    let yesterdaySeriesCheck =
      chartSeries.filter(
        (series) =>
          series.dataFields.valueX.split('_')[
            series.dataFields.valueX.split('_').length - 1
          ] === 'yesterday'
      ).length === 0;
    if (options.length * 2 + 1 >= chartSeries.length) {
      if (chartSeries.length === 0) {
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          yesterdayOptions.push(option + '_yesterday');
          let check = chartSeries.filter(
            (series) => series.dataFields.valueX === option
          );
          if (check.length === 0) {
            this.createSeries(
              option,
              option.split('_').join(' ').charAt(0).toUpperCase() +
                option.split('_').join(' ').slice(1),
              true
            );
          }
        }
        let portriatSeries = this.createSeries(
          'for_portrait',
          'for portrait',
          true
        );
        portriatSeries.hiddenInLegend = true;
        this.addPortrait(portriatSeries);
        for (let i = 0; i < options.length; i++) {
          let yesterdayOption = options[i] + '_yesterday';
          let yesterdayCheck = chartSeries.filter(
            (series) => series.dataFields.valueX === yesterdayOption
          );
          if (yesterdayCheck.length === 0) {
            if (yesterdaySeriesCheck) {
              this.createSeries(
                yesterdayOption,
                yesterdayOption.split('_').join(' ').charAt(0).toUpperCase() +
                  yesterdayOption.split('_').join(' ').slice(1),
                false
              );
              yesterdaySeriesCheck = false;
            } else {
              this.createSeries(
                yesterdayOption,
                yesterdayOption.split('_').join(' ').charAt(0).toUpperCase() +
                  yesterdayOption.split('_').join(' ').slice(1),
                true
              );
            }
          }
        }
        this.toggleYesterday();
      } else {
        this.chart.dispose();
        this.initChart();
      }
    } else if (options.length * 2 + 1 < chartSeries.length) {
      let removeOptions = [];
      for (let i = 0; i < chartSeries.length; i++) {
        let series = chartSeries[i];
        let seriesName = series.name.split(' ').join('_');
        if (
          seriesName.split('_')[seriesName.split('_').length - 1] !== 'sort' &&
          seriesName.split('_')[seriesName.split('_').length - 1] !== 'portrait'
        ) {
          let check = options.filter((option) => option === seriesName);
          if (check.length === 0) {
            if (
              seriesName.split('_')[seriesName.split('_').length - 1] !==
              'yesterday'
            ) {
              removeOptions.push(series.name);
              this.chart.series.removeIndex(i).dispose();
            }
          }
        }
      }
      chartSeries = this.chart.series.values;
      if (chartSeries.length > 0) {
        for (let i = chartSeries.length - 1; i >= 0; i--) {
          let series = chartSeries[i];
          if (
            series.name.split(' ')[series.name.split(' ').length - 1] !==
            'yesterday'
          ) {
            break;
          }
        }
      }
      for (let i = 0; i < removeOptions.length; i++) {
        for (let j = 0; j < chartSeries.length; j++) {
          let series = chartSeries[j];
          if (removeOptions[i] + ' yesterday' === series.name) {
            this.chart.series.removeIndex(j).dispose();
            break;
          }
        }
      }
      chartSeries = this.chart.series.values;
      let foundToday = chartSeries.filter(
        (series) =>
          series.name.split(' ')[series.name.split(' ').length - 1] !==
          'yesterday'
      );
      if (foundToday.length > 0) {
        for (let i = 0; i < chartSeries.length; i++) {
          let series = chartSeries[i];
          if (
            series.name.split(' ')[series.name.split(' ').length - 1] ===
            'yesterday'
          ) {
            series.stacked = false;
            break;
          }
        }
      }
    }
  };

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {this.props.loadingDailyTracker ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className='lds-spinner'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        <div
          id={
            this.props.statDisplayId
              ? this.props.graph_name + '_' + this.props.statDisplayId
              : this.props.graph_name
          }
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    );
  }
}

export default TeamHybridBarChart;
