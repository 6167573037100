import React from 'react';
import { Row, Col, Card, Input, Tooltip, Form, Select } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import InfusionsoftContactSelection from './InfusionsoftContactSelection';
import httpHandler from '../../../../helpers/httpHandler';

const { Option } = Select;
const requiredRule = [
  {
    required: true,
    message: 'Required field'
  }
];
const requiredEmail = [
  {
    type: 'email',
    message: 'Please enter a valid email'
  },
  {
    required: true,
    message: 'Required field'
  }
];
class FormContactElement extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.getContactFieldSelections();
  }
  componentDidUpdate(prevProps) {
    if (this.props.selectedForm !== prevProps.selectedForm) {
      this.getContactFieldSelections();
    }
  }
  getContactFieldSelections = () => {
    httpHandler
      .get('/api/st1/financials/getContactFieldSelectionsForForm', {
        teamId: this.props.selectedTeam.team_id,
        formId: this.props.selectedForm.id
      })
      .then((selections) => {
        let fields = {};
        for (let selection of selections) {
          fields[`contact_${selection.id}`] = null;
        }
        this.setState(
          {
            selections: selections.sort(
              (a, b) => b.field_name.length - a.field_name.length
            ),
            ...fields
          },
          () => this.getContactInfoOfTeam()
        );
      })
      .catch((error) => {})
      .finally(() => {});
  };

  getContactInfoOfTeam = () => {
    httpHandler
      .get('/api/st1/financials/getContactInfoForTeam', {
        teamId: this.props.selectedTeam.team_id
      })
      .then((contacts) => {
        this.setState({ availableContacts: contacts });
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getContactOptions = () => {
    if (
      this.state.availableContacts &&
      this.state.availableContacts.length > 0
    ) {
      let contacts = [];
      contacts.push(
        <Option key={-1} value={-1}>
          --Select One--
        </Option>
      );
      for (let contact of this.state.availableContacts) {
        contacts.push(
          <Option
            key={contact.id}
            value={contact.id}
          >{`${contact.first_name} ${contact.last_name}`}</Option>
        );
      }
      return contacts;
    }
  };
  handleSelectContact = (value) => {
    let selectedContact = this.state.availableContacts.filter(
      (contact) => contact.id === value
    )[0];
    this.setState({ selectedContact: selectedContact }, () => {
      this.props.getSelectedContact(selectedContact);
      this.populateFields();
    });
  };
  populateFields = () => {
    let selections = [...this.state.selections];

    for (let selection of selections) {
      if (this.state.selectedContact) {
        for (let [key, value] of Object.entries(this.state.selectedContact)) {
          if (selection.field_name.toLowerCase() === key.split('_').join(' ')) {
            this.setState({ [`contact_${selection.id}`]: value });
          }
        }
      } else {
        this.setState({ [`contact_${selection.id}`]: null });
      }
    }
  };
  handleChangeInput = (selection, e) => {
    this.setState({ [`contact_${selection.id}`]: e.target.value });
  };
  getContactInputFields = (selection) => {
    if (this.state.selectedContact) {
      return (
        <div style={{ marginBottom: '24px' }}>
          <Row>
            <Input
              onChange={(e) => this.handleChangeInput(selection, e)}
              value={this.state[`contact_${selection.id}`]}
              disabled
            ></Input>
          </Row>
        </div>

        // <Form.Item
        //   name={['contact', `contact_${selection.id}`]}
        //   rules={[{required: false}]}
        // >
        //   <Input onChange={(e) => this.handleChangeInput(selection, e)} placeholder={this.state[`contact_${selection.id}`]} disabled></Input>
        // </Form.Item>
      );
    } else {
      return (
        <Form.Item
          name={['contact', `contact_${selection.id}`]}
          rules={selection.type === 'email' ? requiredEmail : requiredRule}
        >
          <Input onChange={(e) => this.handleChangeInput(selection, e)}></Input>
        </Form.Item>
      );
    }
  };
  getContactFormElements = () => {
    if (this.state.selections && this.state.selections.length > 0) {
      return this.state.selections.map((selection, index) => {
        return (
          <Col
            span={24}
            key={selection.id}
            style={{ marginBottom: '5px', width: '100%' }}
          >
            <span style={{ marginRight: '5px' }}>{selection.field_name}</span>
            <span>
              <Tooltip
                title={selection.tooltip ? selection.tooltip : 'placeholder'}
              >
                <InfoCircleFilled style={{ color: '#0789ed' }} />
              </Tooltip>
            </span>
            {this.getContactInputFields(selection)}
          </Col>
        );
      });
    }
  };
  getSelectedInfusionSoftContact = (contact) => {
    this.setState({ selectedContact: contact }, () => {
      this.props.getSelectedContact(contact);
      this.populateFields();
    });
  };
  getIntegrationContactSelections = () => {
    if (this.props.selectedTeam) {
      if (
        this.props.selectedTeam.integration_id === 1 ||
        this.props.selectedTeam.integration_name === 'infusionsoft'
      ) {
        return (
          <InfusionsoftContactSelection
            selectedForm={this.props.selectedForm}
            selectedTeam={this.props.selectedTeam}
            getSelectedInfusionSoftContact={this.getSelectedInfusionSoftContact}
          />
        );
      } else {
        return (
          <Select
            showSearch
            placeholder={'Select a client contact'}
            value={
              this.state.selectedContact ? this.state.selectedContact.id : -1
            }
            style={{ width: '100%' }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={this.handleSelectContact}
          >
            {this.getContactOptions()}
          </Select>
        );
      }
    }
  };
  render() {
    return (
      <div>
        <Card>
          <div style={{ marginBottom: '10px' }}>
            <span style={{ fontSize: '20px' }}>Contact</span>
          </div>
          <Row style={{ marginBottom: '30px' }}>
            <span>Available Contacts</span>
            {this.getIntegrationContactSelections()}
          </Row>
          <Row>{this.getContactFormElements()}</Row>
        </Card>
      </div>
    );
  }
}

export default FormContactElement;
