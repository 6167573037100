import moment from 'moment-timezone';

interface DateHelperType {
  ToUtcDateTime: (
    selectedDate: Date,
    time: moment.MomentSetObject,
    format: string
  ) => string;
}

const DateHelper: DateHelperType = {
  ToUtcDateTime: (
    selectedDate: Date,
    time: moment.MomentSetObject,
    format: string
  ) => {
    return moment
      .utc(
        new Date(
          moment.utc(selectedDate).set(time).format(format)
        ).toISOString()
      )
      .format(format);
  }
};

export default DateHelper;
