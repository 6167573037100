import React, { Component } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';

import { RiVideoChatFill } from 'react-icons/ri';
import { Tooltip } from 'antd';
import UserContext from '../contexts/UserContext';
import { isMobile } from 'react-device-detect';

class HelpChat extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showHelp: true,
      showHelpPopupOptions: false
    };
  }
  componentDidMount() {
    // (function (d, m) {
    //   var kommunicateSettings = {
    //     appId: "381349438aa80090a1716fdbd0d95f047",
    //     popupWidget: true,
    //     automaticChatOpenOnNavigation: true,
    //   };
    //   var s = document.createElement("script");
    //   s.type = "text/javascript";
    //   s.async = true;
    //   s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
    //   var h = document.getElementsByTagName("head")[0];
    //   h.appendChild(s);
    //   window.kommunicate = m;
    //   m._globals = kommunicateSettings;
    // })(document, window.kommunicate || {});
    let current_url = new URL(window.location.href);
    if (current_url.searchParams.get('source') === 'rap-success') {
      this.setState({ rap: true });
    }
  }
  handleClose = () => {
    this.setState({ showHelp: false });
  };

  openHelp = (type) => {
    window.open(
      'https://clubwealth.zoom.us/j/99195738734?pwd=MktmQWczZFN2YTBZVWJEM21DU3FmZz09'
    );
  };
  render() {
    if (!this.state.showHelp) return <div></div>;
    const helpButton = (
      <div
        style={{
          backgroundColor: 'var(--primary)',
          borderRadius: '50%',
          height: '60px',
          width: '60px',
          position: 'fixed',
          bottom: isMobile ? 110 : 50,
          right: 25,
          boxShadow: '0 5px 5px 0 #5b5e61, 0 6px 10px 0 rgba(0, 0, 0, 0.19)',
          zIndex: 3
        }}
      >
        <CloseCircleFilled
          onClick={this.handleClose}
          style={{
            position: 'absolute',
            right: 5,
            zIndex: 10,
            bottom: 55,
            fontSize: '20px'
          }}
        />
        <a
          href='#0'
          onClick={(e) => {
            e.preventDefault();
            this.openHelp('chat');
          }}
          target='_blank'
        >
          <Tooltip
            title='Click to speak with Club Wealth help desk over Zoom.'
            placement='rightTop'
          >
            <div>
              <RiVideoChatFill
                style={{
                  color: 'white',
                  position: 'absolute',
                  bottom: 10,
                  right: 10,
                  fontSize: '40px'
                }}
              />
            </div>
          </Tooltip>
        </a>
      </div>
    );

    return <div>{helpButton}</div>;
  }
}
export default HelpChat;
