import React from 'react';
import { Row, Result, Button } from 'antd';
import SectionViewer from './SectionViewer';
import BackgroundVideo from '../backgroundVideo/BackgroundVideo';
import httpHandler from '../../../helpers/httpHandler';
import ModalViewer from '../elements/ModalViewer';
import Permissions from '../../../helpers/permissions';
import './pageViewer.css';
import { withRouter } from '../../reusableComponents/withRouter/WithRouter';

class PageViewer extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      pageType: '',
      page: {
        pageSections: [],
        styles: {},
        details: {
          backgroundVideo: false,
          header: -1
        }
      },
      pageNotFound: false,
      ruleAccess: {}
    };
  }
  componentDidMount = async () => {
    await this.loadPage();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      await this.loadPage();
    }
  };

  evaluateElementRulesets = async (page) => {
    let rulesetsToEvaluate = new Set();
    for (let pageSection of page.page.pageSections) {
      for (let sectionRow of pageSection.sectionRows) {
        for (let rowColumn of sectionRow.rowColumns) {
          for (let element of rowColumn.elements) {
            if (
              element.details &&
              element.details.rule_set &&
              element.details.rule_set !== -1
            )
              rulesetsToEvaluate.add(element.details.rule_set);
            if (element.type === 'Carousel') {
              for (let item of element.details.items) {
                if (item.rule_set && item.rule_set !== -1)
                  rulesetsToEvaluate.add(item.rule_set);
              }
            }
            if (element.type === 'Conferences') {
              let occurrences;
              if (
                element.details.showSpecificConferences &&
                element.details.specificConferencesList.length > 0
              ) {
                //get conference occurrences by category and ids
                occurrences = await httpHandler.get(
                  '/api/conference/conferenceOccurrencesByCategoryAndIds',
                  {
                    category: element.details.category,
                    ids: element.details.specificConferencesList.join(),
                    time_frame: element.details.timeFrame,
                    from_date: element.details.fromDate,
                    to_date: element.details.toDate
                  }
                );
              } else {
                //get conference occurrences occurrences by category
                occurrences = await httpHandler.get(
                  '/api/conference/conferenceOccurrencesByCategory',
                  {
                    category: element.details.category,
                    time_frame: element.details.timeFrame,
                    from_date: element.details.fromDate,
                    to_date: element.details.toDate
                  }
                );
              }
              for (let occurrence of occurrences) {
                if (occurrence.status === 'recording_available') {
                  rulesetsToEvaluate.add(occurrence.recording_rule_set);
                } else {
                  rulesetsToEvaluate.add(occurrence.rule_set);
                }
              }
            }
            if (element.type === 'NavBar') {
              element.details.navItems.forEach((item) => {
                if (item.rule_set !== -1) {
                  rulesetsToEvaluate.add(item.rule_set);
                }
                if (item.dropDownItems.length) {
                  item.dropDownItems.forEach((i) => {
                    rulesetsToEvaluate.add(i.rule_set);
                  });
                }
              });
            }
          }
        }
      }
    }

    if (rulesetsToEvaluate.size === 0) return;
    let ruleSetAccessArray = await Permissions.getPermissionsForRuleSetIds(
      Array.from(rulesetsToEvaluate)
    );
    let ruleAccess = {};
    for (let accessItem of ruleSetAccessArray) {
      ruleAccess[accessItem.rule_set_id] = accessItem.permission;
    }
    this.setState({ ruleAccess: ruleAccess });
  };

  loadPage = async () => {
    return await httpHandler
      .get('/api/pageBuilder/page', {
        url: window.location.pathname,
        id: this.props.page_id,
        defaultHeader: this.props.page_id === -2 ? 1 : null
      })
      .then((page) => {
        if (page) {
          this.setState(
            {
              page: page.page,
              pageType: page.type,
              pageNotFound: false
            },
            () => {}
          );
          this.evaluateElementRulesets(page);
        } else {
          this.setState({ pageNotFound: true });
        }
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handlePopup = (popupId) => {
    httpHandler
      .get('/api/pageBuilder/pages', { type: 'popup' })
      .then((popups) => {
        let popup = popups.filter((pop) => pop.id === popupId);
        if (popup.length > 0) {
          this.setState({ selectedPopup: popup[0] }, this.showModal);
        }
      })
      .catch((error) => {})
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  showModal = () => {
    this.setState((prevState) => ({
      visible: true
    }));
  };

  closeModal = () => {
    this.setState((prevState) => ({
      visible: false
    }));
  };

  render() {
    let topLevelStyles = {};
    if (this.state.pageType === 'standard') topLevelStyles.minHeight = '100vh';
    if (this.state.pageNotFound) {
      return (
        <div>
          <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={
              <Button onClick={() => window.history.back()} type='primary'>
                Go Back
              </Button>
            }
          />
        </div>
      );
    }
    return (
      <div style={topLevelStyles}>
        {/* <HelpPanel close={this.props.close} expand={this.props.expand}/> */}
        <Row type='flex' justify='center'>
          <div style={this.state.page?.styles}>
            {this.state.page.details.backgroundVideo ? (
              <BackgroundVideo
                sourceType={this.state.page.details.sourceType}
                source={this.state.page.details.source}
              />
            ) : (
              ''
            )}
            {this.state.page.pageSections.map((pageSection, index) => {
              return (
                <SectionViewer
                  key={index}
                  pageHistory={this.props.history}
                  pageSection={pageSection}
                  handlePopup={this.handlePopup}
                  ruleAccess={this.state.ruleAccess}
                />
              );
            })}
          </div>
        </Row>
        <ModalViewer
          visible={this.state.visible}
          closeModal={this.closeModal}
          pageId={this.state.selectedPopup ? this.state.selectedPopup.id : null}
          curPopup={this.state.selectedPopup}
        />
      </div>
    );
  }
}

export default withRouter(PageViewer);
