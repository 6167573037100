import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { DropDownItem, NavItem } from '../../NavBar';
import classes from './DesktopSubItems.module.scss';
import UserContext from '../../../../../../contexts/UserContext';
import { Permission } from '../DesktopNavItems';

type Props = {
  item: NavItem;
  handleNavigate: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    url: string,
    urlType: string
  ) => void;
  ruleAccess: { [id: number]: boolean };
  editMode: boolean;
};

const DesktopSubItems = ({
  item,
  handleNavigate,
  ruleAccess,
  editMode
}: Props) => {
  const { user_bundle } = useContext(UserContext);
  const isAdmin = () => {
    const permissions: Permission[] = Object.values(user_bundle.permissions);
    const administrator = permissions.find(
      (permission) => (permission.name = 'Administrator')
    );
    return administrator ? true : false;
  };

  const hasPermission = (item: DropDownItem) => {
    if (isAdmin()) return true;
    if (editMode) return true;
    if (!ruleAccess) return false;
    if (!item.rule_set) return true;
    if (item.rule_set === -1) return true;
    return ruleAccess[item.rule_set];
  };

  return (
    <motion.ul
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ type: 'tween' }}
      className={classes['item-list']}
    >
      {item.dropDownItems.map((subItem, index) => {
        return (
          hasPermission(subItem) && (
            <li
              key={index}
              onClick={(e) => handleNavigate(e, subItem.url, subItem.url_type)}
            >
              <a href='#0' onClick={(e) => e.preventDefault()}>
                {subItem.text}
              </a>
            </li>
          )
        );
      })}
    </motion.ul>
  );
};

export default DesktopSubItems;
