const CoursesHelper = {
  reconstructSkeleton: (skeleton_list) => {
    let skeletonObject = {};
    for (let i = 0; i < skeleton_list.length; i++) {
      if (!(skeleton_list[i].section_id in skeletonObject)) {
        skeletonObject[skeleton_list[i].section_id] = {
          id: skeleton_list[i].section_id,
          section_name: skeleton_list[i].section_name,
          complete: skeleton_list[i].section_complete,
          complete_time: skeleton_list[i].complete_time,
          section_index: skeleton_list[i].section_index,
          has_quiz: skeleton_list[i].has_quiz,
          quiz_result_record: skeleton_list[i].quiz_result_record,
          modules: []
        };
      }
      if (skeleton_list[i].module_id) {
        skeletonObject[skeleton_list[i].section_id].modules.push({
          id: skeleton_list[i].module_id,
          section_id: skeleton_list[i].section_id,
          module_name: skeleton_list[i].module_name,
          module_index: skeleton_list[i].module_index,
          clone_of_module_id: skeleton_list[i].clone_of_module_id,
          complete: skeleton_list[i].complete,
          time: skeleton_list[i].module_completed_time,
          video: skeleton_list[i].module_video,
          video_complete: skeleton_list[i].video_complete
        });
      }
    }
    let courseSkeleton = Object.values(skeletonObject);
    courseSkeleton = courseSkeleton.map((section) => {
      section.modules.sort((a, b) =>
        a.module_index > b.module_index ? 1 : -1
      );
      return section;
    });
    courseSkeleton = courseSkeleton.sort((a, b) =>
      a.section_index > b.section_index ? 1 : -1
    );

    return courseSkeleton;
  },
  getProgress: (section) => {
    let complete = 0;
    for (var i = 0; i < section.modules.length; i++) {
      if (section.modules[i].complete) complete++;
    }
    if (
      section.has_quiz &&
      section.quiz_result_record &&
      section.quiz_result_record.passed
    )
      complete++;
    let total_requirement = section.modules.length;
    if (section.has_quiz) total_requirement++;
    return isNaN((complete / total_requirement) * 100)
      ? 100
      : ((complete / total_requirement) * 100).toFixed(0);
  },
  getOverallProgress: (sections, test) => {
    let total = 0;
    let complete = 0;
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].has_quiz) {
        total++;
      }
      if (
        sections[i].has_quiz &&
        sections[i].quiz_result_record &&
        sections[i].quiz_result_record.passed
      )
        complete++;
      for (let j = 0; j < sections[i].modules.length; j++) {
        total++;
        if (sections[i].modules[j].complete) complete++;
      }
    }
    if (test && test.passed) complete++;
    total++;

    return ((complete / total) * 100).toFixed(0);
  }
};

export default CoursesHelper;
