import httpHandler from "../../helpers/httpHandler";
import { LeadSource, sqlReturn } from "../../types/statTracker";

const getLeadSources = async (teamId: number) => {
  const response: Promise<LeadSource[]> = await httpHandler.get(
    `/api/lead-sources/`,
    { teamId }
  );
  return response;
};

const postLeadSource = async (teamId: number, name: string) => {
  const response: Promise<sqlReturn> = await httpHandler.post(
    `/api/lead-sources/`,
    {
      name,
      teamId,
    }
  );
  return response;
};

const patchLeadSource = async (id: number, name: string) => {
  const response = await httpHandler.patch(`/api/lead-sources/${id}`, { name });
  return response;
};

const archiveLeadSource = async (id: number) => {
  const response = await httpHandler.delete(`/api/lead-sources/${id}`);
  return response;
};

export const leadSourcesRoutes = {
  getLeadSources,
  postLeadSource,
  patchLeadSource,
  archiveLeadSource,
};
