import React, { useContext, useState, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import ShoppingCartContext from '../../../contexts/ShoppingCartContext';
import httpHandler from '../../../helpers/httpHandler';

const Text = lazy(() => import('../elements/Text'));
const CustomButton = lazy(() => import('../elements/Button'));
const Image = lazy(() => import('../elements/Image'));
const PDF = lazy(() => import('../elements/PDF'));
const DividerElement = lazy(() => import('../elements/divider/Divider'));
const Empty = lazy(() => import('../elements/empty/Empty'));
const Conferences = lazy(() => import('../elements/conferences/Conferences'));
const Courses = lazy(() => import('../elements/Courses'));
const Card = lazy(() => import('../elements/Card'));
const TabsPages = lazy(() => import('../elements/tabsPages/TabsPages'));
const NavBar = lazy(() => import('../elements/navBar/NavBar'));
const Carousel = lazy(() => import('../elements/carousel/Carousel'));
const AddToCartModal = lazy(() => import('../../commerce/AddToCartModal'));
const CourseCatalog = lazy(() => import('../elements/CourseCatalog'));
const TeamChart = lazy(() => import('../elements/teamChart/TeamChart'));
const Video = lazy(() => import('../elements/Video'));

type Props = {
  element: any;
  handlePopup: (popupId: number) => void;
  ruleAccess: any;
};

const ElementViewer = ({ element, handlePopup, ruleAccess }: Props) => {
  const shoppingCartContext = useContext(ShoppingCartContext);
  const [addingProductToCart, setAddingProductToCart] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const handleClick = async (item: any, hasPermission: boolean) => {
    if (!hasPermission) {
      if (item.redirect_on_no_permission) {
        handleNoPermissionRedirect(item);
      }
    } else if (
      item.clickAction === 'googleLogin' ||
      item.clickAction === 'createGoogleAccount'
    ) {
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=865629118314-1f3ftm3j7bncakfqt6stf9lm72tk7446.apps.googleusercontent.com&redirect_uri=${window.location.origin}&response_type=code&scope=email profile&state=google`;
    } else if (
      item.clickAction === 'microsoftLogin' ||
      item.clickAction === 'createMicrosoftAccount'
    ) {
      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=2699bb9c-64ce-4f45-a2f3-a87b1e520c51&response_type=code&redirect_uri=${encodeURIComponent(
        window.location.origin
      )}&response_mode=query&scope=openid%20email%20offline_access%20profile%20https%3A%2F%2Fgraph.microsoft.com%2FUser.Read&state=microsoft`;
    } else if (
      item.clickAction === 'facebookLogin' ||
      item.clickAction === 'createAccount'
    ) {
      console.log(
        'handling click',
        `https://www.facebook.com/v12.0/dialog/oauth?client_id=${'787179298713207'}&state=facebook&scope=email&redirect_uri=${
          window.location.origin
        }/`
      );
      //old id 2412946138970427
      window.location.href = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${'787179298713207'}&state=facebook&scope=email&redirect_uri=${
        window.location.origin
      }`;
    } else if (item.clickAction === 'redirect') {
      if (item.applyTag) {
        try {
          httpHandler.post('/api/users/applyTag', {
            tagId: item.applyTag.id
          });
        } catch (error) {
          console.error(error);
        }
      }
      handleRedirect(item);
    } else if (item.clickAction === 'addToShoppingCart') {
      addProduct();
      setAddingProductToCart(true);
    } else if (item.clickAction === 'display_pop_up') {
      handlePopup(item.popupId);
    }
  };
  const addProduct = () => {
    httpHandler
      .post(`/api/commerce/products/getProductByInfusionsoftId`, {
        id: element.details.product.product.infusionsoft_id
      })
      .then((product) => {
        shoppingCartContext.addToCart(product[0]);
      })
      .catch((error) => console.error('Error found: ', error));
  };
  const handleRedirect = (item: any) => {
    if (item.redirectType === 'local') {
      navigate(`/${item.url}`);
    } else if (item.redirectType === 'external') {
      window.open('https://' + item.url, '_blank');
    }
  };
  const handleNoPermissionRedirect = (item: any) => {
    //apply no permission tag
    if (item.applyNoPermissionTag) {
      httpHandler.post('/api/users/applyTag', {
        tagId: item.applyNoPermissionTag.id
      });
    }

    if (element.details.no_permission_redirect_type === 'local') {
      navigate(`/${element.details.no_permission_url}`);
    } else if (element.details.no_permission_redirect_type === 'external') {
      window.location.href = 'https://' + element.details.no_permission_url;
    }
  };

  const getComponent = () => {
    switch (element.type) {
      case 'Text':
        return <Text element={element} />;
      case 'NavBar':
        return (
          <NavBar element={element} ruleAccess={ruleAccess} editMode={false} />
        );
      case 'Button':
        return (
          <CustomButton
            element={element}
            handleClick={handleClick}
            ruleAccess={ruleAccess}
          />
        );
      case 'Image':
        return (
          <Image
            element={element}
            pointer={{ cursor: 'pointer' }}
            building={false}
            handleClick={handleClick}
            ruleAccess={ruleAccess}
          />
        );
      case 'Video':
        return <Video element={element} />;
      case 'PDF':
        return <PDF element={element} />;
      case 'Divider':
        return <DividerElement element={element} />;
      case 'Card':
        return (
          <Card
            element={element}
            building={false}
            handleClick={handleClick}
            ruleAccess={ruleAccess}
          />
        );
      case 'Conferences':
        return <Conferences element={element} ruleAccess={ruleAccess} />;
      case 'Courses':
        return <Courses element={element} />;
      case 'CourseCatalog':
        return <CourseCatalog element={element} />;
      case 'Tabs':
        return <TabsPages element={element} />;
      case 'Carousel':
        return (
          <Carousel
            element={element}
            handleClick={handleClick}
            ruleAccess={ruleAccess}
            building={false}
            active={true}
          />
        );
      case 'TeamChart':
        return <TeamChart element={element} />;
      case 'Empty':
        return <Empty element={element} />;
      default:
        return null;
    }
  };
  const handleCloseCartModal = () => {
    setAddingProductToCart(false);
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        textAlign: element.details ? element.details.alignment : 'center'
      }}
    >
      <Suspense fallback={<div>loading</div>}>
        {element.details == null ? '' : getComponent()}
      </Suspense>

      {addingProductToCart ? (
        <AddToCartModal
          addToCart={addingProductToCart}
          product={element.details.product.product}
          closeModal={handleCloseCartModal}
        />
      ) : (
        ''
      )}
    </div>
  );
};
export default ElementViewer;
