import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import classes from './resultItem.module.scss';
import { Course, Section } from './Search';
import { useNavigate } from 'react-router-dom';
import { RiLockFill } from 'react-icons/ri';
import { BsCircle, BsCircleFill } from 'react-icons/bs';

interface CourseCardProps {
  course: Course;
  style: CSSProperties;
  setRowHeight: (index: number, size: number) => void;
  index: number;
  icon?: JSX.Element;
  highlightText: (text: string) => (string | JSX.Element)[];
  handleLockedCourse: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: string,
    courseId: number
  ) => void;
}

interface CourseResult {
  index: number;
  type: string;
  value: string;
}

const LIMIT_RESULT = 5;

const CourseCard = ({
  course,
  setRowHeight,
  index,
  style,
  icon,
  highlightText,
  handleLockedCourse
}: CourseCardProps) => {
  const [limitResult, setLimitResults] = useState<number>(LIMIT_RESULT);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleLoadMore = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setLimitResults((prevState) => prevState + 3);
  };

  const transformCourse = useCallback(() => {
    const courseResults = [];
    let index = 0;
    course.courseName &&
      courseResults.push({
        index: index,
        type: 'courseName',
        value: course.courseName
      });
    index++;
    if (course.sections.length) {
      const sections: Section[] = course.sections;
      for (let i = 0; i < sections.length; i++) {
        courseResults.push({
          index: index,
          type: 'sectionName',
          value: sections[i].sectionName
        });
        index++;
        if (sections[i].modules.length) {
          const modules = sections[i].modules;
          for (let j = 0; j < modules.length; j++) {
            courseResults.push({
              index: index,
              type: 'moduleName',
              value: modules[j]
            });
            index++;
          }
        }
      }
    }
    return courseResults;
  }, [course]);

  const transformedCourse = transformCourse();
  const displayCourse = transformedCourse.slice(0, limitResult);

  /* update height after clicked load more item */
  useEffect(() => {
    containerRef.current &&
      setRowHeight(index, containerRef.current?.clientHeight || 0);
  }, [containerRef, index, setRowHeight, limitResult]);

  const navigate = useNavigate();
  const handleClickCourse = async (courseId: number) => {
    navigate(`/courses/viewCourse/${courseId}`, {
      state: { update: true }
    });
  };

  return (
    <div style={style} className={classes['card']}>
      {!course.hasPermission && (
        <div
          className={classes['locked-card']}
          onClick={(e) => handleLockedCourse(e, 'course', course.courseId)}
        >
          <RiLockFill size={30} />
        </div>
      )}

      <div ref={containerRef}>
        <div
          className={`${classes['result-item']} ${classes['clickable-card']}`}
          onClick={() => handleClickCourse(course.courseId)}
        >
          {course.courseImage ? (
            <div className={classes['item__image-display']}>
              <img
                className={classes['image-display__image']}
                src={course.courseImage}
                alt='topic'
              />
              <div className={classes['image-display__icon']}>{icon}</div>
            </div>
          ) : (
            <div className={classes['item__image-display']}>
              <div className={classes['image-display__image']}></div>
              <div className={classes['image-display__icon']}>{icon}</div>
            </div>
          )}
          <div className={classes['item__list']}>
            {displayCourse.map((result: CourseResult, index: number) => {
              let className;
              if (result.type === 'courseName') {
                className = classes['item__name'];
                return (
                  <div key={result.value + index} className={className}>
                    {highlightText(result.value)}
                  </div>
                );
              } else if (result.type === 'sectionName') {
                className = classes['item__section-name'];
                return (
                  <div key={result.value + index} className={className}>
                    <BsCircleFill size={9} />
                    {` `} {highlightText(result.value)}
                  </div>
                );
              } else if (result.type === 'moduleName') {
                className = classes['item__module-name'];
                return (
                  <div key={result.value + index} className={className}>
                    <BsCircle size={9} />
                    {` `} {highlightText(result.value)}
                  </div>
                );
              } else {
                return <></>;
              }
            })}

            {transformedCourse.length > displayCourse.length && (
              <div
                className={classes['item__load-more']}
                onClick={handleLoadMore}
              >
                Load more ...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
