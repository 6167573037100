import React, { useEffect, useState } from 'react';
import FlipMove from 'react-flip-move';
import { Row, Col } from 'antd';
import TeamVsTeamLeaderboard from './TeamVsTeamLeaderboard';

const StandardLeaderboard = ({
  users_stats,
  rankTextFromIndex,
  grouping,
  teams
}) => {
  const [firstPlace, setFirstPlace] = useState();
  const [secondPlace, setSecondPlace] = useState();

  useEffect(() => {
    if (teams) {
      if (teams.t1.total > teams.t2.total) {
        setFirstPlace(teams.t1);
        setSecondPlace(teams.t2);
      } else {
        setFirstPlace(teams.t2);
        setSecondPlace(teams.t1);
      }
    }
  }, [teams]);

  return (
    <>
      {firstPlace && secondPlace && (
        <TeamVsTeamLeaderboard
          firstPlace={firstPlace}
          secondPlace={secondPlace}
        />
      )}
      {teams && (
        <Row justify='center' align='middle'>
          <h3>Users</h3>
        </Row>
      )}
      <FlipMove>
        {users_stats &&
          users_stats.map((user, index) => {
            return (
              <Row
                style={{
                  borderBottom: 'solid',
                  borderWidth: '1px',
                  borderColor: 'rgb(245, 245, 245)',
                  padding: '10px'
                }}
                type='flex'
                align='middle'
                key={user.id}
              >
                <Col span={4}>
                  <span style={{}}>
                    {rankTextFromIndex(index, users_stats)}
                  </span>
                </Col>
                <Col span={16}>
                  <Row align='middle'>
                    <div
                      style={{
                        width: '50px',
                        height: '50px',
                        margin: 0,
                        backgroundImage: `url("${user.profile_pic_url}")`,
                        backgroundSize: 'contain',
                        borderRadius: '50%',
                        float: 'left'
                      }}
                    />
                    <div style={{ marginLeft: '20px' }}>
                      {grouping === 'allTeams'
                        ? `${user.team_name}`
                        : `${user.first_name} ${user.last_name}`}
                    </div>
                  </Row>
                </Col>
                <Col span={4}>{user.total}</Col>
              </Row>
            );
          })}
      </FlipMove>
    </>
  );
};

export default StandardLeaderboard;
