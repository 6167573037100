import { Card, Col, Modal, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const ListOfSubmissionsModal = ({ isVisible, submissions, onCancel }) => {
  const getFormSubmissionCards = () => {
    return submissions.map((submission, index) => {
      const {
        submission_id,
        form_name,
        date,
        crm_id,
        first_name,
        last_name,
        email,
        phone,
        field_name
      } = submission;

      return (
        <Card key={submission_id}>
          <Row>
            <Col span={24}>{index + 1}</Col>
            <Col span={24} style={{ marginBottom: '10px' }}>
              <div style={{ width: '100%' }}>
                <p
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Rubik',
                    fontSize: '16px'
                  }}
                >
                  {form_name}
                </p>
              </div>
            </Col>
            <Col span={24} style={{ marginBottom: '10px' }}>
              <span>{moment.utc(date, 'YYYY-MM-DD').format('YYYY-MM-DD')}</span>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  color: '#173757'
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'Inter',
                      fontSize: '19px',
                      color: '#1d75f0',
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      window.open(
                        `https://clubwealth.infusionsoft.com/Contact/manageContact.jsp?view=edit&ID=${crm_id}&Sidebar=true`
                      )
                    }
                  >{`${first_name} ${last_name}`}</p>
                </div>
                <div>
                  <span style={{ fontSize: '15px' }}>{crm_id}</span>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <span>{email}</span>
            </Col>
            <Col span={24}>
              <span>{phone}</span>
            </Col>
            {getOwnerInfo(submission)}
            <Col
              span={24}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px'
              }}
            >
              <span>{field_name}</span>
            </Col>
          </Row>
        </Card>
      );
    });
  };

  const getOwnerInfo = (submission) => {
    const { owner_first_name, owner_last_name } = submission;
    return (
      <Col span={24} style={{ marginTop: '10px' }}>
        {owner_first_name ? (
          <div>
            <span>Owner: </span>
            <span>{`${owner_first_name} ${owner_last_name}`}</span>
          </div>
        ) : null}
      </Col>
    );
  };

  return (
    <Modal
      title={`Form submissions`}
      open={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      {submissions?.length > 0 ? (
        getFormSubmissionCards(submissions)
      ) : (
        <div>
          <Row>
            <div>
              <p
                style={{
                  fontWeight: 'bold',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  color: '#173757'
                }}
              >
                No submissions found
              </p>
            </div>
          </Row>
        </div>
      )}
    </Modal>
  );
};

export default ListOfSubmissionsModal;
