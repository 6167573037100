import httpHandler from '../../helpers/httpHandler';

const getBoardLabels = async (boardId: number) => {
  try {
    const boardLabels = await httpHandler.get(
      `/api/board/listLabels/board/${boardId}`
    );
    return boardLabels;
  } catch (error) {
    console.error(`error getting board labels`, error);
    return [];
  }
};

const getBoardLabelTags = async (boardId: number) => {
  try {
    const boardLabelTags = await httpHandler.get(
      `/api/board/listLabels/board/${boardId}/tags`
    );
    return boardLabelTags;
  } catch (error) {
    console.error(`error`, error);
    return [];
  }
};

const listLabelApi = {
  getBoardLabels,
  getBoardLabelTags
};

export default listLabelApi;
