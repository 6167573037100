import { Roles, RoleVisibility } from "../../types/statTracker";

interface VisibilitySQLRow {
  id: number;
  role: Roles;
  stageId: number;
  stageName: string;
  subscribeToStage: null | number;
  visible: number;
  teamId: number;
  stageIndex: number;
}

export const visibilityDataParser = (dataPacket: VisibilitySQLRow[]) => {
  const roleVisibility: RoleVisibility[] = [];

  dataPacket.forEach((row) => {
    const parentIdx = roleVisibility.findIndex((obj) => obj.id === row.id);
    if (parentIdx === -1) {
      const roleObj = {
        id: row.id,
        role: row.role,
        visibilityData: [
          {
            teamId: row.teamId,
            stageId: row.stageId,
            stageName: row.stageName,
            visible: row.visible,
            subscribeToStage:
              row.subscribeToStage === null ? 0 : row.subscribeToStage,
            stageIndex: row.stageIndex,
          },
        ],
      };
      roleVisibility.push(roleObj);
    } else {
      roleVisibility[parentIdx].visibilityData.push({
        teamId: row.teamId,
        stageId: row.stageId,
        stageName: row.stageName,
        visible: row.visible,
        subscribeToStage:
          row.subscribeToStage === null ? 0 : row.subscribeToStage,
        stageIndex: row.stageIndex,
      });
    }
  });
  for (let role of roleVisibility) {
    role.visibilityData.sort((a, b) => (a.stageIndex < b.stageIndex ? -1 : 1));
  }
  return roleVisibility;
};
