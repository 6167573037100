import socketIOClient from 'socket.io-client';

var socketHandler = {};
const roomSubscriptionCounters = new Map();
let tabIsVisible = true; // Set initial visibility state

//This file initiates a socket the first time it is included in another file
//the same socket can then be accessed throughout the project with various connections
socketHandler.getSocket = () => {
  if (socketHandler.socket) {
    return socketHandler.socket;
  } else {
    socketHandler.socket = socketIOClient(
      process.env.NODE_ENV === 'production'
        ? window.location.origin
        : 'http://localhost:3001',
      {
        extraHeaders: {
          Authorization: localStorage.getItem('CWAuthToken')
        }
      }
    );
    //reconnect to subscribed rooms on reconnection
    socketHandler.socket.on('connect', () => {
      for (const roomName of roomSubscriptionCounters.keys()) {
        socketHandler.socket.emit('subscribe', roomName);
      }
    });

    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible' && !tabIsVisible) {
        tabIsVisible = true;
        socketHandler.socket.emit('focus');
      } else {
        tabIsVisible = false;
      }
    });

    return socketHandler.socket;
  }
};

socketHandler.joinRoom = (roomName) => {
  if (roomSubscriptionCounters.has(roomName)) {
    const currentCount = roomSubscriptionCounters.get(roomName);
    roomSubscriptionCounters.set(roomName, currentCount + 1);
  } else {
    roomSubscriptionCounters.set(roomName, 1);
    socketHandler.getSocket().emit('subscribe', roomName);
  }
};

socketHandler.leaveRoom = (roomName) => {
  if (roomSubscriptionCounters.has(roomName)) {
    const currentCount = roomSubscriptionCounters.get(roomName);
    if (currentCount === 1) {
      roomSubscriptionCounters.delete(roomName);
      socketHandler.getSocket().emit('unsubscribe', roomName);
    } else {
      roomSubscriptionCounters.set(roomName, currentCount - 1);
    }
  }
};

export default socketHandler;
