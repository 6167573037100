import React from 'react';
import httpHandler from '../helpers/httpHandler';
import { notification } from 'antd';
import { useStore } from '../store/useStore';
import { postRequest } from '../helpers/httpHandler2';
import { CheckCircleOutlined } from '@ant-design/icons';

const UserContext = React.createContext();

class UserProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      user_bundle: null
    };
  }

  requestUserBundleUpdate = async () => {
    if (this.updating) return;
    this.updating = true;
    let data;
    try {
      data = await httpHandler.get('/api/users/');
    } catch (error) {
      window.localStorage.removeItem('CWAuthToken');
      window.location.href = '/login';
    }
    const currentUrl = new URL(window.location.href);
    const joinTeamCode =
      currentUrl.searchParams.get('joinTeamCode') ||
      window.localStorage.getItem('joinTeamCode');
    if (joinTeamCode) {
      try {
        const team = await postRequest('/api/teams/invite/accept', {
          joinTeamSecretCode: joinTeamCode
        });
        notification.open({
          message: 'Joined Team',
          description: `Succesfully joined ${team.teamName}!`,
          icon: <CheckCircleOutlined style={{ color: '#48a1ff' }} />,
          duration: 0
        });
        window.localStorage.removeItem('joinTeamCode');
      } catch (error) {
        notification.open({
          message: 'Error Joining Team',
          description: 'Unable to Join team.'
        });
        window.localStorage.removeItem('joinTeamCode');
      }
    }

    const teamStaticCode =
      currentUrl.searchParams.get('tsc') || window.localStorage.getItem('tsc');
    if (teamStaticCode) {
      try {
        await postRequest(`/api/teams/requests`, {
          staticCode: teamStaticCode
        });
        notification.open({
          message: 'Sent join team request to the team leader'
        });
      } catch (error) {
        notification.open({ message: error.message });
        console.error('error making request', error);
      }
      window.localStorage.removeItem('tsc');
      currentUrl.searchParams.delete('tsc');
      window.history.pushState({}, document.title, window.location.pathname);
    }

    let user_bundle = JSON.parse(data.user_bundle);
    window.localStorage.setItem('CWAuthToken', data.token);
    await useStore.getState().teamsApi.fetchUserTeams();
    const currentTeam = window.localStorage.getItem('currentTeam');
    const userTeams = await useStore.getState().userTeams;

    if (!currentTeam && userTeams.length) {
      window.localStorage.setItem('currentTeam', JSON.stringify(userTeams[0]));
    }
    this.setState({ user_bundle: user_bundle }, async () => {});

    this.updating = false;
  };

  updateUserBundleDirectly = (user_bundle) => {
    this.updating = true;
    this.setState({ user_bundle: user_bundle }, async () => {
      this.updating = false;
    });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          user_bundle: this.state.user_bundle,
          requestUserBundleUpdate: this.requestUserBundleUpdate,
          updateUserBundleDirectly: this.updateUserBundleDirectly
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserContext;

export { UserProvider };
