import React from 'react';
import { Row } from 'antd';
import BackgroundVideo from '../backgroundVideo/BackgroundVideo';
import RowViewer from './RowViewer';

class SectionViewer extends React.Component {
  render() {
    let style = {};
    if (this.props.pageSection.details.sticky) {
      style = { position: 'sticky', top: 0, zIndex: 3 };
    } else {
      style = { positoin: 'relative' };
    }
    return (
      <div style={style}>
        <Row style={this.props.pageSection.styles}>
          {this.props.pageSection.details.backgroundVideo ? (
            <BackgroundVideo
              sourceType={this.props.pageSection.details.sourceType}
              source={this.props.pageSection.details.source}
            />
          ) : (
            ''
          )}
          {this.props.pageSection.sectionRows.map((sectionRow, index) => {
            return (
              <RowViewer
                key={index}
                pageHistory={this.props.pageHistory}
                sectionRow={sectionRow}
                handlePopup={this.props.handlePopup}
                ruleAccess={this.props.ruleAccess}
              />
            );
          })}
        </Row>
      </div>
    );
  }
}

export default SectionViewer;
