import { pipelineVisibilityRoutes } from '../../api/pipelineVisibilityRoutes/pipelineVisibilityRoutes';
import { getRequest } from '../../helpers/httpHandler2';
import {
  Awaited,
  Pipeline,
  PipelineAssociatedField,
  sqlReturn
} from '../../types/statTracker';
import { GetFn, SetFn, PipelineSlice } from '../../types/zustandTypes';
import { pipelineApi } from '../apis/pipelineApi';

// Getting the pipeline is handled when the team is fetched. The majority of pages in the
// stattracker use the pipeline, so it's best to fetch it with the team
// the actual slice of state *could* be moved to the createTeamSlice,
// but I think it makes the most sense here
export const createPipelineSlice = (set: SetFn, get: GetFn): PipelineSlice => ({
  pipelines: [],
  currentPipeline: null,
  pipelineLoading: true,
  pipelineVisibility: [],
  pipelineFields: {},
  // *** pipelines are fetched in createTeamSlice *** //
  pipelineApi: {
    addPipeline: async (name, teamId, userId) => {
      try {
        const response = await pipelineApi.postPipeline(name, teamId);
        const { insertId } = response;

        const pipeline: Pipeline = {
          id: insertId,
          name,
          teamId,
          userId,
          immutable: 0
        };
        set((state) => {
          state.pipelines.push(pipeline);
          state.currentPipeline = pipeline;
        });
      } catch (error) {
        console.error(error);
      }
    },
    deletePipeline: async (pipelineId) => {
      try {
        await pipelineApi.deletePipeline(pipelineId);
        set((state) => {
          const filteredPl = get().pipelines.filter(
            (pipeline) => pipeline.id !== pipelineId
          );
          state.pipelines = filteredPl;
          state.currentPipeline = filteredPl[0] || null;
        });
      } catch (error) {
        console.error('Error deleting pipeline', error);
      }
    },
    editPipeline: async (pipelineId, name) => {
      try {
        const idx = get().pipelines.findIndex((pl) => pl.id === pipelineId);
        if (idx < 0) {
          console.error('no pipeline found');
          return;
        }
        await pipelineApi.editPipeline(name, pipelineId);
        set((state) => {
          state.pipelines[idx].name = name;
        });
      } catch (error) {
        console.error('Error editing pipeline', error);
      }
    },
    setCurrentPipeline: async (pipeline: Pipeline) => {
      set((state) => {
        state.currentPipeline = pipeline;
      });
    },
    fetchPipelineStages: async (pipelineId) => {
      try {
        const pipelines = get().pipelines;
        const foundIdx = pipelines.findIndex(
          (pipeline) => pipeline.id === pipelineId
        );
        const stages = await pipelineApi.getPipeline(pipelineId);
        if (!stages) {
          return;
        }
        set((state) => {
          state.pipelines[foundIdx].pipelineStages = stages;
          state.currentPipeline = state.pipelines[foundIdx];
        });
      } catch (error) {
        console.error('Error getting pipeline data', error);
      }
    },
    fetchPipelineFields: async (pipelineId) => {
      let assignedFieldsData = await getRequest(
        `/api/pipelines/${pipelineId}/fields`
      );

      const assignedFieldsIds = assignedFieldsData.map(
        (field: PipelineAssociatedField) => field.fieldId
      );

      set((state) => {
        state.pipelineFields[pipelineId] = assignedFieldsIds;
      });
    },
    setPipelineFields: async (pipelineId, pipelineFields) => {
      set((state) => {
        state.pipelineFields[pipelineId] = pipelineFields;
      });
    },
    addPipelineStage: async (stage, teamId) => {
      try {
        const response = await pipelineApi.addStage(
          stage.pipelineId,
          stage,
          teamId
        );
        stage.id = response.insertId;
        set((state) => {
          if (state.currentPipeline) {
            if (state.currentPipeline.pipelineStages) {
              state.currentPipeline.pipelineStages.push(stage);
            } else {
              state.currentPipeline.pipelineStages = [stage];
            }
          }
        });
      } catch (error) {
        console.error('Error adding stage', error);
      }
    },
    deletePipelineStage: async (id, index, teamId) => {
      try {
        const plCopy: Pipeline | null = get().currentPipeline;
        if (!plCopy) return;
        const stages = plCopy.pipelineStages;
        if (!stages) return;
        const removedPl = stages.filter((item) => item.id !== id);
        // adjust indexes of PL elements
        const adjustIndex = removedPl.map((stage) => {
          const tmpStage = { ...stage };
          if (tmpStage.stageIndex > index) {
            tmpStage.stageIndex--;
          }
          return tmpStage;
        });

        set((state) => {
          if (state.currentPipeline) {
            state.currentPipeline.pipelineStages = adjustIndex;
          }
        });

        await pipelineApi.archiveStage(stages[0].pipelineId, index, id, teamId);
      } catch (error) {
        console.error('Could not remove stage', error);
      }
    },
    editPipelineStage: async (
      stageName,
      color,
      stageId,
      pipelineId,
      statFieldId,
      kpiCategoryId
    ) => {
      try {
        const response: Awaited<Awaited<sqlReturn | undefined>> =
          await pipelineApi.updateStage(
            stageName,
            color,
            stageId,
            pipelineId,
            statFieldId,
            kpiCategoryId
          );

        set((state) => {
          const stages = state.currentPipeline?.pipelineStages;

          if (!stages) return;
          const idx = stages.findIndex((pl) => pl.id === stageId);
          stages[idx].name = stageName;
          stages[idx].color = color;
          stages[idx].statFieldId = statFieldId;
          stages[idx].usesTransactionForm = statFieldId ? 0 : 1;
          stages[idx].kpiCategory = kpiCategoryId;
        });
        return response;
      } catch (error) {
        console.error('Error editing pipeline', error);
      }
    },
    movePipelineStage: async (startIndex, endIndex, pipelineId) => {
      try {
        const pipeline = get().currentPipeline;
        if (!pipeline) return;
        const stages = pipeline.pipelineStages;
        if (!stages) return;
        const tmp = [...stages];
        const [{ ...removed }] = tmp.splice(startIndex, 1);
        removed.stageIndex = endIndex;
        tmp.splice(endIndex, 0, removed);

        const change = startIndex < endIndex ? -1 : 1;

        let lowBound = Math.min(startIndex, endIndex);
        let highBound = Math.max(startIndex, endIndex);

        if (change === 1) highBound++;
        if (change === -1) lowBound--;

        const result = tmp.map((stage, index) => {
          const tmpStage = { ...stage };
          if (index > lowBound && index < highBound) {
            tmpStage.stageIndex += change;
          }
          return tmpStage;
        });

        set((state) => {
          const pl = state.pipelines.find((p) => p.id === pipelineId);
          if (!pl) return;
          pl.pipelineStages = result;
          state.currentPipeline = pl;
        });

        await pipelineApi.moveStage(
          removed.id,
          startIndex,
          endIndex,
          pipelineId
        );
      } catch (error) {
        console.error('Error moving item', error);
      }
    },
    getPipelineVisibility: async (pipelineId: number) => {
      try {
        const pipelineVisibility =
          await pipelineVisibilityRoutes.getPipelineStageVisibility(pipelineId);

        set({ pipelineVisibility });
      } catch (error) {
        console.error('Error getting visibility by role', error);
      }
    },
    setPipelineVisibility: async (roleIdx, stageIdx, key) => {
      set((state) => {
        const plVisibility = state.pipelineVisibility;
        const foundRole = plVisibility[roleIdx];
        const stageVisibility = foundRole.visibilityData[stageIdx];

        stageVisibility[key] = stageVisibility[key] === 1 ? 0 : 1;
      });
    }
  }
});
