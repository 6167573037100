import { GetFn, SetFn, TaskSlice } from '../../types/zustandTypes';
import tasksApi from '../apis/tasksApi';

export const createTaskSlice = (set: SetFn, get: GetFn): TaskSlice => ({
  tasks: [],
  tasksApi: {
    fetchUserTasks: async (userId) => {
      const taskData = await tasksApi.fetchUserTasks(userId);
      const completionData = await tasksApi.fetchUserHomeworkCompletion(userId);
      set({
        tasks: taskData.map((task: any) => {
          const homework = tasksApi.mapHwWithCompleted(
            task.todos.homework,
            completionData
          );

          return {
            ...task,
            todos: { ...task.todos, homework },
            totalHomework: homework.length,
            totalCompleted: homework.filter((hw: any) => hw.completed).length,
            totalLink: task.todos.links.length,
            totalDownload: task.todos.downloads.length
          };
        })
      });
    },
    setTasks: (newState) => set({ tasks: newState })
  }
});
