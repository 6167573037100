import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import classes from './NavItemsTray.module.scss';
import { useNavigate } from 'react-router-dom';
import { NavItem } from '../NavBar';
import { FaAngleDown } from 'react-icons/fa';
import produce from 'immer';

type Props = {
  items: NavItem[];
  closeTray: () => void;
};

const NavItemsTray = ({ items, closeTray }: Props) => {
  const navigate = useNavigate();
  const [itemsExpanded, setItemsExpanded] = useState<boolean[]>(
    items.map(() => false)
  );
  const handleNavigate = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    url: string,
    urlType: string
  ) => {
    if (urlType === 'external') {
      window.open(url, '_blank');
    } else {
      e.stopPropagation();
      navigate(url);
    }
    closeTray();
  };

  const itemClicked = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: NavItem,
    index: number
  ) => {
    e.stopPropagation();
    if (item.dropDownItems.length > 0) {
      setItemsExpanded(
        produce((draft) => {
          for (let i = 0; i < draft.length; i++) {
            if (i === index) draft[i] = !itemsExpanded[i];
            else draft[i] = false;
          }
        })
      );
    }
  };

  return (
    <div className={classes['items-container']}>
      {items.map((item, index) => (
        <div
          className={classes.item}
          key={index}
          onClick={(e) => itemClicked(e, item, index)}
        >
          <div className={classes.item__label}>
            <div onClick={(e) => handleNavigate(e, item.url, item.url_type)}>
              {item.text}
            </div>
            {item.dropDownItems && item.dropDownItems.length > 0 && (
              <div className={classes.expand}>
                <FaAngleDown />
              </div>
            )}
          </div>
          {item.dropDownItems && (
            <AnimatePresence>
              {itemsExpanded[index] && (
                <div className={classes['item__sub-items']}>
                  {item.dropDownItems.map((dropDownItem, index) => {
                    return (
                      <div
                        key={index}
                        onClick={(e) =>
                          handleNavigate(
                            e,
                            dropDownItem.url,
                            dropDownItem.url_type
                          )
                        }
                        className={classes['sub-item']}
                      >
                        <span>{dropDownItem.text}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </AnimatePresence>
          )}
        </div>
      ))}
    </div>
  );
};

export default NavItemsTray;
