import React, { useContext, useEffect, useState } from 'react';
import { getRequest, postRequest } from '../../../../../helpers/httpHandler2';
import PrettyButton from '../../../../reusableComponents/standard/prettyButton/PrettyButton';
import classes from './coursePreview.module.scss';
import { Course } from './Search';

import { BsDot } from 'react-icons/bs';
import { CgDollar, CgChevronDown, CgChevronUp } from 'react-icons/cg';
import ShoppingCartContext from '../../../../../contexts/ShoppingCartContext';
import { message } from 'antd';
import { useStore } from '../../../../../store/useStore';
import { UtilityMode } from '../NavBar';
import { useNavigate } from 'react-router-dom';

interface CoursePreviewProps {
  courseId: number;
  onClose: () => void;
  type: string;
}

const CoursePreview = ({ courseId, onClose, type }: CoursePreviewProps) => {
  const [course, setCourse] = useState<Course>();
  const [sectionsExpanded, setSectionsExpanded] = useState<string[]>([]);

  const { addToCart } = useContext(ShoppingCartContext);
  const [setUtilityMode] = useStore((state) => [state.setUtilityMode]);

  const navigate = useNavigate();

  useEffect(() => {
    const getCourse = async () => {
      const course = await getRequest('/api/course/getFullCourseById', {
        id: courseId
      });
      course && setCourse(course);
    };

    getCourse();
  }, [courseId]);

  const handleAddToCart = async () => {
    try {
      const product = await postRequest(
        `/api/commerce/products/getProductByInfusionsoftId`,
        {
          id: course?.productId
        }
      );

      product.length
        ? addToCart(product[0])
        : message.error('error can not find product');
      onClose();
      //add course to cart success and then open the shopping cart
      setUtilityMode(UtilityMode.SHOPPING_CART);
    } catch (error) {
      console.error('error add product to cart ', error);
      message.error('error add product to cart');
    }
  };

  const handleStart = () => {
    navigate(`/courses/viewCourse/${courseId}`);
  };

  const handleExpand = (sectionName: string) => {
    if (sectionsExpanded.includes(sectionName)) {
      //remove
      setSectionsExpanded(sectionsExpanded.filter((s) => s !== sectionName));
    } else {
      setSectionsExpanded((prevSections) => [...prevSections, sectionName]);
    }
  };

  return (
    <div
      className={classes['course-review']}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes['modal__header']}>
        <h3>{course?.courseName}</h3>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className={classes['modal__close']}
        >
          ×
        </div>
      </div>
      <div className={classes['modal__body']}>
        <label>Course Content</label>

        {course?.video && <video src={course.video} controls></video>}
        <div className={classes['body__content-list']}>
          <div className={classes['list__list-item']}>
            {course?.sections.map((section, index) =>
              !sectionsExpanded.includes(section.sectionName) ? (
                <div
                  key={section.sectionName + index + 'modal'}
                  className={classes['list__section']}
                  onClick={() => handleExpand(section.sectionName)}
                >
                  <div>
                    {section.sectionName}
                    {section.modules.length ? (
                      ` (${section.modules.length})`
                    ) : (
                      <></>
                    )}
                  </div>

                  <CgChevronDown size={18} />
                </div>
              ) : (
                <div key={section.sectionName + index + 'modal'}>
                  <div
                    className={classes['list__section']}
                    onClick={() => handleExpand(section.sectionName)}
                  >
                    {section.sectionName}
                    <CgChevronUp size={15} />
                  </div>

                  {section.modules.map((module, index) => {
                    return (
                      <div
                        key={module + index + 'modal'}
                        className={classes['list-module']}
                      >
                        <BsDot size={20} /> {module}
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
        </div>

        <div className={classes['modal-footer']}>
          {type === 'purchase' ? (
            <>
              <div className={classes['footer__price']}>
                <CgDollar />
                {course?.price}
              </div>
              <PrettyButton onClick={handleAddToCart}>ADD TO CART</PrettyButton>
            </>
          ) : (
            <>
              <div className={classes['footer__price']}>FREE</div>
              <PrettyButton onClick={handleStart}>ENROLL & START</PrettyButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoursePreview;
