import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

class TeamLineChart extends React.Component {
  constructor() {
    super();
    this.state = {
      circleRadiusPercentage: Math.min(
        window.innerWidth / 1920,
        window.innerHeight / 937
      )
    };
  }
  componentDidMount() {
    this.setState(
      {
        circleRadiusPercentage: Math.min(
          this.props.graphRef.current.offsetWidth / 1920,
          this.props.graphRef.current.offsetHeight / 937
        )
      },
      () => {
        this.initChart();
      }
    );
    if (this.props.processedDatesTotalData && this.props.dataDisplay) {
      this.initChart();
    }

    let previousResizeFunc =
      window.onresize !== null ? window.onresize : () => {};

    window.onresize = () => {
      previousResizeFunc();
      this.setState({
        circleRadiusPercentage: Math.min(
          this.props.graphRef.current.offsetWidth / 1920,
          this.props.graphRef.current.offsetHeight / 937
        )
      });
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lineChartData !== prevProps.lineChartData &&
      this.props.dataDisplay
    ) {
      if (!this.props.dataForLineChart) {
        if (this.chart) {
          this.chart.dispose();
        }
        this.initChart();
      } else {
        this.updateGraph();
      }
    }
    if (this.props.lineChartSeries !== prevProps.lineChartSeries) {
      this.handleChangeSeries();
    }
  }

  initChart = () => {
    let chart = am4core.create(
      this.props.statDisplayId
        ? this.props.graph_name + '_' + this.props.statDisplayId
        : this.props.graph_name,
      am4charts.XYChart
    );
    this.chart = chart;
    chart.cursor = new am4charts.XYCursor();

    var title = chart.titles.create();
    let label = this.props.dataDisplay.split('_');
    label.splice(label.length - 1);
    label = label.join(' ');

    title.textAlign = 'middle';
    title.marginBottom = '2%';
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.scrollable = true;
    // chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd'
    chart.data = this.props.lineChartData;
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    let categoryAxis;
    let dateAxis;
    if (
      (this.props.rollingUnit === 'weeks' ||
        this.props.rollingUnit === 'months') &&
      this.props.lineChartDisplay === 'rolling_dates'
    ) {
      categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.dataFields.category = 'date';
      categoryAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 16 - this.props.lineChartSeries.length * 1.2 >= 11
            ? 16 - this.props.lineChartSeries.length * 1.2
            : 11
          : 12 * Math.cbrt(this.state.circleRadiusPercentage);
    } else {
      dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;
      chart.cursor.xAxis = dateAxis;
      dateAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 16 - this.props.lineChartSeries.length * 1.2 >= 11
            ? 16 - this.props.lineChartSeries.length * 1.2
            : 11
          : 12 * Math.cbrt(this.state.circleRadiusPercentage);
    }
    // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.min = 0;
    if (this.props.statDisplayId) {
      valueAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 20 - this.props.lineChartSeries.length * 1.2 >= 12
            ? 20 - this.props.lineChartSeries.length * 1.2
            : 12
          : 20 * Math.cbrt(this.state.circleRadiusPercentage);
      title.text = `[bold font-size: 11 #091a33]Member Stats (${label})`;
      var markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 15 - this.props.lineChartSeries.length * 1.2;
      markerTemplate.height = 15 - this.props.lineChartSeries.length * 1.2;
      chart.legend.fontSize = this.props.graph_name.includes('team-management')
        ? 20 - this.props.lineChartSeries.length * 1.2
        : 20 * Math.cbrt(this.state.circleRadiusPercentage);
      chart.legend.maxWidth = 300;
      chart.legend.maxHeight = 60;
    } else {
      title.text = `[bold font-size: 20 #091a33]Member Stats (${label})`;
      valueAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 20
          : 20 * Math.cbrt(this.state.circleRadiusPercentage);
      chart.legend.maxHeight = 100;
    }
    //Solved the issue where the dates appeared reversed when year is in the x-axis label
    chart.events.on('beforedatavalidated', function (ev) {
      chart.data.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
    });
    chart.colors.list = [
      am4core.color('#091a33'),
      am4core.color('rgba(103, 148, 220, 0.9)'),
      am4core.color('rgba(44, 255, 5, 1)'),
      am4core.color('#8ea339'),
      am4core.color('#2b6c80'),
      am4core.color('#3d22b5'),
      am4core.color('#d60000'),
      am4core.color('#b06809'),
      am4core.color('#FF6F91'),
      am4core.color('#FF9671'),
      am4core.color('#FFC75F'),
      am4core.color('#F9F871'),
      am4core.color('#91088f'),
      am4core.color('#0091c2'),
      am4core.color('rgba(43, 108, 128, 0.4)'),
      am4core.color('rgba(176, 104, 9, 0.4)'),
      am4core.color('rgba(0, 145, 194, 0.4)')
    ];

    this.createSeries();
  };
  createSeries = () => {
    if (
      this.props.lineChartData &&
      this.props.lineChartSeries.length > 0 &&
      this.props.lineChartData.length > 0
    ) {
      for (let field of this.props.lineChartSeries) {
        for (let [key] of Object.entries(this.props.lineChartData[0])) {
          if (key === field) {
            this.addSeries(key);
          }
        }
      }
    }
  };
  addSeries = (field) => {
    let label;
    if (field.split('~~').length === 2) {
      label = field.split('~~')[0];
    } else {
      label = field.split('_');
      label.splice(label.length - 1);
      label = label.join(' ');
    }
    var series = this.chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    if (
      (this.props.rollingUnit === 'weeks' ||
        this.props.rollingUnit === 'months') &&
      this.props.lineChartDisplay === 'rolling_dates'
    ) {
      series.dataFields.categoryX = 'date';
    } else {
      series.dataFields.dateX = 'date';
    }

    series.strokeWidth = 2;
    series.minBulletDistance = 10;
    series.tooltipText = `[bold]{date}:[/] ${label}:[/] {valueY}`;
    series.tooltip.pointerOrientation = 'vertical';
    series.legendSettings.labelText = label;
    series.tensionX = 0.77;
    // series.stroke = am4core.color("blue");
  };
  handleChangeSeries = () => {
    let options = this.props.lineChartSeries;
    if (this.chart) {
      let chartSeries = this.chart.series.values;
      if (options.length > chartSeries.length) {
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          let check = chartSeries.filter(
            (series) => series.dataFields.valueY === option
          );
          if (check.length === 0) {
            this.addSeries(option);
          }
        }
      } else if (options.length < chartSeries.length) {
        for (let i = 0; i < chartSeries.length; i++) {
          let series = chartSeries[i];
          let check = options.filter(
            (option) => option === series.dataFields.valueY
          );
          if (check.length === 0) {
            this.chart.series.removeIndex(i);
          }
        }
      }
    }
  };
  updateGraph = () => {
    if (this.chart) {
      let prevData = this.chart.data;
      let curData = this.props.lineChartData;
      for (let curStat of curData) {
        for (let prevStat of prevData) {
          if (curStat.date === prevStat.date) {
            for (let [key, value] of Object.entries(curStat)) {
              for (let [prevKey, prevValue] of Object.entries(prevStat)) {
                if (key === prevKey && value !== prevValue) {
                  prevStat[key] = value;
                }
              }
            }
            break;
          }
        }
      }
      this.chart.invalidateRawData();
      this.props.lineChartUpdated();
    }
  };
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {this.props.loadingLineChart ? (
          <div className='lds-spinner'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : // <div
        // style={{ width: "100%", height: "100%", background: "#f0f2f5",}}
        // ></div>
        null}
        <div
          id={
            this.props.statDisplayId
              ? this.props.graph_name + '_' + this.props.statDisplayId
              : this.props.graph_name
          }
          style={{ width: '100%', height: '100%' }}
        ></div>
      </div>
    );
  }
}

export default TeamLineChart;
