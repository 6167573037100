import React from 'react';
import {
  Card,
  Button,
  Row,
  Col,
  DatePicker,
  Modal,
  Tooltip,
  Popconfirm
} from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import httpHandler from '../../../helpers/httpHandler';
import GraphViewer from '../graphViewer/GraphViewer';
import TeamInputViewer from './teamInputViewer/TeamInputViewer';
import TeamSelectionElement from '../team/teamSelectionElement/TeamSelectionElement';
import './statInputViewer.css';
import moment from 'moment';
import UserContext from '../../../contexts/UserContext';
import FormViewer from '../forms/FormViewer';

class StatInputViewer extends React.Component {
  static contextType = UserContext;

  constructor() {
    super();
    this.state = {
      selectedDate: moment().format('YYYY-MM-DD'),
      team: null,
      dragging: false,
      chart: 'team-hybrid-bar-chart',
      changedStat: null,
      static: false,
      visible: false,
      selectedForm: null,
      loading: true
    };
  }

  componentDidMount() {
    this.getUserAsTeamMembers();
  }

  getUserAsTeamMembers = () => {
    httpHandler
      .get('/api/st1/customStats/findUserAsMemberOfTeam')
      .then((members) => {
        if (members.length === 0) {
          this.createChallengeTeam();
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  createChallengeTeam = () => {
    // this.setState({loading:false})
    httpHandler
      .post('/api/st1/teamStats/createTeam', {
        teamName:
          this.context.user_bundle.user.first_name +
          ' ' +
          this.context.user_bundle.user.last_name,
        auto_generated: true
      })
      .then((team) => {
        this.setState({ loading: false, modalDisplay: true });
      })
      .catch((error) => {})
      .finally(() => {});
  };

  pickDate = (date, dateString) => {
    if (date) {
      this.setState(
        { selectedDate: moment(dateString).utc().format('YYYY-MM-DD') },
        () => {
          this.getUserFormSubmissions();
        }
      );
    } else {
      this.setState(
        { selectedDate: moment().utc().format('YYYY-MM-DD') },
        () => {}
      );
    }
  };

  getSelectedTeam = (team) => {
    this.setState({ team: team }, () => {
      this.getTeamForms();
      this.getStatFieldOrder();
    });
  };

  getFieldConfig = (config) => {};

  getSelectedStatFields = (fields) => {
    this.setState({ curFieldConfig: fields }, () => {
      this.getAllAssociatedFieldsForTeam();
    });
  };

  onDragStart = () => {
    this.setState({ dragging: true });
  };

  onDragEnd = (result) => {
    let { source, destination } = result;
    if (!destination) return;
    let curFields = [...this.state.curFieldConfig];
    let [insert] = curFields.splice(source.index, 1);
    curFields.splice(destination.index, 0, insert);

    this.setState({ curFieldConfig: curFields, dragging: false }, () =>
      this.updateStatFieldOrder()
    );
  };

  statsUpdated = (stat) => {
    this.setState({ changedStat: stat });
  };
  getTeamForms = () => {
    httpHandler
      .get('/api/st1/financials/getFormsForTeam', {
        teamId: this.state.team.team_id
      })
      .then((forms) => {
        this.setState({ activeForms: forms }, () => {
          this.getUserFormSubmissions();
        });
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getAllAssociatedFieldsForTeam = () => {
    httpHandler
      .get('/api/st1/financials/getAllFormAssociatedFieldsForTeam', {
        teamId: this.state.team.team_id
      })
      .then((fields) => {
        this.setState({ allAssociatedFields: fields }, () => {
          let curFields = [...this.state.curFieldConfig];
          for (let field of curFields) {
            for (let associated of fields) {
              if (field.field_id === associated.field_id) {
                field['form_id'] = associated.form_id;
                field['active'] = associated.active;
              }
            }
          }
          this.setState(
            {
              curFieldConfig: curFields.sort((a, b) => b.field_id - a.field_id)
            },
            () => this.getStatFieldOrder()
          );
        });
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getUserFormSubmissions = (field, operationType) => {
    httpHandler
      .get('/api/st1/financials/getUserFormSubmissions', {
        teamId: this.state.team.team_id,
        date: this.state.selectedDate
      })
      .then((submissions) => {
        this.setState({ formSubmissions: submissions }, () => {});
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getStatFieldOrder = () => {
    httpHandler
      .get('/api/st1/financials/getStatFieldOrder', {
        teamId: this.state.team.team_id,
        location: 'input_chart'
      })
      .then((order) => {
        let fields = [...this.state.curFieldConfig];

        this.setState({ fieldItemOrder: order }, () => {
          if (order) {
            let curOrder = order.list_order.split(',');
            let newOrder = [];
            let rest = [];
            for (let cur of curOrder) {
              for (let field of fields) {
                if (field.field_id === parseInt(cur)) {
                  newOrder.push(field);
                  break;
                }
              }
            }
            for (let field of fields) {
              let found = false;
              for (let temp of newOrder) {
                if (temp.field_id === field.field_id) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                rest.push(field);
              }
            }
            this.setState({ curFieldConfig: [...newOrder, ...rest] });
          }
        });
      })
      .catch((error) => {})
      .finally(() => {});
  };
  updateStatFieldOrder = () => {
    httpHandler
      .post('/api/st1/financials/updateStatFieldOrder', {
        teamId: this.state.team.team_id,
        id: this.state.fieldItemOrder ? this.state.fieldItemOrder.id : null,
        location: 'input_chart',
        order: this.state.curFieldConfig
          ? this.state.curFieldConfig.map((field) => field.field_id).join(',')
          : ''
      })
      .then((result) => {
        this.setState({
          fieldItemOrder: {
            id: this.state.fieldItemOrder
              ? this.state.fieldItemOrder.id
              : result.insertId
          }
        });
      });
  };
  loadSelectedStatFields = () => {
    httpHandler.get('/api/st1/customStats/getSelectedStatFields', {
      teamId: this.state.team.team_id
    });
  };
  getUpdatingField = (field, value, operationType) => {
    if (operationType === 'decrement') {
      this.getUserFormSubmissions();
    }

    let selectedField = this.state.allAssociatedFields.filter(
      (curField) => curField.field_id === field.field_id
    )[0];
    let form = selectedField
      ? this.state.activeForms.filter(
          (form) => form.id === selectedField.form_id
        )[0]
      : null;
    if (form && form.active === 1) {
      this.setState(
        {
          visible: true,
          // formInProgress: true,
          decrementNumber: operationType === 'decrement',
          operationType: operationType,
          operationField: field
        },
        () => {
          this.setState({
            selectedForm: form
          });
        }
      );
    }
  };
  handleDeleteFormSubmission = (submission) => {
    httpHandler
      .post('/api/st1/financials/deleteFormSubmission', {
        teamId: this.state.team.team_id,
        formId: submission.form_id,
        id: submission.submission_id,
        fieldId: submission.field_id,
        date: this.state.selectedDate
      })
      .then((response) => {
        this.setState(
          (prevState) => ({
            formSubmissions: prevState.formSubmissions.filter(
              (sub, index) => sub.submission_id !== submission.submission_id
            ),
            formSubmissionDeleted: true,
            formAssociatedField: this.state.operationField
          }),
          () => {
            this.resetFormOperation();
          }
        );
      });
  };
  resetFormOperation = () => {
    this.setState({
      operationType: null,
      formCompleted: false,
      operationField: null,
      formAssociatedField: null,
      visible: false,
      formSubmissionDeleted: false,
      selectedForm: null,
      transactionAmount: null
    });
  };
  formCompleted = (contact, values, submissionId) => {
    this.resetFormOperation();
    this.setState((prevState) => ({
      formCompleted: true,
      formAssociatedField: this.state.operationField
    }));
  };
  getFormSubmissionList = () => {
    if (this.state.formSubmissions && this.state.formSubmissions.length > 0) {
      return (
        <Row style={{ marginTop: '20px' }}>
          <div style={{ marginBottom: '20px' }}>
            <span style={{ fontSize: '20px', fontFamily: 'Rufina' }}>
              Form Submissions
            </span>
          </div>
          <Row
            style={{
              width: '100%',
              marginBottom: '10px',
              background: '#173757',
              height: '30px',
              color: '#fff',
              fontFamily: 'Rubik',
              lineHeight: '30px'
            }}
          >
            <Col span={6} offset={1}>
              Date
            </Col>
            <Col span={8}>Form Name</Col>
            <Col span={9}>Contact Name</Col>
          </Row>
          {this.state.formSubmissions.map((submission, index) => {
            if (
              this.state.operationField &&
              submission.field_id === this.state.operationField.field_id
            ) {
              return (
                <Row
                  style={{
                    width: '100%',
                    marginBottom: '7px',
                    borderBottom: '1px solid #e1e6eb',
                    fontFamily: 'inter'
                  }}
                  key={submission.submission_id}
                >
                  <Col span={6} offset={1}>
                    {moment(submission.date).format('YYYY-MM-DD')}
                  </Col>
                  <Col span={8}>{submission.form_name}</Col>
                  <Col span={8}>
                    {`${submission.first_name} ${submission.last_name}`}
                  </Col>
                  <Col span={1}>
                    <Popconfirm
                      title='Are you sure to delete this form submission?'
                      onConfirm={() =>
                        this.handleDeleteFormSubmission(submission)
                      }
                    >
                      <Tooltip title='Delete form submission'>
                        <DeleteFilled style={{ color: 'red' }} />
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                </Row>
              );
            } else return null;
          })}
        </Row>
      );
    }
  };
  updateDate = (date) => {
    this.setState({
      selectedDate: date
    });
  };
  render() {
    if (this.state.loading) {
      return <div></div>;
    } else {
      return (
        <div>
          <Modal
            open={this.state.visible}
            onCancel={() =>
              this.setState({
                visible: false,
                decrementNumber: false,
                operationType: null,
                operationField: null
              })
            }
            footer={false}
            destroyOnClose={true}
          >
            {!this.state.decrementNumber ? (
              <FormViewer
                // chosenFields={this.props.chosenFields}
                selectedDate={this.state.selectedDate}
                selectedTeam={this.state.team}
                selectedForm={this.state.selectedForm}
                operationField={this.state.operationField}
                formCompleted={this.formCompleted}
                // transactionSettings={this.state.transactionSettings}
                getTransactionAmount={this.getTransactionAmount}
                transactionAmount={this.state.transactionAmount}
                autoUpdateCommission={this.state.autoUpdateCommission}
                preventAutoUpdateCommission={() =>
                  this.setState({ autoUpdateCommission: false })
                }
              />
            ) : (
              this.getFormSubmissionList()
            )}
          </Modal>
          <Modal
            open={this.state.modalDisplay}
            onCancel={() => this.setState({ modalDisplay: false })}
            footer={false}
          >
            <Card>
              <p
                style={{
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '17px'
                }}
              >
                Seems like you don't belong to a team so we have created a team
                for you. You can click on Team Management on the nav bar to
                manage your team.
              </p>
            </Card>
          </Modal>
          <div className='input-stats-screen'>
            <div className='title-header'>
              <h1
                style={{
                  color: '#524e4e',
                  marginRight: '1em',
                  marginBottom: '0'
                }}
              >
                Stat Tracker
              </h1>

              <TeamSelectionElement getSelectedTeam={this.getSelectedTeam} />

              <DatePicker
                onChange={this.pickDate}
                placeholder={this.state.selectedDate}
                disabled={this.state.static}
              />
              <Button
                href={'https://' + window.location.host + '/st1/statDashboard'}
                className='dashboard-button'
              >
                Big Screen View
              </Button>
            </div>
            <div className='underHeader'>
              <div className='chart-view'>
                <GraphViewer
                  graph_name={'input_chart'}
                  selectedChart={null}
                  selectedDate={this.state.selectedDate}
                  getTeamToDisplay={this.getTeamToDisplay}
                  getFieldConfig={this.getFieldConfig}
                  initialized={() => this.setState({ chart: null })}
                  statsUpdated={this.statsUpdated}
                  enableCalendar={() => this.setState({ static: false })}
                  disableCalendar={() => this.setState({ static: true })}
                  getSelectedStatFields={this.getSelectedStatFields}
                  selectedTeam={this.state.team}
                  updateDate={(date) => this.updateDate(date)}
                />
              </div>
              <div className='input-viewer cart-scroll input-fields'>
                <TeamInputViewer
                  selectedDate={this.state.selectedDate}
                  team={this.state.team}
                  curFieldConfig={this.state.curFieldConfig}
                  onDragEnd={this.onDragEnd}
                  onDragStart={this.onDragStart}
                  dragging={this.state.dragging}
                  changedStat={this.state.changedStat}
                  finishStatsUpdate={() => this.setState({ changedStat: null })}
                  getUpdatingField={this.getUpdatingField}
                  operationType={this.state.operationType}
                  formAssociatedField={this.state.formAssociatedField}
                  resetFormOperation={this.resetFormOperation}
                  formCompleted={this.state.formCompleted}
                  formSubmissionDeleted={this.state.formSubmissionDeleted}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default StatInputViewer;
