import { AnimatePresence } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { NavItem } from '../NavBar';
import classes from './DesktopNavItems.module.scss';
import DesktopSubItems from './desktopSubItems/DesktopSubItems';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../../../contexts/UserContext';

type Props = {
  items: NavItem[];
  ruleAccess: { [id: number]: boolean };
  editMode: boolean;
};

export type Permission = {
  admin_role_id: number;
  name: string;
};

const DesktopNavItems = ({ items, ruleAccess, editMode }: Props) => {
  const navigate = useNavigate();

  const handleNavigate = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    url: string,
    urlType: string
  ) => {
    if (urlType === 'external') {
      window.open(url, '_blank');
    } else {
      e.stopPropagation();
      navigate(url);
    }
  };

  const [hoveredItem, setHoveredItem] = useState<NavItem>();
  const { user_bundle } = useContext(UserContext);

  const isAdmin = () => {
    const permissions: Permission[] = Object.values(user_bundle.permissions);
    const administrator = permissions.find(
      (permission) => (permission.name = 'Administrator')
    );
    return administrator ? true : false;
  };

  const hasPermission = (item: NavItem) => {
    if (isAdmin()) return true;
    if (editMode) return true;
    if (!ruleAccess) return false;
    if (!item.rule_set) return true;
    if (item.rule_set === -1) return true;
    return ruleAccess[item.rule_set];
  };

  return (
    <div className={classes.container}>
      {items.map((item, index) => {
        return (
          hasPermission(item) && (
            <div
              key={index}
              onMouseEnter={() => {
                if (hoveredItem !== item) setHoveredItem(item);
              }}
              onMouseLeave={() => {
                if (hoveredItem === item) setHoveredItem(undefined);
              }}
              className={classes.container__item}
              onClick={(e) => handleNavigate(e, item.url, item.url_type)}
            >
              <AnimatePresence>
                {hoveredItem === item &&
                  item.dropDownItems &&
                  item.dropDownItems.length > 0 && (
                    <DesktopSubItems
                      item={item}
                      handleNavigate={handleNavigate}
                      ruleAccess={ruleAccess}
                      editMode={editMode}
                    />
                  )}
              </AnimatePresence>
              <div className={classes.item__label}>
                <span>{item.text}</span>
                {item.dropDownItems.length > 0 && <FaAngleDown />}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default DesktopNavItems;
