import React from 'react';
import { Select } from 'antd';
import httpHandler from '../../../../helpers/httpHandler';

const { Option, OptGroup } = Select;
class TeamSelectionElement extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.loadMyTeams();
  }

  loadMyTeams = () => {
    httpHandler
      .get('/api/st1/teamStats/getMemberTeams')
      .then((memberTeams) => {
        this.setState({ memberTeams: memberTeams });
        if (memberTeams.length > 0) {
          this.setState({ selectedTeam: memberTeams[0] }, () => {});
          if (this.props.getSelectedTeam) {
            this.props.getSelectedTeam(memberTeams[0]);
            this.fetchUserData(memberTeams[0].team_id, this.props.roleIds);
          }
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };
  getTeamOptions = () => {
    return (
      <React.Fragment>
        <OptGroup label='Team'>{this.getTeamSelections()}</OptGroup>
        <OptGroup label='Brokerage' style={{ fontSize: '50px' }}>
          {this.getBrokerageSelections()}
        </OptGroup>
      </React.Fragment>
    );
  };
  getTeamSelections = () => {
    let options = [];
    this.state.memberTeams.forEach((team, index) => {
      if (team.type === 'Team') {
        options.push(
          <Option value={team.team_id} key={index}>
            {team.team_name + ' - ' + team.first_name + ' ' + team.last_name}
          </Option>
        );
      }
    });
    return options;
  };
  getBrokerageSelections = () => {
    let options = [];
    this.state.memberTeams.forEach((team, index) => {
      if (team.type === 'Brokerage') {
        options.push(
          <Option value={team.team_id} key={index + 100}>
            {team.team_name + ' - ' + team.first_name + ' ' + team.last_name}
          </Option>
        );
      }
    });
    return options;
  };
  handleSelectTeam = (value) => {
    // value is the team id
    this.setState(
      {
        selectedTeam: this.state.memberTeams.filter(
          (team) => team.team_id === value
        )[0]
      },
      () => {
        this.props.getSelectedTeam(this.state.selectedTeam);
        this.fetchUserData(this.state.selectedTeam.team_id, this.props.roleIds);
      }
    );
  };

  // two props must be valid
  fetchUserData = (teamId, roleIds) => {
    if (this.props.getTeamMembersData) {
      httpHandler
        .post(`/api/st1/teamStats/getTeamMembersDataByTeamId/${teamId}`, {
          roleIds
        })
        .then((res) => {
          if (this.props.onUsersFetched) {
            this.props.onUsersFetched(res);
          }
        });
    }
  };

  render() {
    return (
      // <div style={{marginRight: "30px", lineHeight: "70px",}}>
      <Select
        style={{
          width: '245px',
          height: this.props.height ? this.props.height : null
        }}
        placeholder={'No team'}
        value={
          this.state.selectedTeam
            ? this.state.selectedTeam.team_name +
              ' - ' +
              this.state.selectedTeam.first_name +
              ' ' +
              this.state.selectedTeam.last_name
            : null
        }
        onChange={this.handleSelectTeam}
      >
        {this.state.memberTeams ? this.getTeamOptions() : null}
      </Select>
      // </div>
    );
  }
}

export default TeamSelectionElement;
