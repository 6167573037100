import { postRequest } from './httpHandler2';

//public key for web-push api - generated by npm package web-push with cmd npx web-push generate-vapid-keys
const publicVapidKey =
  'BNWh8ocQ2EQTHN1bAKkZ_vS8-wTj2PuzIVnpTry7lreYXCk2TSZn7WTXWkC5vFl7SjJOG0wrsv3UlSFvRuHfmVs';

// Copied from the web-push documentation
const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const subscribeToPushNotifications = async () => {
  if (!('serviceWorker' in navigator)) return;
  try {
    const registration = await navigator.serviceWorker.ready;
    // Subscribe to push notifications
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    });

    await postRequest('/api/pushNotifications/registration', subscription);
  } catch (error) {
    console.error('Failed to subscribe to push notifications', error);
  }
};

export { subscribeToPushNotifications };
