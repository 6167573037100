import { DeleteFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";

const Prize = ({ prize, editing, handleDelete }) => {
  return (
    <Row align="middle">
      <Col span={editing ? 6 : 8}>
        {prize.image_url && (
          <img
            src={prize.image_url}
            alt="prize"
            style={{ width: "90px", padding: "5px" }}
          />
        )}
      </Col>
      <Col span={editing ? 14 : 16}>
        <p>
          <strong>
            {prize.rank === 1
              ? "1st Place: "
              : prize.rank === 2
              ? "2nd Place: "
              : prize.rank === 3
              ? "3rd Place: "
              : `${prize.rank}th Place: `}
          </strong>{" "}
          {prize.description}
        </p>
      </Col>
      {editing && (
        <Col span={4}>
          <DeleteFilled
            style={{ marginLeft: "15px", marginBottom: "15px" }}
            onClick={() => handleDelete(prize)}
          />
        </Col>
      )}
    </Row>
  );
};

export default Prize;
