import httpHandler from '../../helpers/httpHandler';
import { Contact } from '../../types/statTracker';
import { ContactFormField, ContactInfoValues } from '../../types/form';
import { processContacts } from '../../helpers/processContacts/processContacts';
import { getRequest } from '../../helpers/httpHandler2';

const getContactFormFields = async () => {
  const contactFormFields: Promise<ContactFormField[]> = await httpHandler.get(
    `/api/contacts/contact-fields`
  );
  return contactFormFields;
};

const getContacts = async (teamId: number) => {
  const contacts: Contact[] = await httpHandler.get(`/api/contacts`, {
    teamId
  });
  const processed = processContacts(contacts);
  return processed;
};

const getContact = async (id: string) => {
  const contact: Contact[] = await getRequest(`/api/contacts/${id}`);
  // process the contacts into the ProcessedContactValue model
  const processed = processContacts(contact);
  return processed;
};

const postContact = async (teamId: number, contactInfo: ContactInfoValues) => {
  try {
    const response = await httpHandler.post(`/api/contacts/`, {
      teamId,
      contactInfo: contactInfo
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
// this route was added to migrate user's contacts from ContactInfo
// it is currently not implemented
const migrateContacts = async (teamId: number) => {
  try {
    const response = await httpHandler.post('/api/contacts/bulk', {
      teamId
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const contactRoutes = {
  getContactFormFields,
  getContacts,
  getContact,
  postContact,
  migrateContacts
};
