import React from 'react';
import { Row } from 'antd';
import BackgroundVideo from '../backgroundVideo/BackgroundVideo';
import ColumnViewer from './ColumnViewer';

class RowViewer extends React.Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Row
          type='flex'
          justify='center'
          align={this.props.sectionRow.details.verticalAlignment}
          style={this.props.sectionRow.styles}
        >
          {this.props.sectionRow.details.backgroundVideo ? (
            <BackgroundVideo
              sourceType={this.props.sectionRow.details.sourceType}
              source={this.props.sectionRow.details.source}
            />
          ) : (
            ''
          )}
          {this.props.sectionRow.rowColumns.map((rowColumn, index) => {
            return (
              <ColumnViewer
                key={index}
                pageHistory={this.props.pageHistory}
                rowColumn={rowColumn}
                handlePopup={this.props.handlePopup}
                ruleAccess={this.props.ruleAccess}
              />
            );
          })}
        </Row>
      </div>
    );
  }
}

export default RowViewer;
