import React from 'react';
import FormItemCard from './formItemCard/FormItemCard';
import httpHandler from '../../../../helpers/httpHandler';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import './teamInputViewer.css';
import socketHandler from '../../../../services/Socket';
import UserContext from '../../../../contexts/UserContext';

class TeamInputViewer extends React.Component {
  static contextType = UserContext;
  constructor() {
    super();
    this.state = {
      hourCalls: 0,
      followUpCalls: 0,
      contacts: 0,
      appointmentSets: 0,
      contractsWritten: 0,
      numberClosed: 0,
      commissionDollars: 0,
      newLeadCalls: 0,
      recordFound: false,
      addedToFacebook: 0
    };
  }

  componentDidMount() {
    if (this.props.team) {
      this.getUserStats();
    }
    socketHandler.getSocket().on('statUpdate', (data) => {
      let { stat } = data;
      if (
        stat.team_id === this.props.team.team_id &&
        this.context.user_bundle.user.id === stat.user_id &&
        stat.date === this.props.selectedDate
      ) {
        this.updateUserStats(stat);
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.getUserStats();
    }
    if (this.props.team !== prevProps.team) {
      this.getUserStats();
    }
    if (this.props.changedStat) {
      this.checkStatChange();
    }
  }

  getUserStats = () => {
    httpHandler
      .get('/api/st1/customStats/getUserStats', {
        startDate: this.props.selectedDate,
        endDate: this.props.selectedDate,
        teamId: this.props.team.team_id
      })
      .then((userStats) => {
        this.setState({ userStats: userStats });
      })
      .catch((error) => {})
      .finally(() => {
        this.props.finishStatsUpdate();
      });
  };

  checkStatChange = () => {
    let { user_id, field, value, date } = this.props.changedStat;
    if (
      this.props.selectedDate === date &&
      user_id === this.state.currentUserId
    ) {
      if (field === 'number_of_appointments_set') {
        if (this.state.appointmentSets !== value) {
          this.getUserStats();
        }
      } else if (field === 'number_of_hours_doing_calls') {
        if (this.state.hourCalls !== value) {
          this.getUserStats();
        }
      } else if (field === 'number_of_follow_up_calls') {
        if (this.state.followUpCalls !== value) {
          this.getUserStats();
        }
      } else if (field === 'number_of_contacts') {
        if (this.state.contacts !== value) {
          this.getUserStats();
        }
      } else if (field === 'number_of_contracts_written') {
        if (this.state.contractsWritten !== value) {
          this.getUserStats();
        }
      } else if (field === 'number_closed') {
        if (this.state.numberClosed !== value) {
          this.getUserStats();
        }
      } else if (field === 'commission_dollars_received') {
        if (this.state.commissionDollars !== value) {
          this.getUserStats();
        }
      } else if (field === 'number_of_new_lead_calls') {
        if (this.state.newLeadCalls !== value) {
          this.getUserStats();
        }
      } else if (field === 'added_to_facebook_group') {
        if (this.state.addedToFacebook !== value) {
          this.getUserStats();
        }
      }
    }
  };

  setRecord = (field, value) => {
    this.recordStats(field, value);
  };
  recordStats = (field, value) => {
    let { field_id } = field;
    if (true) {
      httpHandler
        .post('/api/st1/customStats/oldRecordStats', {
          statValue: value,
          date: this.props.selectedDate,
          teamId: this.props.team.team_id,
          fieldId: field_id,
          teamLeader: this.props.team
        })
        .then((result) => {
          let stats = [...this.state.userStats];
          let fieldForChange = stats.filter(
            (stat) => stat.field_id === field_id
          );
          if (fieldForChange.length > 0) {
            for (let stat of stats) {
              if (stat.field_id === field_id) {
                stat.stat_value = value;
                break;
              }
            }
            this.setState({ userStats: stats }, () =>
              this.props.resetFormOperation()
            );
          } else {
            this.setState(
              (prevState) => ({
                userStats: [
                  ...prevState.userStats,
                  {
                    field_id: field_id,
                    stat_value: value,
                    date: this.props.selectedDate,
                    team_id: this.props.team.team_id
                  }
                ]
              }),
              () => this.props.resetFormOperation()
            );
          }
        })
        .catch((error) => {})
        .finally(() => {});
    }
  };
  updateUserStats = (newStat) => {
    let stats = [...this.state.userStats];
    let found = false;
    for (let stat of stats) {
      if (stat.field_id === newStat.field_id) {
        found = true;
        stat.stat_value = newStat.value;
        break;
      }
    }
    if (!found) {
      this.setState((prevState) => ({
        userStats: [
          ...prevState.userStats,
          { field_id: newStat.field_id, stat_value: newStat.value }
        ]
      }));
    } else {
      this.setState({ userStats: stats });
    }
  };
  incrementRecord = (field, value) => {
    this.recordStats(field, value + 1);
  };

  decrementRecord = (field, value) => {
    if (value - 1 < 0 || Number.isNaN(value)) {
      value = 0;
    } else {
      value--;
    }
    this.recordStats(field, value);
  };

  onDragStart = () => {
    this.props.onDragStart();
  };

  onDragEnd = (result) => {
    this.props.onDragEnd(result);
  };

  render() {
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
      >
        <Droppable droppableId='stats-input-area'>
          {(provided) => (
            <div
              className='cart-scroll'
              style={{ height: '80vh', overflow: 'auto' }}
            >
              <div
                className='inputs-form stats-input-area'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {this.props.curFieldConfig &&
                this.props.curFieldConfig.length > 0
                  ? this.props.curFieldConfig.map((config, index) => {
                      return (
                        <FormItemCard
                          key={config.field_id}
                          index={index}
                          title={config.field_name}
                          field={config}
                          setRecord={this.setRecord}
                          activityValue={
                            this.state.userStats &&
                            this.state.userStats.filter(
                              (stat) => stat.field_id === config.field_id
                            ).length > 0
                              ? this.state.userStats.filter(
                                  (stat) => stat.field_id === config.field_id
                                )[0].stat_value
                              : 0
                          }
                          incrementRecord={this.incrementRecord}
                          decrementRecord={this.decrementRecord}
                          getUpdatingField={this.props.getUpdatingField}
                          formAssociatedField={this.props.formAssociatedField}
                          operationType={this.props.operationType}
                          formCompleted={this.props.formCompleted}
                          formSubmissionDeleted={
                            this.props.formSubmissionDeleted
                          }
                        />
                      );
                    })
                  : null}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default TeamInputViewer;
