import httpHandler from '../../helpers/httpHandler';
import { WidgetLists } from '../../types/board';

const initialize = async (dashboardId: number): Promise<WidgetLists> => {
  try {
    const data = await httpHandler.get(
      '/api/board/widgetLists/getWidgetListItems',
      { dashboardId }
    );
    return data;
  } catch (error) {
    console.error(`error initializing Widget Lists`, error);
    return {};
  }
};

const widgetListApi = {
  initialize
};
export default widgetListApi;
