import { Avatar, Card } from 'antd';
import React from 'react';
import UserContext from '../../../../contexts/UserContext';

class MemberViewer extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super();
    this.state = {
      member: null,
      tagNames: null
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({ member: this.props.location.state.cwu_profile });
    }
  }

  render() {
    if (this.state.member) {
      return (
        <div style={{ width: '100%', minHeight: '100vh' }}>
          <Card
            style={{
              width: '80vw',
              minHeight: '90vh',
              margin: '10px auto 0 auto'
            }}
          >
            <div style={{ width: '200px' }}>
              <Avatar
                size={200}
                shape='square'
                src={`${this.state.member.profile_pic_url}`}
              />
              <h1
                style={{ marginTop: '5px', width: '100%', textAlign: 'center' }}
              >
                {`${this.state.member.first_name} ${this.state.member.last_name}`}{' '}
              </h1>
            </div>
          </Card>
        </div>
      );
    } else {
      return <h1>No member to display</h1>;
    }
  }
}

export default MemberViewer;
