import { GridStack } from 'gridstack';

export const setUpDragInHelper = (myClone) => {
  GridStack.setupDragIn('.sub-div .grid-stack-item ', {
    helper: 'clone'
  });
};

const getUrlPathParams = (url) => {
  const isTeamBoard = url.includes('teams') || url.includes('team');
  const isCoachingBoard = url.includes('coaching');
  const isPublicBoard = url.includes('public');
  return { isTeamBoard, isCoachingBoard, isPublicBoard };
};

const compactGrid = (gridRef) => {
  gridRef.current.compact && gridRef.current.compact();
};

const handleArrangeAndResize = (items) => {
  const maxColumns = 12;
  const width = 2;
  const height = 6;
  const maxWidgetsPerRow = maxColumns / width;

  const modifiedItems = items.map((item, index) => {
    const widgetRow = Math.floor(index / maxWidgetsPerRow);
    const gridRow = widgetRow * height;
    const widgetColumn = index % maxWidgetsPerRow;
    const gridColumn = widgetColumn * width;

    return {
      ...item,
      w: width,
      h: height,
      x: gridColumn,
      y: gridRow
    };
  });
  return modifiedItems;
};

const boardHelpers = {
  setUpDragInHelper,
  getUrlPathParams,
  compactGrid,
  handleArrangeAndResize
};

export default boardHelpers;
