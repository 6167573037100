import React from 'react';
import { Col } from 'antd';
import BackgroundVideo from '../backgroundVideo/BackgroundVideo';
import ElementViewer from './ElementViewer';

class ColumnViewer extends React.Component {
  getWidth(size) {
    let divisions = this.props.rowColumn.divisions;
    switch (size) {
      case 'xs':
        return 24;
      case 'sm':
        if (divisions <= 3) return 24;
        else return 24;
      case 'md':
        if (divisions === 1) return 24;
        else if (divisions <= 3) return 24;
        else if (divisions <= 4) return 12;
        else return 6;
      default:
        if (divisions === 5) return 4;
        return this.props.rowColumn.widthSetting;
    }
  }
  render() {
    return (
      <Col
        style={{ ...this.props.rowColumn.styles, fontSize: 0 }}
        className={this.props.renderHandle ? 'columnDivider' : ''}
        xs={this.getWidth('xs')}
        sm={this.getWidth('sm')}
        md={this.getWidth('md')}
        lg={this.getWidth('lg')}
        xl={this.getWidth('xl')}
      >
        {this.props.rowColumn.details.backgroundVideo ? (
          <BackgroundVideo
            sourceType={this.props.rowColumn.details.sourceType}
            source={this.props.rowColumn.details.source}
          />
        ) : (
          ''
        )}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0
          }}
        ></div>
        {this.props.rowColumn.elements.map((element, index) => {
          return (
            <ElementViewer
              key={index}
              pageHistory={this.props.pageHistory}
              element={element}
              handlePopup={this.props.handlePopup}
              ruleAccess={this.props.ruleAccess}
            />
          );
        })}
      </Col>
    );
  }
}

export default ColumnViewer;
