import React from 'react';
import { Progress } from 'antd';

const SingleStatDisplay = (props) => {
  const getDisplay = () => {
    if (props.statsForDatesTotal && props.statsForDatesTotal.length > 0) {
      let name = '';
      let nameDisplay;
      if (props.selectedSingleStatDataPoint) {
        if (props.selectedSingleStatDataPoint.split('~~').length >= 2) {
          name = props.selectedSingleStatDataPoint.split('~~')[0];
        } else if (props.selectedSingleStatDataPoint.split('_').length >= 3) {
          let names = props.selectedSingleStatDataPoint.split('_');
          name = `${names[0]} (${names[names.length - 1]})`;
          nameDisplay = `${names[0]} ${names[1]}`;
        }
      }
      return props.statsForDatesTotal.map((stat, index) => {
        if (stat.first_name === name) {
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                color: '#173757'
              }}
              key={'single-display_' + stat.user_id}
            >
              {getDataDisplayTitle()}
              <div
                style={{
                  position: 'absolute',
                  bottom: '25%',
                  textAlign: 'center',
                  width: '100%',
                  zIndex: 1,
                  fontFamily: 'Inter',
                  textTransform: 'uppercase'
                }}
              >
                {nameDisplay ? nameDisplay : stat.first_name}
              </div>
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  top: 77,
                  fontFamily: 'Inter',
                  textTransform: 'uppercase'
                }}
              >
                {/* <h4>{stat.first_name}</h4> */}
                <div>
                  <div
                    style={{
                      color: '#173757',
                      textAlign: 'center',
                      margin: '0 auto'
                    }}
                  >
                    Goal: {props.statGoal}
                  </div>
                </div>
              </div>

              <div
                style={{
                  margin: '15px auto 0 auto',
                  width: '250px',
                  position: 'relative'
                }}
                key={`single-stat` + index}
              >
                <div
                  style={{
                    color: '#173757',
                    textAlign: 'center',
                    margin: '0 auto',
                    fontSize: '70px',
                    background: props.isWidget ? '#e8e8e8' : '#fff',
                    width: '60%',
                    height: '40%',
                    zIndex: 2,
                    position: 'absolute',
                    top: '25%',
                    left: '20%',
                    userSelect: 'none'
                  }}
                >
                  {stat[props.dataDisplay]}
                </div>
                <Progress
                  type='circle'
                  strokeColor='#9bd642'
                  percent={Math.floor(
                    (stat[props.dataDisplay] / props.statGoal) * 100
                  )}
                  width={250}
                  strokeWidth={8}
                  strokeLinecap='square'
                  style={{ fontSize: '60px' }}
                ></Progress>
              </div>

              <div
                style={{
                  marginLeft: '7px',
                  fontFamily: 'Rubik',
                  textTransform: 'uppercase',
                  color: '#314a5e'
                }}
              >
                {getTitle()}
              </div>
            </div>
          );
        } else {
          return <React.Fragment key={index}></React.Fragment>;
        }
      });
    } else {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className='lds-spinner'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
  };
  const getDataDisplayTitle = () => {
    let display = props.dataDisplay;
    display = display
      .split('_')
      .slice(0, display.split('_').length - 1)
      .join(' ');
    return (
      <h4
        style={{
          textAlign: 'center',
          textTransform: 'uppercase',
          fontFamily: 'Rubik'
        }}
      >
        {display}
      </h4>
    );
  };
  const getTitle = () => {
    let title = '';
    if (props.datesTotalDisplay === 'rolling_dates') {
      title += 'Rolling dates - ';
      title += props.rollingValue + ' ' + props.rollingUnit;
    } else if (props.datesTotalDisplay === 'previous_dates') {
      title +=
        props.radioOption.split('_')[0].charAt(0).toUpperCase() +
        props.radioOption.split('_')[0].slice(1) +
        ' ' +
        props.radioOption.split('_')[1];
    } else if (props.datesTotalDisplay === 'static_dates') {
      // title += "Static dates - ";
      if (props.staticStart) {
        title += props.staticStart + ' ~ ' + props.staticEnd;
      } else {
        title +=
          props.dates[props.dates.length - 1] + ' ~ ' + props.selectedDate;
      }
    } else if (props.datesTotalDisplay === 'current_dates') {
      title +=
        props.curRadioOption.split('_')[0].charAt(0).toUpperCase() +
        props.curRadioOption.split('_')[0].slice(1) +
        ' ' +
        props.curRadioOption.split('_')[1];
    }
    return title;
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {/* <h4 style={{textAlign: "center", fontFamily: "Rubik", textTransform: "uppercase", color: "#314a5e"}}>{getTitle()}</h4> */}
      {/* {getDataDisplayTitle()} */}
      {getDisplay()}
    </div>
  );
};

export default SingleStatDisplay;
