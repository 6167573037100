import { getRequest } from '../../helpers/httpHandler2';
import { TransactionField } from '../../types/statTracker';
import { GetFn, SetFn, TransactionSlice } from '../../types/zustandTypes';

export const createTransactionSlice = (
  set: SetFn,
  get: GetFn
): TransactionSlice => ({
  transactionFields: [],
  selectFieldOptions: [],
  transactionApi: {
    fetchTransactionFields: async () => {
      let allFieldsData = await getRequest('/api/transactions/fields');
      allFieldsData.sort((a: TransactionField, b: TransactionField) =>
        a.name > b.name ? 1 : -1
      );
      set({
        transactionFields: allFieldsData
      });
    },
    fetchSelectFieldOptions: async () => {
      let selectFieldOptions = await getRequest(
        '/api/transactions/fields/selectOptions'
      );
      set((state) => {
        state.selectFieldOptions = selectFieldOptions;
      });
    }
  }
});
