import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import DeepCopy from '../../../../helpers/DeepCopy';
import { Button, Row, Select } from 'antd';
import { Option } from 'antd/lib/mentions';

const BarChartLeaderboard = ({
  users_stats,
  fieldOrCategory,
  grouping,
  teams
}) => {
  const [sortedUsers, setSortedUsers] = useState([]);

  const colors = {
    blue: { color: 'blue', min: '#6aaaff', max: '#00357a' },
    green: { color: 'green', min: '#58d32f', max: '#218300' },
    red: { color: 'red', min: '#df4646', max: '#610000' }
  };
  const [selectedColor, setSelectedColor] = useState(colors.blue);

  //Pagination Initialization
  const [page, setPage] = useState(1);
  const perPage = 15;
  const indexOfLastUser = page * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users_stats.length / perPage); i++) {
    pageNumbers.push(i);
  }
  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    if (users_stats.length > 0) {
      const usersCopy = DeepCopy.deepCopy(users_stats);
      setSortedUsers(
        usersCopy
          .sort((a, b) => (a.total > b.total ? -1 : 1))
          .map((user) => ({
            ...user,
            first_name:
              grouping === 'allTeams'
                ? `${user.team_name}`
                : `${user.first_name} ${user.last_name}~~${user.id}`
          }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users_stats]);

  useEffect(() => {
    const initChart = () => {
      let chart = am4core.create('chartdiv', am4charts.XYChart);
      chart.hiddenState.properties.opacity = 1; // this creates initial fade-in
      chart.paddingRight = 40;

      chart.data = currentUsers.sort((a, b) => (a.total > b.total ? 1 : -1));
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'first_name';
      // categoryAxis.title.text = "Name";
      categoryAxis.renderer.labels.template.adapter.add(
        'textOutput',
        function (text) {
          return text.split('~~')[0] || text;
        }
      );
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.minGridDistance = 10;
      categoryAxis.renderer.labels.template.dx = -40;
      categoryAxis.renderer.minWidth = 220;
      categoryAxis.renderer.tooltip.dx = -20;
      categoryAxis.tooltip.disabled = true;

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = `${fieldOrCategory}`;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.fillOpacity = 0.3;
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.max = sortedUsers[0].total + 5;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.baseGrid.strokeOpacity = 0;
      valueAxis.renderer.labels.template.dy = 20;

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = 'total';
      series.dataFields.categoryY = 'first_name';
      // series.tooltipText = "{valueX}";
      // series.tooltip.pointerOrientation = "vertical";
      // series.tooltip.dy = -30;
      series.columnsContainer.zIndex = 100;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.fontSize = 24;
      valueLabel.label.valueX = 'total';
      valueLabel.label.text = '{valueX}';
      valueLabel.label.horizontalCenter = 'left';
      valueLabel.label.dx = 55;

      let columnTemplate = series.columns.template;
      columnTemplate.height = am4core.percent(50);
      columnTemplate.maxHeight = 50;
      columnTemplate.column.cornerRadius(60, 10, 60, 10);
      columnTemplate.strokeOpacity = 0;

      series.showOnInit = false;
      series.heatRules.push({
        target: columnTemplate,
        property: 'fill',
        dataField: 'valueX',
        min: am4core.color(selectedColor.min),
        max: am4core.color(selectedColor.max)
      });
      series.mainContainer.mask = undefined;

      let cursor = new am4charts.XYCursor();
      chart.cursor = cursor;
      cursor.lineX.disabled = true;
      cursor.lineY.disabled = true;
      cursor.behavior = 'none';

      let bullet = columnTemplate.createChild(am4charts.CircleBullet);
      bullet.circle.radius = 30;
      bullet.valign = 'middle';
      bullet.align = 'right';
      bullet.isMeasured = true;
      bullet.interactionsEnabled = false;
      bullet.horizontalCenter = 'right';
      bullet.interactionsEnabled = false;

      // let hoverState = bullet.states.create("hover");
      let outlineCircle = bullet.createChild(am4core.Circle);
      outlineCircle.adapter.add('radius', function (radius, target) {
        let circleBullet = target.parent;
        return circleBullet.circle.pixelRadius + 10;
      });

      let image = bullet.createChild(am4core.Image);
      image.width = 60;
      image.height = 60;
      image.horizontalCenter = 'middle';
      image.verticalCenter = 'middle';
      image.propertyFields.href = 'profile_pic_url';

      image.adapter.add('mask', function (mask, target) {
        let circleBullet = target.parent;
        return circleBullet.circle;
      });
    };

    sortedUsers.length > 0 && initChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUsers, page]);

  const nextPage = () => {
    setPage((prev) => prev + 1);
  };

  const lastPage = () => {
    setPage((prev) => prev - 1);
  };

  return (
    <div>
      {users_stats && (
        <div>
          <Row
            type='flex'
            justify='center'
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            <label style={{ marginRight: '10px' }}>
              <strong>Theme: </strong>
            </label>
            <Select
              value={selectedColor.color}
              onChange={(value) => setSelectedColor(colors[value])}
            >
              <Option value={'blue'}>Blue</Option>
              <Option value={'green'}>Green</Option>
              <Option value={'red'}>Red</Option>
            </Select>
          </Row>
          <div
            style={{
              width: '100%',
              marginTop: '15px',
              height: currentUsers.length * 80 + 100 + 'px',
              minHeight: '60vh'
            }}
            className='chartdiv'
          ></div>
          <Row type='flex' justify='center'>
            <Button
              type='primary'
              disabled={page <= 1}
              onClick={lastPage}
              style={{ marginRight: '10px', marginBottom: '20px' }}
            >
              Previous Page
            </Button>
            <Button
              type='primary'
              disabled={page >= pageNumbers.length}
              onClick={nextPage}
              style={{ marginLeft: '10px', marginBottom: '20px' }}
            >
              Next Page
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default BarChartLeaderboard;
