import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  lazy,
  Suspense
} from 'react';
import moment from 'moment';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import BrowserInfo from '../../helpers/BrowserInfo';
import { getRequest, postRequest } from '../../helpers/httpHandler2';
import { message } from 'antd';
import 'react-quill/dist/quill.snow.css';
import './App.css';

import FullScreenLoading from '../reusableComponents/standard/fullScreenLoading/FullScreenLoading';
import SubscriptionProgress from './subscriptionProgress/SubscriptionProgress';

import AdminRoutes from './adminNav/AdminRoutes';
import { ShoppingProvider } from '../../contexts/ShoppingCartContext';
import { VideoProvider } from '../../contexts/VideoCallContext';
import UserContext from '../../contexts/UserContext';
import HelpChat from '../chat';
import ReactGA from 'react-ga'; // For Google Analytics
import PageViewer from '../pageBuilder/viewer/PageViewer';
import { subscribeToPushNotifications } from '../../helpers/WebPushHelpers';
import ST1Routes from './st1Routes/ST1Routes';
import Modal from '../reusableComponents/standard/modal/Modal';
import { AnimatePresence, motion } from 'framer-motion';
import popupStyles from './PopupStyles.module.scss';
import { IoCloseSharp } from 'react-icons/io5';

const TeamRoutes = lazy(() => import('../team/TeamRoutes'));
const NavResizeAnimator = lazy(
  () => import('./navResizeAnimator/NavResizeAnimator')
);
const AccountCompletion = lazy(
  () => import('../onboarding/setupModal/AccountCompletion')
);
const SetCompanyIFSToken = lazy(() => import('../company/SetCompanyIFSToken'));
const TeamVerificationForm = lazy(
  () => import('../company/TeamVerificationForm')
);
const AuthGrant = lazy(() => import('../oauth2/oauthGrant/AuthGrant'));
const DevPortal = lazy(() => import('../devPortal/DevPortal'));
const ShoppingCartViewer = lazy(() => import('../commerce/ShoppingCartViewer'));
const VendorReviews = lazy(
  () => import('../review/vendorReview/VendorReviews')
);
const CheckoutViewer = lazy(() => import('../commerce/CheckoutViewer'));
const MemberLocator = lazy(() => import('../memberLocator/MemberLocator'));
const UserAccountSettings = lazy(
  () =>
    import(
      '../pageBuilder/elements/navBar/userAccountSettings/UserAccountSettings'
    )
);
const CoachDashboard = lazy(
  () => import('../oneOnOneCoaching/coachDashboard/CoachDashboard')
);
const AdminNav = lazy(() => import('./adminNav/AdminNav'));
const NoteDisplay = lazy(
  () => import('../systemAdmin/pageOptions/notes/NoteDisplay')
);
const StatTrackerContainer = lazy(
  () => import('../statTracker2/StatTrackerContainer')
);
const BoardsRoutes = lazy(() => import('./boardsRoutes/BoardsRoutes'));

const CourseRoutes = lazy(() => import('./courseRoutes/CourseRoutes'));
const AccountMatching = lazy(() => import('./accountMatching/AccountMatching'));

//-----------------------------------------------------------------------------------------------------------------
// Beginning of Google Analytics Integration
const trackingId = 'UA-56543811-2'; // Pulls our Google tracking ID
const trackingId2 = 'UA-188203968-1';
ReactGA.initialize([{ trackingId: trackingId }, { trackingId: trackingId2 }]);
ReactGA.pageview(window.location.pathname + window.location.search);
// End of Google Analytics Integration
//---------------------------------------------------------------------------------------------------------------

export type AuthResult = {
  crmContacts: {
    emailContacts: CRMContact[];
    nameContacts: CRMContact[];
  };
  memoryCode: string;
  next: string;
  token?: string;
};

export type CRMContact = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

type Note = {
  id: number;
  summary: string;
  details: string;
  dateTime: string;
  type: string;
};

const App = () => {
  const { user_bundle, requestUserBundleUpdate } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [adminNavOpen, setAdminNavOpen] = useState<boolean>(false);
  const [popupId, setPopupId] = useState<number | null>();
  const [accountMatchingDetails, setAccountMatchingDetails] =
    useState<AuthResult>();

  const [mostRecentNote, setMostRecentNote] = useState<Note>();

  const publicPath = () => {
    return (
      !window.location.href.includes('/boards/public') &&
      (window.location.href.includes('public') ||
        window.location.href.includes('pages') ||
        window.location.href.includes('login'))
    );
  };

  const enterCWU = useCallback(async () => {
    setAccountMatchingDetails(undefined);
    await requestUserBundleUpdate();
    const beforeLogin = localStorage.getItem('beforeLogin');
    if (beforeLogin) {
      localStorage.removeItem('beforeLogin');
      navigate(beforeLogin);
    }

    if (window.location.pathname === '/') {
      getPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestUserBundleUpdate]);

  useEffect(() => {
    const loggedIn = () => {
      //if there is a token user is logged in
      const token = window.localStorage.getItem('CWAuthToken');
      if (token) return true;
    };
    const submitEmailVerification = async (vcode: string) => {
      try {
        let res = await postRequest('/api/auth/submitEmailVerification', {
          vcode: vcode,
          scode: window.localStorage.getItem('scode')
        });
        if (res.next === 'home') {
          window.localStorage.setItem('CWAuthToken', res.token);
          enterCWU();
        }
      } catch (error) {
        console.error('error submitting email verification', error);
      }
    };
    const completeLogin = async (code: string, state: string) => {
      //check query params includes social media login code
      let authPath = '';
      if (state === 'facebook') authPath = '/api/auth/fbCode';
      else if (state === 'google') authPath = '/api/auth/googleAuth';
      else if (state === 'microsoft') authPath = '/api/auth/microsoftAuth';
      try {
        const res: AuthResult = await postRequest(authPath, {
          code: code,
          redirect: `${window.location.origin}${
            state === 'facebook'
              ? '/'
              : '' /*for facebook oauth bug where domain and domain not equal, domain and domain/ are equal  */
          }`,
          scode: window.localStorage.getItem('scode'),
          crmInviteCode: window.localStorage.getItem('cic')
        });
        window.localStorage.removeItem('cic');
        if (res.next === 'home' && res.token) {
          window.localStorage.setItem('CWAuthToken', res.token);
          enterCWU();
        } else if (res.next === 'pick_contact') {
          setAccountMatchingDetails(res);
        }
      } catch (err) {
        message.error('could not login' + err);
      }
    };

    let currentUrl = new URL(window.location.href);
    //oauth code for facebook/microsoft/google
    const code = currentUrl.searchParams.get('code');
    //software name for code
    const state = currentUrl.searchParams.get('state');
    //code for maintaining security throughout the account creation process
    const scode = currentUrl.searchParams.get('scode');
    //email verification code for the account creation process
    const vcode = currentUrl.searchParams.get('vcode');
    //unique code for joining a team
    const joinTeamCode = currentUrl.searchParams.get('joinTeamCode');
    //unique code for account creation process which ensures the correct keap contact id gets matched with new account
    const crmInviteCode = currentUrl.searchParams.get('cic');
    //team static code for requesting joining a team
    const tsc = currentUrl.searchParams.get('tsc');
    //automatically join this occurrence id upon login
    const occurrenceId = currentUrl.searchParams.get('occurrence_id');
    //contest code for join contest
    const contestCode = currentUrl.searchParams.get('contestCode');
    //window.history.pushState(null, '', window.location.href.split('?')[0]);
    //save the data from the query params into local storage
    if (scode) window.localStorage.setItem('scode', scode);
    if (joinTeamCode) window.localStorage.setItem('joinTeamCode', joinTeamCode);
    if (crmInviteCode) window.localStorage.setItem('cic', crmInviteCode);
    if (tsc) window.localStorage.setItem('tsc', tsc);
    if (occurrenceId) window.localStorage.setItem('occurrenceId', occurrenceId);
    if (contestCode) window.localStorage.setItem('contestCode', contestCode);

    if (loggedIn()) {
      enterCWU();
    } else {
      if (vcode) {
        submitEmailVerification(vcode);
      } else if (code && state) {
        completeLogin(code, state);
      } else {
        if (!publicPath()) {
          localStorage.setItem('beforeLogin', window.location.pathname);
          navigate('/login');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterCWU]);

  useEffect(() => {
    if (
      !publicPath() &&
      window.location.pathname !== '/' &&
      !window.localStorage.getItem('CWAuthToken')
    ) {
      navigate('/login');
    } else if (
      window.location.pathname.includes('/home/stattracker/integrations')
    ) {
      const path = window.location.pathname.replace('/home', '');
      navigate(path + window.location.search);
    } else if (
      window.location.pathname.includes('/home/') ||
      window.location.pathname === '/home'
    )
      navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  //get activated popup and show it
  async function getPopup() {
    const userToken = window.localStorage.getItem('CWAuthToken');
    if (!userToken) return;

    try {
      const activatedPopupId = await getRequest(`/api/popup/activatedPopup`);
      // const popupDate = window.localStorage.getItem('popupDate');
      // const today = moment.utc(new Date()).format('YYYY-MM-DD');

      if (activatedPopupId) {
        // if (moment(today).isSame(popupDate)) return;
        // window.localStorage.setItem('popupDate', JSON.stringify(today));

        setPopupId(activatedPopupId);
      }
    } catch (error) {
      console.error('error get activated popup ', error);
    }
  }

  useEffect(() => {
    // const setupPopups = () => {
    //   socketHandler.getSocket().on('trigger_popup', (data: any) => {
    //     setPopupId(data.popupId);
    //   });
    // };

    const handleNotificationPermissions = async () => {
      if (!('Notification' in window)) {
        //This browser does not support notifications
      } else if (Notification.permission === 'granted') {
        //Notification has already been granted, send test notification
        //const notification = new Notification('Hi there!!!!');
      } else if (Notification.permission === 'default') {
        //Notification was previously denied
        //request
        const result = await window.Notification.requestPermission();
        if (result === 'granted') {
          //const notification = new Notification('Well hello there @_@');
          subscribeToPushNotifications();
        }
      }
    };

    const getMostRecentPatchNote = async () => {
      try {
        const note: Note = await getRequest(
          '/api/organization/notes/mostRecent'
        );
        if (
          moment().diff(moment(note.dateTime), 'days') <= 2 &&
          window.localStorage.getItem('recentNote') !== note.id.toString()
        ) {
          setMostRecentNote(note);
        }
      } catch (error) {
        console.error('error getting patch note', error);
      }
    };

    const autoJoinOccurrence = async (occurrenceId: number) => {
      const processRegistrationResult = (response: any) => {
        let uname = `${user_bundle.user.first_name} ${user_bundle.user.last_name}`;
        for (let i = 0; i < response.underscore_count; i++) {
          uname += '_';
        }
        let start_url = `zoommtg://zoom.us/join?confno=${response.zoomConferenceId}&pwd=${response.password}&zc=0&uname=${uname}`;
        if (BrowserInfo.isMobile()) {
          start_url = `zoomus://zoom.us/join?confno=${response.zoomConferenceId}&pwd=${response.password}&zc=0&uname=${uname}`;
        }
        if (!response.host && response.token)
          start_url += `&tk=${response.token}`;
        return start_url;
      };

      const registration = await postRequest(
        '/api/conference/registerUsingLastEmail',
        {
          occurrenceId: occurrenceId
        }
      );
      const startUrl = registration && processRegistrationResult(registration);

      if (startUrl) {
        window.location.href = startUrl;
        window.localStorage.removeItem('occurrenceId');
      }
    };
    if (user_bundle) {
      let currentUrl = new URL(window.location.href);
      const occurrenceId =
        currentUrl.searchParams.get('occurrence_id') ||
        window.localStorage.getItem('occurrence_id');
      // setupPopups();
      handleNotificationPermissions();
      getMostRecentPatchNote();
      if (occurrenceId) autoJoinOccurrence(parseInt(occurrenceId));
    }
  }, [user_bundle]);

  if (publicPath()) {
    return (
      <Suspense fallback={<FullScreenLoading loading={true} />}>
        <PageViewer location={window.location} />
      </Suspense>
    );
  }

  if (accountMatchingDetails) {
    /* Account Matching */
    return (
      <Suspense fallback={<FullScreenLoading loading={true} />}>
        <AccountMatching
          accountMatchingDetails={accountMatchingDetails}
          enterCWU={enterCWU}
        />
      </Suspense>
    );
  }

  if (!user_bundle) {
    return <FullScreenLoading loading={true} />;
  }

  return (
    <ShoppingProvider>
      <VideoProvider>
        <div className='App'>
          <Suspense fallback={<FullScreenLoading loading={true} />}>
            {/* Display Popup */}
            {popupId && (
              <AnimatePresence>
                <Modal>
                  <motion.div
                    transition={{ type: 'tween', duration: 0.5 }}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -100 }}
                    className={popupStyles['modal']}
                  >
                    <div
                      className={popupStyles['modal_close']}
                      onClick={() => setPopupId(null)}
                    >
                      <IoCloseSharp fontSize='1.5rem' />
                    </div>

                    <PageViewer page_id={popupId} />
                  </motion.div>
                </Modal>
              </AnimatePresence>
            )}
            {/* Navigation for System Administrators */}
            {user_bundle && user_bundle.permissions.length > 0 && (
              <NavResizeAnimator
                style={{
                  float: 'left',
                  left: `${
                    adminNavOpen
                      ? '0px'
                      : `${BrowserInfo.isMobile() ? '-100%' : `-230px`}`
                  }`,
                  width: `${BrowserInfo.isMobile() ? '100%' : '230px'}`,
                  position: 'fixed',
                  top: 0,
                  height: '100vh',
                  zIndex: 50
                }}
              >
                <AdminNav
                  open={adminNavOpen}
                  toggleAdminNav={() => setAdminNavOpen(!adminNavOpen)}
                  changeComponent={() => setAdminNavOpen(false)}
                  windowWidth={window.innerWidth}
                />
              </NavResizeAnimator>
            )}
          </Suspense>
          <Suspense fallback={<FullScreenLoading loading={true} />}>
            {/* Show adding/removing events from calendar */}
            <SubscriptionProgress />
            {/* Setup wizard for new account */}
            {user_bundle &&
            (!user_bundle.user.facebook_group_member ||
              !user_bundle.user.conferencing_configured) ? (
              <AccountCompletion
                display={
                  user_bundle &&
                  (!user_bundle.user.facebook_group_member ||
                    !user_bundle.user.conferencing_configured)
                }
              />
            ) : (
              ''
            )}

            {/* 
            <div>
              <Modal
                // title={this.props.curPopup ? this.props.curPopup.title : "Basic Modal"}
                open={popupId ? true : false}
                onOk={() => setPopupId(null)}
                onCancel={() => setPopupId(null)}
                footer={null}
                destroyOnClose={true}
                maskClosable={false}
                width={BrowserInfo.isMobile() ? '90%' : '50%'}
              >
                <PageViewer page_id={popupId} />
              </Modal>
            </div> */}

            <Routes>
              <Route path='/stattracker/*' element={<StatTrackerContainer />} />
              <Route
                path='/*'
                element={
                  <>
                    {/* NavBar */}
                    <div style={{ zIndex: 13, position: 'sticky', top: 0 }}>
                      <PageViewer type={'tab'} page_id={110} />
                    </div>
                    {/* Display patch notes below navbar at top of screen */}
                    {mostRecentNote && (
                      <NoteDisplay
                        note={mostRecentNote}
                        closeNotice={() => {
                          if (mostRecentNote) {
                            window.localStorage.setItem(
                              'recentNote',
                              mostRecentNote.id.toString()
                            );
                            setMostRecentNote(undefined);
                          }
                        }}
                      />
                    )}
                    <Suspense fallback={<FullScreenLoading loading={true} />}>
                      <Routes>
                        <Route
                          path='/teamVerificationForm'
                          element={<TeamVerificationForm />}
                        />
                        <Route path='/vendor' element={<DevPortal />} />
                        <Route path='/oauth' element={<AuthGrant />} />
                        <Route
                          path='/SetCompanyIFSToken'
                          element={<SetCompanyIFSToken />}
                        />
                        {/* The following routes are adapted from homenav */}
                        <Route
                          path='/viewShoppingCart'
                          element={<ShoppingCartViewer />}
                        />
                        <Route path='/partners' element={<VendorReviews />} />
                        <Route path='/checkout' element={<CheckoutViewer />} />

                        <Route
                          path='/member-locator'
                          element={<MemberLocator />}
                        />
                        <Route
                          path='/account/*'
                          element={<UserAccountSettings />}
                        />
                        <Route path='/myClients' element={<CoachDashboard />} />
                        <Route path='/boards/*' element={<BoardsRoutes />} />
                        <Route path='/config/*' element={<AdminRoutes />} />
                        <Route path='/st1/*' element={<ST1Routes />} />
                        <Route path='/courses/*' element={<CourseRoutes />} />
                        <Route path='/teams/*' element={<TeamRoutes />} />
                        <Route
                          path='/*'
                          element={<PageViewer location={location} />}
                        />
                      </Routes>
                    </Suspense>
                  </>
                }
              ></Route>
            </Routes>

            <div style={{ position: 'relative' }}>
              <HelpChat />
            </div>
          </Suspense>
        </div>
      </VideoProvider>
    </ShoppingProvider>
  );
};

export default App;
