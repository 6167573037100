import Axios from 'axios';
import React from 'react';
import httpHandler from '../helpers/httpHandler';
import MediaHttpHandler from '../helpers/MediaHttpHandler';
import VideoCallHandler from '../helpers/VideoCallHandler';
import DomainInfo from '../helpers/DomainInfo';
import VideoSettingsAndConstraints from '../helpers/VideoSettingsAndConstraints';

const VideoCallContext = React.createContext();

class VideoProvider extends React.Component {
  streamTest = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let device = devices[0];
    await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: { ideal: device.deviceId },
        ...VideoSettingsAndConstraints.VIDEO_CONSTRAINTS['hd']
      }
    });
  };
  constructor() {
    super();
    //this.streamTest();
    this.VideoCallHandler = null;
    this.state = {
      mySettings: {
        displayName: 'Loading User...',
        displayNameSet: false,
        device: null,
        canSendMic: false,
        canSendWebcam: false,
        canChangeWebcam: false,
        webcamInProgress: false,
        shareInProgress: false,
        audioOnly: false,
        audioOnlyInProgress: false,
        restartIceInProgress: false,
        me: true
      },
      roomSettings: {
        activeSpeakerId: null,
        roomState: null
      },
      producers: {},
      roomMembers: {},
      consumers: {},
      notifications: [],
      shareState: {
        inProgress: false,
        producerId: -1
      }
    };
  }
  //THIS IS THE PRODUCTION ONE
  requestJoinRoomPermission = async (roomId, asCoach) => {
    return new Promise(async (resolve, reject) => {
      try {
        let joinId = await httpHandler.post(
          '/api/coachingCall/requestJoinRoomPermission',
          { roomType: 'coachingCall', roomId: roomId, asCoach: asCoach }
        );
        resolve(joinId);
      } catch (error) {
        reject(error);
      }
    });
  };
  //THIS IS FOR DEV PURPOSES ONLY
  joinPermissionDirectly = async (roomId, asCoach) => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await Axios.post(
          `${DomainInfo.mediaServerBaseUrl}/api/requestOrCreateRoomData/${roomId}`,
          {
            settings: {
              video_on_entry: true,
              audio_on_entry: true,
              audio_start_recording: true
            }
          },
          { headers: { Authorization: `token ${'Wealth123!'}` } }
        );
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  };
  joinRoom = async (roomId, roomType, displayName) => {
    return new Promise(async (resolve, reject) => {
      //get permission to join call from cwu server
      try {
        //let joinId = await this.requestJoinRoomPermission(roomId, false);

        let newSettings = this.state.mySettings;
        newSettings.displayName = displayName;
        this.setState({
          mySettings: newSettings
        });

        let joinId = await this.joinPermissionDirectly(roomId, false);
        this.VideoCallHandler = new VideoCallHandler(
          this,
          roomId,
          joinId,
          displayName
        );
        resolve();
      } catch (error) {
        console.error('error getting permission to join call', error);
        reject();
      }
    });
  };
  leaveRoom = async () => {
    await MediaHttpHandler.post(
      `/rooms/${this.VideoCallHandler.roomId}/leaveRoom`,
      {}
    );
    localStorage.removeItem('vidAuthorization');
    this.reset();
  };
  reset = () => {
    this.videoCallHandler = null;
    this.setState({
      mySettings: {
        displayName: 'Disconnected',
        displayNameSet: false,
        device: null,
        canSendMic: false,
        canSendWebcam: false,
        canChangeWebcam: false,
        webcamInProgress: false,
        shareInProgress: false,
        audioOnly: false,
        audioOnlyInProgress: false,
        restartIceInProgress: false,
        me: true,
        myId: null
      },
      roomSettings: {
        activeSpeakerid: null,
        roomState: null
      },
      producers: {},
      roomMembers: {},
      consumers: {},
      notifications: [],
      shareState: {
        inProgress: false,
        consumer: null,
        producerMemberId: -1
      }
    });
  };

  changeMySetting = async (setting, value) => {
    await this.setState((prevState) => ({
      mySettings: { ...prevState.mySettings, [setting]: value }
    }));
  };
  changeRoomSetting = async (setting, value) => {
    await this.setState((prevState) => ({
      roomSettings: { ...prevState.roomSettings, [setting]: value }
    }));
  };
  addProducer = async (producer) => {
    await this.setState((prevState) => {
      let producers = { ...prevState.producers };
      producers[producer.id] = producer;
      return { producers };
    });
  };
  removeProducer = async (producerId) => {
    await this.setState((prevState) => {
      let producers = Object.assign({}, prevState.producers);
      delete producers[producerId];
      return { producers: producers };
    });
  };
  setProducerPaused = async (producerId) => {
    await this.setState((prevState) => {
      let producers = Object.assign({}, prevState.producers);
      producers[producerId].paused = true;
      return { producers: producers };
    });
  };
  setProducerResumed = async (producerId) => {
    await this.setState((prevState) => {
      let producers = Object.assign({}, prevState.producers);
      producers[producerId].paused = false;
      return { producers: producers };
    });
  };
  setProducerTrack = async (producerId, track) => {
    await this.setState((prevState) => {
      let producers = Object.assign({}, prevState.producers);
      producers[producerId].track = track;
      return { producers: producers };
    });
  };
  addConsumer = async (consumer, producerMemberid) => {
    await this.setState((prevState) => {
      let roomMembers = Object.assign({}, prevState.roomMembers);
      let consumers = Object.assign({}, prevState.consumers);
      //roomMembers[producerMemberid].consumers.push(consumer);
      roomMembers[producerMemberid].consumers = [
        ...roomMembers[producerMemberid].consumers,
        consumer
      ];
      consumers[consumer.id] = consumer;
      return { consumers: consumers, roomMembers: roomMembers };
    });
  };
  setShareConsumer = async (consumer, producerMemberId) => {
    await this.setState((prevState) => {
      let consumers = Object.assign({}, prevState.consumers);
      consumers[consumer.id] = consumer;
      let shareState = {
        inProgress: true,
        consumer: consumer,
        producerMemberId: producerMemberId
      };
      return { consumers: consumers, shareState: shareState };
    });
  };
  removeConsumer = async (consumerId) => {
    await this.setState((prevState) => {
      let roomMembers = Object.assign({}, prevState.roomMembers);
      let consumers = Object.assign({}, prevState.consumers);
      let shareState = Object.assign({}, prevState.shareState);

      for (let roomMember of Object.values(roomMembers)) {
        roomMember.consumers = roomMember.consumers.filter(
          (consumer) => consumer.id !== consumerId
        );
      }

      if (shareState.inProgress && shareState.consumer.id === consumerId) {
        shareState = {
          inProgress: false
        };
      }

      delete consumers[consumerId];

      return {
        consumers: consumers,
        roomMembers: roomMembers,
        shareState: shareState
      };
    });
  };
  setConsumerLayers = async (consumerId, spatialLayer, temporalLayer) => {
    await this.setState((prevState) => {
      let consumers = Object.assign({}, prevState.consumers);
      consumers[consumerId].spatialLayer = spatialLayer;
      consumers[consumerId].temporalLayer = temporalLayer;
      return consumers;
    });
  };
  setConsumerPaused = async (consumerId, producerMemberid) => {
    await this.setState((prevState) => {
      let roomMembers = Object.assign({}, prevState.roomMembers);
      let consumers = Object.assign({}, prevState.consumers);

      roomMembers[producerMemberid].consumers.find(
        (consumer) => (consumer.id = consumerId)
      ).paused = true;

      consumers[consumerId].paused = true;
      return { consumers: consumers, roomMembers: roomMembers };
    });
  };

  setConsumerResumed = async (consumerId, producerMemberid) => {
    await this.setState((prevState) => {
      let roomMembers = Object.assign({}, prevState.roomMembers);
      let consumers = Object.assign({}, prevState.consumers);

      roomMembers[producerMemberid].consumers.find(
        (consumer) => (consumer.id = consumerId)
      ).paused = false;

      consumers[consumerId].paused = false;
      return { consumers: consumers, roomMembers: roomMembers };
    });
  };

  addRoomMember = async (roomMember) => {
    await this.setState((prevState) => ({
      roomMembers: { ...prevState.roomMembers, [roomMember.id]: roomMember }
    }));
  };
  removeRoomMember = async (roomMemberId) => {
    await this.setState((prevState) => {
      let roomMembers = Object.assign({}, prevState.roomMembers);
      delete roomMembers[roomMemberId];
      return { roomMembers: roomMembers };
    });
  };
  render = () => {
    return (
      <VideoCallContext.Provider
        value={{
          ...this.state,
          joinRoom: this.joinRoom,
          leaveRoom: this.leaveRoom,
          videoCallHandler: this.VideoCallHandler
        }}
      >
        {this.props.children}
      </VideoCallContext.Provider>
    );
  };
}

export default VideoCallContext;
export { VideoProvider };
