import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import classes from './resultItem.module.scss';
import { Board, BoardList } from './Search';

interface BoardResult {
  index: number;
  type: string;
  value: string;
}

interface BoardCardProps {
  board: Board;
  style: CSSProperties;
  setRowHeight: (index: number, size: number) => void;
  index: number;
  icon?: JSX.Element;
  highlightText: (text: string) => (string | JSX.Element)[];
}

const LIMIT_RESULT = 5;

const BoardCard = ({
  board,
  setRowHeight,
  index,
  style,
  icon,
  highlightText
}: BoardCardProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [limitResult, setLimitResults] = useState<number>(LIMIT_RESULT);

  const resizeBackground = (backgroundUrl: string) => {
    let url = backgroundUrl.replace('url(', '').replace(')', '');
    return `${url}?auto=compress&cs=tinysrgb&h=50`;
  };

  const transformBoard = useCallback(() => {
    const courseResults = [];
    let index = 0;
    board.boardName &&
      courseResults.push({
        index: index,
        type: 'boardName',
        value: board.boardName
      });
    index++;
    if (board.list.length) {
      const list: BoardList[] = board.list;
      for (let i = 0; i < list.length; i++) {
        courseResults.push({
          index: index,
          type: 'boardList',
          value: list[i].listName
        });
        index++;
        if (list[i].boardItems.length) {
          const boardItems = list[i].boardItems;
          for (let j = 0; j < boardItems.length; j++) {
            courseResults.push({
              index: index,
              type: 'boardCard',
              value: boardItems[j]
            });
            index++;
          }
        }
      }
    }
    return courseResults;
  }, [board]);

  const transformedBoard = transformBoard();
  const displayBoard = transformedBoard.slice(0, limitResult);
  const navigate = useNavigate();
  const handleClickBoard = (boardId: number, boardType: string) => {
    navigate(`/boards/${boardType}/${boardId}`);
  };

  const handleLoadMore = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setLimitResults((prevState) => prevState + 3);
  };

  /* reset board card height */
  useEffect(() => {
    setRowHeight(index, containerRef.current?.clientHeight || 0);
  }, [containerRef, index, setRowHeight, limitResult]);

  return (
    <div style={style} className={classes['card']}>
      <div ref={containerRef}>
        <div
          className={`${classes['result-item']} ${classes['clickable-card']}`}
          onClick={(e) => handleClickBoard(board.id, board.boardType)}
        >
          {board.background ? (
            <div className={classes['item__image-display']}>
              <img
                className={classes['image-display__image']}
                src={resizeBackground(board.background)}
                alt='board'
              />
              <div className={classes['image-display__icon']}>{icon}</div>
            </div>
          ) : (
            <div className={classes['item__image-display']}>
              <div className={classes['image-display__image']}></div>
              <div className={classes['image-display__icon']}>{icon}</div>
            </div>
          )}
          <div className={classes['item__list']}>
            {displayBoard.map((result: BoardResult, index: number) => {
              let className;
              if (result.type === 'boardName') {
                className = classes['item__name'];
                return (
                  <div key={result.value + index} className={className}>
                    {highlightText(result.value)}
                  </div>
                );
              } else if (result.type === 'boardList') {
                className = classes['item__section-name'];
                return (
                  <div key={result.value + index} className={className}>
                    <BsCircleFill size={9} />
                    {` `} {highlightText(result.value)}
                  </div>
                );
              } else if (result.type === 'boardCard') {
                className = classes['item__module-name'];
                return (
                  <div key={result.value + index} className={className}>
                    <BsCircle size={9} />
                    {` `} {highlightText(result.value)}
                  </div>
                );
              } else {
                return <></>;
              }
            })}

            {transformedBoard.length > displayBoard.length && (
              <div
                className={classes['item__load-more']}
                onClick={handleLoadMore}
              >
                Load more ...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardCard;
