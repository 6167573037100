/*
  Contains build information about which server domain to access depending on 
  whether react client is in dev, tesing, or production mode
*/

module.exports = {
  baseUrl:
    process.env.NODE_ENV === 'production'
      ? window.location.origin
      : 'http://localhost:3001',
  developmentRedirectUrl: 'https://127.0.0.1',
  productionRedirectUrl: 'https://clubwealthu.com',
  redirectUrl:
    process.env.mode === 'production'
      ? 'https://clubwealthu.com'
      : 'https://127.0.0.1',
  mediaServerBaseUrl:
    process.env.REACT_APP_MEDIA_TEST === 'true'
      ? 'https://videotest.clubwealth.com'
      : 'https://videoapi.clubwealth.com'
};
