import { notification } from 'antd';
import ts from 'typescript';
import { Form, FormField } from '../../types/form';
import { ProcessedContactValues } from '../../types/statTracker';
import { FormSlice, GetFn, SetFn } from '../../types/zustandTypes';
import { transactionFormApi } from '../apis/transactionFormApi';
import { parseSortedField } from '../../helpers/formSliceHelpers';
import { contactRoutes } from '../../api/contactRoutes/contactRoutes';

export const createFormSlice = (set: SetFn, get: GetFn): FormSlice => ({
  currentPage: 0,
  currentFormFields: [],
  currentForm: null,
  currentFormTriggerFieldSet: new Set([]),
  availableFormFields: [],
  filteredAvailableFields: [],
  formFieldGroups: [],
  formFieldGroupFilter: new Set([]),
  formFieldCollection: {},
  // Instead of updating multiple arrays of formFields, we just create
  // a collection of required form fields. We would just use this to reference
  // whether a field is required or not.
  requiredFormFields: {},
  contactInfos: {},
  sortedFormFields: {},
  isFormLoading: true,
  isFormDataInitialized: false,
  formsApi: {
    setCurrentFormFields: (formFields: FormField[]) =>
      set({ currentFormFields: formFields }),
    setAvailableFormFields: (availableFormFields: FormField[]) =>
      set({ availableFormFields }),
    setRequiredFormFields: (requiredFormFields: { [key: string]: FormField }) =>
      set({ requiredFormFields }),
    getTeamForm: async (formId) => {
      try {
        const [currentForm]: Form[] = await transactionFormApi.getForm(formId);

        if (currentForm) {
          const currentFormFields = await transactionFormApi.getTeamFormFields(
            currentForm.id
          );
          set({ currentForm, currentFormFields, isFormLoading: false });
        } else {
          throw new Error('There is no transaction form for this team');
        }
      } catch (error) {
        console.error(error);
      }
    },
    filterAvailableFormsFieldsByFormFieldGroup: (formFields: FormField[]) => {
      //either filter the filtered or filter avaialble if no input
      const filteredAvailableFormFields: FormField[] = [];
      //filter avaialbleFormFields if no input provided

      if (get().formFieldGroupFilter.size === 0) return formFields;

      formFields.forEach((formField) => {
        if (get().formFieldGroupFilter.has(formField.group_id)) {
          filteredAvailableFormFields.push(formField);
        }
      });

      return filteredAvailableFormFields;
    },
    setFilteredAvailableFields: (filteredAvailableFields: FormField[]) => {
      set({ filteredAvailableFields });
    },
    setFormFieldGroupFilter: (formFieldGroupFilter: ts.Set<number>) => {
      set({ formFieldGroupFilter });
    },
    initializeFormData: async () => {
      const formId = get().selectedTeam?.formId;

      if (!formId) {
        return;
      }

      const promiseArray = [
        transactionFormApi.getAllFormFields(),
        transactionFormApi.getTeamFormFields(formId),
        transactionFormApi.getFormFieldGroups()
      ];

      const result = await Promise.all(promiseArray);

      const currentFormFieldCollection: { [key: number]: FormField } = {};

      const availableFormFields = result[0];
      const currentFormFields = result[1];
      const formFieldGroups = result[2];

      currentFormFields.forEach((formField: FormField) => {
        currentFormFieldCollection[formField.id] = formField;
      });

      const formFieldCollection: { [key: number]: FormField } = {};
      const newAvailableFormFields: FormField[] = [];

      availableFormFields.forEach((formField: FormField) => {
        if (!currentFormFieldCollection[formField.id])
          newAvailableFormFields.push(formField);
      });

      availableFormFields.forEach((formField: FormField) => {
        formFieldCollection[formField.id] = formField;
      });

      newAvailableFormFields.sort((a, b) => {
        let fa = a.name,
          fb = b.name;

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      set({
        availableFormFields: newAvailableFormFields,
        currentFormFields,
        formFieldCollection,
        formFieldGroups
      });
    },
    // Checks whether the form field would be filtered by the current Filters
    isFiltered: (formField: FormField, input: string) => {
      const formFieldName = formField.name.toLowerCase();
      const lcInput = input.toLowerCase();

      //check if theres an input
      if (lcInput.length) {
        //check if theres group filter
        if (get().formFieldGroupFilter.size) {
          // check if matches the input and the grouop filter
          if (
            formFieldName.includes(lcInput) &&
            get().formFieldGroupFilter.has(formField.group_id)
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          // check only via input

          if (formFieldName.toLowerCase().includes(lcInput)) return true;
          else return false;
        }
      } else {
        // check if there's a group filter
        if (get().formFieldGroupFilter.size) {
          //check if the formfields group id belongs to the filter
          if (get().formFieldGroupFilter.has(formField.group_id)) {
            return true;
          } else {
            return false;
          }
        }

        return true;
      }
    },
    getTeamContacts: async (teamId: number) => {
      try {
        const contactInfos = await contactRoutes.getContacts(teamId);
        const collection: { [key: string]: ProcessedContactValues } = {};
        contactInfos.forEach((element: ProcessedContactValues) => {
          collection[element.id] = element;
        });
        set({ contactInfos: collection });
      } catch (error) {
        console.error(error);
      }
    },
    setSortedFormFields: async (userId) => {
      const formId = get().selectedTeam?.formId;
      if (!formId) {
        console.error('This team has no form id.');
        return;
      }
      const formFields = get().currentFormFields;
      if (!formFields.length) {
        const currentFormFields = await transactionFormApi.getTeamFormFields(
          formId
        );
        set({ currentFormFields });
      }
      if (formId !== -1) {
        const currentFormFields = get().currentFormFields;
        const sortedFormFields = await parseSortedField(
          currentFormFields,
          formId,
          userId
        );
        set({ sortedFormFields });
      }
      set({ isFormLoading: false });
    },
    getTransactionData: async (formId: number, userId: number) => {
      const shallowFormFields = get().currentFormFields;
      const sortedFormFields = await parseSortedField(
        shallowFormFields,
        formId,
        userId
      );

      const currentFormFields = await transactionFormApi.getTeamFormFields(
        formId
      );
      set({ sortedFormFields, currentFormFields });
    },
    setIsFormDataInitialized: (isFormDataInitialized: boolean) => {
      set({ isFormDataInitialized });
    },
    saveNewFormFields: async (formId, teamId) => {
      try {
        const formFields = get().currentFormFields;
        const requiredFormFields = get().requiredFormFields;
        const result = await transactionFormApi.patchFormField(
          formId,
          teamId,
          requiredFormFields,
          formFields
        );

        if (result.ok) {
          notification.success({
            message: 'Changes Saved',
            description: 'Your new set of changes has been saved.'
          });
        }
      } catch (error) {
        notification.error({
          message: 'Error request',
          description:
            'We are unable to process your request. Please submit a bug report or visit our help desk. '
        });
        console.error(error);
      }
    },
    addContact: (contact) => {
      set((state) => ({
        contactInfos: { ...state.contactInfos, [contact.id]: contact }
      }));
    }
  }
});
