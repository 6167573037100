import React from "react";

class BackgroundVideo extends React.Component {
  constructor() {
    super();
    this.state = {
      width: "auto",
      height: "auto"
    };
    this.shellRef = React.createRef();
    window.addEventListener("resize", () => {
      this.setWidthHeight();
    });
  }
  componentDidMount() {
    this.setWidthHeight();
  }
  setWidthHeight = () => {};
  getYoutubeId = url => {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
      return match[2];
    } else {
      //error
    }
  };

  htmlByVideoType = () => {
    if (this.props.sourceType === "source_url") {
      return (
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            height: "100%",
            position: "absolute",
            left: "0",
            top: "0"
          }}
        >
          <video
            key={this.props.source}
            style={{
              objectFit: "cover",
              minWidth: "100%",
              minHeight: "100%"
            }}
            autoPlay
            loop
            muted
          >
            <source src={this.props.source} />
          </video>
        </div>
      );
    } else {
      return "";
    }
  };
  render() {
    return <div ref={this.shellRef}>{this.htmlByVideoType()}</div>;
  }
}

export default BackgroundVideo;
