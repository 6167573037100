import httpHandler from './httpHandler';

const permissionsHelper = {
  getPermissionsForRuleSetIds: (rule_set_ids) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .get('/api/organization/permissionsForRuleSetIds', {
          rule_set_ids: rule_set_ids
        })
        .then((permissions) => {
          resolve(permissions);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {});
    });
  },
  getPermissionForRuleSetId: (rule_set_id) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .get('/api/organization/permissionForRuleSetId', {
          rule_set_id: rule_set_id
        })
        .then((permission) => {
          resolve(permission);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {});
    });
  }
};

export default permissionsHelper;
