import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { FaBookReader, FaSearch, FaTable, FaVideo } from 'react-icons/fa';

import { getRequest, postRequest } from '../../../../../helpers/httpHandler2';
import classes from './search.module.scss';
import { VariableSizeList } from 'react-window';

import useRefDimensions from '../../../../../hooks/useRefDimensions';
import RecordingCard from './RecordingCard';
import CourseCard from './CourseCard';
import BoardCard from './BoardCard';
import RecordingModal from './RecordingModal';
import Modal from '../../../../reusableComponents/standard/modal/Modal';

import FullScreenLoading from '../../../../reusableComponents/standard/fullScreenLoading/FullScreenLoading';
import { AiOutlineInbox } from 'react-icons/ai';
import HelpDeskModal from './HelpDeskModal';
import { AnimatePresence, motion } from 'framer-motion';
import { message } from 'antd';
import CoursePreview from './CoursePreview';

interface s {
  type: string;
  index: number;
  hasPermission: boolean;
}

export interface RecordingList {
  date: string;
  recordingId: number;
  occurrenceId: number;
  tags: string;
}

export interface Recording extends s {
  topic: string;
  image: string;
  recordingsList: RecordingList[];
}

export interface Section {
  sectionName: string;
  modules: string[];
}

export interface Course extends s {
  courseId: number;
  courseImage?: string;
  video?: string;
  price?: string;
  productId: number;
  courseName: string;
  sections: Section[];
}

export interface BoardList {
  listName: string;
  boardItems: string[];
}

export interface Board extends s {
  id: number;
  boardName: string;
  list: BoardList[];
  background: string;
  boardType: string;
}

export interface ResultsData {
  recordings: Recording[];
  courses: Course[];
  boards: Board[];
}

interface CategoryList {
  label: string;
  icon?: JSX.Element;
}

interface TotalResults {
  all: number;
  recording: number;
  course: number;
  board: number;
}

const categoryList: CategoryList[] = [
  { label: 'All', icon: <FaSearch size={'20px'} /> },
  { label: 'Recordings', icon: <FaVideo size={'20px'} /> },
  { label: 'Courses', icon: <FaBookReader size={'20px'} /> },
  { label: 'Boards', icon: <FaTable size={'20px'} /> }
];

const Search = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [resultsRender, setResultsRender] = useState<s[]>([]);
  const [resultsData, setResultsData] = useState<ResultsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRecording, setSelectedRecording] = useState<Recording>();
  const [selectedOccId, setSelectedOccId] = useState<number>();
  const [openRecordingModal, setOpenRecordingModal] = useState<boolean>(false);
  const [coursePreview, setCoursePreview] = useState<boolean>(false);
  const [coursePreviewId, setCoursePreviewId] = useState<number>();
  const [helpDeskModal, setHelpDeskModal] = useState<boolean>(false);
  const [recordingTopic, setRecordingTopic] = useState<string>('');
  const [totalResults, setTotalResults] = useState<TotalResults>();

  const listRef = useRef<any>(null);
  const rowHeights = useRef<{ [key: number]: number }>({});
  const listContainerRef = useRef<HTMLDivElement>(null);

  const { width: listContainerWidth, height: listContainerHeight } =
    useRefDimensions(listContainerRef);

  const getRowHeight = (index: any): number => {
    return rowHeights.current[index] || 100;
  };

  const setRowHeight = (index: number, size: number) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const handleSearch = useCallback(async () => {
    if (!inputValue) {
      setResultsRender([]);
      setResultsData(undefined);
    }
    if (inputValue.length >= 3) {
      setLoading(true);
      try {
        const resultsData: ResultsData = await getRequest(
          '/api/navbarsearching',
          {
            input: inputValue
          }
        );
        setResultsData(resultsData);
      } catch (error) {
        console.error('error');
        if (typeof resultsData === 'string') message.error(resultsData);
      }

      setLoading(false);
    }
  }, [inputValue, resultsData]);

  const filterResults = (
    resultsData: ResultsData,
    selectedCategory: string
  ) => {
    const results: s[] = [];
    if (selectedCategory === 'All' && resultsData) {
      Object.values(resultsData).forEach((item) => {
        item.forEach((result: any, index: number) => {
          results.push({ ...result, index: index });
        });
      });
    } else {
      const categoryName = selectedCategory.toLowerCase();
      const categoryResults =
        resultsData && resultsData[categoryName as keyof ResultsData];

      categoryResults?.forEach((result: any, index: number) => {
        results.push({ ...result, index: index });
      });
    }

    const sortedResults = results.sort(
      (a, b) => Number(b.hasPermission) - Number(a.hasPermission)
    );
    return sortedResults;
  };

  useEffect(() => {
    const recordingsCount = resultsData?.recordings.length || 0;
    const coursesCount = resultsData?.courses.length || 0;
    const boardsCount = resultsData?.boards.length || 0;

    setTotalResults({
      all: recordingsCount + coursesCount + boardsCount,
      recording: recordingsCount,
      course: coursesCount,
      board: boardsCount
    });
  }, [resultsData]);

  useEffect(() => {
    if (resultsData && selectedCategory) {
      const filteredResults = filterResults(resultsData, selectedCategory);
      setResultsRender(filteredResults);
    }
  }, [selectedCategory, resultsData]);

  const highlightText = (text: string) => {
    if (!text) return [''];
    else if (!inputValue) return [text];
    const formattedInput = inputValue
      .replace(/[^\w\s]/gi, '')
      .replace(/\s\s+/g, ' ')
      .toLowerCase();
    const partsText = text.split(new RegExp(`(${formattedInput})`, 'gi'));

    const result = partsText.map((part, index) =>
      part.toLowerCase() === formattedInput ? (
        <b className={classes['hightlight']} key={part + index}>
          {part}
        </b>
      ) : (
        part
      )
    );
    return result;
  };

  const countTotalResults = (label: string) => {
    if (label === 'Recordings') {
      return totalResults?.recording;
    } else if (label === 'Courses') {
      return totalResults?.course;
    } else if (label === 'Boards') {
      return totalResults?.board;
    } else if (label === 'All') {
      return totalResults?.all;
    }
  };

  const handleSelectRecording = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    recordings: Recording,
    occurrenceId: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedRecording(recordings);
    setSelectedOccId(occurrenceId);
    setOpenRecordingModal(true);
  };

  const handleSelectCategory = (category: string) => {
    setSelectedCategory(category);
  };

  const handleLockedCourse = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: string,
    courseId?: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (type === 'course') {
      setCoursePreview(true);
      setCoursePreviewId(courseId);
    } else if (type === 'recording') {
      setHelpDeskModal(true);
    }
  };

  const handleLockedRecording = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    topic: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setRecordingTopic(topic);
    setHelpDeskModal(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    //cache user crm tags
    postRequest('/api/navbarsearching/cachingCrmTags');
  }, []);

  return (
    <div className={classes['search-container']}>
      <AnimatePresence>
        {openRecordingModal && selectedRecording && selectedOccId && (
          <Modal>
            <motion.div
              transition={{ type: 'tween' }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <RecordingModal
                recordings={selectedRecording}
                selectedOccId={selectedOccId}
                setOpenRecordingModal={setOpenRecordingModal}
              />
            </motion.div>
          </Modal>
        )}
        {coursePreview && coursePreviewId && (
          <Modal>
            <motion.div
              transition={{ type: 'tween' }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <CoursePreview
                courseId={coursePreviewId}
                onClose={() => setCoursePreview(false)}
                type={'purchase'}
              />
            </motion.div>
          </Modal>
        )}
        {helpDeskModal && (
          <Modal>
            <motion.div
              transition={{ type: 'tween' }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              <HelpDeskModal
                setHelpDeskModal={setHelpDeskModal}
                recordingTopic={recordingTopic}
              />
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
      <div className={classes['search__input']}>
        <div>
          <FaSearch />
          <input
            placeholder='Search'
            type='text'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          ></input>
        </div>
      </div>

      <div className={classes['result__category']}>
        {categoryList.map((item: CategoryList) => (
          <div
            key={item.label}
            onClick={() => item.label && handleSelectCategory(item.label)}
            className={
              selectedCategory === item.label
                ? classes['category__selected']
                : classes['category__item']
            }
          >
            {item.icon} {` ${item.label}`}{' '}
            {`(${countTotalResults(item.label)})`}
          </div>
        ))}
      </div>
      <div ref={listContainerRef} className={classes['result__list']}>
        {loading && <FullScreenLoading loading={loading} />}

        {resultsRender.length === 0 && !loading && (
          <div className={classes['list__no-result']}>
            <AiOutlineInbox size={80} />
            <p>No result found</p>
          </div>
        )}

        {resultsRender.length > 0 && !loading && (
          <VariableSizeList
            ref={listRef}
            width={listContainerWidth}
            height={listContainerHeight}
            itemCount={resultsRender.length}
            itemSize={(index: number) => getRowHeight(index)}
            className={classes['variableSizeList']}
          >
            {({ index, style }: { index: number; style: CSSProperties }) => {
              const originIndex = resultsRender[index].index;
              if (
                resultsRender[index].type === 'recording' &&
                resultsData?.recordings[originIndex]
              ) {
                return (
                  <RecordingCard
                    recordings={resultsData?.recordings[originIndex]}
                    setRowHeight={setRowHeight}
                    highlightText={highlightText}
                    index={index}
                    style={style}
                    icon={categoryList[1] && categoryList[1].icon}
                    handleSelectRecording={handleSelectRecording}
                    handleLockedRecording={handleLockedRecording}
                  />
                );
              } else if (
                resultsRender[index].type === 'course' &&
                resultsData?.courses[originIndex]
              ) {
                return (
                  <CourseCard
                    course={resultsData?.courses[originIndex]}
                    setRowHeight={setRowHeight}
                    index={index}
                    style={style}
                    icon={categoryList[2].icon}
                    highlightText={highlightText}
                    handleLockedCourse={handleLockedCourse}
                  />
                );
              } else if (
                resultsRender[index].type === 'board' &&
                resultsData?.boards[originIndex]
              ) {
                return (
                  <BoardCard
                    board={resultsData?.boards[originIndex]}
                    setRowHeight={setRowHeight}
                    index={index}
                    style={style}
                    icon={categoryList[3].icon}
                    highlightText={highlightText}
                  />
                );
              } else {
                return <></>;
              }
            }}
          </VariableSizeList>
        )}
      </div>
    </div>
  );
};

export default Search;
