import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);
am4core.options.autoDispose = true;

class TeamDatesTotalChart extends React.Component {
  constructor() {
    super();
    this.state = {
      circleRadiusPercentage: Math.min(
        window.innerWidth / 1920,
        window.innerHeight / 937
      )
    };
  }

  componentDidMount() {
    this.setState(
      {
        circleRadiusPercentage: Math.min(
          this.props.graphRef.current.offsetWidth / 1920,
          this.props.graphRef.current.offsetHeight / 937
        )
      },
      () => {
        this.initChart();
      }
    );
    if (this.props.processedDatesTotalData && this.props.dataDisplay) {
      this.initChart();
    }

    let previousResizeFunc =
      window.onresize !== null ? window.onresize : () => {};

    window.onresize = () => {
      previousResizeFunc();
      this.setState(
        {
          circleRadiusPercentage: Math.min(
            this.props.graphRef.current.offsetWidth / 1920,
            this.props.graphRef.current.offsetHeight / 937
          )
        },
        () => {
          this.initChart();
        }
      );
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.processedDatesTotalData !==
        prevProps.processedDatesTotalData &&
      this.props.processedDatesTotalData
    ) {
      if (!this.props.dataForDatesTotalChart && !this.chart) {
        this.initChart();
      } else if (
        this.props.dataForDatesTotalChart !== prevProps.dataForDatesTotalChart
      ) {
        this.updateGraph();
      } else {
        if (this.chart) {
          this.chart.dispose();
        }
        this.initChart();
      }
    }
  }

  initChart = () => {
    let chart = am4core.create(
      this.props.statDisplayId
        ? this.props.graph_name + '_' + this.props.statDisplayId
        : this.props.graph_name,
      am4charts.XYChart
    );
    this.chart = chart;
    let title = chart.titles.create();
    let newTitle = this.getTitle();
    //let field = this.props.dataDisplay.split("_").join(" ");
    let label = this.props.dataDisplay.split('_');
    label.splice(label.length - 1);
    label = label.join(' ');

    title.textAlign = 'middle';
    title.marginBottom = '2%';
    this.chart.data = this.props.processedDatesTotalData;
    chart.colors.list = [am4core.color('#091a33')];
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'first_name';
    if (this.props.statDisplayId) {
    } else {
    }

    categoryAxis.renderer.grid.template.opacity = 0;
    // categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color('#091a33');
    // categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.dx = -13;
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.minWidth = 120;
    // categoryAxis.renderer.cellStartLocation = 0.1;
    // categoryAxis.renderer.cellEndLocation = 0.9;
    // categoryAxis.renderer.cellStartLocation = 0.2;
    // categoryAxis.renderer.cellEndLocation = 0.8;
    if (this.props.processedDatesTotalData) {
      if (this.props.processedDatesTotalData.length === 1) {
        categoryAxis.renderer.cellStartLocation = 0.4;
        categoryAxis.renderer.cellEndLocation = 0.5;
      } else if (this.props.processedDatesTotalData.length === 2) {
        categoryAxis.renderer.cellStartLocation = 0.4;
        categoryAxis.renderer.cellEndLocation = 0.6;
      } else if (this.props.processedDatesTotalData.length === 3) {
        categoryAxis.renderer.cellStartLocation = 0.35;
        categoryAxis.renderer.cellEndLocation = 0.65;
      } else if (this.props.processedDatesTotalData.length === 4) {
        categoryAxis.renderer.cellStartLocation = 0.32;
        categoryAxis.renderer.cellEndLocation = 0.67;
      } else if (this.props.processedDatesTotalData.length === 5) {
        categoryAxis.renderer.cellStartLocation = 0.25;
        categoryAxis.renderer.cellEndLocation = 0.75;
      } else {
        categoryAxis.renderer.cellStartLocation = 0.2;
        categoryAxis.renderer.cellEndLocation = 0.8;
      }
    }
    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.opacity = 0.9;
    valueAxis.renderer.grid.template.stroke = am4core.color('#091a33');
    // valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
    // valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    // valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.baseGrid.disabled = true;
    // valueAxis.renderer.minGridDistance = 40;
    valueAxis.rangeChangeEasing = am4core.ease.linear;
    valueAxis.rangeChangeDuration = 1000;
    if (this.props.statDisplayId) {
      categoryAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 12
          : 12 * Math.cbrt(this.state.circleRadiusPercentage);
      title.text = `[bold font-size: 11 #091a33]${label} - ${newTitle}`;
      valueAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 20
          : 20 * Math.cbrt(this.state.circleRadiusPercentage);
    } else {
      valueAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 20
          : 20 * Math.cbrt(this.state.circleRadiusPercentage);
      categoryAxis.renderer.labels.template.fontSize =
        this.props.graph_name.includes('team-management')
          ? 20
          : 18 * Math.cbrt(this.state.circleRadiusPercentage);
      title.text = `[bold font-size: 17 #091a33]${label} - ${newTitle}`;
    }

    valueAxis.renderer.labels.template.fill = am4core.color('#091a33');
    // chart.events.on("ready", function(ev) {
    //   valueAxis.min = valueAxis.minZoomed;
    //   valueAxis.max = valueAxis.maxZoomed;
    // });
    this.creatSeries();
    categoryAxis.renderer.labels.template.adapter.add(
      'textOutput',
      function (text) {
        if (text) {
          return text.replace(/ \(.*/, '');
        }
      }
    );
  };

  modifyByLength = (value) => {
    return this.props.processedDatesTotalData.length > 8
      ? value * (8 / this.props.processedDatesTotalData.length)
      : value;
  };

  creatSeries = () => {
    let series = this.chart.series.push(new am4charts.ColumnSeries());
    this.series = series;
    series.columns.template.events.on(
      'hit',
      function (ev) {
        this.props.getClickedOnSeries(
          ev.target._virtualParent.dataFields,
          ev.target.dataItem._dataContext
        );
      },
      this
    );
    var hoverState = series.columns.template.states.create('hover');
    hoverState.properties.fill = am4core.color('#c0ecfc');
    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    let categoryAxis = this.chart.yAxes._values[0];
    series.dataFields.valueX = this.props.dataDisplay;
    // series.dataFields.valueX = field;
    series.dataFields.categoryY = 'first_name';
    categoryAxis.sortBySeries = series;
    var labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.fontSize =
      15 * Math.cbrt(this.state.circleRadiusPercentage);
    labelBullet.locationX = 0.5;
    labelBullet.label.text = '{valueX}';
    labelBullet.label.fill = am4core.color('#fff');

    labelBullet.label.fontWeight = 'bold';
    labelBullet.label.adapter.add('textOutput', function (text, target) {
      // Hide labels with 0 value
      if (target.dataItem && target.dataItem.valueX === 0) {
        return '';
      }
      return text;
    });

    this.addPortrait(series);
  };
  addPortrait = (series) => {
    var columnTemplate = series.columns.template;
    var bullet = columnTemplate.createChild(am4charts.CircleBullet);

    if (this.props.graph_name.includes('team-management')) {
      if (this.props.statDisplayId && this.props.processedDatesTotalData) {
        bullet.circle.radius =
          20 - this.props.processedDatesTotalData.length * 1.2 >= 9
            ? 20 - this.props.processedDatesTotalData.length * 1.2
            : 9;
        bullet.dx = 20 - this.props.processedDatesTotalData.length * 1.2;
      } else {
        if (this.props.processedDatesTotalData) {
          bullet.circle.radius =
            40 - this.props.processedDatesTotalData.length * 1.2;
        }
        bullet.dx = 16;
      }
    } else {
      bullet.dx = 16 * Math.cbrt(this.state.circleRadiusPercentage);
      bullet.circle.radius = 30 * Math.cbrt(this.state.circleRadiusPercentage);
    }

    if (this.props.processedDatesTotalData) {
      bullet.circle.radius = this.modifyByLength(bullet.circle.radius);
      bullet.dx = this.modifyByLength(bullet.dx);
    }

    bullet.valign = 'middle';
    bullet.align = 'right';
    bullet.isMeasured = true;
    bullet.interactionsEnabled = false;
    bullet.horizontalCenter = 'right';
    bullet.fill = am4core.color('#36597a');
    bullet.stroke = am4core.color('#36597a');

    series.zIndex = 100;
    series.mainContainer.mask = undefined;
    var outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add('radius', function (radius, target) {
      var circleBullet = target.parent;
      return circleBullet.circle.pixelRadius + 2;
    });
    var image = bullet.createChild(am4core.Image);
    // let bullets = bullet.createChild(am4charts.LabelBullet);
    // bullets.label.text = "HELOLOOLOLO"
    //var dataItem = series.tooltipDataItem;
    // image.width =  this.props.initData.length === 1 ? 100 : 60;
    // image.height =  this.props.initData.length === 1 ? 100: 60;
    image.width = 60 * Math.cbrt(this.state.circleRadiusPercentage);
    image.height = 60 * Math.cbrt(this.state.circleRadiusPercentage);

    if (this.props.processedDatesTotalData) {
      image.width = this.modifyByLength(image.width);
      image.height = this.modifyByLength(image.height);
    }

    image.horizontalCenter = 'middle';
    image.verticalCenter = 'middle';
    image.propertyFields.href = 'profile_pic_url';
    image.adapter.add('mask', function (mask, target) {
      var circleBullet = target.parent;
      return circleBullet.circle;
    });
  };

  getTitle = () => {
    let title = '';
    if (this.props.datesTotalDisplay === 'rolling_dates') {
      title += 'Rolling dates - ';
      title += this.props.rollingValue + ' ' + this.props.rollingUnit;
    } else if (this.props.datesTotalDisplay === 'previous_dates') {
      title +=
        this.props.radioOption.split('_')[0].charAt(0).toUpperCase() +
        this.props.radioOption.split('_')[0].slice(1) +
        ' ' +
        this.props.radioOption.split('_')[1];
    } else if (this.props.datesTotalDisplay === 'static_dates') {
      // title += "Static dates - ";
      if (this.props.staticStart) {
        title += this.props.staticStart + ' ~ ' + this.props.staticEnd;
      } else {
        title +=
          this.props.dates[this.props.dates.length - 1] +
          ' ~ ' +
          this.props.selectedDate;
      }
    } else if (this.props.datesTotalDisplay === 'current_dates') {
      title +=
        this.props.curRadioOption.split('_')[0].charAt(0).toUpperCase() +
        this.props.curRadioOption.split('_')[0].slice(1) +
        ' ' +
        this.props.curRadioOption.split('_')[1];
    }
    return title;
  };

  updateGraph = () => {
    if (this.chart) {
      let { date } = this.props.dataForDatesTotalChart;
      let newData = [...this.props.processedDatesTotalData];
      let oldData = this.chart.data;
      if (this.props.dates.includes(date)) {
        for (let data of newData) {
          for (let prevData of oldData) {
            if (data.first_name === prevData.first_name) {
              if (
                (data.user_id &&
                  prevData.user_id &&
                  data.user_id === prevData.user_id) ||
                (data.squad_id &&
                  prevData.squad_id &&
                  data.squad_id === prevData.squad_id) ||
                (data.team_id &&
                  prevData.team_id &&
                  data.team_id === prevData.team_id)
              ) {
                for (let [key, value] of Object.entries(data)) {
                  prevData[key] = value;
                }
              }
            }
          }
        }

        this.chart.invalidateRawData();
      }
    }
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {!this.props.processedDatesTotalData ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className='lds-spinner'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : null}
        <div
          id={
            this.props.statDisplayId
              ? this.props.graph_name + '_' + this.props.statDisplayId
              : this.props.graph_name
          }
          style={{ width: '100%', height: '100%' }}
        ></div>
      </div>
    );
  }
}

export default TeamDatesTotalChart;
