/*
  Simply returns relevant information about the browser
*/

var BrowserInfo = {};
BrowserInfo.isMobile = () => {
  // #problem Goes undefined by default on Firefox ???
  if (typeof window.orientation !== "undefined") {
    return true;
  } else return false;
};

BrowserInfo.browserName = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      +navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (+navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (+navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (+navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    +navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};
export default BrowserInfo;
