import DomainInfo from './DomainInfo';
import axios from 'axios';

//this class is used once the user is logged in, so basically any component nested under /
//It makes sure the user is logged in and redirects them if not.
//This also make it easy if we want to switch form axios to another httphandler

var httpHandler = {};

var handleError = function (reject, error) {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 426) {
      window.location.href = '/login';
    } else if (error.response.data) {
      console.error('error looks like: ', error.response.data);
      reject(error.response.data);
    }
  } else {
    reject('');
  }
};

httpHandler.get = function (path, params) {
  return new Promise(function (resolve, reject) {
    try {
      axios
        .get(DomainInfo.baseUrl + path, {
          params: params,
          headers: { AuthToken: localStorage.getItem('CWAuthToken') }
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          handleError(reject, error);
        });
    } catch (error) {
      console.error('error :(', error);
    }
  });
};

httpHandler.post = function (path, body) {
  return new Promise(function (resolve, reject) {
    axios
      .post(DomainInfo.baseUrl + path, body, {
        headers: { AuthToken: localStorage.getItem('CWAuthToken') }
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleError(reject, error);
      });
  });
};

httpHandler.postFile = function (path, body, params) {
  return new Promise(function (resolve, reject) {
    axios
      .post(DomainInfo.baseUrl + path, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          AuthToken: localStorage.getItem('CWAuthToken')
        },
        timeout: 0,
        params: params
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleError(reject, error);
      });
  });
};

httpHandler.patch = function (path, body) {
  return new Promise(function (resolve, reject) {
    axios
      .patch(DomainInfo.baseUrl + path, body, {
        headers: { AuthToken: localStorage.getItem('CWAuthToken') }
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleError(reject, error);
      });
  });
};

httpHandler.delete = function (path, params) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(DomainInfo.baseUrl + path, {
        data: params,
        headers: { AuthToken: localStorage.getItem('CWAuthToken') }
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        handleError(reject, error);
      });
  });
};

export default httpHandler;
