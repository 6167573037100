import React, { useContext } from 'react';
import { CartItemType } from './ShoppingCart';
import classes from './cartItem.module.scss';
import { FaTrashAlt } from 'react-icons/fa';
import ShoppingCartContext from '../../../../../contexts/ShoppingCartContext';

interface CartItemProp {
  item: CartItemType;
}

const CartItem = ({ item }: CartItemProp) => {
  const { removeFromCart } = useContext(ShoppingCartContext);

  const handleRemoveItem = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: CartItemType
  ) => {
    e.stopPropagation();
    removeFromCart(item);
  };

  return (
    <div className={classes['cart-item']}>
      <div className={classes['item__image']}>
        {item.imageUrl ? (
          <img src={item.imageUrl} alt='productImage'></img>
        ) : (
          <div></div>
        )}
      </div>
      <div className={classes['item__body']}>
        <span className={classes['body__item-name']}>{item.name}</span>
        <div
          className={classes['item__remove']}
          onClick={(e) => handleRemoveItem(e, item)}
        >
          <FaTrashAlt size={8} /> Remove Item
        </div>
      </div>
      <div className={classes['item__price']}>${item.price}</div>
    </div>
  );
};

export default CartItem;
