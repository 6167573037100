import React from 'react';
import httpHandler from '../helpers/httpHandler';
import moment from 'moment-timezone';
import { message } from 'antd';
const ShoppingCartContext = React.createContext();

class ShoppingProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      shopping_cart: {
        itemList: [],
        totalPrice: 0
      }
    };
  }

  componentDidMount = () => {
    this.populateItemList();
    this.modifyProductsWithDiscounts();
  };

  populateItemList = () => {
    let tempItemList = [];
    let tempTotalPrice = 0;
    let curDate = new Date().getTime();

    let temp = window.localStorage.getItem('cwProduct')
      ? JSON.parse(window.localStorage.getItem('cwProduct'))
      : [];
    if (temp.length > 1) {
      temp.sort(
        (a, b) => new Date(b['addToCartDate']) - new Date(a['addToCartDate'])
      );
    }
    for (let i = 0; i < temp.length; i++) {
      let item = temp[i];
      if (
        curDate - new Date(item['addToCartDate']).getTime() <
        60 * 60 * 24 * 1000
      ) {
        tempItemList.push(item);
      }
    }
    for (let i = 0; i < tempItemList.length; i++) {
      let item = tempItemList[i];
      tempTotalPrice += item.price * 100;
    }

    let shopping_cart = this.state.shopping_cart;
    shopping_cart.itemList = tempItemList;
    shopping_cart.totalPrice = tempTotalPrice;

    window.localStorage.setItem('cwProduct', JSON.stringify(tempItemList));
  };

  addToCart = async (curItem) => {
    curItem['addToCartDate'] = new Date();
    let shopping_cart = this.state.shopping_cart;
    let { itemList, totalPrice } = shopping_cart;
    try {
      if (!window.localStorage.getItem('cwProduct')) {
        let result = await this.getProductDiscount(curItem);
        window.localStorage.setItem('cwProduct', JSON.stringify([result]));
        itemList.push(result);
        totalPrice += result.price * 100;
        shopping_cart.itemList = itemList;
        shopping_cart.totalPrice = totalPrice;
        this.setState({ shopping_cart: shopping_cart });

        await this.modifyProductsWithDiscounts();
      } else {
        let products = JSON.parse(window.localStorage.getItem('cwProduct'));

        let found = false;
        for (let i = 0; i < products.length; i++) {
          if (products[i].id === curItem.id) {
            found = true;
            break;
          }
        }
        if (!found) {
          //get discount
          let result = await this.getProductDiscount(curItem);
          await products.push(result);
          await window.localStorage.setItem(
            'cwProduct',
            JSON.stringify(products)
          );
          await itemList.unshift(result);
          totalPrice += result.price * 100;
          shopping_cart.totalPrice = totalPrice;
          shopping_cart.itemList = itemList;
          this.setState({ shopping_cart: shopping_cart });

          await this.modifyProductsWithDiscounts();
        }
      }
    } catch (error) {
      console.error(error);
      message.error(error);
    }
  };

  removeFromCart = (curItem) => {
    let shopping_cart = this.state.shopping_cart;
    let { itemList, totalPrice } = shopping_cart;

    let newList = [];
    for (let i = 0; i < itemList.length; i++) {
      let item = itemList[i];
      if (item.id !== curItem.id) {
        newList.push(item);
      }
    }
    totalPrice -= curItem.price * 100;
    window.localStorage.setItem('cwProduct', JSON.stringify(newList));
    shopping_cart.itemList = newList;
    shopping_cart.totalPrice = totalPrice;
    this.setState({ shopping_cart: shopping_cart });
  };

  printCart = () => {};

  clearCart = () => {
    let shopping_cart = this.state.shopping_cart;
    shopping_cart.itemList = [];
    shopping_cart.totalPrice = 0;
    this.setState({ shopping_cart: shopping_cart });
  };

  modifyProductsWithDiscounts = async () => {
    let myProducts = [];
    let promiseArr = [];

    this.state.shopping_cart.itemList.forEach((product) => {
      promiseArr.push(this.getProductDiscount(product));
    });

    myProducts = await Promise.all(promiseArr);

    let newTotalPrice = 0;

    myProducts.forEach((element) => {
      const product = element;

      if (product.discount) {
        if (product.discount.is_discount_percentage) {
          const parsedDiscountAmount =
            (product.discount.discount_amount / 100) * product.price;

          newTotalPrice += product.price - parsedDiscountAmount;
        } else {
          const parsedDiscountAmount = product.discount.discount_amount;
          newTotalPrice += product.price - parsedDiscountAmount;
        }
      } else {
        newTotalPrice += product.price;
      }
    });
    this.setState({
      shopping_cart: {
        itemList: myProducts,
        //Multiplied to 100 because it's divided by 100 when displayed. Division is from previous code
        totalPrice: newTotalPrice * 100
      }
    });
  };

  getProductDiscount = (product) => {
    return new Promise((resolve, reject) => {
      httpHandler
        .get(
          `/api/commerce/discounts/getProductDiscountByProductId/${product.id}`
        )
        .then((res) => {
          let allDiscounts = res.filter(
            //The discount is applicable if is_active and (no end_date or the current date is between the given dates)
            (discount) =>
              discount.is_active === 1 &&
              (!discount.use_date ||
                (moment(new Date(discount.start_date)).isSameOrBefore(
                  moment(new Date())
                ) &&
                  moment(new Date(discount.end_date)).isSameOrAfter(
                    moment(new Date())
                  ) &&
                  discount.use_date))
          );

          let fixedPriceDiscount = {
            discount_amount: 0,
            is_static: true
          };
          let percentagePriceDiscount = {
            discount_amount: 0,
            is_static: true
          };

          allDiscounts.forEach((element) => {
            if (element.is_discount_percentage === 1) {
              if (
                percentagePriceDiscount.discount_amount <
                element.discount_amount
              ) {
                percentagePriceDiscount = element;
              }
            } else {
              if (
                fixedPriceDiscount.discount_amount < element.discount_amount
              ) {
                fixedPriceDiscount = element;
              }
            }
          });

          if (
            fixedPriceDiscount?.discount_amount <
            (percentagePriceDiscount.discount_amount / 100) * product.price
          ) {
            if (percentagePriceDiscount.is_static) {
              product.discount = null;
              resolve(product);
            } else {
              product.discount = percentagePriceDiscount;
              resolve(product);
            }
          } else {
            if (fixedPriceDiscount.is_static) {
              product.discount = null;
              resolve(product);
            } else {
              product.discount = fixedPriceDiscount;
              resolve(product);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          product.discount = null;
          resolve(product);
        });
    });
  };

  render() {
    return (
      <ShoppingCartContext.Provider
        value={{
          shopping_cart: this.state.shopping_cart,
          addToCart: this.addToCart,
          removeFromCart: this.removeFromCart,
          printCart: this.state.printCart,
          clearCart: this.state.clearCart
        }}
      >
        {this.props.children}
      </ShoppingCartContext.Provider>
    );
  }
}

export default ShoppingCartContext;
export { ShoppingProvider };
